import React, { useEffect, useState, useCallback } from "react";
import Content from "../../../components/containers/Content";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {
  REPORT_PRODUCT_REPORT_PAGE_LOADED,
  REDIRECT,
} from "../../../constants/actionTypes";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import UtcDatePicker from "../../../components/common/UtcDatePicker";
import Button from "../../../components/common/Button";
import services from "../../../api/index";
import base64 from "base-64";
import { Endpoints } from "../../../constants/endpoints";

ChartJS.register(ArcElement, Tooltip, Legend, Title);

const mapStateToProps = (state) => {
  return {
    reportProductResult: state.report.reportProductResult,
    pageSize: state.common.pageSize,
  };
};
let timer = null;
const options = (direction) => {
  return {
    cutout: "50%",
    animation: false,
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      },
    },
    plugins: {
      legend: {
        labels: {
          padding: 5,
        },
        display: true,
        position: direction ?? "left",
        align: "center",
      },

      datalabels: {
        color: "#fff",
        font: {
          weight: "bold",
          size: 17,
        },
        formatter: (value) => {
          return value;
        },
      },
    },
  };
};

const mapDispatchToProps = (dispatch) => ({
  PageOnLoad: (payload) => {
    dispatch({ type: REPORT_PRODUCT_REPORT_PAGE_LOADED, payload });
  },
  onRedirect: (redirectTo) => dispatch({ type: REDIRECT, redirectTo }),
});

const fixTimezoneOffset = (date, isUTC = false) => {
  if (!date) return "";
  date = new Date(date);
  if (isUTC) {
    return {
      utc: new Date(date.getTime()).toJSON(),
      date: new Date(date.getTime() + date.getTimezoneOffset() * 60000),
    };
  } else {
    return {
      utc: new Date(date.getTime() - date.getTimezoneOffset() * 60000).toJSON(),
      date,
    };
  }
};

function ProductReport(props) {
  const [minDate, setMinDate] = useState(
    props.match.params.filter
      ? fixTimezoneOffset(
          JSON.parse(base64.decode(props.match.params.filter)).minDate.date
        )
      : undefined
  );
  const [maxDate, setMaxDate] = useState(
    props.match.params.filter
      ? fixTimezoneOffset(
          JSON.parse(base64.decode(props.match.params.filter)).maxDate.date
        )
      : undefined
  );
  const [products, setProducts] = useState(undefined);
  const [productName, setProductName] = useState(
    props.match.params.filter
      ? JSON.parse(base64.decode(props.match.params.filter)).productName
      : undefined
  );
  const [productSku, setProductSku] = useState(
    props.match.params.filter
      ? JSON.parse(base64.decode(props.match.params.filter)).productSku
      : undefined
  );
  useEffect(() => {
    document.title = "Product Report";
  }, []);

  const GetProductReport = useCallback(() => {
    if (
      minDate === undefined ||
      maxDate === undefined ||
      productSku === undefined
    )
      return;

    let filter = {
      minDate: minDate,
      maxDate: maxDate,
      productSku: productSku,
      productName: productName,
    };
    let url = Endpoints.Management.ProductReport.url.replace(
      ":filter?",
      base64.encode(JSON.stringify(filter))
    );

    props.onRedirect(url);

    services.report
      .GetStatusCountByProductSku(productSku, minDate.utc, maxDate.utc)
      .then((res) => {
        props.PageOnLoad(res);
      })
      .catch((err) => {
        if (err.response?.data?.message)
          toast.error(<div>{err.response?.data.message}</div>);
        return undefined;
      });
  }, [minDate, maxDate, productSku, productName, props]);

  const onFilter = (value) => {
    waitTimeOut(() => {
      getProducts({
        keywords: value,
      });
    });
  };

  useEffect(() => {
    props.PageOnLoad(undefined);

    getProducts();
    GetProductReport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const waitTimeOut = (callback) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      callback();
    }, 1000);
  };

  const handleChange = (item) => {
    props.PageOnLoad(undefined);
    setProductName(item.Name);
    setProductSku(item.Sku);
  };

  const getProducts = (params) => {
    const {
      keywords,
      name,
      sku,
      mSku,
      validTo,
      validFrom,
      releaseDateFrom,
      releaseDateTo,
      publisherIds,
      providerIds,
      store,
      onlyActivePromotions,
      sortBy,
      pageIndex = 0,
      pageSize = props.pageSize,
    } = params || {};
    Promise.all([
      services.product.getAllProducts(
        keywords,
        name,
        sku,
        mSku,
        validTo,
        validFrom,
        releaseDateFrom,
        releaseDateTo,
        publisherIds,
        providerIds,
        store,
        onlyActivePromotions,
        sortBy,
        pageIndex,
        pageSize
      ),
    ])
      .then((res) => {
        setProducts(res[0]);
        return res;
      })
      .catch((err) => {
        if (err.response?.data?.message)
          toast.error(<div>{err.response?.data.message}</div>);
        return undefined;
      });
  };
  return (
    <Content pageTitle="Product Report">
      <div className="report-box-container">
        <div className="d-flex justify-content-center mt-5">
          <div className="d-flex flex-column ">
            <div className="w-100">
              <div className=" filter-product">
                <div className="btn-group w-100">
                  <button
                    className="btn btn-transparent shadow-none dropdown-toggle mr-2 dropdown-toggle-split mb-2"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    data-bs-auto-close="outside"
                  >
                    <span>
                      {productName === undefined ? "Select Game" : productName}
                    </span>
                  </button>
                  <div className="dropdown-menu w-100 border-0">
                    <div className="input-group flex-nowrap align-items-center search px-3">
                      <input
                        onChange={(ev) => {
                          onFilter(ev.target.value);
                        }}
                        defaultValue={productName}
                        type="text"
                        className="form-control bg-white border-0 rounded-0 shadow-none "
                        placeholder="Search"
                        aria-describedby="addon-wrapping"
                      />
                    </div>
                    <div className="allocation-select-product-filter-list p-2">
                      {products?.Items.map((item, key) => {
                        return (
                          <div className="form-check" key={key}>
                            <input
                              className="form-check-input"
                              name="product"
                              type="radio"
                              checked={item.Sku === productSku}
                              value={item.Name}
                              id={"item" + key}
                              onChange={() => handleChange(item)}
                            />
                            <label
                              className="form-check-label fw-filter"
                              htmlFor={"item" + key}
                            >
                              {item.Name}
                            </label>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex flex-column ">
              <div className="d-flex flex-row justify-content-center">
                <UtcDatePicker
                  maxDate={maxDate?.date}
                  minDate={fixTimezoneOffset(new Date(2020, 1, 1), true).date}
                  selected={minDate?.date}
                  timeIntervals={30}
                  onChange={(date) => {
                    if (
                      maxDate?.date > date?.date ||
                      minDate === undefined ||
                      maxDate === undefined
                    )
                      setMinDate(date);
                    else {
                      setMinDate(
                        fixTimezoneOffset(
                          new Date(minDate?.date).getTime() - 1000 * 60 * 30
                        )
                      );
                    }
                  }}
                  placeholderText="Start Date"
                />
                <UtcDatePicker
                  disabled={minDate === undefined}
                  disableTimeFilter
                  minDate={minDate?.date}
                  selected={maxDate?.date}
                  timeIntervals={30}
                  onChange={(date) => {
                    if (minDate?.date < date?.date) setMaxDate(date);
                    else {
                      setMaxDate(
                        fixTimezoneOffset(
                          new Date(minDate?.date).getTime() + 1000 * 60 * 30
                        )
                      );
                    }
                  }}
                  placeholderText="End Date"
                />
              </div>
              <div className="d-flex justify-content-end mt-2">
                {(minDate !== undefined ||
                  maxDate !== undefined ||
                  productName !== undefined ||
                  productSku !== undefined) && (
                  <div className="filter-clear filter-clear-desktop">
                    <div>
                      <div className="btn-group pe-2 pt-3 rounded-1 align-items-center">
                        <a
                          type="button"
                          className="button text px-2"
                          onClick={() => {
                            setMinDate(undefined);
                            setMaxDate(undefined);
                            setProductName(undefined);
                            setProductSku(undefined);
                            props.PageOnLoad(undefined);
                          }}
                        >
                          <span className="mx-1">Clear All Filters</span>
                        </a>
                      </div>
                    </div>
                  </div>
                )}

                <Button
                  disabled={
                    minDate === undefined ||
                    maxDate === undefined ||
                    productSku === undefined
                  }
                  className="confirm-button px-5"
                  label="Filter"
                  bindEvent={() => {
                    if (
                      minDate !== undefined &&
                      maxDate !== undefined &&
                      productSku !== undefined
                    )
                      GetProductReport();
                  }}
                ></Button>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-xxl-6 mb-4">
          <div className="row">
            <div className="col-12 col-xl-12 mb-4">
              <div className="row">
                <div className="col-md-12 mb-3">
                  <div className="d-flex flex-column justify-content-center "></div>
                </div>
                <div className="col-md-12 mb-3">
                  <div className="d-flex flex-column justify-content-center"></div>
                </div>
                <div className="col-md-12 mb-3 justify-content-end"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center flex-lg-row flex-column charts-padding">
          {minDate !== undefined &&
            maxDate !== undefined &&
            productSku !== undefined &&
            props.reportProductResult?.ProductStatusCount?.length > 0 && (
              <div className="canvas-wrapper">
                <h6 className="title">Order Report</h6>
                <Doughnut
                  plugins={[ChartDataLabels]}
                  data={{
                    labels: props.reportProductResult?.ProductStatusCount.map(
                      (item) => item.Status
                    ),
                    datasets: [
                      {
                        label: "# of Votes",
                        data: props.reportProductResult?.ProductStatusCount.map(
                          (item) => item.Count
                        ),
                        backgroundColor: [
                          "rgba(255, 99, 132, 1)",
                          "rgba(54, 162, 235, 1)",
                          "rgba(255, 206, 86, 1)",
                          "rgba(75, 192, 192, 1)",
                          "rgba(153, 102, 255, 1)",
                          "rgba(255, 159, 64, 1)",
                        ],
                        borderWidth: 0,
                      },
                    ],
                  }}
                  options={options("right")}
                  redraw
                />
              </div>
            )}

          {minDate !== undefined &&
            maxDate !== undefined &&
            productSku !== undefined &&
            props.reportProductResult?.OrderStatusCount.OrderStatuses.length >
              0 && (
              <div className="canvas-wrapper">
                <h6 className="title">Product Report</h6>
                <Doughnut
                  plugins={[ChartDataLabels]}
                  data={{
                    labels:
                      props.reportProductResult?.OrderStatusCount.OrderStatuses.map(
                        (item) => item.Status
                      ),
                    datasets: [
                      {
                        label: "# of Votes",
                        data: props.reportProductResult?.OrderStatusCount.OrderStatuses.map(
                          (item) => item.Count
                        ),
                        backgroundColor: [
                          "rgba(255, 99, 132, 1)",
                          "rgba(54, 162, 235, 1)",
                          "rgba(255, 206, 86, 1)",
                          "rgba(75, 192, 192, 1)",
                          "rgba(153, 102, 255, 1)",
                          "rgba(255, 159, 64, 1)",
                        ],
                        borderWidth: 0,
                      },
                    ],
                  }}
                  options={options()}
                  redraw
                />
              </div>
            )}

          {props.reportProductResult != undefined &&
            props.reportProductResult.ProductStatusCount.length === 0 && (
              <div className="alert alert-danger" role="alert">
                <strong>{productName}</strong> report not found.
              </div>
            )}
        </div>
        {props.reportProductResult?.OrderStatusCount.InventoryCount && (
          <div className="d-flex mb-1 flex-column align-items-center justify-content-center mt-3">
            <div className="inventory-title">
              <h4>Inventories</h4>
            </div>
            <div className="inventory-item">
              <p>
                Remaining Count:
                {" " +
                  props.reportProductResult?.OrderStatusCount.InventoryCount
                    .RemainingCount}
              </p>
            </div>
            <div className="inventory-item">
              <p>
                Total Count:
                {" " +
                  props.reportProductResult?.OrderStatusCount.InventoryCount
                    .Count}
              </p>
            </div>
          </div>
        )}
      </div>
    </Content>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductReport);
