import { applyMiddleware, createStore } from "redux";
import { createLogger } from "redux-logger";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import { routerMiddleware } from "react-router-redux";
import { createBrowserHistory } from "history";
import {
  localStorageMiddleware,
  promiseMiddleware,
  redirectMiddleware,
} from "./middleware";
import reducer from "./reducer";
import thunk from "redux-thunk";

export const history = createBrowserHistory();

// Build the middleware for intercepting and dispatching navigation actions
const myRouterMiddleware = routerMiddleware(history);

const getMiddleware = () => {
  if (process.env.NODE_ENV === "production") {
    return applyMiddleware(
      myRouterMiddleware,
      promiseMiddleware,
      redirectMiddleware,
      localStorageMiddleware,
      thunk
    );
  } else {
    // Enable additional logging in non-production environments.
    return applyMiddleware(
      myRouterMiddleware,
      promiseMiddleware,
      redirectMiddleware,
      localStorageMiddleware,
      thunk,
      createLogger()
    );
  }
};

export const store = createStore(reducer, composeWithDevTools(getMiddleware()));
