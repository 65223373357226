import { OrderStatus } from "../../../../../../../constants/defaults";

import api from "../../../../../../../api";

const resume = ({ Id, Status }) => ({
  isEnabled: [OrderStatus.Paused, OrderStatus.Failed].includes(Status),
  modal: {
    title: "Resume Order",
    accessibility: {
      submit: {
        onClick: () => api.orders.reprocess(Id),
        successMessage: "Order is resumed, successfully",
        title: "Resume",
        theme: "success",
      },
    },
    trigger: {
      title: "Resume",
      theme: "success",
    },
    description: "Order will be resumed.",
  },
});

export default resume;
