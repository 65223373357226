// eslint-disable-next-line import/prefer-default-export
export const getSorting = (sortBy, sortby) => {
  // If the sorted value is valid
  if (sortBy !== undefined && sortby !== null && sortby !== undefined) {
    // If the sorted value is more than one and a descent or ascent is made on it
    // If the changed value is wanted to be descent
    if (sortby.split(",").includes(sortBy)) {
      return sortby.replace(sortBy, `-${sortBy}`);
    }
    // If the changed value is wanted to be ascent
    else if (sortby.split(",").includes(`-${sortBy}`)) {
      return sortby.replace(`-${sortBy}`, sortBy);
    }
    // If the sorted value is more than one and descent or ascent is performed on another variable
    else {
      return `${sortby},${sortBy}`;
    }
  }
  // If the sorted value is not valid
  else {
    return sortBy;
  }
};

let timer = null;
export const waitTimeOut = (callback, milisecond = 1000) => {
  clearTimeout(timer);
  timer = setTimeout(() => {
    callback();
  }, milisecond);
};
