const useBlending = ({ actualBlending, order, promotion }) => {
  const totalQuantity = order?.Quantity;
  const orderBlending = order?.Blending;
  const promotionBlending = promotion?.Countries;
  const promotionPrices =
    promotion?.Product?.Prices?.reduce((prev, curr) => {
      if (!curr.Countries?.length) {
        return prev;
      }

      const temp = { ...prev };

      curr.Countries.forEach((e) => {
        temp[e] = {
          Cost: curr.Cost,
          Price: curr.Price,
        };
      });

      return temp;
    }, {}) || {};

  const blending = {};

  /* order blending */

  orderBlending?.forEach((e) => {
    blending[e.Code] = {
      ...e,
      Percentage: ((e.Quantity / totalQuantity) * 100)?.toFixed?.(2) || 0,
      Promotion: {},
      Actual: {},
      Currency: "€",
    };
  });

  /* promotion blending */

  promotionBlending?.forEach((e) => {
    if (!blending[e.Code]) {
      blending[e.Code] = {
        Code: e.Code,
        Quantity: 0,
        Percentage: 0,
        Currency: "€",
        Actual: {},
      };
    }

    if (!blending[e.Code].Promotion) {
      blending[e.Code].Promotion = {
        Percentage: null,
      };
    }

    blending[e.Code].Promotion.Percentage = e.Weight;
  });

  /* actual blending */

  actualBlending?.forEach((e) => {
    if (!blending[e.CountryCode]) {
      blending[e.CountryCode] = {
        Code: e.CountryCode,
        Quantity: 0,
        Percentage: 0,
        Currency: "€",
        Promotion: {},
      };
    }

    if (!blending[e.CountryCode].Actual) {
      blending[e.CountryCode].Actual = {
        Percentage: null,
        Cost: null,
        Count: null,
        TotalCost: null,
      };
    }

    blending[e.CountryCode].Actual.Percentage = e.ActualPercentage;
    blending[e.CountryCode].Actual.Cost = Math.max(
      e.ActualTotalCost / e.ActualCount,
      0
    ).toFixed(2);
    blending[e.CountryCode].Actual.Count = e.ActualCount;
    blending[e.CountryCode].Actual.TotalCost = e.ActualTotalCost;
  });

  /* pricing */
  const sum = {
    Cost: 0,
    PromotionCost: 0,
    ActualCost: 0,
  };

  Object.keys(blending || {}).forEach((e) => {
    if (!promotionPrices[e]) return;

    try {
      blending[e].Cost = promotionPrices[e].Cost?.toFixed?.(2);
      blending[e].Promotion.Cost = promotionPrices[e].Cost?.toFixed?.(2);

      sum.Cost += blending[e].Cost * blending[e].Quantity;
      sum.PromotionCost +=
        blending[e].Promotion.Cost * blending[e].Promotion.Percentage;
      sum.ActualCost += blending[e].Actual.Cost * blending[e].Actual.Count;
    } catch (error) {
      console.log(e, error, promotionPrices[e], blending[e]);
    }
  });

  const data = Object.values(blending || {});

  /* total */

  const total = data?.reduce(
    (prev, curr) => {
      const Quantity = (prev.Quantity || 0) + (curr.Quantity || 0);

      return {
        Quantity,
        Percentage: (Quantity / (order.Quantity || 1)) * 100,
        AvgCost: 0,
        Promotion: {
          AvgCost: 0,
          Percentage:
            (prev.Promotion?.Percentage || 0) +
            (curr.Promotion?.Percentage || 0),
        },
        Actual: {
          AvgCost: 0,
          Percentage:
            (prev.Actual?.Percentage || 0) + (curr.Actual?.Percentage || 0),
          Count: (prev.Actual?.Count || 0) + (curr.Actual?.Count || 0),
          TotalCost:
            (prev.Actual?.TotalCost || 0) + (curr.Actual?.TotalCost || 0),
        },
        Currency: "€",
      };
    },
    {
      AvgCost: 0,
      Promotion: {
        AvgCost: 0,
      },
      Actual: {
        AvgCost: 0,
      },
    }
  ) || {
    AvgCost: 0,
    Promotion: {
      AvgCost: 0,
    },
    Actual: {
      AvgCost: 0,
    },
  };

  total.AvgCost = (sum.Cost / totalQuantity)?.toFixed?.(2);
  total.Promotion.AvgCost = (sum.PromotionCost / 100)?.toFixed?.(2);
  total.Actual.AvgCost = (
    sum.ActualCost / (total.Actual?.Count || 1)
  )?.toFixed?.(2);

  return {
    data,
    blending,
    total,
  };
};

export default useBlending;
