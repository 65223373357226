import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

import { OrderPaidStatus } from "../../../../constants/defaults";

const OrderPaymentStatus = ({ classname, data }) => {
  const color =
    {
      [OrderPaidStatus.Paid]: "yellowish-green",
      [OrderPaidStatus.PartyPaid]: "mango",
      [OrderPaidStatus.UnPaid]: "carnation",
    }[data.PaidStatus] || "cloudy-blue";

  return (
    <span className={classNames("m-0 text-no-wrap", classname, color)}>
      {data.PaidStatus}
    </span>
  );
};

const propStyles = {};

const styles = {};

OrderPaymentStatus.defaultProps = {};

OrderPaymentStatus.propTypes = {};

export default OrderPaymentStatus;
