export default (req) => ({
  getRegions: () => req.get(`/MerkurRegion/GetAll`),
  getFilters: (Keywords, PageIndex = 0, PageSize = 10) =>
    req.get(`/MerkurRegion/GetFilters`, {
      params: {
        Keywords,
        PageIndex,
        PageSize,
      },
    }),
});
