import React, { useState } from "react";
import Button from "../../../../../components/common/Button";
import NumberInput from "../../../../../components/common/NumberInput";
import { connect } from "react-redux";
import {
  ORDER_LIST,
  OFFER_DETAILS,
  SELECT_OFFER_ALL_MY_OFFERS,
  CHANGE_ALL_MY_OFFERS_DETAILS,
} from "../../../../../constants/actionTypes";
import { OrderStatus } from "../../../../../constants/defaults";
import { getLatestOrder } from "../../../../../functions/orderValues";
import NumberFormatter from "../../../../../components/common/NumberFormatter";
import { Icons } from "../../../../../constants/icons";
import CheckPermission, {
  StaticPermission,
} from "../../../../../constants/permission";

const mapStateToProps = (state) => {
  return { order: state.order, mypermission: state.permission.mypermission };
};

const mapDispatchToProps = (dispatch) => ({
  onChangeCustomPrice: (value) =>
    dispatch({ type: OFFER_DETAILS, key: "customPrice", value }),
  offerListOnLoad: (payload) => dispatch({ type: ORDER_LIST, payload }),
  onSelectOffer: (offer) =>
    dispatch({ type: SELECT_OFFER_ALL_MY_OFFERS, offer }),
  onChangeDemandQuantity: (value) =>
    dispatch({
      type: CHANGE_ALL_MY_OFFERS_DETAILS,
      key: "parentDemandQuantity",
      value,
    }),
  onChangeDemandPrice: (value) =>
    dispatch({
      type: CHANGE_ALL_MY_OFFERS_DETAILS,
      key: "parentDemandPrice",
      value,
    }),
});

const OrderModalLeftSide = (props) => {
  const changeCustomPrice = (ev) =>
    props.onChangeCustomPrice(ev.target.checked);
  const [buttonDisable, setButtonDisable] = useState(false);

  const latestOrder = getLatestOrder(
    props.order.selected_all_my_offer?.History,
    false
  );

  const { Status, Product, Id } = props.order.selected_all_my_offer;
  const { customPrice, parentDemandQuantity, parentDemandPrice } = props.order;

  const isDemandPrice =
    latestOrder?.OfferPrice === undefined ||
    latestOrder?.OfferPrice === null ||
    latestOrder?.OfferPrice === "";
  const isDemandQuantity =
    latestOrder?.OfferQuantity === undefined ||
    latestOrder?.OfferQuantity === null ||
    latestOrder?.OfferQuantity === "";

  return (
    <div className="modal-left-side">
      <div className="go-back">
        <img src={Icons.goBackIcon} />
        <Button
          className="go-back-button"
          bindEvent={() => props.handleClose()}
          label="Go back"
        />
      </div>
      <div className="price">
        <label>Salesteam offered:</label>
        <div className="total">
          {isDemandPrice || isDemandQuantity ? (
            <div className="no-demand-label">
              <label>There's no demand yet.</label>
            </div>
          ) : (
            <NumberFormatter
              offerPrice={latestOrder?.OfferPrice}
              offerQuantity={latestOrder?.OfferQuantity}
              supVisible
            />
          )}
        </div>
        <div className="multiply">
          {isDemandQuantity ? "" : latestOrder?.OfferQuantity + " x "}
          {isDemandPrice ? (
            ""
          ) : (
            <NumberFormatter offerPrice={latestOrder?.OfferPrice} supVisible />
          )}
        </div>
      </div>
      {Status !== OrderStatus.Withdraw && (
        <div className="d-flex flex-column py-3 fmw-100">
          <div className="d-inline rounded-1">
            <div className="d-flex">
              <input
                className="form-check-input form-check-input-partner"
                type="checkbox"
                onChange={(ev) => changeCustomPrice(ev)}
                defaultChecked={customPrice}
                id="props.customPrice"
              />
              <div className="d-inline fmw-100">
                <div className="d-flex">
                  <label
                    className={`${
                      customPrice
                        ? "modal-body-demand-text form-check-label m-0"
                        : "modal-body-demand-text-inactive form-check-label m-0"
                    } `}
                    htmlFor="props.customPrice"
                  >
                    Demand Special Price
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {customPrice || Status === OrderStatus.Withdraw ? (
        <>
          <div className="number-inputs">
            <div className="number-input-quantity">
              <NumberInput
                label="Quantity"
                value={parentDemandQuantity}
                onChange={(ev, val) => {
                  props.onChangeDemandQuantity(ev);
                  setButtonDisable(val);
                }}
                intNumber
                minValue={Product.VoiduPromotion.MinQuantity}
                maxValue={Product.VoiduPromotion.MaxQuantity}
                visible
              />
            </div>
            <div className="number-input-price">
              <NumberInput
                label="New Price"
                value={parentDemandPrice}
                onChange={(ev) => props.onChangeDemandPrice(ev)}
                visible
                showMinMax
              />
            </div>

            <div className="quantity-box">
              <div className="d-flex flex-row justify-content-end">
                <div className="d-flex flex-column ">
                  <span className="quantity-text">QTY Min </span>
                  <span className="quantity-text">Max </span>
                </div>
                <div className="d-flex flex-column align-items-end">
                  <span>{Product.VoiduPromotion.MinQuantity}</span>
                  <span>{Product.VoiduPromotion.MaxQuantity}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="min-max-description">
            {Product.VoiduPromotion.MinQuantity > parentDemandQuantity ? (
              <span>
                Please enter an amount that is larger than the min QTY
              </span>
            ) : null}
            {Product.VoiduPromotion.MaxQuantity < parentDemandQuantity ? (
              <span>Please enter an amount that is less than the max QTY</span>
            ) : null}
          </div>
          <div className="order-modal-buttons">
            <Button
              className="modal-resubmit-button"
              bindEvent={() =>
                props.resubmit(parentDemandQuantity, parentDemandPrice, Id)
              }
              label="Resubmit"
              disabled={buttonDisable}
              buttonIcon={Icons.thickIcon}
            />
          </div>
        </>
      ) : (
        <div className="order-modal-buttons">
          <Button
            className="modal-resubmit-button"
            bindEvent={() => props.confirm(Id)}
            label="Approve Order"
            visible={CheckPermission(
              StaticPermission.Order.Confirm,
              props.mypermission
            )}
            buttonIcon={Icons.thickIcon}
          />
          <Button
            className="modal-reject-button"
            visible={CheckPermission(
              StaticPermission.Order.Reject,
              props.mypermission
            )}
            bindEvent={() => props.reject(Id)}
            label="Reject Order"
            buttonIcon={Icons.crossIcon}
          />
        </div>
      )}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderModalLeftSide);
