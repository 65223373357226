import React from "react";

function PlatformFilter(props) {
  const { platformList, filter, onPlatformsClear, onPlatformDone } =
    props || {};

  return (
    <>
      {platformList === undefined && <></>}
      {platformList && (
        <div className="filter-price d-block">
          <div className="btn-group pe-2 pt-1 rounded-1 align-items-center">
            <button
              type="button"
              className="btn btn-transparent shadow-none dropdown-toggle mr-2 dropdown-toggle-split "
              data-bs-toggle="dropdown"
              aria-expanded="false"
              data-bs-auto-close="outside"
              id="Platforms"
              style={
                filter.Platforms.length > 0
                  ? { color: "#264EA7" }
                  : { color: "#A4B9E8" }
              }
            >
              <span className="mx-1 text">Platforms</span>
              <span className="badge">
                {filter.Platforms.length > 0 && filter.Platforms.length}
              </span>
            </button>

            <div className="dropdown-menu border-0 p-1 py-1">
              <div className="Platforms-filter-list px-3 py-4 pb-3">
                <div className="d-flex justify-content-between"></div>
                <div className="platform-list overflow-auto">
                  {platformList.map((item, index) => {
                    return (
                      <div className="form-check" key={index}>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="flexCheckboxDefaultPlatform"
                          value={item}
                          checked={filter?.Platforms.includes(item)}
                          id={`platform_${index}`}
                          onChange={(ev) => {
                            props.onPlatformChange(ev);
                          }}
                        />
                        <span
                          className="form-check-label fw-filter"
                          htmlFor={`platform_${index}`}
                        >
                          {item}
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="d-flex flex-row m-2">
                {filter.Platforms.length > 0 && (
                  <button
                    onClick={onPlatformsClear}
                    className="button filter-actions clear-all"
                  >
                    Clear
                  </button>
                )}
                <button
                  onClick={onPlatformDone}
                  className="button filter-actions done"
                >
                  Done
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default PlatformFilter;
