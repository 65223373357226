/* eslint-disable */

export const VLCHash = (r) => {
  function o(r, o) {
    return (r << o) | (r >>> (32 - o));
  }
  function e(r) {
    for (var o = "", e = 7; 0 <= e; e--)
      o += ((r >>> (4 * e)) & 15).toString(16);
    return o;
  }
  for (
    var t,
      a,
      h,
      n,
      C,
      c,
      f,
      d,
      u = Array(80),
      A = 1732584193,
      g = 4023233417,
      i = 2562383102,
      s = 271733878,
      p = 3285377520,
      S = (r = (function (r) {
        r = r.replace(/\r\n/g, "\n");
        for (var o = "", e = 0; e < r.length; e++) {
          var t = r.charCodeAt(e);
          t < 128
            ? (o += String.fromCharCode(t))
            : (127 < t && t < 2048
                ? (o += String.fromCharCode((t >> 6) | 192))
                : ((o += String.fromCharCode((t >> 12) | 224)),
                  (o += String.fromCharCode(((t >> 6) & 63) | 128))),
              (o += String.fromCharCode((63 & t) | 128)));
        }
        return o;
      })(r)).length,
      l = [],
      m = 0;
    m < S - 3;
    m += 4
  )
    (a =
      (r.charCodeAt(m) << 24) |
      (r.charCodeAt(m + 1) << 16) |
      (r.charCodeAt(m + 2) << 8) |
      r.charCodeAt(m + 3)),
      l.push(a);
  switch (S % 4) {
    case 0:
      m = 2147483648;
      break;
    case 1:
      m = (r.charCodeAt(S - 1) << 24) | 8388608;
      break;
    case 2:
      m = (r.charCodeAt(S - 2) << 24) | (r.charCodeAt(S - 1) << 16) | 32768;
      break;
    case 3:
      m =
        (r.charCodeAt(S - 3) << 24) |
        (r.charCodeAt(S - 2) << 16) |
        (r.charCodeAt(S - 1) << 8) |
        128;
  }
  for (l.push(m); l.length % 16 != 14; ) l.push(0);
  for (
    l.push(S >>> 29), l.push((S << 3) & 4294967295), t = 0;
    t < l.length;
    t += 16
  ) {
    for (m = 0; m < 16; m++) u[m] = l[t + m];
    for (m = 16; m <= 79; m++)
      u[m] = o(u[m - 3] ^ u[m - 8] ^ u[m - 14] ^ u[m - 16], 1);
    for (m = 0, h = A, n = g, C = i, c = s, f = p; m <= 19; m++)
      (d =
        (o(h, 5) + ((n & C) | (~n & c)) + f + u[m] + 1518500249) & 4294967295),
        (f = c),
        (c = C),
        (C = o(n, 30)),
        (n = h),
        (h = d);
    for (m = 20; m <= 39; m++)
      (d = (o(h, 5) + (n ^ C ^ c) + f + u[m] + 1859775393) & 4294967295),
        (f = c),
        (c = C),
        (C = o(n, 30)),
        (n = h),
        (h = d);
    for (m = 40; m <= 59; m++)
      (d =
        (o(h, 5) + ((n & C) | (n & c) | (C & c)) + f + u[m] + 2400959708) &
        4294967295),
        (f = c),
        (c = C),
        (C = o(n, 30)),
        (n = h),
        (h = d);
    for (m = 60; m <= 79; m++)
      (d = (o(h, 5) + (n ^ C ^ c) + f + u[m] + 3395469782) & 4294967295),
        (f = c),
        (c = C),
        (C = o(n, 30)),
        (n = h),
        (h = d);
    (A = (A + h) & 4294967295),
      (g = (g + n) & 4294967295),
      (i = (i + C) & 4294967295),
      (s = (s + c) & 4294967295),
      (p = (p + f) & 4294967295);
  }
  return (d = e(A) + e(g) + e(i) + e(s) + e(p))
    .toString()
    .replace(/^(.{4})(.{5})(.{5})(.*?)$/g, "V$1-L$2-K$3")
    .toUpperCase();
};
