import React from "react";

function StoreFilter(props) {
  const { stores, isStoreAvailable, filter, onStoreChecked, onStoreClear } =
    props;

  return (
    <>
      {isStoreAvailable && (
        <div className="filter-provider d-block">
          <div className="btn-group pe-2 pt-1 rounded-1 align-items-center">
            <button
              type="button"
              className="btn btn-transparent shadow-none dropdown-toggle mr-2 dropdown-toggle-split"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              data-bs-auto-close="outside"
              style={filter.Store ? { color: "#264EA7" } : { color: "#A4B9E8" }}
            >
              <span className="mx-1 text">Store</span>
              <span className="badge">{filter.Store && 1}</span>
            </button>
            <div className="dropdown-menu border-0 p-1 py-1">
              {stores?.map((item, index) => {
                let randomNumber = Math.floor(Math.random() * 1000);
                return (
                  <div
                    className="publisher-filter-list px-4 py-2 pb-2 "
                    key={index}
                  >
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        value={item}
                        id={`store_${index}_${randomNumber}`}
                        onChange={(ev) => onStoreChecked(ev)}
                        checked={filter.Store === item}
                      />
                      <label
                        className="form-check-label fw-filter"
                        htmlFor={`store_${index}_${randomNumber}`}
                      >
                        {item}
                      </label>
                    </div>
                  </div>
                );
              })}
              {filter.Store != undefined > 0 && (
                <div className="m-2">
                  <button
                    onClick={onStoreClear}
                    className="button filter-actions clear-all"
                  >
                    Clear
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default StoreFilter;
