import React from "react";

import NumberFormatter from "./common/NumberFormatter";

function OrderExtraData(props) {
  return (
    <div className="card border-0 shadow">
      <div className="card-body">
        <div className="table-responsive">
          <table className="table table-centered table-nowrap mb-0 rounded">
            <thead className="thead-light">
              <tr>
                <th className="border-0 rounded-start">Order Total</th>
                <th className="border-0">Order Count</th>
                <th className="border-0">Remaining Keys</th>
                <th className="border-0">Requested Keys</th>
                <th className="border-0">Actual Cost</th>
                <th className="border-0">Real Actual Cost</th>
                <th className="border-0 rounded-end">Profit</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border-0 fw-bold">
                  <span>
                    <NumberFormatter
                      offerPrice={props.data?.OrderTotal}
                      supVisible
                    />
                  </span>
                </td>
                <td className="border-0 fw-bold">
                  <span>{props.data?.OrderCount}</span>
                </td>
                <td className="border-0 fw-bold text-danger">
                  <span>{props.data?.RemainingKeyCount}</span>
                </td>
                <td className="border-0 fw-bold">
                  <span>{props.data?.RequestedKeyCount}</span>
                </td>
                <td className="border-0 fw-bold">
                  <span>
                    <NumberFormatter
                      offerPrice={props.data?.ActualCost}
                      supVisible
                    />
                  </span>
                </td>
                <td className="border-0 fw-bold">
                  <span>
                    <NumberFormatter
                      offerPrice={props.data?.RealActualCost}
                      supVisible
                    />
                  </span>
                </td>
                <td className="border-0 fw-bold text-danger">
                  <span>
                    <NumberFormatter
                      offerPrice={props.data?.Profit}
                      supVisible
                    />
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default OrderExtraData;
