import React from "react";
const NumberInput = (props) => {
  const handleBlur = (ev) => {
    let value = ev.target.value;
    const isTargetValue =
      value === undefined || value === null || Number.isNaN(value);

    let afterComa = value.replace(/^([-+] ?)?[0-9]+./g, "");
    let beforeComa = value.replace(/(.[0-9]+)?$/g, "");

    //unvalid number
    if (isTargetValue) {
      props.onChange(0, true);
    }

    if (value === "") {
      props.onChange("", true);
      return;
    }
    //float number
    else if (value.includes(".") && !props.intNumber) {
      if (value < props.minValue && props.minValue) {
        props.onChange(props.minValue ?? 0);
      }
      //float number equal zero
      if (parseInt(value) === 0) {
        props.onChange("0", false);
      }
      //float number less than 1
      if (value < 1 && parseFloat(value) !== 0) {
        props.onChange(
          beforeComa.replace(/\b0+/g, "0") + "." + afterComa.replace(/0+$/, "")
        );
      }
      //float number more than 1
      else if (value >= 1) {
        if (parseInt(afterComa) !== 0) {
          props.onChange(
            beforeComa.replace(/\b0+/g, "") + "." + afterComa.replace(/0+$/, "")
          );
        }
      }
    }
    //int number
    else if (value > (props.minValue ?? 0)) {
      value = parseInt(value).toFixed(1);
      let intBeforeComa = value.replace(/(.[0-9]+)?$/g, "");
      props.onChange(
        intBeforeComa.replace(/\b0+/g, "") + "",
        props.minValue <= value || value >= props.maxValue ? false : true
      );
    }

    if (value > props.maxValue && props.maxValue) {
      props.onChange(props.maxValue);
    }
    if (value < props.minValue && props.maxValue) {
      props.onChange(props.minValue);
    }
  };
  return props.visible ? (
    <div
      className={
        props.notFlex
          ? ""
          : `d-flex flex-row ${
              props.centerAlign
                ? "justify-content-center"
                : "justify-content-start"
            }`
      }
    >
      <div
        className={`d-flex ${props.notPadding ? "" : "py-2"} ${
          props.showMinMax ? "fmw-200" : "fmw-100"
        } ${props.percentage === undefined ? "flex-column" : ""}`}
      >
        <label>{props.label && props.label}</label>
        <input
          onBlur={(ev) => handleBlur(ev)}
          className={`number-input ${props.notPadding && " text-center"}`}
          placeholder={props.placeholder}
          key={props.key}
          name={props.name}
          type="number"
          onKeyDown={(ev) => ev.key === "e" && ev.preventDefault()}
          value={props.value ?? ""}
          onChange={(ev) => {
            let value = ev.target.value;

            if (value === "") {
              props.onChange("");
            } else if (value >= 0) {
              if (props.maxValue) {
                if (props.maxValue < value) {
                  props.onChange(props.maxValue, false);
                } else {
                  props.onChange(
                    props.intNumber &&
                      (value.includes(".") || value.includes(","))
                      ? value.replace(",", "").replace(/(.[0-9]+)?$/g, "")
                      : value,
                    false
                  );
                }
              } else if (value > 10000) {
                props.onChange(10000, false);
              } else {
                props.onChange(
                  props.intNumber &&
                    (value.includes(".") || value.includes(","))
                    ? value.replace(",", "").replace(/(.[0-9]+)?$/g, "")
                    : value
                );
              }
            }
          }}
        ></input>
        {props.percentage && (
          <div className="mb-2 number-input-percentage">%</div>
        )}
      </div>
    </div>
  ) : (
    <></>
  );
};

export default NumberInput;
