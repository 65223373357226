import React from "react";
import NumberFormat from "react-number-format";

export default function ProgressBar(props) {
  const { percentage, value, subVariants, tableProgressBar } = props;

  return (
    <>
      <div className="progress-wrapper">
        <h5 className="credit-limit-subtitle">
          Progress (Remaining: {percentage}% )
        </h5>
        <div className="progress">
          <div
            className="progress-bar bg-success"
            role="progressbar"
            style={{ width: `${percentage}%` }}
            aria-valuenow="50"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>
        {subVariants && (
          <div className="progress-variants pt-2">
            <div className="row pb-2">
              <div className="col-8 label h6">Remaining</div>
              <div className="col-4 value text-right h5">
                <NumberFormat
                  className="text-primary"
                  value={value.slice(1)}
                  thousandSeparator
                  displayType="text"
                ></NumberFormat>
              </div>
              <div className="col-8 label h6">{subVariants[0].label}</div>
              <div className="col-4 value text-right h5">
                <NumberFormat
                  className="text-primary"
                  value={subVariants[0].value}
                  thousandSeparator
                  displayType="text"
                ></NumberFormat>
              </div>
              <div className="divider"></div>
              <div className="col-8 label h6">{subVariants[1].label}</div>
              <div className="col-4 value text-right h5">
                <NumberFormat
                  className="text-primary"
                  value={subVariants[1].value}
                  thousandSeparator
                  displayType="text"
                ></NumberFormat>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
