import React, { useState, useEffect } from "react";
import Button from "../../../../../components/common/Button";
import NumberInput from "../../../../../components/common/NumberInput";
import { connect } from "react-redux";
import {
  OFFER_DETAILS,
  CHANGE_ALL_MY_OFFERS_DETAILS,
} from "../../../../../constants/actionTypes";
import { OrderStatus } from "../../../../../constants/defaults";
import {
  getDemandedOrder,
  getLatestOrder,
} from "../../../../../functions/orderValues";
import NumberFormatter from "../../../../../components/common/NumberFormatter";
import { Icons } from "../../../../../constants/icons";

const mapStateToProps = (state) => {
  return { order: state.order };
};

const mapDispatchToProps = (dispatch) => ({
  onChangeCustomPrice: (value) =>
    dispatch({ type: OFFER_DETAILS, key: "customPrice", value }),
  onChangeOfferQuantity: (value) =>
    dispatch({
      type: CHANGE_ALL_MY_OFFERS_DETAILS,
      key: "managementDemandQuantity",
      value,
    }),
  onChangeOfferPrice: (value) =>
    dispatch({
      type: CHANGE_ALL_MY_OFFERS_DETAILS,
      key: "managementDemandPrice",
      value,
    }),
});

const ManagementOrderModalLeftSide = (props) => {
  useEffect(() => {
    if (!props.resetInputs) {
      props.onChangeOfferQuantity(demandOrder?.OfferQuantity ?? 0);
      props.onChangeOfferPrice(demandOrder?.OfferPrice ?? 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.resetInputs]);

  const changeCustomPrice = (ev) =>
    props.onChangeCustomPrice(ev.target.checked);

  const [buttonDisable, setButtonDisable] = useState(false);

  const { customPrice, managementDemandQuantity, managementDemandPrice } =
    props.order;

  const demandOrder = getDemandedOrder(
    props.order.selected_all_offers?.History,
    false
  );

  const latestOrder = getLatestOrder(
    props.order.selected_all_offers?.History,
    false
  );

  const isOfferPrice =
    demandOrder?.OfferPrice === undefined ||
    demandOrder?.OfferPrice === null ||
    demandOrder?.OfferPrice === "";
  const isOfferQuantity =
    demandOrder?.OfferQuantity === undefined ||
    demandOrder?.OfferQuantity === null ||
    demandOrder?.OfferQuantity === "";
  return (
    <div>
      <div className="modal-left-side">
        <div className="go-back">
          <img src={Icons.goBackIcon} />
          <Button
            className="go-back-button"
            bindEvent={() => props.handleClose()}
            label="Go back"
          />
        </div>
        <div className="order-view-modal-body-text">
          <label>{props.order.selected_all_offers?.Product.ProductName}</label>
        </div>
        <div>
          <img src={Icons.greenUnderlineIcon} />
        </div>

        <div className="price">
          <label>Gamevenue, demanded </label>
          <div className="total">
            {isOfferPrice || isOfferQuantity ? (
              "0"
            ) : demandOrder?.OfferQuantity != 0 ? (
              <NumberFormatter
                offerPrice={demandOrder?.OfferPrice}
                offerQuantity={demandOrder?.OfferQuantity}
                supVisible
              />
            ) : (
              <NumberFormatter offerPrice={0} offerQuantity={0} supVisible />
            )}
          </div>
          <div className="multiply">
            {(isOfferQuantity ? "0" : demandOrder?.OfferQuantity) + " x "}
            {isOfferPrice ? (
              "0"
            ) : (
              <NumberFormatter
                offerPrice={demandOrder?.OfferPrice}
                supVisible
              />
            )}
          </div>
        </div>

        {props.order.selected_all_offers?.Status === OrderStatus.AdminRefused ||
        props.order.selected_all_offers?.Status ===
          OrderStatus.SalesmanApproved ? (
          <></>
        ) : (
          props.order.selected_all_offers?.Status !== OrderStatus.Revoked &&
          props.order.selected_all_offers?.Status !== OrderStatus.Submitted && (
            <div className="d-flex flex-column py-3 fmw-100">
              <div className="d-inline rounded-1">
                <div className="d-flex">
                  <input
                    className="form-check-input form-check-input-partner"
                    type="checkbox"
                    onChange={(ev) => changeCustomPrice(ev)}
                    defaultChecked={customPrice}
                    id="props.customPrice"
                  />
                  <div className="d-inline fmw-100">
                    <div className="d-flex">
                      <label
                        className={`${
                          customPrice
                            ? "modal-body-demand-text form-check-label m-0"
                            : "modal-body-demand-text-inactive form-check-label m-0"
                        } `}
                        htmlFor="props.customPrice"
                      >
                        Propose New Offer
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        )}
        {customPrice ||
        props.order.selected_all_offers?.Status === OrderStatus.Revoked ||
        props.order.selected_all_offers?.Status === OrderStatus.Submitted ||
        props.order.selected_all_offers?.Status === OrderStatus.AdminRefused ? (
          <div className="d-flex flex-column justify-content-between">
            <div className="number-inputs">
              <div className="number-input-quantity">
                <NumberInput
                  label="Quantity"
                  defaultValue={demandOrder?.OfferQuantity ?? 0}
                  onChange={(ev, val) => {
                    props.onChangeOfferQuantity(ev);
                    setButtonDisable(val);
                  }}
                  value={managementDemandQuantity}
                  intNumber
                  minValue={
                    props.order.selected_all_offers.Product.VoiduPromotion
                      .MinQuantity
                  }
                  maxValue={
                    props.order.selected_all_offers.Product.VoiduPromotion
                      .MaxQuantity
                  }
                  visible
                ></NumberInput>
              </div>
              <div className="number-input-price">
                <NumberInput
                  label="New Price"
                  defaultValue={demandOrder?.OfferPrice ?? 0}
                  onChange={(ev) => props.onChangeOfferPrice(ev)}
                  value={managementDemandPrice}
                  visible
                  showMinMax
                ></NumberInput>
              </div>
              <div className="quantity-box">
                <div className="d-flex flex-row justify-content-end">
                  <div className="d-flex flex-column ">
                    <span className="quantity-text">QTY Min </span>
                    <span className="quantity-text">Max </span>
                  </div>
                  <div className="d-flex flex-column align-items-end">
                    <span>
                      {
                        props.order.selected_all_offers.Product.VoiduPromotion
                          .MinQuantity
                      }
                    </span>
                    <span>
                      {
                        props.order.selected_all_offers.Product.VoiduPromotion
                          .MaxQuantity
                      }
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="min-max-description">
              {props.order.selected_all_offers.Product.VoiduPromotion
                .MinQuantity > managementDemandQuantity ? (
                <span>
                  Please enter an amount that is larger than the min QTY
                </span>
              ) : null}
              {props.order.selected_all_offers.Product.VoiduPromotion
                .MaxQuantity < managementDemandQuantity ? (
                <span>
                  Please enter an amount that is less than the max QTY
                </span>
              ) : null}
            </div>
            <div className="order-modal-buttons">
              <Button
                disabled={props.btnDisable}
                className="modal-resubmit-button"
                type="button"
                bindEvent={() =>
                  props.rebid(
                    managementDemandQuantity,
                    managementDemandPrice,
                    props.order.selected_all_offers.Id
                  )
                }
                visible
                label="Rebid"
                buttonIcon={Icons.thickIcon}
              />
              <Button
                disabled={props.btnDisable}
                className="modal-reject-button"
                type="button"
                bindEvent={() =>
                  props.refuse(props.order.selected_all_offers.Id)
                }
                visible
                label="Refuse"
                buttonIcon={Icons.crossIcon}
              />
            </div>
          </div>
        ) : (
          <div className="order-modal-buttons">
            <Button
              disabled={props.btnDisable}
              className="modal-resubmit-button"
              type="button"
              bindEvent={() => {
                props.handleClose();
                props.confirmModalOpen();
              }}
              visible={
                props.order.selected_all_offers?.Status ===
                  OrderStatus.Submitted ||
                (props.order.selected_all_offers?.Status ===
                  OrderStatus.SalesmanApproved &&
                  !customPrice)
              }
              label="Approve"
              buttonIcon={Icons.thickIcon}
            />
            <Button
              disabled={props.btnDisable}
              className="modal-reject-button"
              type="button"
              bindEvent={() => props.refuse(props.order.selected_all_offers.Id)}
              visible={
                props.order.selected_all_offers?.Status ===
                  OrderStatus.Submitted ||
                (props.order.selected_all_offers?.Status ===
                  OrderStatus.SalesmanApproved &&
                  !customPrice)
              }
              label="Refuse"
              buttonIcon={Icons.crossIcon}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManagementOrderModalLeftSide);
