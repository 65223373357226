import React from "react";
import Pager from "../../../../components/Pager";
import Loader from "../../../../components/Loader";
import Filter from "../../../../components/Filter";
import Content from "../../../../components/containers/Content";
import BlankPage from "../../../../components/BlankPage";
import { toast } from "react-toastify";
import { getSorting } from "../../../../functions/FilterFunctions";
import { connect } from "react-redux";
import {
  MANAGEMENT_PROMOTION_UPDATE_FIELD,
  GET_BASKET,
} from "../../../../constants/actionTypes";
import base64 from "base-64";
import NumberInput from "../../../../components/common/NumberInput";
import Button from "../../../../components/common/Button";
import services from "../../../../api/index";

const mapStateToProps = (state) => ({
  Basket: state.basket,
  pageSize: state.common.pageSize,
});

const mapDispatchToProps = (dispatch) => ({
  onChangeProperty: (key, value) =>
    dispatch({ type: MANAGEMENT_PROMOTION_UPDATE_FIELD, key, value }),
  basketOnLoad: (payload) => dispatch({ type: GET_BASKET, payload }),
});
class BulkPromotion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tableIsLoading: false,
      pageindex: 0,
      products: undefined,
      filter: null,
      sortBy: undefined,
      sortName: "down",
      Items: {},
      responseBulkPromotion: [],
      btnDisable: false,
    };
  }

  componentDidMount() {
    this.selectProducts();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.pageSize !== this.props.pageSize) {
      this.onFilter({ pageindex: 0 });
    }
  }

  shouldComponentUpdate() {
    let filter = this.props.match.params.filter;
    if (filter !== undefined)
      if (base64.encode(JSON.stringify(this.state.filter)) !== filter) {
        this.setState({ filter: JSON.parse(base64.decode(filter)) });
      }
    return true;
  }

  selectProducts() {
    let ev = this.state.filter;

    this.getProducts({
      keywords: ev?.Text,
      publisherIds: ev?.Publishers?.join(","),
      sortBy: this.state.sortBy,
      pageIndex: this.state.pageindex,
    });
  }

  getProducts(params) {
    const {
      keywords = "",
      validfrom = "",
      validto = "",
      pageindex = this.state.pageindex,
      pagesize = this.props.pageSize,
      name = "",
      sku = "",
      mSku = "",
      ReleaseDateFrom = "",
      ReleaseDateTo = "",
      PublisherIds = "",
      MinPrice = undefined,
      MaxPrice = undefined,
      operatingSystems = "",
      platforms = "",
      SortBy = "",
    } = params || {};
    this.setState({ pageindex: pageindex, tableIsLoading: true });
    return Promise.all([
      services.campaigns.getCampaignsFromPartner(
        keywords,
        validfrom,
        validto,
        pageindex,
        pagesize,
        name,
        sku,
        mSku,
        ReleaseDateFrom,
        ReleaseDateTo,
        PublisherIds,
        MinPrice,
        MaxPrice,
        operatingSystems,
        platforms,
        SortBy
      ),
    ])
      .then((res) => {
        this.setState({ products: res[0], tableIsLoading: false });
        return res;
      })
      .catch((err) => {
        if (err.response?.data?.message)
          toast.error(<div>{err.response?.data.message}</div>);
        return undefined;
      });
  }

  onChangePageIndex(pageindex) {
    this.onFilter({ pageindex: pageindex });
  }

  onFilter(params) {
    let { filter, sortBy = undefined, pageindex = 0 } = params || {};
    let ev = filter;
    if (ev !== undefined) this.setState({ filter: ev });
    else ev = this.state.filter;
    sortBy = getSorting(sortBy, this.state.sortBy);
    this.setState({ sortBy: sortBy });

    this.getProducts({
      keywords: ev?.Text,
      PublisherIds: ev?.Publishers?.join(","),
      SortBy: sortBy,
      pageindex: pageindex,
    });
  }

  bulkPromotion() {
    this.setState({ btnDisable: true });

    let bulkPromotionItems = Object.values(this.state.Items)
      .filter((e) => e.demandPrice && e.quantity)
      .map((item) => ({
        promotionId: item.promotionId,
        productId: item.productId,
        demandPrice: item.demandPrice,
        quantity: item.quantity,
      }));

    console.log(this.state.Items);

    console.log(bulkPromotionItems);

    if (bulkPromotionItems.length === 0) {
      toast.error(<div>Quantity fields are required !</div>);
      this.setState({ btnDisable: false });
      return undefined;
    }

    return Promise.all([services.product.bulkAddToCart(bulkPromotionItems)])
      .then((res) => {
        this.props.basketOnLoad(
          Promise.all([services.product.getBasket()])
            .then((result) => {
              return result;
            })
            .catch((err) => {
              if (err.response?.data?.message)
                toast.error(<div>{err.response?.data.message}</div>);
              return undefined;
            })
        );
        toast.success(<div>The product added to your cart!</div>);
        this.setState({
          Items: [],
          btnDisable: false,
        });
      })
      .catch((err) => {
        if (err.response?.data?.message)
          toast.error(<div>{err.response?.data.message}</div>);
        this.setState({ btnDisable: false });
      });
  }

  changeQuantity(quantity, index, item) {
    quantity = parseInt(quantity);

    let values = this.state.Items;

    if (quantity == 0 && values[item.Id]) {
      delete values[item.Id];
    } else {
      values[item.Id] = {
        promotionId: item.Id,
        productId: item.Product.Id,
        quantity: quantity,
        demandPrice:
          this.state.Items[index]?.demandPrice === undefined
            ? item.Price
            : this.state.Items[index]?.demandPrice,
      };
    }

    console.log(values);
    this.setState({
      Items: values,
    });
  }

  getItem({ Id }) {
    return this.state.Items?.[Id] || null;
  }

  isError = (productId) => {
    if (
      this.state.responseBulkPromotion.length > 0 &&
      this.state.responseBulkPromotion.filter(
        (item) => item.Status === "Error" && productId === item.ProductId
      ).length > 0
    ) {
      return "error-input";
    } else {
      return "";
    }
  };

  render() {
    return (
      <Content>
        <div className="d-flex flex-row">
          <h1>Active Promotion</h1>
        </div>
        {this.state.products === undefined ? (
          <Loader />
        ) : (
          <div>
            <Filter
              filter={this.state.filter}
              onFilter={(ev, sortBy) => this.onFilter(ev, sortBy)}
              isPublisherAvailable
            />
            {this.state.tableIsLoading ? (
              <Loader />
            ) : this.state.products.Items.length === 0 ? (
              <BlankPage description="There is no products." />
            ) : (
              <div>
                <div className="table-total-count">
                  Total Count: {this.state.products.TotalCount}
                </div>
                <div className="table-responsive-xl">
                  <table
                    className="table table-centered table-nowrap table-hover mb-0 rounded table-responsive cursor-pointer"
                    id="datatable"
                  >
                    <thead className="thead-light d-short border-0">
                      <tr>
                        <th
                          className="bg-transparent border-0 "
                          onClick={() => {
                            this.setState({
                              sortName:
                                this.state.sortName === "up" ? "down" : "up",
                            });
                            this.onFilter({ sortBy: "Name" });
                          }}
                        >
                          Product Name - Sku
                          <i
                            className={`fas fa-sort-amount-${this.state.sortName} px-2`}
                          ></i>
                        </th>
                        <th className="bg-transparent border-0 ">Region</th>
                        <th className="bg-transparent border-0 ">Publisher</th>
                        <th className="bg-transparent border-0 ">Price</th>

                        <th className="bg-transparent border-0  text-center">
                          Quantity
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.products.Items.map((item, index) => {
                        return (
                          <tr
                            className="rounded-1 mb-1 align-middle border-bottom"
                            key={item.Id}
                          >
                            <td className="ps-0">
                              {(
                                <div>
                                  <p>{item.Product.Name}</p>
                                  <p>{item.Product.Sku}</p>
                                </div>
                              ) ?? "-"}
                            </td>
                            <td className="ps-0">
                              {item.Region || item.Product.Region}
                            </td>
                            <td className="ps-0">
                              {item.Product.Publisher ?? "-"}
                            </td>
                            <td className="ps-0">{item.Price ?? "-"}</td>

                            <td
                              className={`ps-3 bulk-order-number-inputs ${this.isError(
                                item.Id
                              )} w-25`}
                            >
                              <NumberInput
                                intNumber
                                centerAlign
                                value={this.getItem(item)?.quantity}
                                onChange={(ev) => {
                                  this.changeQuantity(ev, index, item);
                                }}
                                visible
                                minValue={
                                  item.MinQuantity < 20 ? 20 : item.MinQuantity
                                }
                                maxValue={item.MaxQuantity}
                              ></NumberInput>
                              <div className="min-max-description text-center">
                                {item.MinQuantity < 20 ||
                                (item.MinQuantity >
                                  this.state.Items.filter(
                                    (f) =>
                                      f.productId === item.Product.Id &&
                                      f.promotionId == item.Id
                                  )[0]?.quantity &&
                                  this.state.Items.filter(
                                    (f) =>
                                      f.productId === item.Product.Id &&
                                      f.promotionId == item.Id
                                  )[0]?.quantity) ? (
                                  <span>
                                    Minimum order quantity should be{" "}
                                    {item.MinQuantity < 20
                                      ? 20
                                      : item.MinQuantity}
                                  </span>
                                ) : null}
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <Pager
                  pageIndex={this.state.products.PageIndex}
                  totalPages={this.state.products.TotalPages}
                  totalCount={this.state.products.TotalCount}
                  onChange={(pageindex) => this.onChangePageIndex(pageindex)}
                />
                <div className="d-flex flex-row float-end">
                  <Button
                    disabled={this.state.btnDisable}
                    className="bulk-order-save-button"
                    bindEvent={() => this.bulkPromotion()}
                    label="Add to cart"
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </Content>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BulkPromotion);
