import { OrderStatus } from "../../../../../../../constants/defaults";

import api from "../../../../../../../api";

const refund = ({ Id, Status, DeliveredQuantity, Quantity }) => ({
  isEnabled:
    [
      OrderStatus.Completed,
      OrderStatus.Refund,
      OrderStatus.PromotionExpired,
      OrderStatus.Failed,
    ].includes(Status) && DeliveredQuantity === Quantity,
  modal: {
    title: "Refund Order",
    accessibility: {
      submit: {
        onClick: () => api.orders.refundManagement(Id),
        successMessage: "Order is refunded, successfully",
        title: "Refund",
        theme: "warning",
      },
    },
    trigger: {
      title: "Refund",
      theme: "warning",
    },
    description: "Order will be refunded.",
  },
});

export default refund;
