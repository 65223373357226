import React, { useState, useEffect } from "react";
import Button from "../../../../components/common/Button";
import { toast } from "react-toastify";
import { Icons } from "../../../../constants/icons";
import { CHANGE_ALL_MY_OFFERS_DETAILS } from "../../../../constants/actionTypes";
import { connect } from "react-redux";
import services from "../../../../api/index";
import NumberInput from "../../../../components/common/NumberInput";

const mapStateToProps = (state) => {
  return {
    order: state.order,
  };
};

const mapDispatchToProps = (dispatch) => ({
  InventoriesLoad: (payload) => {
    dispatch({ type: CHANGE_ALL_MY_OFFERS_DETAILS, payload });
  },
  InventoryCheck: (value) => {
    dispatch({
      type: CHANGE_ALL_MY_OFFERS_DETAILS,
      key: "InventoryCheckResult",
      value,
    });
  },
});

function ManuelStockModal(props) {
  const [creditMemos, setCreditMemos] = useState(undefined);

  return (
    <div className="container order-modal">
      <div className="d-flex my-4 mx-2 flex-column justify-content-start">
        <div className="go-back">
          <img src={Icons.goBackIcon} />
          <Button
            className="go-back-button"
            bindEvent={() => props.handleClose()}
            label="Go back"
          />
        </div>
        <div className="create-package-header">
          <p className="h4">Manuel Stock Edit</p>
        </div>
        <div className="modal-number-inputs">
          <NumberInput
            label="Cost Per Item"
            onChange={(ev) => props.changeManualStock({ CostPerItem: ev })}
            value={props.order?.CostPerItemForManualStock}
            visible
            showMinMax
          ></NumberInput>
        </div>
        <div className="modal-number-inputs">
          <NumberInput
            label="Quantity"
            onChange={(ev) => props.changeManualStock({ Quantity: ev })}
            value={props.order?.QuantityForManualStock}
            visible
            showMinMax
            intNumber
          ></NumberInput>
        </div>

        <Button
          className="confirm-button  float-end"
          bindEvent={() => {
            services.orders
              .manualInventoryUpsert(
                props.order?.ProductIdForManualStock,
                props.order?.QuantityForManualStock,
                props.order?.CostPerItemForManualStock
              )
              .then((res) => {
                props.refreshList();
                toast.success(<div>Saved successfully.</div>);
              })
              .catch((err) => {
                if (err.response?.data?.message)
                  toast.error(<div>{err.response?.data.message}</div>);
              });
          }}
          label="Save"
        />
      </div>
    </div>
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(ManuelStockModal);
