import { MANAGEMENT_ALLOCATION_PAGE_LOADED } from "../constants/actionTypes";

const defaultState = {
  allocations: undefined,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case MANAGEMENT_ALLOCATION_PAGE_LOADED:
      return {
        ...state,
        allocations: action.payload,
        filterIsLoading: false,
      };
    default:
      return state;
  }
};
