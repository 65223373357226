import React, { useState } from "react";
import classNames from "classnames";

import { Icons } from "../../../../constants/icons";

const CollapseHeader = ({ title }) => <h6 className="m-0">{title}</h6>;

const Collapse = ({ children, disabled, header, title, HeaderItem }) => {
  const [isOpen, setOpen] = useState(() => true);

  const Header = HeaderItem || CollapseHeader;

  return (
    <div className="card d-flex flex-column justify-content-center p-3">
      <div
        className={classNames(
          "d-flex justify-content-between align-items-center",
          !disabled && "cursor-pointer"
        )}
        onClick={() => {
          if (disabled) return;

          setOpen((prev) => !prev);
        }}
      >
        <Header {...{ title, ...header }} />
        <img
          hidden={disabled}
          src={isOpen ? Icons.collapseArrowDownIcon : Icons.collapseArrowUpIcon}
        />
      </div>
      <div
        className={classNames(
          "border-top mt-4 pt-4",
          "collapse",
          isOpen && "show"
        )}
      >
        {children}
      </div>
    </div>
  );
};

export default Collapse;
