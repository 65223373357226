/* eslint-disable react/jsx-curly-brace-presence */
import React, { useEffect, useState } from "react";
import Loader from "../../../components/Loader";
import NumberFormat from "react-number-format";
import { toast } from "react-toastify";
import BlankPage from "../../../components/BlankPage";
import Content from "../../../components/containers/Content";
import { connect } from "react-redux";
import Pager from "../../../components/Pager";
import base64 from "base-64";
import Filter from "../../../components/Filter";
import services from "../../../api/index";
import Button from "../../../components/common/Button";
import MarkAsPaidModal from "./components/MarkAsPaidModal";
import MerModal from "../../../components/common/MerModal";
import CreatePaymentPlanModal from "./components/CreatePaymentPlanModal";
import { Endpoints } from "../../../constants/endpoints";
import { Icons } from "../../../constants/icons";
import { REDIRECT } from "../../../constants/actionTypes";
import { getSorting, waitTimeOut } from "../../../functions/FilterFunctions";
import moment from "moment";
import { fixTimezoneOffset } from "../../../functions/dateConverter";

const mapStateToProps = (state) => {
  return {
    pageSize: state.common.pageSize,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onRedirect: (redirectTo) => dispatch({ type: REDIRECT, redirectTo }),
});

function CompanyAccount(props) {
  const [myfilter, setFilter] = useState(
    props.match.params.filter
      ? JSON.parse(base64.decode(props.match.params.filter))
      : null
  );
  const [pageindex, setPageindex] = useState(0);
  const [tableIsLoading, setTableIsLoading] = useState(true);
  const [paymentPlans, setPaymentPlans] = useState(undefined);
  const [partnerSummary, setPartnerSummary] = useState(undefined);
  const [showMarkAsPaidModal, setShowMarkAsPaidModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [partnerId, setPartnerId] = useState(props.match.params.partnerid);
  const [activeTab, setActiveTab] = useState("summary");
  const [sortTypeDate, setSortTypeDate] = useState("up");
  const [sortby, setSortBy] = useState(null);

  const partnerSummaryOrderTitles = {
    TotalAmountOfInvoicedOrders: "Invoiced Total",
    TotalAmountOfCompletedOrders: "Completed Total",
    TotalAmountOfClosedOrders: "Closed Total",
    TotalAmountOfProgressingOrders: "Progressing Total",
    TotalAmountOfPausedOrders: "Paused Total",
  };
  const partnerSummaryPaymentTitles = {
    Balance: "Balance",
    TotalAmountOfPastDuePayment: "Past Due Payment",
    TotalAmountOfPayment: " Paid",
    TotalAmountOfPaymentPlan: "Planned Payment",
  };
  useEffect(() => {
    onFilter({ pageindex: 0, sortBy: sortby ? undefined : "-PaymentDate" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.pageSize]);

  const getPartnerSummary = async () => {
    Promise.all([services.accounting.partnerSummary(partnerId)])
      .then((res) => {
        let partnerSummaryResult = res[0];
        partnerSummaryResult.Balance =
          partnerSummaryResult.TotalAmountOfInvoicedOrders -
          partnerSummaryResult.TotalAmountOfPayment;
        setPartnerSummary(res[0]);
      })
      .catch((err) => {
        if (err.response?.data?.message)
          toast.error(<div>{err.response?.data.message}</div>);
      });
  };
  const getPaymentPlans = async (params) => {
    setTableIsLoading(true);
    const {
      keywords = "",
      pageindex_ = pageindex,
      pagesize = props.pageSize,
      SortBy = sortby,
    } = params || {};
    setPageindex(pageindex_);
    Promise.all([
      services.accounting.paymentplanSearch(
        pageindex_,
        pagesize,
        partnerId,
        keywords,
        SortBy
      ),
    ])
      .then((res) => {
        setPaymentPlans(res);
        setTableIsLoading(false);
      })
      .catch((err) => {
        if (err.response?.data?.message)
          toast.error(<div>{err.response?.data.message}</div>);
      });
  };

  const onFilter = async (params) => {
    let { filter, pageindex_ = 0, sortBy = undefined } = params || {};
    let ev = filter;
    if (ev !== undefined) setFilter(ev);
    else ev = myfilter;
    sortBy = getSorting(sortBy, sortby) ?? sortby;
    setSortBy(sortBy);
    getPartnerSummary();

    await getPaymentPlans({
      keywords: ev?.Text,
      pageindex_: pageindex_,
      SortBy: sortBy + ",-PlannedPaymentDate",
    });
  };

  const onChangePageIndex = (pageindex_) => {
    setTableIsLoading(true);
    onFilter({ pageindex_: pageindex_ });
  };
  moment.locale("en");

  return (
    <Content pageTitle="Company Account">
      {paymentPlans === undefined ? (
        <Loader />
      ) : (
        <div>
          <div>
            <div className="d-flex flex-row border-bottom">
              <div className="go-back m-3 me-5">
                <img src={Icons.goBackIcon} />
                <Button
                  className="go-back-button"
                  bindEvent={() =>
                    props.onRedirect(Endpoints.Management.Companies.url)
                  }
                  label="Go back"
                />
              </div>

              <ul className="nav nav-tabs ">
                <li className="nav-item">
                  <a
                    className={
                      "nav-link " + (activeTab == "summary" ? "active" : "")
                    }
                    href="#"
                    onClick={() => setActiveTab("summary")}
                  >
                    Summary
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={
                      "nav-link " + (activeTab == "list" ? "active" : "")
                    }
                    href="#"
                    onClick={() => setActiveTab("list")}
                  >
                    Payment Plans
                  </a>
                </li>
              </ul>
            </div>
            {activeTab === "list" && (
              <Button
                className="add-user-button px-4 m-1 float-end"
                bindEvent={() => {
                  setSelectedItem(undefined);
                  setShowCreateModal(true);
                }}
                label="Create Payment Plan"
              ></Button>
            )}

            {activeTab === "list" && (
              <Filter
                filter={myfilter}
                onFilter={(ev) => onFilter(ev)}
              ></Filter>
            )}
            {activeTab === "list" &&
              (tableIsLoading ? (
                <Loader />
              ) : paymentPlans[0]?.Items.length === 0 ? (
                <BlankPage description="There is no credit limit." />
              ) : (
                <div>
                  <div>
                    <div className="table-responsive-xl">
                      <table
                        className="table table-centered table-nowrap table-hover mb-0 rounded table-responsive cursor-pointer table-width-100"
                        id="datatable"
                      >
                        <thead className="thead-light d-short border-0">
                          <tr>
                            <th className="bg-transparent border-0 ">
                              Partner
                            </th>
                            <th
                              className="bg-transparent border-0 "
                              onClick={() => {
                                setSortTypeDate(
                                  sortTypeDate === "up" ? "down" : "up"
                                );
                                onFilter({
                                  sortBy: "PaymentDate",
                                });
                              }}
                            >
                              Payment Date
                              <i
                                className={`fas fa-sort-amount-${sortTypeDate} px-2`}
                              ></i>
                            </th>
                            <th className="bg-transparent border-0 ">
                              Planned Payment Date
                            </th>
                            <th className="bg-transparent border-0 ">Note</th>
                            <th className="bg-transparent border-0 ">Status</th>
                            <th className="bg-transparent border-0 ">Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          {paymentPlans[0]?.Items.map((item, index) => {
                            // if (index === 0) alert(item.PlannedPaymentDate);
                            return (
                              <tr
                                className={
                                  "rounded-1 mb-1 align-middle border-bottom " +
                                  (item.PlannedPaymentDate !== null &&
                                  moment(
                                    fixTimezoneOffset(new Date(), true).date
                                  ).diff(new Date(item.PlannedPaymentDate)) >
                                    0 &&
                                  item.Status === "Waiting"
                                    ? "pastduepayment-card"
                                    : "")
                                }
                                key={index}
                              >
                                <td>{item.PartnerName}</td>
                                <td>
                                  {item.PaymentDate &&
                                    moment(new Date(item.PaymentDate)).format(
                                      "DD MMMM YYYY HH:mm"
                                    )}
                                </td>
                                <td>
                                  {item.PlannedPaymentDate !== null &&
                                    moment(
                                      new Date(item.PlannedPaymentDate)
                                    ).format("DD MMMM YYYY HH:mm")}
                                </td>
                                <td>{item.Note}</td>
                                <td>{item.Status}</td>
                                <td>
                                  <NumberFormat
                                    className="text-primary"
                                    value={item.Amount}
                                    thousandSeparator
                                    displayType="text"
                                  ></NumberFormat>
                                  €
                                </td>
                                <td>
                                  <div
                                    class="btn-group"
                                    role="group"
                                    aria-label="Button group with nested dropdown"
                                  >
                                    <div className="btn-group" role="group">
                                      <button
                                        id="btnGroupDrop1"
                                        type="button"
                                        className="btn btn-primary dropdown-toggle"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                      >
                                        Actions{" "}
                                        <span className="fas fa-chevron-down"></span>
                                      </button>
                                      <ul
                                        className="dropdown-menu"
                                        aria-labelledby="btnGroupDrop1"
                                      >
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            href="#"
                                            onClick={() => {
                                              setSelectedItem(item);
                                              setShowMarkAsPaidModal(true);
                                            }}
                                          >
                                            Mark as Paid
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            href="#"
                                            onClick={() => {
                                              setSelectedItem(item);
                                              setShowCreateModal(true);
                                            }}
                                          >
                                            Update
                                          </a>
                                        </li>
                                        <li>
                                          <Button
                                            className="dropdown-item"
                                            href="#"
                                            confirmable
                                            bindEvent={async () => {
                                              Promise.all([
                                                await services.accounting.paymentplanDelete(
                                                  item.Id
                                                ),
                                              ])
                                                .then((res) => {
                                                  waitTimeOut(() => {
                                                    onFilter();
                                                  }, 500);
                                                })
                                                .catch((err) => {
                                                  if (
                                                    err.response?.data?.message
                                                  )
                                                    toast.error(
                                                      <div>
                                                        {
                                                          err.response?.data
                                                            .message
                                                        }
                                                      </div>
                                                    );
                                                  if (err.message)
                                                    toast.error(
                                                      <div>{err.message}</div>
                                                    );
                                                });
                                            }}
                                          >
                                            Delete
                                          </Button>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>

                    <Pager
                      pageIndex={paymentPlans[0]?.PageIndex}
                      totalPages={paymentPlans[0]?.TotalPages}
                      totalCount={paymentPlans[0]?.TotalCount}
                      onChange={(pageIndex) => onChangePageIndex(pageIndex)}
                    />
                  </div>
                </div>
              ))}

            {activeTab === "summary" && (
              <div>
                <h1 className="m-3">Payments</h1>
                <div className="d-flex flex-wrap">
                  {partnerSummary &&
                    Object.keys(partnerSummaryPaymentTitles).map((item) => (
                      <div className="w-25">
                        <div
                          className={
                            "card text-dark bg-light company-summary-card payment-card " +
                            (partnerSummaryPaymentTitles[item] ===
                            partnerSummaryPaymentTitles.TotalAmountOfPastDuePayment
                              ? " pastduepayment-card"
                              : "")
                          }
                        >
                          <div className="card-header border-0"> </div>
                          <div className="card-body mt-2">
                            <h5 className="card-title d-flex">
                              {partnerSummaryPaymentTitles[item]}
                              {partnerSummaryPaymentTitles[item] ===
                                partnerSummaryPaymentTitles.Balance && (
                                <Button
                                  className="info-button"
                                  tooltipDesc={`= ${partnerSummaryOrderTitles.TotalAmountOfInvoicedOrders} - ${partnerSummaryPaymentTitles.TotalAmountOfPayment}`}
                                  buttonIcon={Icons.infoIcon}
                                  iconWidth={20}
                                  iconHeight={20}
                                  registerTip="create-promotion-from-google-sheet"
                                  place="right"
                                  label=""
                                ></Button>
                              )}
                            </h5>
                            <p className="card-text fs-4 fw-bolder">
                              €{}
                              <NumberFormat
                                className="text-primary"
                                value={partnerSummary[item]}
                                thousandSeparator
                                displayType="text"
                              ></NumberFormat>
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            )}

            {activeTab === "summary" && (
              <div>
                <h1 className="m-3">Orders</h1>

                <div className="d-flex flex-wrap">
                  {partnerSummary &&
                    Object.keys(partnerSummaryOrderTitles).map((item) => (
                      <div className="w-25">
                        <div className="card text-dark bg-light company-summary-card order-card">
                          <div className="card-header border-0"> </div>
                          <div className="card-body mt-2">
                            <h5 className="card-title d-flex">
                              {partnerSummaryOrderTitles[item]}
                            </h5>
                            <p className="card-text fs-4 fw-bolder">
                              €{}
                              <NumberFormat
                                className="text-primary"
                                value={partnerSummary[item]}
                                thousandSeparator
                                displayType="text"
                              ></NumberFormat>
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      <MerModal
        showModal={showCreateModal}
        ariaLabelledby="contained-modal-title-vcenter"
        onHide={() => setShowCreateModal(false)}
      >
        <CreatePaymentPlanModal
          handleClose={() => {
            setShowCreateModal(false);
            waitTimeOut(() => {
              onFilter({ pageindex: 0 });
            }, 500);
          }}
          partnerId={partnerId}
          item={selectedItem}
        />
      </MerModal>

      <MerModal
        showModal={showMarkAsPaidModal}
        ariaLabelledby="contained-modal-title-vcenter"
        onHide={() => setShowMarkAsPaidModal(false)}
      >
        <MarkAsPaidModal
          handleClose={() => {
            setShowMarkAsPaidModal(false);
            onFilter({ pageindex: 0 });
          }}
          item={selectedItem}
        />
      </MerModal>
    </Content>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyAccount);
