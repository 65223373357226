/* eslint-disable no-prototype-builtins */
import React from "react";

function PriceFilter(props) {
  const { priceList } = props || {};
  const {
    minPrice,
    inputPriceFilter,
    maxPrice,
    priceFilterClick,
    filter,
    onPriceChange,
    onPriceClear,
  } = props;
  return (
    <>
      {priceList === undefined && <></>}
      {priceList && (
        <div className="filter-price d-block">
          <div className="btn-group pe-2 pt-1 rounded-1 align-items-center">
            <button
              type="button"
              className="btn btn-transparent shadow-none dropdown-toggle mr-2 dropdown-toggle-split " //+
              data-bs-toggle="dropdown"
              aria-expanded="false"
              data-bs-auto-close="outside"
              id="Price"
              style={
                filter.Price.hasOwnProperty("up") ||
                filter.Price.hasOwnProperty("down")
                  ? { color: "#264EA7" }
                  : { color: "#A4B9E8" }
              }
            >
              <span className="mx-1 text">Price</span>
              <span className="badge">
                {(filter.Price.hasOwnProperty("up") ||
                  filter.Price.hasOwnProperty("down")) &&
                  "1"}
              </span>
            </button>

            <div className="dropdown-menu border-0 p-1 py-1">
              <div className="px-3 py-4 pb-3">
                <div className="d-flex align-items-start flex-row justify-content-between ">
                  <div className="price-input">
                    <div htmlFor="min">Min</div>
                    <input
                      type="text"
                      id="min"
                      name="min"
                      placeholder={minPrice}
                      onChange={(e) => inputPriceFilter(e)}
                    />
                  </div>
                  <div className="price-input">
                    <div htmlFor="max">Max</div>
                    <input
                      type="text"
                      id="max"
                      name="max"
                      placeholder={maxPrice}
                      onChange={(e) => inputPriceFilter(e)}
                    />
                  </div>
                  <div>
                    <button
                      className="price-button"
                      onClick={() => priceFilterClick()}
                      disabled={maxPrice === 0 ? true : false}
                    >
                      <i className="fas fa-check"></i>
                    </button>
                  </div>
                </div>
                {priceList.map((item, index) => {
                  return (
                    <div className="form-check" key={index}>
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefaultPrice"
                        value={index}
                        checked={
                          filter.Price.up === item.up &&
                          filter.Price.down === item.down
                        }
                        onChange={() => onPriceChange(item)}
                        id={`price_${index}`}
                      />
                      <label
                        className="form-check-label fw-filter"
                        htmlFor={`price_${index}`}
                      >
                        {item.down === undefined && <>{`Under € ${item.up}`}</>}
                        {item.up === undefined && <>{`${item.down} € Above`}</>}
                        {item.down != undefined && item.up != undefined && (
                          <>{`${item.down} € - ${item.up} €`}</>
                        )}
                      </label>
                    </div>
                  );
                })}
              </div>
              {(filter.Price.hasOwnProperty("up") ||
                filter.Price.hasOwnProperty("down")) && (
                <div className="m-2">
                  <button
                    onClick={onPriceClear}
                    className="button filter-actions clear-all"
                  >
                    Clear
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default PriceFilter;
