import React, { useState, useEffect } from "react";
import { Icons } from "../../../../../constants/icons";
import Button from "../../../../../components/common/Button";
import services from "../../../../../api/index";

const ManagementOrderSendToInventoryConfirm = (props) => {
  const [unDelivered, setUnDelivered] = useState(true);
  const [isUpdate, setOrderUpdate] = useState(false);

  return (
    <div className="order-confirm-modal send-inventory-confirm">
      <div className="order-confirm-icon">
        <img src={Icons.maskIcon}></img>
      </div>
      <div className="order-confirm-title">
        <label>
          (
          {unDelivered
            ? "UnDelivered keys will be sent to the inventory, are you sure to proceed?"
            : "All keys will be sent to the inventory, are you sure to proceed?"}
          )
        </label>
      </div>
      <div className="order-confirm-description mb-3">
        <span>If you close this popup, your order status is not change.</span>
      </div>
      {props.isBilled && (
        <div className="order-confirm-description">
          <span>
            Invoice has been created, please make sure you created a credit
            note.
          </span>
        </div>
      )}
      {props.packages.length > 0 && (
        <div className="order-confirm-description">
          <span>
            Partner has been received keys, these keys will be sent to
            inventory.
          </span>
        </div>
      )}

      <div className="px-4 p-2 rounded-1 m-auto">
        <div className="d-flex">
          <div className="form-check form-switch">
            <input
              className="form-check-input form-check-input-partner"
              type="checkbox"
              defaultChecked={unDelivered}
              id="undeliveredkey"
              onChange={(e) => setUnDelivered(!unDelivered)}
            />
          </div>
          <div className="d-inline fmw-100">
            <div className="d-flex">
              <label
                className="modal-body-demand-text form-check-label m-0"
                htmlFor="SentToInvenyoryOneInput"
              >
                Send Only Undelivered Keys to Inventory
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className="px-4 p-2 rounded-1 m-auto">
        <div className="d-flex">
          <div className="form-check form-switch">
            <input
              className="form-check-input form-check-input-partner"
              type="checkbox"
              defaultChecked={isUpdate}
              id="updateprice"
              onChange={(e) => setOrderUpdate(!isUpdate)}
            />
          </div>
          <div className="d-inline fmw-100">
            <div className="d-flex">
              <label
                className="modal-body-demand-text form-check-label m-0"
                htmlFor="SentToInvenyoryTwoInput"
              >
                Update quantity and price of the orders ?
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex  justify-content-center">
        <Button
          disabled={props.btnDisable}
          className="order-confirm-yes-button"
          bindEvent={() => props.sendToInventory({ unDelivered, isUpdate })}
          label="Send To Inventory"
          buttonIcon={Icons.thickIcon}
        />
      </div>
      <div className="d-flex  justify-content-center">
        <Button
          className="order-confirm-no-button"
          bindEvent={() => props.showModal()}
          label="Go Back"
          buttonIcon={Icons.leftArrowIcon}
        />
      </div>
    </div>
  );
};

export default ManagementOrderSendToInventoryConfirm;
