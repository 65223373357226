import React from "react";
import Steppper from "../../../../components/stepper/Stepper";
import SelectGame from "./promotion-steps/SelectGame";
import SelectRegions from "./promotion-steps/SelectRegions";
import SetPercentages from "./promotion-steps/SetPercentages";
import SetPrice from "./promotion-steps/SetPrice";
import SelectOsPlatform from "./promotion-steps/SelectOsPlatform";
import SetPromotion from "./promotion-steps/SetPromotion";
import PublishPromotion from "./promotion-steps/PublishPromotion";
import { connect } from "react-redux";
import {
  MANAGEMENT_PROMOTION_UPDATE_FIELD,
  REDIRECT,
} from "../../../../constants/actionTypes";
import { toast } from "react-toastify";
import { Endpoints } from "../../../../constants/endpoints";
import { fixTimezoneOffset } from "../../../../functions/dateConverter";
import Content from "../../../../components/containers/Content";
import services from "../../../../api/index";

const mapStateToProps = (state) => {
  return {
    promotions: state.promotions,
  };
};
const mapDispatchToProps = (dispatch) => ({
  onRedirect: (redirectTo) => dispatch({ type: REDIRECT, redirectTo }),
  onChangeProperty: (key, value) =>
    dispatch({ type: MANAGEMENT_PROMOTION_UPDATE_FIELD, key, value }),
});

class NewPromotions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      promotionId: this.props.match.params.promotionid,
      currentStep: this.props.match.params.promotionid ? 2 : 1,
    };
    if (this.props.match.params.promotionid) {
      this.getCampaignFromManagement();
    }
  }
  stepsArray = [
    {
      title: (
        <>
          Select <br /> Game
        </>
      ),
      content: <SelectGame title="Select Game" />,
    },
    {
      title: (
        <>
          Select <br /> Regions
        </>
      ),
      content: (
        <SelectRegions
          GoToSelectGame={() => this.goFirstPage()}
          title="Select Regions"
        />
      ),
    },
    {
      title: (
        <>
          Set <br /> Percentages
        </>
      ),
      content: (
        <SetPercentages
          GoToSelectGame={() => this.goFirstPage()}
          title="Set Percentages by Country"
        />
      ),
    },
    {
      title: (
        <>
          Set <br /> Price
        </>
      ),
      content: (
        <SetPrice GoToSelectGame={() => this.goFirstPage()} title="Set Price" />
      ),
    },
    {
      title: (
        <>
          Select <br /> OS & Platform
        </>
      ),
      content: (
        <SelectOsPlatform
          GoToSelectGame={() => this.goFirstPage()}
          title="Select OS & Platform"
        />
      ),
    },
    {
      title: (
        <>
          Set Promotion
          <br />
          Details
        </>
      ),
      content: (
        <SetPromotion
          GoToSelectGame={() => this.goFirstPage()}
          title="Set Promotion Details"
        />
      ),
    },
    {
      title: (
        <>
          Promotion
          <br />
          Summary
        </>
      ),
      content: (
        <PublishPromotion
          GoToSelectGame={() => this.goFirstPage()}
          title="Promotion Summary"
        />
      ),
    },
  ];

  getCampaignFromManagement = () => {
    return Promise.all([
      services.campaigns
        .getCampaignFromManagement(this.state.promotionId)
        .then((res) => {
          this.editPromotion(res);
          return res;
        })
        .catch((err) => {
          if (err.status !== 401 && err.status !== undefined)
            if (err.response?.data?.message)
              toast.error(<div>{err.response?.data.message}</div>);
          return undefined;
        }),
    ]);
  };

  editPromotion = (promotion) => {
    let countries = [];

    promotion.Countries.filter((item) => {
      return promotion.Product.Prices.filter((f) => {
        return f.Countries.filter((t) =>
          t === item.Code
            ? countries.push({ code: item.Code, weight: item.Weight })
            : null
        );
      });
    });

    this.props.onChangeProperty("promotionStatus", "Edit");
    this.props.onChangeProperty("selectedMerkurRegion", promotion.Region);
    this.props.onChangeProperty("selectedPromotionId", promotion.Id);
    this.props.onChangeProperty("selectedProduct", promotion.Product);
    this.props.onChangeProperty(
      "selectedRegion",
      promotion.Product.Prices.filter(
        (f) =>
          promotion.Countries.filter((country) =>
            f.Countries.includes(country.Code)
          ).length > 0
      )
    );

    this.props.onChangeProperty("changePercentage", countries);
    this.props.onChangeProperty("tier1Price", promotion.Tier1Price);
    this.props.onChangeProperty("tier2Price", promotion.Tier2Price);
    this.props.onChangeProperty("tier3Price", promotion.Tier3Price);
    this.props.onChangeProperty("setOS", promotion.OperatingSystems);
    this.props.onChangeProperty("setPlatforms", promotion.Platforms);
    this.props.onChangeProperty("minQuantity", promotion.MinQuantity);
    this.props.onChangeProperty("maxQuantity", promotion.MaxQuantity);
    this.props.onChangeProperty(
      "validTo",
      fixTimezoneOffset(promotion.ValidTo, false)
    );
    this.props.onChangeProperty(
      "validFrom",
      fixTimezoneOffset(promotion.ValidFrom, false)
    );
    this.props.onChangeProperty("checkPromotion", true);
  };

  goFirstPage = () => {
    if (!this.state.promotionId) {
      this.setState({ currentStep: 1 });
    }
  };

  handleClick = (clickType) => {
    let newStep = this.state.currentStep;
    let isContinue = true;
    let percentageSum = Array.from(
      new Map(
        this.props.promotions.changePercentage.map((item) => [
          item.code,
          item.weight,
        ])
      ).values()
    ).reduce((sum, weight) => sum + parseInt(weight === "" ? 0 : weight), 0);
    percentageSum = percentageSum < 0 ? 0 : percentageSum;

    /*
    let percentageSum = this.props.promotions?.changePercentage.reduce(
      (sum, current) => {
        return sum + parseInt(current.weight);
      },
      0
    );
    */
    if (clickType === "next") {
      switch (this.state.currentStep) {
        case 1:
          if (this.props.promotions.selectedProduct === undefined) {
            toast.error(<div>You must select a product!</div>);
            isContinue = false;
          } else if (this.props.promotions.isProductAlreadyCreated) {
            toast.error(<div>There is a promotion for the same product.</div>);
            isContinue = false;
          }
          break;
        case 2:
          if (this.props.promotions.selectedRegion.length < 1) {
            toast.error(<div>You must select a region!</div>);
            isContinue = false;
          }
          break;
        case 3:
          if (percentageSum !== 100) {
            toast.error(<div>Percentage rate should be 100.</div>);
            isContinue = false;
          }
          break;
        case 4:
          if (
            this.props.promotions.tier1Price === undefined ||
            this.props.promotions.tier1Price === null
          ) {
            toast.error(<div>Tier 1 price is a required field!</div>);
            isContinue = false;
          } else if (
            this.props.promotions?.calculatedAverage?.Cost >
            this.props.promotions.tier1Price
          ) {
            toast.error(
              <div>Tier 1 price can not be smaller than Provider Cost!</div>
            );
            isContinue = false;
          } else if (
            this.props.promotions?.calculatedAverage?.Cost >
              this.props.promotions.tier2Price &&
            this.props.promotions.tier2Price !== 0
          ) {
            toast.error(
              <div>Tier 2 price can not be smaller than Provider Cost!</div>
            );
            isContinue = false;
          } else if (
            this.props.promotions?.calculatedAverage?.Cost >
              this.props.promotions.tier3Price &&
            this.props.promotions.tier3Price !== 0
          ) {
            toast.error(
              <div>Tier 3 price can not be smaller than Provider Cost!</div>
            );
            isContinue = false;
          }
          break;
        case 5:
          if (
            this.props.promotions.setOS.length === 0 ||
            this.props.promotions.setPlatforms.length === 0
          ) {
            toast.error(
              <div>
                You don't select <strong>Operating Systems </strong>and
                <strong>Platforms</strong>
              </div>
            );
            isContinue = false;
          }
          break;
        case 6:
          if (
            parseInt(this.props.promotions.minQuantity) >
            parseInt(this.props.promotions.maxQuantity)
          ) {
            toast.error(
              <div>Max Quantity can not be smaller than Min Quantity!</div>
            );
            isContinue = false;
          }
          if (!parseInt(this.props.promotions.minQuantity)) {
            toast.error(<div>Min Quantity is a required field!</div>);
            isContinue = false;
          }
          if (!parseInt(this.props.promotions.maxQuantity)) {
            toast.error(<div>Max Quantity is a required field!</div>);
            isContinue = false;
          }
          if (
            new Date(this.props.promotions.validTo).getTime() <=
            new Date(
              this.props.promotions.selectedProduct.ReleaseDate
            ).getTime()
          ) {
            toast.error(
              <div>End date must be later than game release date!</div>
            );
            isContinue = false;
          }
          if (
            this.props.promotions.validTo.date.getFullYear() <= 1970 ||
            !this.props.promotions.validTo.date
          ) {
            toast.error(<div>End date is a required field!</div>);
            isContinue = false;
          }
          if (
            this.props.promotions.validFrom.date.getFullYear() <= 1970 ||
            !this.props.promotions.validFrom.date
          ) {
            toast.error(<div>Start date is a required field!</div>);
            isContinue = false;
          }
          if (
            this.props.promotions.validFrom.date >=
            this.props.promotions.validTo.date
          ) {
            toast.error(<div>End date must be later than start date!</div>);
            isContinue = false;
          }
          break;
      }
    }
    if (isContinue === false) return;
    clickType === "next" ? newStep++ : newStep--;
    if (newStep > 0 && newStep <= this.stepsArray.length) {
      this.setState({ currentStep: newStep });
    }
  };

  publishNow = () => {
    if (!this.props.promotions.checkPromotion) {
      toast.error(<div>Please check the promotion.</div>);
      return;
    }

    let productId = this.props.promotions.selectedProduct.Id;
    let providerPriceIds = this.props.promotions.selectedRegion.map(
      (item) => item.Id
    );
    let minQuantity = parseInt(this.props.promotions.minQuantity);
    let maxQuantity = parseInt(this.props.promotions.maxQuantity);
    let tier1Price = this.props.promotions.tier1Price;
    let tier2Price = this.props.promotions.tier2Price;
    let tier3Price = this.props.promotions.tier3Price;
    let validFrom = this.props.promotions.validFrom.utc;
    let validTo = this.props.promotions.validTo.utc;
    let platforms = this.props.promotions.setPlatforms;
    let operatingSystems = this.props.promotions.setOS;
    let countries = this.props.promotions.changePercentage;
    let campaignId = this.props.promotions.selectedPromotionId;
    let region = this.props.promotions.selectedMerkurRegion;

    if (this.state.promotionId) {
      services.campaigns
        .updatePromotion(
          campaignId,
          productId,
          providerPriceIds,
          tier1Price,
          tier2Price,
          tier3Price,
          validFrom,
          validTo,
          minQuantity,
          maxQuantity,
          operatingSystems,
          platforms,
          countries,
          region
        )
        .then((data) => {
          toast.success(
            <div>{`The promotion ${data.Id} has been updated!`}</div>
          );
          this.setState({ currentStep: this.state.currentStep + 1 });
          setTimeout(() => {
            this.props.onRedirect(
              Endpoints.Management.Promotions.url
                .replace(":" + Endpoints.Management.Promotions.url.split(":"))
                .replace("/:filter?", "")
                .replace("/:sort?", "")
                .replace("/:page?", "")
            );
          }, 2000);
        })
        .catch((err) => {
          if (err.response?.data?.message)
            toast.error(<div>{err.response?.data.message}</div>);
        });
    } else {
      services.campaigns
        .createPromotion(
          productId,
          providerPriceIds,
          tier1Price,
          tier2Price,
          tier3Price,
          validFrom,
          validTo,
          minQuantity,
          maxQuantity,
          operatingSystems,
          platforms,
          countries,
          region
        )
        .then((data) => {
          toast.success(
            <div>{`The promotion ${data.Id} has been created!`}</div>
          );
          this.setState({ currentStep: this.state.currentStep + 1 });
          setTimeout(() => {
            this.props.onRedirect(
              Endpoints.Management.Promotions.url
                .replace(":" + Endpoints.Management.Promotions.url.split(":"))
                .replace("/:filter?", "")
                .replace("/:sort?", "")
                .replace("/:page?", "")
            );
          }, 2000);
        })
        .catch((err) => {
          if (err.response?.data?.message)
            toast.error(<div>{err.response?.data.message}</div>);
        });
    }
  };
  render() {
    return (
      <Content>
        <h1>
          {this.state.promotionId ? "Edit Promotion" : "Create New Promotion"}
        </h1>{" "}
        <Steppper
          currentStepNumber={this.state.currentStep - 1}
          steps={this.stepsArray}
        />
        <div className="d-flex flex-row justify-content-center">
          <div className="stepper-footer">
            {this.state.currentStep !== this.stepsArray.length + 1 && (
              <div
                className={`d-flex p-2 mt-4 ${
                  !this.state.promotionId
                    ? this.state.currentStep !== 1
                      ? "justify-content-between"
                      : "justify-content-end"
                    : this.state.currentStep !== 2
                    ? "justify-content-between"
                    : "justify-content-end"
                }`}
              >
                {!this.state.promotionId
                  ? this.state.currentStep !== 1 && (
                      <a
                        className="button back"
                        onClick={() => this.handleClick()}
                      >
                        Previous
                      </a>
                    )
                  : this.state.currentStep !== 2 && (
                      <a
                        className="button back text-end"
                        onClick={() => this.handleClick()}
                      >
                        Previous
                      </a>
                    )}
                <div className="flex-auto d-flex flex-row-reverse">
                  <button
                    className="button large"
                    onClick={() =>
                      this.state.currentStep !== 7
                        ? this.handleClick("next")
                        : this.publishNow()
                    }
                  >
                    {this.state.currentStep === 7
                      ? this.state.promotionId
                        ? "Update"
                        : "Publish"
                      : "Next"}{" "}
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </Content>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewPromotions);
