export default (req) => ({
  getAll: (
    keywords,
    pageIndex,
    pageSize,
    startDate,
    finishDate,
    statuses,
    MinPrice,
    MaxPrice,
    CustomerId,
    sortBy
  ) =>
    req.get("/invoice/me/search", {
      params: {
        keywords,
        PageIndex: pageIndex,
        PageSize: pageSize,
        StartDate: startDate,
        FinishDate: finishDate,
        Statuses: statuses,
        PriceFrom: MinPrice,
        PriceTo: MaxPrice,
        CustomerId: CustomerId,
        SortBy: sortBy,
      },
    }),
  getAllManagement: (
    keywords,
    pageIndex,
    pageSize,
    startDate,
    finishDate,
    statuses,
    MinPrice,
    MaxPrice,
    CustomerIds,
    sortBy
  ) =>
    req.get("/invoice/management/search", {
      params: {
        keywords,
        PageIndex: pageIndex,
        PageSize: pageSize,
        StartDate: startDate,
        FinishDate: finishDate,
        Statuses: statuses,
        PriceFrom: MinPrice,
        PriceTo: MaxPrice,
        CustomerIds: CustomerIds,
        SortBy: sortBy,
      },
    }),
  createCreditMemo: (invoiceId, reason) =>
    req.post("/creditnote/management/create", {
      InvoiceId: invoiceId,
      Reason: reason,
    }),
  listCreditMemo: (
    invoiceId = "",
    keywords = "",
    pageindex = 0,
    pagesize = 10,
    priceFrom = "",
    priceTo = "",
    customerIds = "",
    customerName = "",
    statuses = "",
    startDate = "",
    finishDate = "",
    sortBy = ""
  ) =>
    req.get(
      `/creditnote/management/search?PageIndex=${pageindex}&PageSize=${pagesize}&InvoiceId=${invoiceId}&Keywords=${keywords}&PriceFrom=${priceFrom}&PriceTo=${priceTo}&CustomerIds=${customerIds}&CustomerName=${customerName}&Statuses=${statuses}&StartDate=${startDate}&EndDate=${finishDate}&SortBy=${sortBy}`
    ),
  send: (invoiceid) => req.post(`/invoice/management/${invoiceid}/send`),
});
