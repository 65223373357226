import React from "react";
import moment from "moment";
import {
  getFontSize,
  OrderStatus,
  TabName,
  superadmin,
} from "../../../../../constants/defaults";
import { Icons } from "../../../../../constants/icons";
import {
  OrderStatusDesc,
  OrderPaidStatusDesc,
} from "../../../../../constants/types";
import { Link } from "react-router-dom";
import { Endpoints } from "../../../../../constants/endpoints";
import base64 from "base-64";
import NumberFormatter from "../../../../../components/common/NumberFormatter";
import { connect } from "react-redux";

const mapStateToProps = (state) => {
  return {
    common: state.common,
    currentUser: state.auth.currentUser,
  };
};

const ManagementOrderCollapseHeader = (props) => {
  const { item } = props;

  let EstimatedProfitPerc =
    ((item.Offer.OfferPrice - item.Product.VoiduPromotion.EstimatedCost) *
      100) /
    item.Offer.OfferPrice;

  let EstimatedProfit =
    item.Offer.OfferPrice - item.Product.VoiduPromotion.EstimatedCost;

  let ActualProfitPerc =
    ((item.Offer.OfferPrice - item.Product.VoiduPromotion.ActualCost) * 100) /
    item.Offer.OfferPrice;

  let ActualProfit =
    item.Offer.OfferPrice - item.Product.VoiduPromotion.ActualCost;

  const calculateAvailableQuantity = () => {
    const totalQuantity =
      item?.CompletedQuantity + parseInt(item?.BufferedQuantity || 0);

    return totalQuantity - item?.DeliveredQuantity;
  };

  const calculateRemainingQuantity = () => {
    const totalQuantity =
      item?.CompletedQuantity + item?.BufferedQuantity <= item?.Quantity
        ? item?.CompletedQuantity + item?.BufferedQuantity
        : item?.Quantity;

    return item?.Offer.OfferQuantity - totalQuantity;
  };

  return (
    <div className="collapse-card-header" id={`heading${item.Id}`}>
      <div className="header-name">
        <div class="d-flex justify-content-between mb-2">
          <div className="header-name d-flex flex-column gap-2">
            <span>{item.Product.ProductName}</span>
            <span className="mango">{item.Product.VoiduPromotion?.Region}</span>
          </div>
          <div
            className="p-2"
            style={
              item.LastBuyKeyStatus && item.LastKeyTime
                ? {}
                : { display: "none" }
            }
          >
            Current Status:{" "}
            <span>
              {item?.LastBuyKeyStatus === "Completed"
                ? "Received"
                : item?.LastBuyKeyStatus}
              &nbsp; - &nbsp;
              {
                (moment.locale("en"),
                moment(item.LastKeyTime).format("DD MMMM yyyy, H:mm:ss"))
              }
            </span>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-11" onClick={() => props.collapseShow()}>
          <div className="header-body row">
            <div className="order-container column order-info">
              <div className="order-no text-decoration-underline d-flex align-items-center gap-2">
                <Link
                  to={Endpoints.Management.OrderDetail.url.replace(
                    ":" + Endpoints.Management.OrderDetail.url.split(":")[1],
                    item?.Id
                  )}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {item.OrderNumber}
                </Link>
                {item.IsBilled ? (
                  <Link
                    to={Endpoints.Management.Invoices.url
                      .replace(
                        ":" + Endpoints.Management.Invoices.url.split(":")[1],
                        base64.encode(
                          JSON.stringify({
                            DateRange: {},
                            InvoiceStatus: [],
                            InvoicedStatus: "",
                            OnlyActivePromotion: "",
                            OperatingSystems: [],
                            PartnerId: [],
                            Platforms: [],
                            Price: {},
                            Providers: [],
                            Publishers: [],
                            Status: [],
                            Text: item.OrderNumber,
                          })
                        )
                      )
                      .replace(
                        ":" + Endpoints.Management.Invoices.url.split(":")[2],
                        "/0"
                      )
                      .replace(
                        ":" + Endpoints.Management.Invoices.url.split(":")[3],
                        "/0"
                      )}
                    className="view-detail-link"
                  >
                    <img src={Icons.isBilledIcon} />
                  </Link>
                ) : null}
              </div>
              <div className="order-date">
                Created Date <sup>UTC</sup> :{" "}
                {
                  (moment.locale("en"),
                  moment(item.CreatedAt).format("DD MMMM yyyy, H:mm:ss"))
                }
              </div>
              {item.EstimatedFinishDate && (
                <div className="order-date">
                  Est. Finish Time <sup>UTC</sup> :{" "}
                  {
                    (moment.locale("en"),
                    moment(item.EstimatedFinishDate).format(
                      "DD MMMM yyyy, H:mm:ss"
                    ))
                  }
                </div>
              )}
              <div className="order-date">
                Release Date <sup>UTC</sup> :{" "}
                {
                  (moment.locale("en"),
                  moment(item.Product.ProductReleaseDate).format(
                    "DD MMMM yyyy, H:mm:ss"
                  ))
                }
              </div>
            </div>
            <div className="status-container column">
              <div className="status-horizontal-container">
                <div className="status-vertical-container">
                  <img src={Icons.iconUser} className="cloudy-blue"></img>
                  <div className="order-status">{item.Partner.Name}</div>
                </div>
                <div className="status-vertical-container">
                  <img
                    src={OrderStatusDesc(item.Status)?.srcIcon}
                    className={OrderStatusDesc(item.Status)?.iconColor}
                  ></img>
                  <div className="order-status">
                    {OrderStatusDesc(item.Status)?.statusDesc}
                  </div>
                </div>
                <div className="status-vertical-container">
                  <img
                    src={OrderPaidStatusDesc(item.PaidStatus)?.srcIcon}
                    className={OrderPaidStatusDesc(item.PaidStatus)?.iconColor}
                  ></img>
                  <div className="order-status">
                    {OrderPaidStatusDesc(item.PaidStatus)?.statusDesc}
                  </div>
                </div>
                <div className="order-sub-status">
                  {OrderStatusDesc(item.Status)?.statusDesc ===
                    OrderStatus.Processing ||
                  OrderStatusDesc(item.Status)?.statusDesc ===
                    OrderStatus.Completed ||
                  OrderStatusDesc(item.Status)?.statusDesc ===
                    OrderStatus.Closed ||
                  OrderStatusDesc(item.Status)?.statusDesc ===
                    OrderStatus.Paused ||
                  OrderStatusDesc(item.Status)?.subStatus ===
                    OrderStatus.PromotionExpired ? (
                    <div>
                      <div>
                        <span>
                          Available: <b>{calculateAvailableQuantity()}</b> -
                          Remaining: <b>{calculateRemainingQuantity()}</b> -
                          Delivered: <b>{item.DeliveredQuantity}</b>
                        </span>
                      </div>
                      <div>
                        <span>
                          PreCompleted: <b>{item.PreCompletedQuantity}</b> -
                          BackOrdered: <b>{item.BackOrderedQuantity}</b> -
                          Allocated: <b>{item.BufferedQuantity}</b>
                        </span>
                      </div>
                      <div>
                        <span>
                          Quantity Send to Inventory:{" "}
                          <b>{item.InventoryQuantity}</b>
                        </span>
                      </div>
                    </div>
                  ) : (
                    OrderStatusDesc(item.Status)?.subStatus
                  )}
                </div>
              </div>
            </div>
            <div className="price-container column">
              <div className="d-flex flex-column flex-lg-row flex-md-column justify-content-end mb-3">
                <div className="demand-container">
                  <label>Order</label>
                  <div className="multiply">
                    {item.Offer.OfferQuantity + " x"}&nbsp;
                    <NumberFormatter
                      offerPrice={item.Offer.OfferPrice}
                      supVisible
                    />
                  </div>
                </div>
                <div className="total-container">
                  <label>Total</label>
                  <div
                    className="total-amount"
                    style={{
                      fontSize:
                        props.common?.device_info.width > 768 &&
                        getFontSize({
                          textLength:
                            (
                              (item.Offer.OfferPrice
                                ? 0
                                : item.Offer.OfferPrice) *
                              (item.Offer.OfferQuantity
                                ? 0
                                : item.Offer.OfferQuantity)
                            )
                              .toFixed(2)
                              .toString().length + 4,
                          minSize:
                            props.common?.device_info.width > 1400 ? 1 : 1.5,
                          maxSize:
                            props.common?.device_info.width > 1400 ? 1.2 : 1.25,
                        }),
                    }}
                  >
                    <NumberFormatter
                      offerPrice={item.Offer.OfferPrice}
                      offerQuantity={item.Offer.OfferQuantity}
                      supVisible
                    />
                  </div>
                </div>
              </div>

              <div className="d-flex fs-6 justify-content-between w-75">
                <div className="order-history mt-0">Actual Cost</div>
                <div className="d-flex flex-row justify-content-end">
                  <div className="order-history mt-0">
                    <NumberFormatter
                      offerPrice={item.Product.VoiduPromotion.ActualCost}
                      supVisible
                    />
                  </div>
                </div>
              </div>
              {item.Product.VoiduPromotion.ActualCost > 0 && (
                <div className="d-flex fs-6 justify-content-between w-75">
                  <span className="order-history mt-0">Actual Profit</span>
                  <div className="d-flex flex-row justify-content-end">
                    <div
                      className={
                        "order-history mt-0" +
                        (ActualProfitPerc < 0
                          ? " text-danger"
                          : " text-success")
                      }
                    >
                      {`${(ActualProfit * item.Offer.OfferQuantity).toFixed(
                        2
                      )}  (${ActualProfitPerc.toFixed(2)}%)`}
                    </div>
                  </div>
                </div>
              )}

              <div className="d-flex fs-6 justify-content-between w-75">
                <div className="order-history mt-0">Estimated Cost</div>
                <div className="order-history mt-0">
                  <NumberFormatter
                    offerPrice={item.Product.VoiduPromotion.EstimatedCost}
                    supVisible
                  />
                </div>
              </div>

              {props.currentUser?.scope[0] === superadmin && (
                <div className="d-flex fs-6 justify-content-between w-75">
                  <div className="order-history mt-0">RealEstimated Cost</div>
                  <div className="order-history mt-0">
                    <NumberFormatter
                      offerPrice={item.Product.VoiduPromotion.RealEstimatedCost}
                      supVisible
                    />
                  </div>
                </div>
              )}

              {item.Product.VoiduPromotion.EstimatedCost > 0 && (
                <div className="d-flex fs-6 justify-content-between w-75">
                  <span className="order-history mt-0">Estimated Profit</span>
                  <div className="d-flex flex-row justify-content-end">
                    <div
                      className={
                        "order-history mt-0" +
                        (EstimatedProfitPerc < 0
                          ? " text-danger"
                          : " text-success")
                      }
                    >
                      {`${(EstimatedProfit * item.Offer.OfferQuantity).toFixed(
                        2
                      )} (${EstimatedProfitPerc.toFixed(2)}%)`}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="col-1 my-auto d-flex flex-column align-items-end">
          {props.tabName !== TabName.Management.allOrders && (
            <input
              type="checkbox"
              className="m-1"
              checked={props.selectedItems.includes(item.Id)}
              onChange={(ev) => {
                props.changeSelectedItem(item.Id);
              }}
            ></input>
          )}
          <div onClick={() => props.collapseShow()}>
            <img
              src={
                props.showCollapse && props.selectedItemId === item.Id
                  ? Icons.collapseArrowUpIcon
                  : Icons.collapseArrowDownIcon
              }
            ></img>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps)(ManagementOrderCollapseHeader);
