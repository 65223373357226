import React from "react";
import { connect } from "react-redux";
import { getFontSize, OrderStatus } from "../../../../../constants/defaults";
import { getDemandedOrder } from "../../../../../functions/orderValues";
import NumberFormatter from "../../../../../components/common/NumberFormatter";
import moment from "moment";

const mapStateToProps = (state) => {
  return { order: state.order, common: state.common };
};

const OrderModalRightSide = (props) => {
  const isDemandPrice =
    props.order.parentDemandPrice === undefined ||
    props.order.parentDemandPrice === null ||
    props.order.parentDemandPrice === "" ||
    props.order.parentDemandPrice === 0 ||
    Number.isNaN(props.order.parentDemandPrice);

  const isDemandQuantity =
    props.order.parentDemandQuantity === undefined ||
    props.order.parentDemandQuantity === null ||
    props.order.parentDemandQuantity === "" ||
    props.order.parentDemandQuantity === 0 ||
    Number.isNaN(props.order.parentDemandQuantity);

  const { selected_all_my_offer, parentDemandQuantity, parentDemandPrice } =
    props.order;

  const demandedOrder = getDemandedOrder(
    props.order.selected_all_offers?.History,
    false
  );

  return (
    <div className="modal-right-side">
      {props.order.selected_all_offers?.Status === OrderStatus.Processing && (
        <p className="completion-time">
          Estimated Completion Time:
          {Math.floor(
            new Date(props.order.selected_all_offers.EstimatedFinishDate) / 100
          ) > 0
            ? moment(
                props.order.selected_all_offers.EstimatedFinishDate
              ).format(" DD-MM-YYYY H:mm:ss")
            : 0}
        </p>
      )}
      <div className="price1">
        <label>List Price</label>
        <div
          className="total"
          style={{
            fontSize:
              props.common?.device_info.width > 768 &&
              getFontSize({
                textLength:
                  (
                    selected_all_my_offer?.Product.VoiduPromotion.Price *
                    (isDemandQuantity
                      ? demandedOrder?.OfferQuantity
                      : parentDemandQuantity)
                  )
                    .toFixed(2)
                    .toString().length + 4,
                minSize: props.common?.device_info.width > 1400 ? 1.15 : 1.9,
                maxSize: props.common?.device_info.width > 1400 ? 1.4 : 1.75,
              }),
          }}
        >
          {isDemandQuantity ? (
            <NumberFormatter
              offerPrice={demandedOrder?.OfferQuantity}
              offerQuantity={
                selected_all_my_offer?.Product.VoiduPromotion.Price
              }
              supVisible
            />
          ) : (
            <NumberFormatter
              offerPrice={selected_all_my_offer?.Product.VoiduPromotion.Price}
              offerQuantity={parentDemandQuantity}
              supVisible
            />
          )}
        </div>
        <div className="multiply">
          {isDemandQuantity
            ? demandedOrder?.OfferQuantity
            : parentDemandQuantity}
          {" x "}
          {
            <NumberFormatter
              offerPrice={selected_all_my_offer?.Product.VoiduPromotion.Price}
              supVisible
            />
          }
        </div>
      </div>
      <div className="price2">
        <label>Gamevenue demanded:</label>
        <div
          className="total"
          style={{
            fontSize:
              props.common?.device_info.width > 768 &&
              getFontSize({
                textLength:
                  (
                    (isDemandPrice ? 0 : parentDemandPrice) *
                    (isDemandQuantity ? 0 : parentDemandQuantity)
                  )
                    .toFixed(2)
                    .toString().length + 4,
                minSize: props.common?.device_info.width > 1400 ? 1.15 : 1.9,
                maxSize: props.common?.device_info.width > 1400 ? 1.4 : 1.75,
              }),
          }}
        >
          {isDemandPrice || isDemandQuantity ? (
            <NumberFormatter offerPrice={0} offerQuantity={0} supVisible />
          ) : (
            <NumberFormatter
              offerPrice={parentDemandPrice}
              offerQuantity={parentDemandQuantity}
              supVisible
            />
          )}
        </div>
        <div className="multiply">
          {isDemandQuantity ? "0" : parentDemandQuantity}
          {" x "}
          {isDemandPrice ? (
            <NumberFormatter offerPrice={0} supVisible />
          ) : (
            <NumberFormatter offerPrice={parentDemandPrice} supVisible />
          )}
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps)(OrderModalRightSide);
