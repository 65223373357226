import React from "react";
import Pager from "../../../components/Pager";
import Loader from "../../../components/Loader";
import Filter from "../../../components/Filter";
import Content from "../../../components/containers/Content";
import BlankPage from "../../../components/BlankPage";
import { toast } from "react-toastify";
import { getSorting } from "../../../functions/FilterFunctions";
import { connect } from "react-redux";
import { MANAGEMENT_PROMOTION_UPDATE_FIELD } from "../../../constants/actionTypes";
import base64 from "base-64";
import NumberInput from "../../../components/common/NumberInput";
import Button from "../../../components/common/Button";
import MerModal from "../../../components/common/MerModal";
import services from "../../../api/index";
import moment from "moment";

const mapStateToProps = (state) => {
  return {
    promotions: state.promotions,
    pageSize: state.common.pageSize,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onChangeProperty: (key, value) =>
    dispatch({ type: MANAGEMENT_PROMOTION_UPDATE_FIELD, key, value }),
});
class BulkOrder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tableIsLoading: false,
      pageindex: 0,
      products: undefined,
      filter: null,
      sortBy: undefined,
      sortName: "down",
      selectedPartnerId: undefined,
      responseBulkOrder: [],
      btnDisable: false,
      showModal: false,
      partners: [],
      Items: {},
    };
  }

  componentDidMount() {
    this.getPartners();
    this.selectProducts();
  }

  getPartners = (params) => {
    const { keywords = "", pageindex = 0, pagesize = 50 } = params || {};
    Promise.all([services.company.getCompanies(keywords, pageindex, pagesize)])
      .then((res) => {
        this.setState({
          partners: res[0].Items,
        });
        return res;
      })
      .catch((err) => {
        if (err.response?.data?.message)
          toast.error(<div>{err.response?.data.message}</div>);
        return undefined;
      });
  };

  shouldComponentUpdate() {
    let filter = this.props.match.params.filter;
    if (filter !== undefined)
      if (base64.encode(JSON.stringify(this.state.filter)) !== filter) {
        this.setState({ filter: JSON.parse(base64.decode(filter)) });
      }
    return true;
  }

  selectProducts() {
    let ev = this.state.filter;

    this.getProducts({
      keywords: ev?.Text,
      publisherIds: ev?.Publishers?.join(","),
      providerIds: ev?.Providers?.join(","),
      store: ev?.Store,
      sortBy: this.state.sortBy,
      pageIndex: this.state.pageindex,
      onlyActivePromotions:
        ev?.OnlyActivePromotion !== ""
          ? ev?.OnlyActivePromotion === "1"
            ? true
            : false
          : "",
    });
  }

  getProducts(params) {
    this.setState({ tableIsLoading: true });
    const {
      keywords = "",
      validfrom = "",
      validto = "",
      pageindex = this.state.pageindex,
      pagesize = this.props.pageSize,
      name = "",
      sku = "",
      mSku = "",
      ReleaseDateFrom = "",
      ReleaseDateTo = "",
      PublisherIds = "",
      SortBy = "",
      MinPrice = undefined,
      MaxPrice = undefined,
      operatingSystems = "",
      platforms = "",
      published = true,
      promotionStatus = true,
      ProviderIds = "",
    } = params || {};

    this.setState({ pageindex: pageindex });
    return Promise.all([
      services.campaigns.getCampaignsFromManagement(
        keywords,
        validfrom,
        validto,
        pageindex,
        pagesize,
        name,
        sku,
        mSku,
        ReleaseDateFrom,
        ReleaseDateTo,
        PublisherIds,
        MinPrice,
        MaxPrice,
        SortBy,
        operatingSystems,
        platforms,
        published,
        promotionStatus,
        ProviderIds
      ),
    ])
      .then((res) => {
        this.setState({ products: res[0], tableIsLoading: false });
        return res;
      })
      .catch((err) => {
        if (err.response?.data?.message)
          toast.error(<div>{err.response?.data.message}</div>);
        return undefined;
      });
  }

  onChangePageIndex(pageindex) {
    this.onFilter({ pageindex: pageindex });
  }

  onFilter(params) {
    let { filter, sortBy = undefined, pageindex = 0 } = params || {};

    let ev = filter;

    if (ev?.PartnerId.length > 0) {
      if (this.state.selectedPartnerId !== ev.PartnerId[0]) {
        this.setState({ selectedPartnerId: ev.PartnerId[0] });
        return;
      }
    }

    if (ev !== undefined) this.setState({ filter: ev });
    else ev = this.state.filter;
    sortBy = getSorting(sortBy, this.state.sortBy);
    this.setState({ sortBy: sortBy });

    this.getProducts({
      keywords: ev?.Text,
      PublisherIds: ev?.Publishers?.join(","),
      ProviderIds: ev?.Providers?.join(","),
      store: ev?.Store,
      SortBy: sortBy,
      pageindex: pageindex,
    });
  }

  bulkOrder() {
    this.setState({ btnDisable: true });

    const bulkOrderItems = Object.values(this.state.Items)
      .filter((e) => e.demandPrice && e.quantity)
      .map((item) => ({
        promotionId: item.promotionId,
        productId: item.productId,
        demandPrice: item.demandPrice,
        quantity: item.quantity,
      }));

    if (!this.state.selectedPartnerId) {
      toast.error(<div>You must select partner !</div>);
      this.setState({ btnDisable: false });
      return;
    }

    if (bulkOrderItems.length === 0) {
      toast.error(<div>Quantity and Price fields are required!</div>);
      this.setState({ btnDisable: false });
      return;
    }

    Promise.all([
      services.orders.bulkOrder(this.state.selectedPartnerId, bulkOrderItems),
    ])
      .then((res) => {
        if (res[0].filter((item) => item.Status === "Error").length > 0) {
          // deep copy
          const items = JSON.parse(JSON.stringify(this.state.Items));

          res[0].forEach((e) => {
            if (e.Status === "Error") {
              toast.error(<div>{e.Message}</div>);
              return;
            }

            items[e.PromotionId] = {};
            delete items[e.PromotionId];
          });

          this.setState({
            responseBulkOrder: res[0],
            btnDisable: false,
            Items: items,
          });
        } else {
          toast.success(
            <div>Your bulk orders have been placed successfully!</div>
          );
          this.setState({
            Items: {},
            btnDisable: false,
            showModal: false,
          });
        }
      })
      .catch((err) => {
        if (err.response?.data?.message)
          toast.error(<div>{err.response?.data.message}</div>);
        this.setState({ btnDisable: false });
        return undefined;
      });
  }

  onChange(key, value, item) {
    // why number input sending string????

    if (key == "demandPrice") {
      value = parseFloat(value);
    } else {
      value = parseInt(value);

      if (!value) {
        this.state.Items[item.Id] = null;
        return;
      }
    }

    // deep copy
    const values = JSON.parse(JSON.stringify(this.state.Items || {}));

    // update values
    values[item.Id] = this.state.Items[item.Id] || {
      promotionId: item.Id,
      productId: item.Product.Id,
      name: item.Product.Name,
      productRegion: item.Product.Region,
      merkurRegion: item.Region,
    };
    values[item.Id][key] = value;

    this.setState((prev) => ({
      ...prev,
      Items: values,
      responseBulkOrder: [],
    }));
  }

  isError = ({ Id }) => {
    if (
      this.state.responseBulkOrder.length > 0 &&
      this.state.responseBulkOrder.filter(
        (item) => item.Status === "Error" && item.PromotionId === Id
      ).length > 0
    ) {
      return "error-input";
    } else {
      return "";
    }
  };

  getItem({ Id }) {
    return this.state.Items?.[Id] || null;
  }

  render() {
    return (
      <Content pageTitle>
        <div className="d-flex flex-row">
          <h1>Bulk Order</h1>
        </div>
        {this.state.products === undefined ? (
          <Loader />
        ) : (
          <div>
            <div className="row">
              <Filter
                filter={this.state.filter}
                onFilter={(ev, sortBy) => this.onFilter(ev, sortBy)}
                isPublisherAvailable
                isPartnerAvailable
                singlePartnerFilter
                isProviderAvailable
              />
            </div>

            {this.state.tableIsLoading ? (
              <Loader />
            ) : this.state.products.Items.length === 0 ? (
              <BlankPage description="There is no products." />
            ) : (
              <div>
                <div className="table-total-count">
                  Total Count: {this.state.products.TotalCount}
                </div>
                <div className="table-responsive-xl">
                  <table
                    className="table table-centered table-nowrap table-hover mb-0 rounded table-responsive cursor-pointer"
                    id="datatable"
                  >
                    <thead className="thead-light d-short border-0">
                      <tr>
                        <th
                          className="bg-transparent border-0 "
                          onClick={() => {
                            this.setState({
                              sortName:
                                this.state.sortName === "up" ? "down" : "up",
                            });
                            this.onFilter({ sortBy: "Name" });
                          }}
                        >
                          Product Name - Sku
                          <i
                            className={`fas fa-sort-amount-${this.state.sortName} px-2`}
                          ></i>
                        </th>
                        <th className="bg-transparent border-0 ">Publisher</th>
                        <th className="bg-transparent border-0 text-center">
                          Provider Region
                        </th>
                        <th className="bg-transparent border-0 text-center">
                          Merkur Region
                        </th>
                        <th className="bg-transparent border-0 text-center">
                          Valid From
                        </th>
                        <th className="bg-transparent border-0 text-center">
                          Valid To
                        </th>
                        <th className="bg-transparent border-0 ">Price</th>
                        <th className="bg-transparent border-0 ">
                          Stock Quantity
                        </th>
                        <th className="bg-transparent border-0  text-center">
                          Sales Price
                        </th>
                        <th className="bg-transparent border-0  text-center">
                          Quantity
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.products.Items.map((item, index) => {
                        return (
                          <tr
                            className="rounded-1 mb-1 align-middle border-bottom"
                            key={index}
                          >
                            <td className="ps-0">
                              {(
                                <div>
                                  <p>{item.Product.Name}</p>
                                  <p>{item.Product.Sku}</p>
                                </div>
                              ) ?? "-"}
                            </td>
                            <td className="ps-0">
                              {item.Product.Publisher ?? "-"}
                            </td>
                            <td className="ps-0 text-center">
                              {item.Product.Region ?? "-"}
                            </td>
                            <td className="ps-0 text-center">
                              {item.Region ?? "-"}
                            </td>
                            <td className="ps-0 text-center">
                              {item.ValidFrom
                                ? moment
                                    .utc(item.ValidFrom)
                                    .format("DD.MM.yyyy HH:mm:ss")
                                : "-"}
                            </td>
                            <td className="ps-0 text-center">
                              {item.ValidTo
                                ? moment
                                    .utc(item.ValidTo)
                                    .format("DD.MM.yyyy HH:mm:ss")
                                : "-"}
                            </td>
                            <td className="ps-0 text-center">
                              {item.Tier1Price ?? "-"}
                            </td>
                            <td className="ps-0 text-center">
                              {item.Product.StockQuantity === "0"
                                ? "-"
                                : item.Product.StockQuantity}
                            </td>
                            <td
                              className={`ps-0 bulk-order-number-inputs ${this.isError(
                                item
                              )}`}
                            >
                              <NumberInput
                                centerAlign
                                value={this.getItem(item)?.demandPrice}
                                onChange={(ev) => {
                                  this.onChange("demandPrice", ev, item);
                                }}
                                visible
                              ></NumberInput>
                              <div className="min-max-description text-center"></div>
                            </td>
                            <td
                              className={`ps-0 bulk-order-number-inputs quantity ${this.isError(
                                item
                              )}`}
                            >
                              <NumberInput
                                intNumber
                                centerAlign
                                value={this.getItem(item)?.quantity}
                                onChange={(ev) => {
                                  this.onChange("quantity", ev, item);
                                }}
                                visible
                                //minValue={item.MinQuantity}
                                maxValue={item.MaxQuantity}
                              ></NumberInput>
                              <div className="min-max-description text-center">
                                {this.getItem(item)?.quantity &&
                                item.MinQuantity >
                                  this.getItem(item)?.quantity ? (
                                  <span>
                                    Minimum order quantity should be{" "}
                                    {item.MinQuantity}
                                  </span>
                                ) : null}
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <Pager
                  pageIndex={this.state.products.PageIndex}
                  totalPages={this.state.products.TotalPages}
                  totalCount={this.state.products?.TotalCount}
                  onChange={(pageindex) => this.onChangePageIndex(pageindex)}
                />
                <Button
                  // disabled={
                  //   Object.values(this.state.Items).filter(
                  //     (e) => !(e?.demandPrice && e?.quantity)
                  //   ).length || !this.state.selectedPartnerId
                  // }
                  className="bulk-order-save-button float-end"
                  bindEvent={() => this.setState({ showModal: true })}
                  label="Preview"
                />
              </div>
            )}

            <MerModal
              id="order-confirm"
              showModal={this.state.showModal}
              ariaLabelledby="contained-modal-title-vcenter"
              onHide={() => {
                if (!this.state.btnDisable) this.setState({ showModal: false });
              }}
            >
              <div className="order-confirm-modal p-3">
                <div className="d-flex justify-content-center">
                  <h3>Selected Products</h3>
                </div>
                <div className="text-left">
                  <h5>
                    Partner:
                    {" " +
                      this.state.partners.filter(
                        (key) => key.Id === this.state.selectedPartnerId
                      )?.[0]?.Name}
                  </h5>
                </div>
                <div className="table-responsive-xxl">
                  <table
                    className="table table-centered table-nowrap table-hover mb-0 rounded table-responsive cursor-pointer"
                    id="datatable"
                  >
                    <thead className="thead-light d-short border-0">
                      <tr>
                        <th>Product</th>
                        <th>Provider Region</th>
                        <th>Merkur Region</th>
                        <th>Price</th>
                        <th>Quantity</th>
                        <th>#</th>
                      </tr>
                      {Object.values(this.state.Items || {}).map(
                        (item, index) => {
                          if (!item || !item.demandPrice || !item.quantity) {
                            return null;
                          }

                          return (
                            <tr
                              key={`${item.name}-${item.productRegion}-${item.merkurRegion}`}
                            >
                              <td>{item.name}</td>
                              <td>{item.productRegion}</td>
                              <td>{item.merkurRegion}</td>
                              <td>{item.demandPrice}</td>
                              <td>
                                <div
                                  className={
                                    this.getItem(item)?.MinQuantity >
                                      item.quantity && "text-danger"
                                  }
                                >
                                  {item.quantity}
                                </div>
                              </td>
                              <td>
                                <a
                                  type="button"
                                  className="button text px-2 remove-bulk-order"
                                  onClick={() => {
                                    let values = JSON.parse(
                                      JSON.stringify(this.state.Items)
                                    );

                                    values[item.promotionId] = {};
                                    delete values[item.promotionId];

                                    if (Object.values(values).length === 0) {
                                      this.setState({
                                        Items: {},
                                        showModal: false,
                                      });

                                      return;
                                    }

                                    this.setState({
                                      Items: values,
                                      showModal: true,
                                    });
                                  }}
                                >
                                  <span className="mx-1">Remove</span>
                                </a>
                              </td>
                            </tr>
                          );
                        }
                      )}
                    </thead>
                  </table>
                  <div className="d-flex flex-row justify-content-end">
                    <Button
                      disabled={this.state.btnDisable}
                      className="bulk-order-save-button"
                      bindEvent={() => this.bulkOrder()}
                      label="Save"
                    />
                  </div>
                </div>
              </div>
            </MerModal>
          </div>
        )}
      </Content>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BulkOrder);
