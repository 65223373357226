import React, { useEffect, useState } from "react";
import Content from "../../components/containers/Content";
import ProgressBar from "../../components/ProgressBar";
import Loader from "../../components/Loader";
import { toast } from "react-toastify";
import services from "../../api/index";

export default function MyCreditLimit(props) {
  const [accountingInfo, setAccountingInfo] = useState({});
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = () => {
    setLoading(true);
    services.user
      .info()
      .then((res) => {
        setAccountingInfo(res.AccountingInfo);
        setLoading(false);
        return res;
      })
      .catch((err) => {
        if (err.response?.data?.message)
          toast.error(<div>{err.response?.data.message}</div>);
        return undefined;
      });
  };

  const percentage =
    (100 * accountingInfo.Balance) / accountingInfo.TotalCreditLimit;

  const isPercentage =
    accountingInfo.Balance === null ||
    accountingInfo.TotalCreditLimit === null ||
    accountingInfo.Balance === undefined ||
    accountingInfo.TotalCreditLimit === undefined ||
    accountingInfo.Balance === "" ||
    accountingInfo.TotalCreditLimit === "" ||
    accountingInfo.Balance === 0 ||
    accountingInfo.TotalCreditLimit === 0;

  return (
    <Content pageTitle="Credit Limit (Monthly)">
      {isLoading ? (
        <Loader />
      ) : (
        <div>
          <div className="col-6 col-xl-4">
            <ProgressBar
              value={`$${accountingInfo.Balance}`}
              percentage={
                isPercentage ? 0 : percentage === Infinity ? 0 : percentage
              }
              subVariants={[
                {
                  label: "Balance",
                  value: accountingInfo.Balance,
                },
                {
                  label: "Total",
                  value: accountingInfo.TotalCreditLimit,
                },
              ]}
            />
          </div>
        </div>
      )}
    </Content>
  );
}
