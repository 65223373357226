import React from "react";
import { connect } from "react-redux";
import {
  LOGOUT,
  REDIRECT,
  GET_BASKET,
  RETURN_URL,
} from "../constants/actionTypes";
import { salesman, superadmin, finance, partner } from "../constants/defaults";
import Button from "./common/Button";
import { Icons } from "../constants/icons";
import { toast } from "react-toastify";
import services from "../api/index";

const mapStateToProps = (state) => ({
  ...state.settings,
  Basket: state.basket,
});

const mapDispatchToProps = (dispatch) => ({
  onClickLogout: () => dispatch({ type: LOGOUT }),
  onRedirect: (key) => dispatch({ type: REDIRECT, redirectTo: key }),
  basketOnLoad: (payload) => dispatch({ type: GET_BASKET, payload }),
  onReturnUrl: () => dispatch({ type: RETURN_URL, undefined }),
});

class Navbar extends React.Component {
  async getBasket() {
    this.props.basketOnLoad(
      Promise.all([services.product.getBasket()])
        .then((res) => {
          return res;
        })
        .catch((err) => {
          if (err.response?.data?.message)
            toast.error(<div>{err.response?.data.message}</div>);
          return undefined;
        })
    );
  }

  componentDidMount() {
    if (this.props.currentUser !== null)
      if (this.props.currentUser.scope[0] === partner) this.getBasket();
  }

  shouldComponentUpdate(nextProps) {
    if (this.props.Basket !== nextProps.Basket) {
      return true;
    }
    return false;
  }

  logout = () => {
    localStorage.removeItem("jwt");
    this.props.onClickLogout();
    this.props.onRedirect("/login");
    this.props.onReturnUrl();
  };

  render() {
    const { basket } = this.props.Basket;
    return (
      <main className="content">
        <nav className="navbar navbar-top navbar-expand navbar-dashboard navbar-dark ps-0 pe-2 pb-0">
          <div className="container-fluid">
            <div className="d-block mb-4 mb-md-0 d-none">
              <nav aria-label="breadcrumb" className="d-none d-md-inline-block">
                <ol className="breadcrumb breadcrumb-dark breadcrumb-transparent m-0">
                  <li className="breadcrumb-item">
                    <a href="#">
                      <span className="fas fa-home"></span>
                    </a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="#">Merkur</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Promotions
                  </li>
                </ol>
              </nav>
            </div>
            <div className="d-block mb-4 mb-md-0"></div>
            <div
              className="d-flex justify-content-end"
              id="navbarSupportedContent"
            >
              <div className="d-flex align-items-center">
                <ul className="navbar-nav align-items-center">
                  <li className="px-2">
                    <div className="text-black text-right font-base fw-bold lh-sm ">
                      Welcome,
                      <div className="mb-0 ">
                        {this.props.currentUser.FirstName +
                          " " +
                          this.props.currentUser.LastName}
                        !
                      </div>
                    </div>
                  </li>
                  <li
                    className="nav-item dropdown"
                    style={{
                      display:
                        this.props.currentUser.scope.filter(
                          (item) => item === salesman || item === superadmin
                        ).length > 0
                          ? "none"
                          : "inline",
                    }}
                  >
                    <a
                      className="nav-link text-dark icon-cart"
                      data-unread-notifications="true"
                      onClick={() => this.props.onRedirect("/basket")}
                      role="button"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <span className="icon icon-sm">
                        <img
                          src={Icons.basketIcon}
                          className="card-img-top border-white basket-icon"
                        />
                        <span
                          className="basket-count position-absolute"
                          value={
                            basket !== undefined ? basket[0]?.Items.length : "0"
                          }
                        ></span>
                      </span>
                    </a>
                  </li>

                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle pt-1 px-0"
                      href="#"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <div className="media d-flex align-items-center">
                        <div id="profileSmallImage">
                          {this.props.currentUser.FirstName.slice(
                            0,
                            1
                          ).toUpperCase()}
                          {this.props.currentUser.LastName.slice(
                            0,
                            1
                          ).toUpperCase()}
                        </div>
                      </div>
                    </a>
                    <div className="dropdown-menu dashboard-dropdown dropdown-menu-end  pb-3 border-0">
                      <div className="d-flex flex-column flex-lg-row justify-content-around text-center text-lg-left pt-4 px-4 pb-2">
                        <div className="profile-container">
                          <div id="profileImage">
                            {this.props.currentUser.FirstName.slice(
                              0,
                              1
                            ).toUpperCase()}
                            {this.props.currentUser.LastName.slice(
                              0,
                              1
                            ).toUpperCase()}
                          </div>
                        </div>
                        <div className="p-2 mt-2">
                          <h5 className=" h2 text-center">
                            {this.props.currentUser.FirstName +
                              " " +
                              this.props.currentUser.LastName}
                          </h5>
                          <div className="min-mail-area mb-2">
                            {this.props.currentUser.Email}
                          </div>
                          <div className="d-flex flex-column flex-lg-row justify-content-around text-center   ">
                            <Button
                              className="my-account-button px-3 w-100 mb-1"
                              label="My Account"
                              bindEvent={() =>
                                this.props.onRedirect("/myaccount")
                              }
                              buttonIcon={Icons.iconUsers}
                              iconWidth={20}
                              iconHeight={20}
                            />

                            <Button
                              className="logout-button px-3 w-100 mb-1"
                              label="Logout"
                              bindEvent={() => this.logout()}
                              buttonIcon={Icons.logoutIcon}
                              iconWidth={20}
                              iconHeight={20}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </main>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
