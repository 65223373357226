import { routerReducer } from "react-router-redux";
import { combineReducers } from "redux";
import auth from "./reducers/auth";
import common from "./reducers/common";
import order from "./reducers/order";
import management from "./reducers/management";
import product from "./reducers/product";
import promotions from "./reducers/promotions";
import basket from "./reducers/basket";
import invoice from "./reducers/invoice";
import report from "./reducers/report";
import allocations from "./reducers/allocations";
import companies from "./reducers/companies";
import permission from "./reducers/permission";
import vendors from "./reducers/vendors";

export default combineReducers({
  auth,
  common,
  management,
  product,
  order,
  promotions,
  basket,
  invoice,
  router: routerReducer,
  report,
  companies,
  allocations,
  permission,
  vendors,
});
