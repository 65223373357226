export default (req) => ({
  createPromotionFromGoogleSheet: () =>
    req.post("/sheet/management/createpromotions"),
  updateGoogleSheet: () => req.put("/sheet/management/update"),
  updateUpcomingGoogleSheet: (promotionId) =>
    req.put("/sheet/management/update/" + promotionId + "/upcoming"),
  updateActiveGoogleSheet: (promotionId) =>
    req.put("/sheet/management/update/" + promotionId + "/active"),
  createinventoryreport: () =>
    req.post("/sheet/management/createinventoryreport"),
  genbaUpcomingPromotions: (promotionId) =>
    req.put(`/sheet/management/updateUpcomingPromotion/` + promotionId, {}),
  genbaActivePromotions: (promotionId) =>
    req.put(`/sheet/management/updateActivePromotion/` + promotionId, {}),
});
