import {
  USERS_PAGE_LOADED,
  MANAGEMENT_GET_PRODUCT,
  CHANGE_PRICE_VALUE,
  GET_REGIONS,
  SELECTED_UPDATE_USER,
} from "../constants/actionTypes";

const defaultState = {
  selectedProduct: undefined,
  getProduct: undefined,
  regionList: [],
  selectedPromotionId: undefined,
};
export default (state = defaultState, action) => {
  switch (action.type) {
    case SELECTED_UPDATE_USER:
      return {
        ...state,
        selectedUpdateUser: action.value,
      };
    case USERS_PAGE_LOADED:
      return {
        ...state,
        userList: action.payload,
      };

    case MANAGEMENT_GET_PRODUCT:
      return {
        ...state,
        getProduct: action.payload,
      };
    case CHANGE_PRICE_VALUE:
      return {
        ...state,
        getProduct: state.getProduct.map(
          (product) =>
            (product = {
              ...product,
              Prices: product.Prices.map((price) =>
                price.id === action.id
                  ? { ...price, Price: action.price }
                  : price
              ),
            })
        ),
      };
    case GET_REGIONS:
      return {
        ...state,
        regionList: action.payload,
      };
  }

  return state;
};
