import React from "react";
import { Icons } from "../constants/icons";

export default function BlankPage(props) {
  return (
    <div className="blank-page">
      <div className="blank-page-container">
        <div className="blank-page-mask-icon">
          <img src={Icons.maskIcon}></img>
        </div>
        <div className="blank-page-description">
          <label>{props.description}</label>
        </div>
      </div>
    </div>
  );
}
