import React from "react";
import { waitTimeOut } from "../../functions/FilterFunctions";

function SelectList(props) {
  const {
    list,
    disable,
    selectedList,
    onFilter,
    listOnChecked,
    onRemoveName,
    Title,
  } = props;

  return (
    <>
      {disable === false && (
        <>
          <div className=" user-input">
            <label htmlFor="userRole" className="form-label">
              {Title}
            </label>
          </div>
          <div className="select-list border-bottom">
            <div className="btn-group rounded-1 w-100">
              <button
                type="button"
                className="btn btn-transparent shadow-none "
                data-bs-toggle="dropdown"
                aria-expanded="false"
                data-bs-auto-close="outside"
                id="providers"
                style={
                  selectedList.length > 0
                    ? { color: "#264EA7" }
                    : { color: "#A4B9E8" }
                }
              >
                <span className="text">
                  {selectedList.length === 0
                    ? "Select"
                    : selectedList?.map((m) => m.Name + ", ")}
                </span>
                <span className="badge">
                  {selectedList.length > 0 && selectedList.length}
                </span>
              </button>

              <div className="dropdown-menu border-0 p-1 py-1">
                <div className="input-group flex-nowrap align-items-center search px-4 pt-3">
                  <input
                    onChange={(ev) => {
                      let keywords = ev.target.value;
                      waitTimeOut(() => {
                        onFilter({ keywords: keywords });
                      });
                    }}
                    type="text"
                    className="form-control bg-white border-0 rounded-0 shadow-none "
                    placeholder="Search"
                    aria-describedby="addon-wrapping"
                    id="provider-filter-search"
                  />
                </div>
                <div id="provider-main">
                  {selectedList?.map((item, key) => {
                    return (
                      <div key={key} className="provider-tag px-2">
                        {item.Name}
                        <i
                          onClick={() => onRemoveName(item)}
                          className="fas fa-times mx-2"
                        />
                      </div>
                    );
                  })}
                </div>
                <div className="px-3 py-4 pb-3">
                  {list?.map((item, key) => {
                    return (
                      <div className="form-check" key={key}>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value={item.Id}
                          id={"list_" + item.Id}
                          onChange={(ev) => listOnChecked(ev, item.Name)}
                          checked={
                            selectedList.filter((f) => f.Id === item.Id)
                              .length > 0
                          }
                        />
                        <label
                          className="form-check-label fw-filter"
                          htmlFor={"list_" + item.Id}
                        >
                          {item.Name}
                        </label>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default SelectList;
