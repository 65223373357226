import React from "react";
import { Countries_Regions } from "../../../../../constants/defaults";
import NumberInput from "../../../../../components/common/NumberInput";
import PromotionHeader from "../../../../../components/common/PromotionHeader";
import { connect } from "react-redux";
import { MANAGEMENT_PROMOTION_UPDATE_FIELD } from "../../../../../constants/actionTypes";
import Loader from "../../../../../components/Loader";
import { toast } from "react-toastify";
import services from "../../../../../api/index";

const mapStateToProps = (state) => {
  return {
    promotions: state.promotions,
  };
};
const mapDispatchToProps = (dispatch) => ({
  onChangePercentage: (price) => {
    dispatch({
      type: MANAGEMENT_PROMOTION_UPDATE_FIELD,
      key: "changePercentage",
      value: price,
    });
  },
  onCalculatedAverage: (price) => {
    dispatch({
      type: MANAGEMENT_PROMOTION_UPDATE_FIELD,
      key: "calculatedAverage",
      value: price,
    });
  },
});

class SetPercentages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      regionCountry: Countries_Regions.filter((i, n) => {
        return [
          ...new Set(
            [].concat(
              ...this.props.promotions.selectedRegion.map(
                (item) => item.Countries
              )
            )
          ),
        ].find((o) => o === i.alpha2);
      }).reduce((result, currentValue) => {
        (result[currentValue["region"]] =
          result[currentValue["region"]] || []).push(currentValue);
        return result;
      }, {}),
      isLoading: true,
    };
    this.changePercentage(this.defaultPercentage());
  }

  componentDidMount() {
    this.defaultPercentage();
    this.setState({ isLoading: false });
  }

  defaultPercentage = () => {
    let countriesPercentage = [];
    for (
      let regionIndex = 0;
      regionIndex < this.props.promotions.selectedRegion?.length;
      regionIndex++
    ) {
      for (
        let index = 0;
        index <
        this.props.promotions.selectedRegion[regionIndex].Countries?.length;
        index++
      ) {
        countriesPercentage.push({
          code: this.props.promotions.selectedRegion[regionIndex].Countries[
            index
          ],
          weight: 0,
        });
        if (this.props.promotions.changePercentage.length !== 0) {
          for (
            let countryPercentageIndex = 0;
            countryPercentageIndex < countriesPercentage.length;
            countryPercentageIndex++
          ) {
            let findItem = this.props.promotions.changePercentage.filter(
              (ff) =>
                ff.code === countriesPercentage[countryPercentageIndex].code
            );
            if (findItem.length !== 0) {
              countriesPercentage[countryPercentageIndex].weight =
                findItem[0].weight;
            }
          }
        }
      }
    }
    this.props.onChangePercentage(countriesPercentage);
    return countriesPercentage;
  };

  changePercentage = (ev, code) => {
    let countriesPercentage = this.defaultPercentage();

    let arr =
      this.props.promotions?.changePercentage.length === 0
        ? countriesPercentage
        : this.props.promotions?.changePercentage;
    for (let index = 0; index < arr.length; index++) {
      if (arr[index].code === code) {
        arr[index].weight = ev;
      }
    }
    let totalPercentage = arr.reduce((a, v) => (a += parseInt(v.weight)), 0);
    if (totalPercentage === 100) {
      this.getAverage();
    } else {
      this.props.onCalculatedAverage(null);
    }
    this.props.onChangePercentage(arr);
  };

  getAverage = () => {
    let selectedProductId = this.props.promotions?.selectedProduct.Id;
    let selectedCountries = this.props.promotions?.changePercentage;

    services.product
      .getAverage(selectedCountries, selectedProductId)
      .then((data) => {
        this.props.onCalculatedAverage(data);
      })
      .catch((err) => {
        if (err.response?.data?.message)
          toast.error(<div>{err.response?.data.message}</div>);
      });
  };

  render() {
    const keys = Object.keys(this.state.regionCountry);

    let percentageSum = Array.from(
      new Map(
        this.props.promotions.changePercentage.map((item) => [
          item.code,
          item.weight,
        ])
      ).values()
    ).reduce((sum, weight) => sum + parseInt(weight === "" ? 0 : weight), 0);

    /*
     -- old calculated twice code error
    let percentageSum = uniquePromotions?.reduce((sum, current) => {
      console.log(
        "sum + weight:" +
          sum +
          " + " +
          parseInt(current.weight === "" ? 0 : current.weight)
      );
      return sum + parseInt(current.weight === "" ? 0 : current.weight);
    }, 0);
    */
    percentageSum = percentageSum < 0 ? 0 : percentageSum;
    return (
      <>
        {this.state.isLoading ? (
          <Loader />
        ) : (
          <>
            <PromotionHeader
              product={this.props.promotions.selectedProduct?.Name}
              title={this.props.title}
              goToSelectGame={() => this.props.GoToSelectGame()}
            />
            <div className="d-flex flex-row justify-content-center">
              <div className="promotion-percentage-price-box pe-0">
                <div className="table-responsive">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th></th>
                        <th className="promotion-country-list-table-header text-center">
                          Cost (€)
                        </th>
                        <th className="promotion-country-list-table-header text-center">
                          Sales Price
                        </th>
                        <th className="promotion-country-list-table-header text-start w-25">
                          Percentage
                        </th>
                      </tr>
                    </thead>
                    {keys.map((key, keyIndex) => {
                      return (
                        <tbody key={keyIndex} className="set-percentages-table">
                          <tr>
                            <td colSpan={4}>
                              <h5 className="promotion-country-list-region-header ms-5">
                                {key}
                              </h5>
                            </td>
                          </tr>
                          {this.state.regionCountry[key].map(
                            (item, countryKeyIndex) => {
                              return (
                                <tr key={countryKeyIndex}>
                                  <td>
                                    <span
                                      className={
                                        "flag-icon flag-icon-" +
                                        item.alpha2.toLowerCase()
                                      }
                                    ></span>
                                    <span className="ms-1">{item.name}</span>
                                  </td>
                                  <td className="text-center">
                                    <div>
                                      {Math.round(
                                        this.props.promotions.selectedRegion.filter(
                                          (m) =>
                                            m.Countries.find(
                                              (f) => f === item.alpha2
                                            )
                                        )[0]?.Cost * 100
                                      ) / 100}
                                    </div>
                                  </td>
                                  <td className="text-center">
                                    <div>
                                      {Math.round(
                                        this.props.promotions.selectedRegion.filter(
                                          (m) =>
                                            m.Countries.find(
                                              (f) => f === item.alpha2
                                            )
                                        )[0]?.Price * 100
                                      ) / 100}
                                    </div>
                                  </td>
                                  <td className="number-promotion-inputs">
                                    <NumberInput
                                      id="promotion-input"
                                      value={
                                        this.props.promotions.changePercentage?.filter(
                                          (f) => {
                                            return f.code === item.alpha2;
                                          }
                                        )[0]?.weight
                                      }
                                      onChange={(ev) =>
                                        this.changePercentage(ev, item.alpha2)
                                      }
                                      minValue="0"
                                      visible
                                      percentage
                                      intNumber
                                    ></NumberInput>
                                  </td>
                                </tr>
                              );
                            }
                          )}
                        </tbody>
                      );
                    })}
                  </table>
                </div>
              </div>
            </div>
            <div className=" pe-0 percentage-sum-content">
              <div className="percentage-sum">
                <div className="percentage-sum-p">
                  {"Total: "}
                  <span className={percentageSum > 100 ? "danger" : "success"}>
                    {Number.isNaN(percentageSum) ? 0 : percentageSum}
                  </span>
                  {" / 100"}
                </div>
              </div>
            </div>

            <div className="promotion-country-list-footer">
              <h5>
                {this.props.promotions?.calculatedAverage?.Cost &&
                  `Avg. Cost: ${this.props.promotions?.calculatedAverage?.Cost}`}
              </h5>
              <h5>
                {this.props.promotions?.calculatedAverage?.Price &&
                  `Avg. Sales Price: ${this.props.promotions?.calculatedAverage?.Price}`}
              </h5>
            </div>
          </>
        )}
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SetPercentages);
