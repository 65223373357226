import React, { useState, useEffect } from "react";
import Button from "../../../../components/common/Button";
import { toast } from "react-toastify";
import { Icons } from "../../../../constants/icons";
import { connect } from "react-redux";
import { Endpoints } from "../../../../constants/endpoints";
import { Link } from "react-router-dom";

const mapStateToProps = (state) => {};

const mapDispatchToProps = (dispatch) => ({});

function ListInvoiceLinesModal(props) {
  return (
    <div className="container order-modal">
      <div className="d-flex my-4 mx-2 flex-column justify-content-start">
        <div className="go-back">
          <img src={Icons.goBackIcon} />
          <Button
            className="go-back-button"
            bindEvent={() => props.handleClose()}
            label="Go back"
          />
        </div>
        <div className="text-center">
          <h2>Orders</h2>
        </div>
        <div className="table-responsive py-3 px-6">
          <table
            className="table table-centered table-nowrap table-hover mb-0 rounded table-responsive cursor-pointer "
            id="datatable"
          >
            <thead className="thead-light d-short border-0">
              <tr>
                <th className="bg-transparent border-0 px-1">Order Number</th>
                <th className="bg-transparent border-0 px-4">Product Name</th>
                <th className="bg-transparent border-0 px-4">Quantity</th>
                <th className="bg-transparent border-0 ">Sku</th>
              </tr>
            </thead>
            <tbody>
              {props.selectedInvoice !== undefined &&
                props.selectedInvoice.LineItems.map((item) => (
                  <tr className="rounded-1 mb-1 align-middle border-bottom">
                    <td className="px-2 text-center">
                      {item.OrderNumber}
                      <Link
                        to={Endpoints.Management.OrderDetail.url.replace(
                          ":" +
                            Endpoints.Management.OrderDetail.url.split(":")[1],
                          item?.OrderId
                        )}
                        className="invoice-order-detail-link"
                      >
                        <img className="mb-1" src={Icons.rightArrowIcon} />
                      </Link>
                    </td>
                    <td className="px-2 text-center">{item.Name}</td>
                    <td className="px-2 text-center">{item.Quantity}</td>
                    <td className="px-2 text-center">{item.Sku}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListInvoiceLinesModal);
