import React, { useState, useEffect } from "react";
import { Icons } from "../../../../../constants/icons";
import Button from "../../../../../components/common/Button";
import services from "../../../../../api/index";

const ManagementOrderCancelModal = (props) => {
  const [sendBackOrderedItemsToInventory, setSendBackOrderedItemsToInventory] =
    useState(true);
  const [inventoryCount, setInventoryCount] = useState(-1);

  const GetInventoryCount = () => {
    services.orders.getInventoryCount(props.Id).then((res) => {
      setInventoryCount(res);
    });
  };

  useEffect(() => {
    GetInventoryCount();
  });

  return (
    <div className="order-confirm-modal send-inventory-confirm">
      <div className="order-confirm-icon">
        <img src={Icons.maskIcon}></img>
      </div>
      <div className="order-confirm-title">
        <label>
          Back Ordered Items will be sent to the inventory, are you sure to
          proceed?
        </label>
        {inventoryCount !== -1 && <label>({inventoryCount} items)</label>}
      </div>

      <div className="px-4 p-2 rounded-1 m-auto">
        <div className="d-flex">
          <div className="form-check form-switch">
            <input
              className="form-check-input form-check-input-partner"
              type="checkbox"
              onChange={(ev) => {
                setSendBackOrderedItemsToInventory(
                  !sendBackOrderedItemsToInventory
                );
              }}
              defaultChecked={sendBackOrderedItemsToInventory}
              id="SendBackOrderedItemsToInventory"
            />
          </div>
          <div className="d-inline fmw-100">
            <div className="d-flex">
              <label
                className="modal-body-demand-text form-check-label m-0"
                htmlFor="SendBackOrderedItemsToInventory"
              >
                Send Back Ordered Items To Inventory
              </label>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Button
          disabled={props.btnDisable}
          className="order-confirm-yes-button"
          bindEvent={() => props.cancel(sendBackOrderedItemsToInventory)}
          label="Cancel Order"
          buttonIcon={Icons.thickIcon}
        />
      </div>
      <div>
        <Button
          className="order-confirm-no-button"
          bindEvent={() => props.showModal()}
          label="Go Back"
          buttonIcon={Icons.leftArrowIcon}
        />
      </div>
    </div>
  );
};

export default ManagementOrderCancelModal;
