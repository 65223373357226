export function getLocalRefreshToken() {
  const jwt = JSON.parse(localStorage.getItem("jwt"));
  return jwt?.RefreshToken;
}

export function getLocalAccessToken() {
  const jwt = JSON.parse(localStorage.getItem("jwt"));
  return jwt?.AccessToken;
}

export function updateLocalAccessToken(token) {
  let jwt = JSON.parse(localStorage.getItem("jwt"));
  jwt.AccessToken = token;
  localStorage.setItem("jwt", JSON.stringify(jwt));
}

export const getJwt = () => {
  if (localStorage.getItem("jwt") != null) {
    JSON.parse(localStorage.getItem("jwt"));
  }
};

export function setJwt(jwt) {
  localStorage.setItem("jwt", JSON.stringify(jwt));
}

export function removeJwt() {
  localStorage.removeItem("jwt");
}
