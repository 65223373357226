import React from "react";
import Pager from "../../../components/Pager";
import Loader from "../../../components/Loader";
import Filter from "../../../components/Filter";
import Content from "../../../components/containers/Content";
import BlankPage from "../../../components/BlankPage";
import { Icons } from "../../../constants/icons";
import Button from "../../../components/common/Button";
import MerModal from "../../../components/common/MerModal";
import PriceModal from "./components/PriceModal";
import { toast } from "react-toastify";
import { defaultDateFormat } from "../../../constants/defaults";
import moment from "moment";
import { getSorting } from "../../../functions/FilterFunctions";
import { Endpoints } from "../../../constants/endpoints";
import { connect } from "react-redux";
import {
  MANAGEMENT_PROMOTION_UPDATE_FIELD,
  REDIRECT,
} from "../../../constants/actionTypes";
import GoogleSheetConfirm from "./components/GoogleSheetConfirm";
import base64 from "base-64";
import services from "../../../api/index";
import RequestProductModal from "./components/RequestProductModal";

const mapStateToProps = (state) => {
  return {
    promotions: state.promotions,
    redirectTo: state.common.redirectTo,
    pageSize: state.common.pageSize,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onChangeProperty: (key, value) =>
    dispatch({ type: MANAGEMENT_PROMOTION_UPDATE_FIELD, key, value }),
  onRedirect: (redirectTo) => dispatch({ type: REDIRECT, redirectTo }),
});
class ProviderPromotions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPriceModal: false,
      selectedProduct: undefined,
      tableIsLoading: false,
      pageindex: 0,
      products: undefined,
      filter: this.props.match.params.filter
        ? JSON.parse(base64.decode(this.props.match.params.filter))
        : null,
      sortBy: undefined,
      sortDate: "down",
      sortName: "down",
      sortSku: "down",
      showConfirmModal: false,
    };
  }

  componentDidMount() {
    document.title = "Provider Products";
    this.selectProducts();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.pageSize !== this.props.pageSize) {
      this.onFilter({ pageindex: 0 });
    }
  }

  shouldComponentUpdate() {
    let filter = this.props.match.params.filter;
    if (filter !== undefined)
      if (base64.encode(JSON.stringify(this.state.filter)) !== filter) {
        this.setState({ filter: JSON.parse(base64.decode(filter)) });
      }
    return true;
  }

  selectProducts() {
    let ev = this.state.filter;

    this.getProducts({
      keywords: ev?.Text,
      publisherIds: ev?.Publishers?.join(","),
      providerIds: ev?.Providers?.join(","),
      promotionIds: ev?.PromotionIds?.join(","),
      store: ev?.Store,
      sortBy: this.state.sortBy,
      pageIndex: this.state.pageindex,
      onlyActivePromotions:
        ev?.OnlyActivePromotion !== ""
          ? ev?.OnlyActivePromotion === "1"
            ? true
            : false
          : "",
    });
  }

  priceModalClose = () => {
    this.setState({ showPriceModal: false });
  };

  priceModalOpen = (item) => {
    this.setState({
      showPriceModal: true,
      selectedProduct: item,
    });
  };

  getProductStockStatus = (sku) => {
    return Promise.all([services.product.getProductStockStatusBySku(sku)])
      .then((res) => {
        //this.setState({ products: res[0], tableIsLoading: false });
        if (res[0]?.StockStatus == 1) {
          toast.success(<div>Product is Stock!</div>);
        } else {
          toast.error(<div>Product isn't Stock! </div>);
        }

        return res;
      })
      .catch((err) => {
        if (err.response?.data?.message)
          toast.error(<div>{err.response?.data.message}</div>);
        return undefined;
      });
  };

  syncProductPrice = (sku) => {
    return Promise.all([services.product.syncProductPriceBySky(sku)])
      .then((res) => {
        //this.setState({ products: res[0], tableIsLoading: false });
        toast.success(<div>Product Price is sync in 1-2 minute!</div>);
        return res;
      })
      .catch((err) => {
        if (err.response?.data?.message)
          toast.error(<div>{err.response?.data.message}</div>);
        return undefined;
      });
  };

  getProducts(params) {
    this.setState({ tableIsLoading: true });
    const {
      keywords,
      name,
      sku,
      mSku,
      validTo,
      validFrom,
      releaseDateFrom,
      releaseDateTo,
      publisherIds,
      providerIds,
      promotionIds,
      store,
      onlyActivePromotions = "true",
      sortBy,
      pageIndex = this.state.pageindex,
      pageSize = this.props.pageSize,
    } = params || {};
    this.setState({ pageindex: pageIndex });
    return Promise.all([
      services.product.getAllProducts(
        keywords,
        name,
        sku,
        mSku,
        validTo,
        validFrom,
        releaseDateFrom,
        releaseDateTo,
        publisherIds,
        providerIds,
        promotionIds,
        store,
        onlyActivePromotions,
        sortBy,
        pageIndex,
        pageSize
      ),
    ])
      .then((res) => {
        this.setState({ products: res[0], tableIsLoading: false });
        return res;
      })
      .catch((err) => {
        if (err.response?.data?.message)
          toast.error(<div>{err.response?.data.message}</div>);
        return undefined;
      });
  }

  onChangePageIndex(pageindex) {
    this.onFilter({ pageindex: pageindex });
  }

  onFilter(params) {
    let {
      filter,
      sortBy = undefined,
      pageindex = 0,
      pageSize = this.props.pageSize,
    } = params || {};

    let ev = filter;
    if (ev !== undefined) this.setState({ filter: ev });
    else ev = this.state.filter;
    sortBy = getSorting(sortBy, this.state.sortBy);
    this.setState({ sortBy: sortBy });

    console.log("Filter: " + JSON.stringify(ev));

    let url = Endpoints.Management.ProviderProducts.url
      .replace(":filter?", base64.encode(ev ? JSON.stringify(ev) : "0"))
      .replace(":sort?", sortBy ? sortBy : "0")
      .replace(":page?", pageindex);
    this.props.onRedirect(url);

    this.getProducts({
      keywords: ev?.Text,
      publisherIds: ev?.Publishers?.join(","),
      providerIds: ev?.Providers?.join(","),
      promotionIds: ev?.PromotionIds?.join(","),
      store: ev?.Store,
      sortBy: sortBy,
      pageIndex: pageindex,
      onlyActivePromotions:
        ev?.OnlyActivePromotion !== ""
          ? ev?.OnlyActivePromotion === "1"
            ? true
            : false
          : "",
      pageSize: pageSize,
    });
  }

  createPromotion(product) {
    this.props.onChangeProperty("selectedProduct", product);
    this.props.onChangeProperty("selectedProductId", product.Id);
    this.props.onRedirect(Endpoints.Management.NewPromotions.url);
    this.props.onChangeProperty("promotionStatus", "Create");
  }

  updateGoogleSheet = () => {
    return Promise.all([
      services.googleSheet
        .updateGoogleSheet()
        .then((res) => {
          toast.success(<div>Google sheet have been updated!</div>);
          this.setState({ showConfirmModal: false });
          return res;
        })
        .catch((err) => {
          if (err.response?.data?.message)
            toast.error(<div>{err.response?.data.message}</div>);
          return undefined;
        }),
    ]);
  };

  render() {
    return (
      <Content>
        <div className="d-flex flex-row">
          <h1>Provider Products</h1>
          <Button
            className="add-update-from-google-sheet mx-3"
            label=""
            bindEvent={() => this.setState({ showConfirmModal: true })}
            buttonIcon={Icons.updateGoogleSheetIcon}
            iconWidth={20}
            iconHeight={20}
            registerTip="update-google-sheet"
            place="right"
            tooltipDesc="Update Google Sheet"
          />
          <RequestProductModal />
        </div>
        {this.state.products === undefined ? (
          <Loader />
        ) : (
          <div>
            <Filter
              filter={this.state.filter}
              onFilter={(ev, sortBy) => this.onFilter(ev, sortBy)}
              isStoreAvailable
              isProviderAvailable
              isPublisherAvailable
              isActivePromotion
            />
            {this.state.tableIsLoading ? (
              <Loader />
            ) : this.state.products.Items.length === 0 ? (
              <BlankPage description="There is no products." />
            ) : (
              <div>
                <div className="table-total-count">
                  Total Count: {this.state.products.TotalCount}
                </div>
                <div className="table-responsive-xl">
                  <table
                    className="table table-centered table-nowrap table-hover mb-0 rounded table-responsive cursor-pointer"
                    id="datatable"
                  >
                    <thead className="thead-light d-short border-0">
                      <tr>
                        <th className="bg-transparent border-0 ">Store</th>
                        <th className="bg-transparent border-0 ">Provider</th>
                        <th
                          className="bg-transparent border-0 "
                          onClick={() => {
                            this.setState({
                              sortName:
                                this.state.sortName === "up" ? "down" : "up",
                            });
                            this.onFilter({ sortBy: "Name" });
                          }}
                        >
                          Product Name
                          <i
                            className={`fas fa-sort-amount-${this.state.sortName} px-2`}
                          ></i>
                        </th>
                        <th
                          className="bg-transparent border-0 "
                          onClick={() => {
                            this.setState({
                              sortDate:
                                this.state.sortDate === "up" ? "down" : "up",
                            });
                            this.onFilter({ sortBy: "ReleaseDate" });
                          }}
                        >
                          Release Date
                          <i
                            className={`fas fa-sort-amount-${this.state.sortDate} px-2`}
                          ></i>
                        </th>
                        <th className="bg-transparent border-0 ">Region</th>
                        <th
                          className="bg-transparent border-0 "
                          onClick={() => {
                            this.setState({
                              sortSku:
                                this.state.sortSku === "up" ? "down" : "up",
                            });
                            this.onFilter({ sortBy: "Sku" });
                          }}
                        >
                          SKU
                          <i
                            className={`fas fa-sort-amount-${this.state.sortSku} px-2`}
                          ></i>
                        </th>
                        <th className="bg-transparent border-0 ">Janus ID</th>
                        <th className="bg-transparent border-0 ">Publisher</th>
                        <th className="bg-transparent border-0 ">
                          Provider Status
                        </th>
                        <th className="bg-transparent border-0 ">Actions</th>
                        <th className="bg-transparent border-0 "></th>
                        <th className="bg-transparent border-0 "></th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.products.Items.map((item, index) => {
                        return (
                          <tr
                            className="rounded-1 mb-1 align-middle border-bottom"
                            key={index}
                          >
                            <td className="ps-0">{item.Store ?? "-"}</td>
                            <td className="ps-0">
                              {item.Provider.Name ?? "-"}
                            </td>
                            <td className="ps-0">{item.Name ?? "-"}</td>
                            <td className="ps-0">
                              {moment
                                .utc(item.ReleaseDate)
                                .format(defaultDateFormat + "\tHH:mm:ss") ??
                                "-"}
                            </td>
                            <td className="ps-0">{item.Region ?? "-"}</td>
                            <td className="ps-0">{item.Sku ?? "-"}</td>
                            <td className="ps-0">{item.JanusId ?? "-"}</td>
                            <td className="ps-0">
                              {item.Publisher.Name ?? "-"}
                            </td>
                            <td className="ps-0">
                              {item.PromotionStatus ? "Active" : "Passive"}
                            </td>
                            <td className="ps-0">
                              <div class="dropdown">
                                <button
                                  class="btn btn-light dropdown-toggle"
                                  type="button"
                                  id="providerProductActionMenu"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  ...
                                </button>
                                <ul
                                  class="dropdown-menu"
                                  aria-labelledby="providerProductActionMenu"
                                >
                                  <li>
                                    <a
                                      className="dropdown-item px-4"
                                      onClick={() =>
                                        this.getProductStockStatus(item.Sku)
                                      }
                                    >
                                      Check Stock
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      className="dropdown-item px-4"
                                      onClick={() => this.priceModalOpen(item)}
                                    >
                                      Prices{" "}
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      className="dropdown-item px-4"
                                      onClick={(_item) => {
                                        this.createPromotion(item);
                                      }}
                                    >
                                      Create Promotion
                                    </a>
                                  </li>
                                  {item.Provider.Name == "Genba" ? (
                                    <li className="dropdown-divider"></li>
                                  ) : (
                                    ""
                                  )}
                                  {item.Provider.Name == "Genba" ? (
                                    <li>
                                      <a
                                        className="dropdown-item px-4"
                                        onClick={() =>
                                          this.syncProductPrice(item.Sku)
                                        }
                                      >
                                        Sync Product Price
                                      </a>
                                    </li>
                                  ) : (
                                    ""
                                  )}
                                </ul>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <Pager
                  pageIndex={this.state.products.PageIndex}
                  totalPages={this.state.products.TotalPages}
                  totalCount={this.state.products?.TotalCount}
                  onChange={(pageindex) => this.onChangePageIndex(pageindex)}
                />
                <MerModal
                  id="order-confirm"
                  showModal={this.state.showPriceModal}
                  ariaLabelledby="contained-modal-title-vcenter"
                  onHide={() => this.priceModalClose()}
                  scrollable
                  size="xl"
                >
                  <PriceModal
                    selectedProduct={this.state.selectedProduct}
                    handleClose={() => this.priceModalClose()}
                  />
                </MerModal>
                <MerModal
                  id="order-confirm"
                  showModal={this.state.showConfirmModal}
                  ariaLabelledby="contained-modal-title-vcenter"
                  onHide={() => this.setState({ showConfirmModal: false })}
                >
                  <GoogleSheetConfirm
                    handleClose={() =>
                      this.setState({ showConfirmModal: false })
                    }
                    updateGoogleSheet={() => this.updateGoogleSheet()}
                  ></GoogleSheetConfirm>
                </MerModal>
              </div>
            )}
          </div>
        )}
      </Content>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProviderPromotions);
