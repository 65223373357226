import { REDIRECT, SELECTED_PAGESIZE } from "./constants/actionTypes";
import jwt_decode from "jwt-decode";
import { Endpoints } from "./constants/endpoints";
import { partner } from "./constants/defaults";

const isPromise = (v) => {
  return v && typeof v.then === "function";
};
// SELECTED_PAGESIZE
const localStorageMiddleware = (store) => (next) => (action) => {
  if (action.type === SELECTED_PAGESIZE) {
    localStorage.setItem("pagesize", action.payload);
  }
  next(action);
};

const promiseMiddleware = (store) => (next) => (action) => {
  if (isPromise(action.payload)) {
    action.payload.then(
      (res) => {
        action.payload = res;
        store.dispatch(action);
      },
      (error) => {
        action.error = true;
        action.payload = error;
        store.dispatch(action);
      }
    );

    return;
  }

  next(action);
};

const redirectMiddleware = (store) => (next) => (action) => {
  const jwt = JSON.parse(localStorage.getItem("jwt"));
  const decoded_jwt = jwt ? jwt_decode(jwt?.AccessToken) : null;
  if (decoded_jwt !== null && action.type !== REDIRECT)
    if (
      decoded_jwt?.scope.includes(partner) &&
      window.location.pathname.includes("management")
    ) {
      Redirect(store, Endpoints.Partner.Home.url);
    }
  const currentState = store.getState();

  if (
    window.location.pathname.includes("/login") &&
    currentState.auth.jwt &&
    action.type !== REDIRECT &&
    decoded_jwt !== null
  )
    Redirect(
      store,
      decoded_jwt?.scope.includes(partner)
        ? Endpoints.Partner.Home.url
        : Endpoints.Management.Home.url
    );

  if (window.location.pathname === "/" && action.type !== REDIRECT) {
    if (!localStorage.getItem("jwt")) {
      store.dispatch({ type: REDIRECT, redirectTo: "/login" });
    } else {
      Redirect(
        store,
        decoded_jwt?.scope.includes(partner)
          ? Endpoints.Partner.Home.url
          : Endpoints.Management.Home.url
      );
    }
  }

  next(action);
};

const Redirect = (store, url) => {
  store.dispatch({
    type: REDIRECT,
    redirectTo: url,
  });
};

// eslint-disable-next-line import/prefer-default-export
export { promiseMiddleware, redirectMiddleware, localStorageMiddleware };
