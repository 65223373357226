import { useCallback, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";

const useTable = ({ data, fetchData, mapper = (e) => e }) => {
  const [error, setError] = useState(() => null);
  const [items, setItems] = useState(() => null);
  const [loading, setLoading] = useState(() => false);
  const [page, setPage] = useState(() => ({
    PageIndex: 0,
    PageSize: 10,
    TotalCount: 0,
    TotalPages: 0,
    HasPreviousPage: false,
    HasNextPage: false,
  }));

  useEffect(() => {
    if (!fetchData) return () => {};

    (async () => {
      try {
        setLoading(true);
        setError(null);

        const result = await fetchData({
          PageIndex: page.PageIndex,
          PageSize: page.PageSize,
        });

        setPage(() => ({
          PageIndex: result.PageIndex,
          PageSize: result.PageSize,
          TotalCount: result.TotalCount,
          TotalPages: result.TotalPages,
          HasPreviousPage: result.HasPreviousPage,
          HasNextPage: result.HasNextPage,
        }));

        setItems(() => result.Items);
      } catch (err) {
        setError(err);
        toast.error(err.message || "Unknown error");
      } finally {
        setLoading(false);
      }
    })();

    return () => {
      // setError(null);
      // setItems(null);
      // setLoading(false);
      // setPage({
      //   PageIndex: 0,
      //   PageSize: 10,
      //   TotalCount: 0,
      //   TotalPages: 0,
      //   HasPreviousPage: false,
      //   HasNextPage: false,
      // });
    };
  }, [fetchData, page.PageIndex, page.PageSize]);

  const onPrevPage = useCallback(() => {
    if (!page.HasPreviousPage) return;

    setPage((prev) => ({ ...prev, PageIndex: prev.PageIndex - 1 }));
  }, [page.HasPreviousPage]);

  const onNextPage = useCallback(() => {
    if (!page.HasNextPage) return;

    setPage((prev) => ({ ...prev, PageIndex: prev.PageIndex + 1 }));
  }, [page.HasNextPage]);

  const onFirstPage = useCallback(() => {
    setPage((prev) => ({ ...prev, PageIndex: 0 }));
  }, []);

  const onLastPage = useCallback(() => {
    setPage((prev) => ({
      ...prev,
      PageIndex: Math.max(prev.TotalPages - 1, 0),
    }));
  }, []);

  const values = useMemo(() => items || data || [], [items, data]);

  return {
    error,
    data: values.map(mapper),
    loading,
    page,
    pagination: {
      onFirst: onFirstPage,
      onLast: onLastPage,
      onPrev: onPrevPage,
      onNext: onNextPage,
      onPageSize: (size) =>
        setPage((prev) => ({ ...prev, PageIndex: 0, PageSize: size })),
    },
  };
};

export default useTable;
