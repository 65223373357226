import { toast } from "react-toastify";

import api from "../../../../../api";

const useOrderItem = ({ data, userScope }) => {
  const { Id, OrderId, Status } = data || {};

  const onRetry = async () => {
    try {
      toast.info("Retrying to get key of order item...");
      await api.orders.retryManagementOrderItem(OrderId, Id);
      toast.success("Order item retry process finished, successfully");
    } catch (error) {
      toast.error(error.message || "Unknown error");
    }
  };

  const onRetryAll = async () => {
    try {
      toast.info("Retrying to get key of all order items...");
      await api.orders.retryAllManagementOrderItem(OrderId);
      toast.success("Order item retry process finished, successfully");
    } catch (error) {
      toast.error(error.message || "Unknown error");
    }
  };

  const onRefundAllBackOrdered = async () => {
    try {
      toast.info("Refunding back-ordered the order items...");
      await api.orders.refundBackOrderedItems(OrderId);
      toast.success("Refund process is finished, successfully");
    } catch (error) {
      toast.error(error.message || "Unknown error");
    }
  };

  const onDeleteError = async () => {
    try {
      toast.info("Refunding back-ordered the order items...");
      await api.orders.deleteManagementOrderItem(OrderId, Id);
      toast.success("Refund process is finished, successfully");
    } catch (error) {
      toast.error(error.message || "Unknown error");
    }
  };

  const onDeleteAllError = async () => {
    try {
      toast.info("Refunding back-ordered the order items...");
      await api.orders.deleteManagementErrorOrderItem(OrderId);
      toast.success("Refund process is finished, successfully");
    } catch (error) {
      toast.error(error.message || "Unknown error");
    }
  };

  return {
    onRetry,
    onRetryAll,
    onRefundAllBackOrdered,
    onDeleteError,
    onDeleteAllError,
  };
};

export default useOrderItem;
