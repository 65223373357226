/* eslint-disable no-prototype-builtins */
import React from "react";
import moment from "moment";
import "moment/locale/tr";
import DatePicker from "react-datepicker";

function DateFilter(props) {
  const {
    dateList,
    onDateChange,
    selectedDateItem,
    customDate,
    filter,
    onChoseCustomDate,
    onChangeCustomDate,
    onDateRangeClear,
  } = props;

  return (
    <>
      {dateList === undefined && <></>}
      {dateList && (
        <div className="filter-price d-block">
          <div className="btn-group pe-2 pt-1 rounded-1 align-items-center">
            <button
              type="button"
              className="btn btn-transparent shadow-none dropdown-toggle mr-2 dropdown-toggle-split "
              data-bs-toggle="dropdown"
              aria-expanded="false"
              data-bs-auto-close="outside"
              id="DateRange"
              style={
                filter.DateRange.hasOwnProperty("min")
                  ? { color: "#264EA7" }
                  : { color: "#A4B9E8" }
              }
            >
              <span className="mx-1 text">Date Range</span>
              <span className="badge">
                {filter.DateRange.hasOwnProperty("min") && 1}
              </span>
            </button>

            <div className="dropdown-menu border-0 p-1 py-1">
              <div className="publisher-filter-list px-3 py-4 pb-3">
                {dateList.map((item, index) => {
                  return (
                    <div className="form-check" key={index}>
                      <input
                        className="form-check-input"
                        type="radio"
                        name="selectedDate"
                        id={`date_${item.name}`}
                        checked={selectedDateItem === index}
                        onChange={(ev) => onDateChange(item, index)}
                      />
                      <label
                        className="form-check-label fw-filter"
                        htmlFor={`date_${item.name}`}
                      >
                        {item.text}
                      </label>
                    </div>
                  );
                })}
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="selectedDate"
                    id="date_choose"
                    checked={customDate}
                    onChange={(ev) => onChoseCustomDate(dateList.length)}
                  />
                  <label
                    className="form-check-label fw-filter"
                    htmlFor="date_choose"
                  >
                    Choose Date
                  </label>
                </div>
                {customDate && (
                  <>
                    <>
                      <label>Start Date:</label>
                      <DatePicker
                        className="form-control rounded-1 bg-white"
                        selected={
                          filter.DateRange.min
                            ? new Date(filter.DateRange.min)
                            : new Date()
                        }
                        onChange={(date) => onChangeCustomDate(date)}
                        dateFormat="dd/MM/yyyy"
                        maxDate={new Date(filter.DateRange.max)}
                      />
                    </>
                    <>
                      <label>End Date:</label>
                      <DatePicker
                        className="form-control rounded-1 bg-white"
                        selected={
                          filter.DateRange.max
                            ? new Date(filter.DateRange.max)
                            : new Date(moment().add(7, "d").toISOString())
                        }
                        onChange={(date) => onChangeCustomDate(date, true)}
                        dateFormat="dd/MM/yyyy"
                        minDate={new Date(filter.DateRange.min)}
                      />
                    </>
                  </>
                )}
              </div>
              {filter.DateRange.hasOwnProperty("min") && (
                <div className="m-2">
                  <button
                    onClick={onDateRangeClear}
                    className="button filter-actions clear-all"
                  >
                    Clear
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default DateFilter;
