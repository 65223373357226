import React, { useCallback } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { toast } from "react-toastify";

import Button from "../../../../../../../components/common/v2/button";
import api from "../../../../../../../api";

const ModalCheckProductStock = ({ data }) => {
  const { order } = data || {};

  const onSubmit = useCallback(async () => {
    try {
      toast.info(<div>Cheking product stock...</div>);
      const result = await api.product.getProductStockStatusBySku(
        order.Product.ProductSku
      );

      if (!result.StockStatus) {
        throw new Error("Product have not any stocks");
      }

      toast.success(<div>Product is in stock.</div>);
    } catch (error) {
      toast.error(<div>{error.message || "Unknown message"}</div>);
    }
  }, [order]);

  if (!order) {
    return <span>Order data is not provided.</span>;
  }

  return <Button onClick={onSubmit} theme="info" title="Check Product Stock" />;
};

export default ModalCheckProductStock;
