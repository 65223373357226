import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

import Modal from "../../../../../../../components/common/v2/modal";
import { OrderItemStatus } from "../../../../../../../constants/defaults";
import Button from "../../../../../../../components/common/v2/button";

import useOrderItem from "../../../hooks/useOrderItem";

const ModalOrderItemRefundAllBackOrdered = ({ data, userScope }) => {
  const { OrderId, Status } = data || {};
  const { onRefundAllBackOrdered } = useOrderItem({ data, userScope });

  return (
    <Modal
      trigger={{ theme: "link-danger", title: "Refund All", type: "link" }}
      accessibility={{
        close: {
          theme: "light",
        },
        submit: {
          onClick: onRefundAllBackOrdered,
          title: "Refund All",
          theme: "danger",
        },
      }}
      title="Refund All Back Ordered Items"
    >
      <div
        className="d-flex flex-column align-items-center gap-4"
        style={{ fontSize: 14 }}
      >
        <div className="d-flex flex-column align-items-center gap-2">
          <h6 className="m-0">All back orders will be refunded to provider</h6>
        </div>
        <div className="d-flex flex-column align-items-center gap-2">
          <h6 className="m-0">Order Id</h6>
          <span>{OrderId}</span>
        </div>
        <div className="d-flex flex-column align-items-center gap-2">
          <h6 className="m-0">Order Status</h6>
          <span>{Status}</span>
        </div>
      </div>
    </Modal>
  );
};

export default ModalOrderItemRefundAllBackOrdered;
