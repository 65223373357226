import React from "react";

function PromotionStatusFilter(props) {
  const { onActivePromotionChecked } = props || {};

  return (
    <>
      {props.isActivePromotion === true && (
        <div className="filter-price d-block">
          <div className="btn-group pe-2 pt-1 rounded-1 align-items-center">
            <button
              type="button"
              className="btn btn-transparent shadow-none dropdown-toggle mr-2 dropdown-toggle-split"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              data-bs-auto-close="outside"
              style={
                props.filter.OnlyActivePromotion
                  ? { color: "#264EA7" }
                  : { color: "#A4B9E8" }
              }
            >
              <span className="mx-1 text">Promotion Status</span>
              <span className="badge">
                {props.filter.OnlyActivePromotion !== "" && "1"}
              </span>
            </button>
            <div className="dropdown-menu border-0">
              <div className="partner-filter-list p-2">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    name="flexRadioDefaultUser"
                    type="radio"
                    value="1"
                    id="invoices_1"
                    onChange={() => onActivePromotionChecked("1")}
                    checked={props.filter.OnlyActivePromotion === "1"}
                  />
                  <label
                    className="form-check-label fw-filter"
                    htmlFor="invoices_1"
                  >
                    Active
                  </label>
                </div>

                <div className="form-check">
                  <input
                    className="form-check-input"
                    name="flexRadioDefaultUser"
                    type="radio"
                    value="0"
                    id="invoices_2"
                    onChange={() => props.onActivePromotionChecked("0")}
                    checked={props.filter.OnlyActivePromotion === "0"}
                  />
                  <label
                    className="form-check-label fw-filter"
                    htmlFor="invoices_2"
                  >
                    Passive
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default PromotionStatusFilter;
