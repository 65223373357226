import React from "react";
import { toast } from "react-toastify";
import Content from "../../../components/containers/Content";
import Button from "../../../components/common/Button";
import { Icons } from "../../../constants/icons";
import Loader from "../../../components/Loader";
import services from "../../../api/index";

class MyAccount extends React.Component {
  constructor() {
    super();
    this.state = {
      password: {
        oldPass: undefined,
        newPass: undefined,
        confirmPass: undefined,
      },
      firstName: undefined,
      lastName: undefined,
      email: undefined,
      userRole: undefined,
      companyName: undefined,
      companyEmail: undefined,
      apiKey: undefined,
      secretKey: "************",
      accountPageLoading: true,
    };
  }

  componentDidMount() {
    this.getUserInfo();
  }

  changePassword = (e) => {
    this.setState({
      password: { ...this.state.password, [e.target.name]: e.target.value },
    });
  };

  getUserInfo = () => {
    Promise.all([services.user.info()])
      .then((res) => {
        this.setState({
          accountPageLoading: false,
          firstName: res[0].FirstName,
          lastName: res[0].LastName,
          email: res[0].Email,
          userRole: res[0].Roles[0],
          apiKey: res[0].ApiKey,
          companyName: res[0].CompanyInfo.Name,
          companyEmail: res[0].CompanyInfo.Email,
        });
        return res;
      })
      .catch((err) => {
        if (err.response?.data?.message)
          toast.error(<div>{err.response?.data.message}</div>);
        return undefined;
      });
  };

  waitTimeOut = (value) => {
    this.setState({ secretKey: value.SecretKey, apiKey: value.ApiKey });
    setTimeout(() => {
      this.setState({ secretKey: "************" });
    }, 30000);
  };

  createSecretKey() {
    Promise.all([services.user.createSecretKey()])
      .then((res) => {
        this.waitTimeOut(res[0]);
        return res;
      })
      .catch((err) => {
        if (err.response?.data?.message)
          toast.error(<div>{err.response?.data.message}</div>);
        return undefined;
      });
  }

  updatePassword = (params) => {
    if (
      (this.state.password.newPass === this.state.password.confirmPass &&
        this.state.password.oldPass !== undefined) ||
      (this.state.password.newPass === this.state.password.confirmPass &&
        this.state.password.newPass !== undefined)
    ) {
      const {
        oldPass = this.state.password.oldPass,
        newPass = this.state.password.newPass,
      } = params || {};
      Promise.all([services.user.updatePartnerPassword(oldPass, newPass)])
        .then((res) => {
          toast.success(<div>Password updated successfuly!</div>);
          return res;
        })
        .catch((err) => {
          if (err.response?.data?.message)
            toast.error(<div>{err.response?.data.message}</div>);
          return undefined;
        });
    } else if (
      this.state.password.oldPass === undefined ||
      this.state.password.newPass === undefined
    ) {
      toast.error(<div>Password cannot be empty!</div>);
    } else {
      toast.error(
        <div>Your confirmation password does not match the new password!</div>
      );
    }
  };

  render() {
    return (
      <Content pageTitle="My Account">
        {this.state.accountPageLoading ? (
          <Loader />
        ) : (
          <div className="container px-xxl-9 mt-3 mb-3">
            <div className="row">
              <div className="col">
                <div className="mb-3 user-input">
                  <label htmlFor="firstName" className="form-label">
                    First Name
                  </label>
                  <p className="form-label">{this.state.firstName}</p>
                </div>
              </div>
              <div className="col">
                <div className="mb-3 user-input">
                  <label className="form-label">Last Name</label>
                  <p className="form-label">{this.state.lastName}</p>
                </div>
              </div>
              <div className="mb-3 user-input">
                <label htmlFor="userRole" className="form-label">
                  Role
                </label>
                <p className="form-label">{this.state.userRole}</p>
              </div>
            </div>
            <div className="mb-3 user-input">
              <label htmlFor="email" className="form-label">
                Email address
              </label>
              <p className="form-label">{this.state.email}</p>
            </div>
            <div className="row">
              <div className="col">
                <div className="mb-3 user-input">
                  <label htmlFor="companyName" className="form-label">
                    Company Name
                  </label>
                  <p className="form-label">{this.state.companyName}</p>
                </div>
              </div>
              <div className="col">
                <div className="mb-3 user-input">
                  <label htmlFor="companyEmail" className="form-label">
                    Company Email
                  </label>
                  <p className="form-label">{this.state.companyEmail}</p>
                </div>
              </div>
            </div>
            <div className="mb-3 user-input">
              <label htmlFor="password" className="form-label">
                Current Password
              </label>
              <input
                type="password"
                id="oldPass"
                name="oldPass"
                placeholder=""
                value={this.state.password.oldPass}
                onChange={this.changePassword}
              />
            </div>
            <div className="mb-3 user-input">
              <label htmlFor="password" className="form-label">
                New Password
              </label>
              <input
                type="password"
                id="newPass"
                name="newPass"
                placeholder=""
                value={this.state.password.newPass}
                onChange={this.changePassword}
              />
            </div>
            <div className="mb-3 user-input">
              <label htmlFor="password" className="form-label">
                Confirm New Password
              </label>
              <input
                type="password"
                id="confirmPass"
                name="confirmPass"
                placeholder=""
                value={this.state.password.confirmPass}
                onChange={this.changePassword}
              />
            </div>
            <div className="text-end">
              <Button
                className="confirm-button px-4 me-0"
                bindEvent={() => this.updatePassword()}
                buttonIcon={Icons.thickIcon}
                label="Update Password"
              />
            </div>
            <div className="row">
              <div className="col-4">
                <div className="user-input">
                  <label className="form-label">Api Key</label>
                  <p className="form-label ">{this.state.apiKey}</p>
                </div>
              </div>
              <div className="col-8">
                <div className="user-input">
                  <div>
                    <label className="form-label">Secret Key</label>

                    <div className="d-flex flex-row justify-content-start align-items-center">
                      <p className="form-label mb-0 ">{this.state.secretKey}</p>
                      {this.state.secretKey !== "************" && (
                        <Button
                          className="copy-button px-2"
                          bindEvent={() => {
                            navigator.clipboard.writeText(this.state.secretKey);
                            toast.success(
                              <div>Secretkey copied successfuly!</div>
                            );
                          }}
                          buttonIcon={Icons.copyIcon}
                          label=""
                        />
                      )}
                    </div>

                    {this.state.secretKey !== "************" && (
                      <div className="min-max-description">
                        <span>
                          Please copy your secret key. This code will be
                          invisible after 30 seconds.
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="text-end">
              <Button
                className="confirm-button px-4 me-0"
                bindEvent={() => this.createSecretKey()}
                buttonIcon={Icons.thickIcon}
                label="Create Api Credentials"
              />
            </div>
          </div>
        )}
      </Content>
    );
  }
}

export default MyAccount;
