import React from "react";

function InvoicedFilter(props) {
  const { onInvoicedChecked, onDeliveredChecked } = props || {};

  let selectedCount =
    (props.filter.InvoicedStatus !== "" && 1) +
    (props.filter.DeliveredStatus !== "" && 1);
  return (
    <>
      {props.isInvoicedAvailable === true && (
        <div className="filter-price d-block">
          <div className="btn-group pe-2 pt-1 rounded-1 align-items-center">
            <button
              type="button"
              className="btn btn-transparent shadow-none dropdown-toggle mr-2 dropdown-toggle-split"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              data-bs-auto-close="outside"
              style={
                props.filter.InvoicedStatus || props.filter.DeliveredStatus
                  ? { color: "#264EA7" }
                  : { color: "#A4B9E8" }
              }
            >
              <span className="mx-1 text">Other</span>
              <span className="badge">
                {selectedCount > 0 ? selectedCount : ""}
              </span>
            </button>
            <div className="dropdown-menu border-0">
              <div className="radio-filter p-2">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    name="InvoicedStatus"
                    type="radio"
                    value="1"
                    id="invoices_1"
                    onChange={() => onInvoicedChecked("1")}
                    checked={props.filter.InvoicedStatus === "1"}
                  />
                  <label
                    className="form-check-label fw-filter"
                    htmlFor="invoices_1"
                  >
                    Invoiced
                  </label>
                </div>

                <div className="form-check">
                  <input
                    className="form-check-input"
                    name="InvoicedStatus"
                    type="radio"
                    value="0"
                    id="invoices_2"
                    onChange={() => onInvoicedChecked("0")}
                    checked={props.filter.InvoicedStatus === "0"}
                  />
                  <label
                    className="form-check-label fw-filter"
                    htmlFor="invoices_2"
                  >
                    Uninvoiced
                  </label>
                </div>
                {props.filter.InvoicedStatus && (
                  <div className="m-2">
                    <button
                      className="button filter-actions clear-all"
                      onClick={() => onInvoicedChecked("")}
                    >
                      Clear
                    </button>
                  </div>
                )}
              </div>
              {props.isDeliveredAvailable && (
                <div>
                  <hr></hr>
                  <div className="radio-filter p-2">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        name="DeliveredStatus"
                        type="radio"
                        value="1"
                        id="delivered_1"
                        onChange={() => onDeliveredChecked("1")}
                        checked={props.filter.DeliveredStatus === "1"}
                      />
                      <label
                        className="form-check-label fw-filter"
                        htmlFor="delivered_1"
                      >
                        Delivered
                      </label>
                    </div>

                    <div className="form-check">
                      <input
                        className="form-check-input"
                        name="DeliveredStatus"
                        type="radio"
                        value="0"
                        id="delivered_2"
                        onChange={() => onDeliveredChecked("0")}
                        checked={props.filter.DeliveredStatus === "0"}
                      />
                      <label
                        className="form-check-label fw-filter"
                        htmlFor="delivered_2"
                      >
                        UnDelivered
                      </label>
                    </div>
                    {props.filter.DeliveredStatus && (
                      <div className="m-2">
                        <button
                          className="button filter-actions clear-all"
                          onClick={() => onDeliveredChecked("")}
                        >
                          Clear
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default InvoicedFilter;
