import React, { useState } from "react";
import NumberInput from "../../../../components/common/NumberInput";
import { connect } from "react-redux";
import Button from "../../../../components/common/Button";
import { Icons } from "../../../../constants/icons";

const mapStateToProps = (state) => {
  return { Basket: state.basket };
};

const BasketEditModal = (props) => {
  const { DemandQuantity, DemandPrice, basket, selectedItem } = props.Basket;

  const [buttonDisable, setButtonDisable] = useState(false);

  return (
    <div>
      <div className="partner-modal-container">
        <div className="go-back my-4 mt-5">
          <img src={Icons.goBackIcon} />
          <Button
            className="go-back-button"
            bindEvent={() => props.handleClose()}
            label="Go back"
          />
        </div>
      </div>
      <div className="partner-modal-container">
        <div className="d-flex flex-column">
          <div className="mb-1">
            <h2 className="modal-body-text">{selectedItem?.Name}</h2>
          </div>
          <div>
            <h2 className="modal-body-subtext">by {selectedItem?.Publisher}</h2>
            <img src={Icons.greenUnderlineIcon} />
          </div>
          <div className="modal-number-inputs d-flex">
            <NumberInput
              visible
              value={DemandQuantity}
              onChange={(ev, val) => {
                props.changeDemandQuantity(ev);
                setButtonDisable(val);
              }}
              label="Quantity"
              intNumber
              minValue={
                selectedItem.MinQuantity < 20 ? 20 : selectedItem.MinQuantity
              }
              maxValue={selectedItem.MaxQuantity}
            ></NumberInput>
            <div className="quantity-box">
              <div className="d-flex flex-row  justify-content-end">
                <div className="d-flex flex-column ">
                  <span className="quantity-text">QTY Min </span>
                  <span className="quantity-text">Max </span>
                </div>
                <div className="d-flex flex-column align-items-end">
                  <span>
                    {selectedItem.MinQuantity < 20
                      ? 20
                      : selectedItem.MinQuantity}
                  </span>
                  <span>{selectedItem.MaxQuantity}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="min-max-description">
            {selectedItem.MinQuantity > DemandQuantity ||
            selectedItem.MinQuantity < 20 ? (
              <span>
                Please enter an amount that is larger than the min QTY
              </span>
            ) : null}
            {selectedItem.MaxQuantity < DemandQuantity ? (
              <span>Please enter an amount that is less than the max QTY</span>
            ) : null}
          </div>
          <div className="modal-number-inputs">
            <NumberInput
              visible
              className="modal-number-inputs"
              value={DemandPrice}
              onChange={(ev) => props.changeDemandPrice(ev)}
              label="Demand Price"
            ></NumberInput>
            <span className="new-price-text">
              Original price is {selectedItem.Price.toFixed(2)}€.
            </span>
          </div>
        </div>
      </div>
      <div className="order-modal-buttons mt-4">
        <Button
          className="modal-button-success"
          bindEvent={() => {
            props.saveBasketItem(
              DemandQuantity,
              DemandPrice,
              selectedItem.ProductId
            );
            props.handleClose();
          }}
          label="Update Order Item"
          disabled={
            buttonDisable ||
            !DemandQuantity ||
            DemandQuantity === "0" ||
            !DemandPrice ||
            DemandPrice === "0"
          }
          buttonIcon={Icons.bigThickIcon}
        />
      </div>
    </div>
  );
};

export default connect(mapStateToProps)(BasketEditModal);
