import React, { useEffect, useState } from "react";
import Content from "../../components/containers/Content";
import services from "../../api/index";
import Loader from "../../components/Loader";
import Filter from "../../components/Filter";
import { dateTypes } from "../../constants/defaults";
import {
  MANAGEMENT_INVIOCE_PAGE_LOADED,
  REDIRECT,
} from "../../constants/actionTypes";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import Pager from "../../components/Pager";
import moment from "moment";
import BlankPage from "../../components/BlankPage";
import { Link } from "react-router-dom";
import { Endpoints } from "../../constants/endpoints";
import { Icons } from "../../constants/icons";
import { getSorting } from "../../functions/FilterFunctions";
import base64 from "base-64";
import JsFileDownloader from "js-file-downloader";
import { getLocalAccessToken } from "../../api/services/token";
import MerModal from "../../components/common/MerModal";
import CreditNoteModal from "./inventories/components/CreditNoteModal";
import Button from "../../components/common/Button";
import ListCreditNoteModal from "./inventories/components/ListCreditNoteModal";
import ListInvoiceLinesModal from "./inventories/components/ListInvoiceLinesModal";

const mapStateToProps = (state) => {
  return {
    management_invoice: state.invoice.management_invoice,
    pageSize: state.common.pageSize,
  };
};

const mapDispatchToProps = (dispatch) => ({
  ManagmentOnLoad: (payload) => {
    dispatch({ type: MANAGEMENT_INVIOCE_PAGE_LOADED, payload });
  },
  onRedirect: (redirectTo) => dispatch({ type: REDIRECT, redirectTo }),
});

function Invoices(props) {
  const [pgeIndex, setPgeIndex] = useState(
    props.match.params.page ? props.match.params.page : 0
  );
  const [myfilter, setFilter] = useState(
    props.match.params.filter
      ? JSON.parse(base64.decode(props.match.params.filter))
      : null
  );
  const [sortby, setSortBy] = useState(props.match.params.sort);
  const [selectedInvoice, setSelectedInvoice] = useState("");
  const [sortTypeDate, setSortTypeDate] = useState("down");
  const [showModal, setShowModal] = useState(false);
  const [listShowModal, setListShowModal] = useState(false);
  const [listShowDetailsModal, setListShowDetailsModal] = useState(false);

  useEffect(() => {
    document.title = "Invoices";
  }, []);
  useEffect(() => {
    let ev = myfilter;

    getAllManagement({
      keywords: ev?.Text,
      pageindex: 0,
      pagesize: props.pageSize,
      StartDate: ev?.DateRange?.min,
      FinishDate: ev?.DateRange?.max,
      Statuses: ev?.InvoiceStatus?.join(","),
      MinPrice: ev?.Price?.down,
      MaxPrice: ev?.Price?.up,
      userIds: ev?.PartnerId?.length !== 0 ? ev?.PartnerId?.join(",") : "",
      SortBy: sortby,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.pageSize]);

  const getAllManagement = (params) => {
    const {
      keywords = "",
      pageindex = pgeIndex,
      pagesize = 10,

      StartDate = "",
      FinishDate = "",
      Statuses = "",
      MinPrice = "",
      MaxPrice = "",
      userIds = "",
      SortBy = "",
    } = params || {};
    setPgeIndex(pageindex);
    return props.ManagmentOnLoad(
      Promise.all([
        services.invoice.getAllManagement(
          keywords,
          pageindex,
          pagesize,
          StartDate,
          FinishDate,
          Statuses,
          MinPrice,
          MaxPrice,
          userIds,
          SortBy
        ),
      ])
        .then((res) => {
          return res;
        })
        .catch((err) => {
          if (err.response?.data?.message)
            toast.error(<div>{err.response?.data.message}</div>);
          return undefined;
        })
    );
  };

  const onFilter = (params) => {
    let { filter = undefined, sortBy = undefined, pageindex = 0 } = params;
    let ev = filter;
    if (ev !== undefined) setFilter(ev);
    else ev = myfilter;
    sortBy = getSorting(sortBy, sortby);
    setSortBy(sortBy);

    let url = Endpoints.Management.Invoices.url
      .replace(":filter?", base64.encode(ev ? JSON.stringify(ev) : "0"))
      .replace(":sort?", sortBy ? sortBy : "0")
      .replace(":page?", pageindex);
    props.onRedirect(url);

    getAllManagement({
      keywords: ev?.Text,
      pageindex: pageindex,
      pagesize: props.pageSize,
      StartDate: ev?.DateRange?.min,
      FinishDate: ev?.DateRange?.max,
      Statuses: ev?.InvoiceStatus?.join(","),
      MinPrice: ev?.Price?.down,
      MaxPrice: ev?.Price?.up,
      userIds: ev?.PartnerId?.length !== 0 ? ev?.PartnerId?.join(",") : "",
      SortBy: sortBy,
    });
  };

  const onChangePageIndex = (pageindex) => {
    onFilter({ pageindex: pageindex });
  };

  const downloadFile = (invoiceid, filename) => {
    const token = getLocalAccessToken();
    let download = new JsFileDownloader({
      url: `${services.API_ROOT}/invoice/management/downloadpdf/${invoiceid}`,
      headers: [{ name: "Authorization", value: "Bearer " + token }],
      autoStart: false,
      nameCallback: () => {
        return filename;
      },
    });

    download
      .start()
      .then(() => {})
      .catch(function (error) {
        // handle errors
      });
  };

  return (
    <Content pageTitle="Invoices">
      {props.management_invoice === undefined ? (
        <Loader />
      ) : (
        <div>
          <Filter
            filter={myfilter}
            onFilter={(ev, sortBy) => onFilter(ev, sortBy)}
            isPartnerAvailable
            invoicesStatusVisible
            priceList={[
              { down: undefined, up: 1000 },
              { down: 1000, up: 5000 },
              { down: 5000, up: 10000 },
              { down: 10000, up: undefined },
            ]}
            dateList={[
              dateTypes.ALLTIME,
              dateTypes.THISWEEK,
              dateTypes.THISMONTH,
              dateTypes.LAST2MONTH,
            ]}
            invoicesStatusList={[
              "Sent",
              "Draft",
              "Paid",
              "PartialPaid",
              "Credited",
            ]}
          ></Filter>
          {props.management_invoice[0]?.Items.length === 0 ? (
            <BlankPage description="There is no invoice." />
          ) : (
            <div>
              <div className="table-total-count">
                Total Count: {props.management_invoice[0]?.TotalCount}
              </div>
              <div className="table-responsive-xl p-0">
                <table
                  className="table table-centered table-nowrap table-hover mb-0 rounded table-responsive cursor-pointer"
                  style={{ minWidth: "1200px" }}
                  id="datatable"
                >
                  <thead className="thead-light d-short border-0">
                    <tr>
                      <th className="bg-transparent border-0 ps-2">
                        Invoice No
                      </th>
                      <th className="bg-transparent border-0 ps-2">Status</th>
                      <th className="bg-transparent border-0 ps-2">Order No</th>
                      <th className="bg-transparent border-0 ps-2">
                        Invoice Id
                      </th>
                      <th
                        className="bg-transparent border-0 ps-2"
                        onClick={() => {
                          setSortTypeDate(
                            sortTypeDate === "up" ? "down" : "up"
                          );
                          onFilter({
                            sortBy: "CreatedAt",
                          });
                        }}
                      >
                        Date
                        <i
                          className={`fas fa-sort-amount-${sortTypeDate} px-2`}
                        ></i>
                      </th>
                      <th className="bg-transparent border-0 ps-2">Partner</th>
                      {/* <th className="bg-transparent border-0 ps-2"></th>
                      <th className="bg-transparent border-0 ps-2"></th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {props.management_invoice.length > 0 ? (
                      props.management_invoice[0].Items.map((item, index) => {
                        return (
                          <tr
                            className="rounded-1 mb-1 align-middle border-bottom"
                            key={index}
                          >
                            <td className="ps-2">{item.InvoiceNumber}</td>
                            <td className="ps-2">{item.Status}</td>
                            <td className="ps-2">
                              <div className="d-flex my-4 mx-2 flex-column justify-content-start">
                                <div className="go-back">
                                  <Button
                                    className="send-package-button px-4"
                                    label="Show Orders"
                                    bindEvent={() => {
                                      setSelectedInvoice(item);
                                      setListShowDetailsModal(true);
                                    }}
                                  ></Button>
                                </div>
                              </div>
                            </td>
                            <td className="ps-2">
                              {item.Accounting.ExternalInvoiceId}
                              <a
                                className="px-1"
                                onClick={() => {
                                  downloadFile(item.Id, item.Filename);
                                }}
                                hidden={
                                  item.Filename === null ||
                                  item.ExistPdf === false
                                }
                              >
                                <img
                                  className="mb-1"
                                  src={Icons.pdfIcon}
                                  style={{ height: "20px" }}
                                />
                              </a>
                            </td>
                            <td className="ps-2">
                              {moment
                                .utc(item.CreatedAt)
                                .format("DD/MM/YYYY - HH:mm:ss")}{" "}
                            </td>
                            <td className="ps-2">{item.Customer.Name}</td>

                            {/* <td className="ps-2">
                              <Button
                                className="confirm-button px-3"
                                bindEvent={() => {
                                  setSelectedInvoice(item);
                                  setListShowModal(!listShowModal);
                                }}
                                label="List Credit Memo"
                                buttonIcon={Icons.thickIcon}
                              />
                            </td>
                            <td className="ps-2 ">
                              <Button
                                className="confirm-button px-3"
                                bindEvent={() => {
                                  setSelectedInvoice(item);
                                  setShowModal(!showModal);
                                }}
                                label="Create Credit Memo"
                                buttonIcon={Icons.thickIcon}
                              />
                            </td> */}
                          </tr>
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </tbody>
                </table>
              </div>
              <Pager
                pageIndex={props.management_invoice[0]?.PageIndex}
                totalPages={props.management_invoice[0]?.TotalPages}
                totalCount={props.management_invoice[0]?.TotalCount}
                onChange={(pageIndex) => onChangePageIndex(pageIndex)}
              />
              <MerModal
                showModal={showModal}
                ariaLabelledby="contained-modal-title-vcenter"
                onHide={() => setShowModal(false)}
              >
                <CreditNoteModal
                  handleClose={() => setShowModal(false)}
                  selectedInvoice={selectedInvoice}
                />
              </MerModal>
              <MerModal
                showModal={listShowModal}
                ariaLabelledby="contained-modal-title-vcenter"
                onHide={() => setListShowModal(false)}
              >
                <ListCreditNoteModal
                  handleClose={() => setListShowModal(false)}
                  selectedInvoice={selectedInvoice}
                />
              </MerModal>
              <MerModal
                showModal={listShowDetailsModal}
                ariaLabelledby="contained-modal-title-vcenter"
                onHide={() => setListShowDetailsModal(false)}
                scrollable
              >
                <ListInvoiceLinesModal
                  handleClose={() => setListShowDetailsModal(false)}
                  selectedInvoice={selectedInvoice}
                />
              </MerModal>
            </div>
          )}
        </div>
      )}
    </Content>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Invoices);
