import React, { useEffect, useState } from "react";
import moment from "moment";
import Content from "../../../components/containers/Content";
import Loader from "../../../components/Loader";
import base64 from "base-64";
import {
  REDIRECT,
  REPORT_ORDER_PROCESS_BY_STATUS,
} from "../../../constants/actionTypes";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import Pager from "../../../components/Pager";
import BlankPage from "../../../components/BlankPage";
import { Endpoints } from "../../../constants/endpoints";
import services from "../../../api/index";

const mapStateToProps = (state) => {
  return {
    reportOrderProcessByStatus: state.report.reportOrderProcessByStatus,
  };
};

const mapDispatchToProps = (dispatch) => ({
  PageOnLoad: (payload) => {
    dispatch({ type: REPORT_ORDER_PROCESS_BY_STATUS, payload });
  },
  onRedirect: (redirectTo) => dispatch({ type: REDIRECT, redirectTo }),
});

function OrderProcessReport(props) {
  const [pageIndex, setPgeIndex] = useState(0);
  const [myfilter, setFilter] = useState(
    props.match.params.filter
      ? JSON.parse(base64.decode(props.match.params.filter))
      : null
  );

  useEffect(() => {
    document.title = "Order Process";
  }, []);
  useEffect(() => {
    let ev = myfilter;

    getOrderProcessByStatus({
      pageindex: ev?.pageindex,
    }); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getOrderProcessByStatus = (params) => {
    const { pageindex } = params || {};
    setPgeIndex(pageindex);

    return props.PageOnLoad(
      Promise.all([services.orders.getOrderProcessByStatus(pageindex)])
        .then((res) => {
          return res;
        })
        .catch((err) => {
          if (err.response?.data?.message)
            toast.error(<div>{err.response?.data.message}</div>);
          return undefined;
        })
    );
  };

  const onChangePageIndex = (pageindex) => {
    onFilter({ pageindex: pageindex });
  };

  const onFilter = (params) => {
    let { filter = myfilter, sortBy = undefined, pageindex = 0 } = params;
    let ev = filter;
    if (ev !== undefined) setFilter(ev);
    else ev = myfilter;

    let url = Endpoints.Management.OrderProcessByStatus.url.replace(
      ":page?",
      pageindex
    );
    props.onRedirect(url);

    getOrderProcessByStatus({
      pageindex: pageindex,
    });
  };

  return (
    <Content pageTitle="">
      <div className="d-flex flex-row justify-content-between">
        <h1>Order Process Reports</h1>
      </div>
      {props.reportOrderProcessByStatus === undefined ? (
        <Loader />
      ) : (
        <div>
          {props.reportOrderProcessByStatus[0]?.Items.length === 0 ? (
            <BlankPage description="There is no sellers." />
          ) : (
            <div>
              <div className="table-total-count">
                Total Count: {props.reportOrderProcessByStatus[0]?.TotalCount}
              </div>
              <div className="table-responsive-xl p-0">
                <table
                  className="table table-centered table-nowrap table-hover mb-0 rounded table-responsive cursor-pointer"
                  id="datatable"
                >
                  <thead className="thead-light d-short border-0">
                    <tr>
                      <th className="bg-transparent border-0">Product Name</th>
                      <th className="bg-transparent border-0">Total</th>
                      <th className="bg-transparent border-0">Delivered</th>
                      <th className="bg-transparent border-0">Blended</th>
                      <th className="bg-transparent border-0">
                        Waiting Blending
                      </th>
                      <th className="bg-transparent border-0">
                        Waiting Delivering
                      </th>
                      <th className="bg-transparent border-0">
                        Last Key Status
                      </th>
                      <th className="bg-transparent border-0">Last Key Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.reportOrderProcessByStatus.length > 0 ? (
                      props.reportOrderProcessByStatus[0].Items.map(
                        (item, index) => {
                          return (
                            <tr
                              className="rounded-1 mb-1 align-middle border-bottom"
                              key={index}
                            >
                              <td className="ps-0">{item.ProductName}</td>
                              <td className="ps-0">{item.Total}</td>
                              <td className="ps-0">{item.Delivered}</td>
                              <td className="ps-0">{item.Blended}</td>
                              <td className="ps-0">{item.WaitingBlending}</td>
                              <td className="ps-0">{item.WaitingDelivering}</td>
                              <td className="ps-0">{item.LastKeyStatus}</td>
                              <td className="ps-0">
                                {
                                  (moment.locale("en"),
                                  moment(item.LastKeyTime).format(
                                    "DD MMMM yyyy, H:mm:ss"
                                  ))
                                }
                              </td>
                            </tr>
                          );
                        }
                      )
                    ) : (
                      <></>
                    )}
                  </tbody>
                </table>
              </div>
              <Pager
                pageIndex={props.reportOrderProcessByStatus[0]?.PageIndex}
                totalPages={props.reportOrderProcessByStatus[0]?.TotalPages}
                totalCount={props.reportOrderProcessByStatus[0]?.TotalCount}
                onChange={(pageindex) => onChangePageIndex(pageindex)}
              />
            </div>
          )}
        </div>
      )}
    </Content>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderProcessReport);
