export default (req) => ({
  getAll: () => req.get("/user/management/GetAll"),
  create: (
    firstName,
    lastName,
    email,
    password,
    role,
    isActive,
    companyInfoId,
    permissionGroups
  ) =>
    req.post("/user/management/createUser", {
      FirstName: firstName,
      LastName: lastName,
      Email: email,
      Password: password,
      Role: role,
      IsActive: isActive,
      CompanyInfoId: companyInfoId,
      PermissionGroups: permissionGroups,
    }),
  info: () => req.get("/user/me"),
  createSecretKey: () => req.put("/user/me/ReCreateApiCredentials"),
  getUser: (id) => req.get(`/user/management/${id}`),
  updateUser: (
    userId,
    firstName,
    lastName,
    email,
    role,
    isActive,
    companyInfoId,
    permissionGroups
  ) =>
    req.put(`/user/management/${userId}/UpdateUser`, {
      FirstName: firstName,
      LastName: lastName,
      Email: email,
      Role: role,
      IsActive: isActive,
      CompanyInfoId: companyInfoId,
      PermissionGroups: permissionGroups,
    }),
  updatePassword: (userId, oldPass, newPass) =>
    req.put(`/user/management/${userId}/ChangePassword`, {
      OldPassword: oldPass,
      NewPassword: newPass,
    }),
  changePassword: (userId, password) =>
    req.put(`/user/management/${userId}/SetPassword`, {
      Password: password,
    }),
  updatePartnerPassword: (oldPass, newPass) =>
    req.put("/user/me/ChangePassword", {
      OldPassword: oldPass,
      NewPassword: newPass,
    }),

  searchPermissionGroup: (pageIndex, pageSize, keywords) =>
    req.get("/permissiongroup/management/search", {
      params: {
        PageIndex: pageIndex,
        PageSize: pageSize,
        Keywords: keywords,
      },
    }),
  getByIdPermissionGroup: (id) => req.get(`/permissiongroup/management/${id}`),
  allPermissions: () => req.get(`/permission/management/all`),
  updatePermissionGroup: (id, body) =>
    req.put(`/permissiongroup/management/${id}/Update`, body),
  createPermissionGroup: (body) =>
    req.post(`/permissiongroup/management/create`, body),
  getPermission: () => req.get("/permission/me"),
  deletePermission: (permissionGroupId) =>
    req.del(`/permissiongroup/management/${permissionGroupId}/Delete`),
});
