import React, { Component } from "react";
import PublishFinish from "../../pages/management/promotions/new-promotion/promotion-steps/PublishFinish";

class Stepper extends Component {
  constructor() {
    super();
    this.state = {
      steps: [],
    };
  }

  componentDidMount() {
    const { steps, currentStepNumber } = this.props;

    const stepsState = steps.map((step, index) => {
      const stepObj = {};
      stepObj.description = step;
      stepObj.selected = index === 0 ? true : false;
      stepObj.completed = false;
      return stepObj;
    });

    const currentSteps = this.updateStep(currentStepNumber, stepsState);

    this.setState({
      steps: currentSteps,
    });
  }

  componentDidUpdate(prevProps) {
    const { steps } = this.state;
    const currentSteps = this.updateStep(this.props.currentStepNumber, steps);

    if (prevProps.currentStepNumber !== this.props.currentStepNumber) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        steps: currentSteps,
      });
    }
  }

  updateStep = (stepNumber, steps) => {
    const newSteps = [...steps];
    let stepCounter = 0;
    while (stepCounter < newSteps.length) {
      // Current step
      if (stepCounter === stepNumber) {
        newSteps[stepCounter] = {
          ...newSteps[stepCounter],
          selected: true,
          completed: false,
        };
        stepCounter++;
      }
      // Past step
      else if (stepCounter < stepNumber) {
        newSteps[stepCounter] = {
          ...newSteps[stepCounter],
          selected: true,
          completed: true,
        };
        stepCounter++;
      }
      // Future step
      else {
        newSteps[stepCounter] = {
          ...newSteps[stepCounter],
          selected: false,
          completed: false,
        };
        stepCounter++;
      }
    }

    return newSteps;
  };

  render() {
    const { steps } = this.state;
    const stepsJSX = steps.map((step, index) => {
      return (
        <>
          <div
            key={"steps" + index}
            className={`steps steps-${
              step.selected ? "selected" : step.completed && "completed"
            }`}
          >
            <div
              className={`steps-text py-2 steps-text-${
                step.selected && !step.completed
                  ? "selected"
                  : step.completed && "incompleted"
              }`}
            >
              {index + 1}
            </div>
            <div
              className={`steps-title steps-title-${
                this.props.currentStepNumber === index
                  ? "selected"
                  : this.props.currentStepNumber > index
                  ? "done"
                  : "incompleted"
              }`}
            >
              {step.description.title}
            </div>
          </div>

          {index !== steps.length - 1 &&
            this.props.currentStepNumber < index + 1 && (
              <>
                {step.selected ? (
                  <div className="d-flex steps-line steps-line-selected"></div>
                ) : (
                  <div className="d-flex steps-line"></div>
                )}
              </>
            )}

          {this.props.currentStepNumber > index && (
            <>
              <div className="d-flex steps-line steps-line-done"></div>
            </>
          )}
        </>
      );
    });

    return (
      <div className="create-update-promotion-container">
        <div className="d-flex items-center steps-navigator mx-4 p-4">
          {this.props.currentStepNumber !== this.state.steps.length && stepsJSX}
        </div>
        <div>
          {this.state.steps.map((content, key) => (
            <div key={key}>
              {this.props.currentStepNumber !== this.state.steps.length &&
                content.selected &&
                !content.completed &&
                content.description.content}
            </div>
          ))}
          {this.props.currentStepNumber === this.state.steps.length && (
            <PublishFinish title="Publish Finish" />
          )}
        </div>
      </div>
    );
  }
}

export default Stepper;
