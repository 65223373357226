export default (req) => ({
  getAllocations: (statuses, keywords, pagesize, pageindex) =>
    req.get("/orderallocation/management/search", {
      params: {
        Statuses: statuses,
        Pagesize: pagesize,
        Pageindex: pageindex,
        keywords: keywords,
      },
    }),
  deleteAllocation: (allocationId) =>
    req.put(`/orderallocation/management/delete/${allocationId}`),
  createAllocation: (productId, startDate, endDate) =>
    req.post("/orderallocation/management/create", {
      ProductId: productId,
      StartDate: startDate,
      EndDate: endDate,
    }),
});
