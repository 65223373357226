import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

import { OrderItemStatus } from "../../../../../../../constants/defaults";
import Button from "../../../../../../../components/common/v2/button";

import useOrderItem from "../../../hooks/useOrderItem";

const ModalOrderItemRetry = ({ data, userScope }) => {
  const { Status } = data || {};
  const { onRetry } = useOrderItem({ data, userScope });

  if (
    ![
      OrderItemStatus.OutOfStock,
      OrderItemStatus.BackOrdered,
      OrderItemStatus.PreCompleted,
    ].includes(Status)
  ) {
    return null;
  }

  return (
    <Button
      onClick={onRetry}
      theme="light"
      title={<i className="fas fa-redo"></i>}
    />
  );
};

export default ModalOrderItemRetry;
