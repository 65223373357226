import React from "react";
import { Route, Redirect } from "react-router-dom";

// eslint-disable-next-line import/prefer-default-export
export const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...rest}
    render={(props) =>
      localStorage.getItem("jwt") ? (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname:
              "/login/" +
              (window.location.pathname !== "/login"
                ? encodeURIComponent(window.location.pathname)
                : ""),
            state: {
              from: props.location,
            },
          }}
        />
      )
    }
  />
);
