import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  convertUTCToLocalDate,
  fixTimezoneOffset,
} from "../../functions/dateConverter";
class UtcDatePicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      minDate:
        new Date(this.props.minDate).getFullYear() < 2000
          ? fixTimezoneOffset(new Date().setMinutes(30))
          : fixTimezoneOffset(this.props.minDate),
      maxDate:
        new Date(this.props.maxDate).getFullYear() < 2000
          ? fixTimezoneOffset(new Date().setMinutes(30))
          : fixTimezoneOffset(this.props.maxDate),
      selected:
        new Date(this.props.selected).getFullYear() < 2000
          ? fixTimezoneOffset(new Date().setMinutes(30))
          : fixTimezoneOffset(this.props.selected),
    };
  }

  filterPassedMinTime = (time) => {
    if (this.props.disableTimeFilter) return true;
    const currentDate = this.state.maxDate.date;
    const selectedDate = new Date(time);
    return currentDate?.getTime() > selectedDate?.getTime();
  };

  filterPassedMaxTime = (time) => {
    if (this.props.disableTimeFilter) return true;
    const currentDate = this.state.minDate.date;
    const selectedDate = new Date(time);

    return currentDate?.getTime() < selectedDate?.getTime();
  };

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    if (nextProps.minDate) {
      this.setState({
        minDate: fixTimezoneOffset(nextProps.minDate),
      });
    }
    if (nextProps.maxDate) {
      this.setState({
        maxDate: fixTimezoneOffset(nextProps.maxDate),
      });
    }
    if (nextProps.selected) {
      this.setState({
        selected: fixTimezoneOffset(nextProps.selected),
      });
    } else
      this.setState({
        selected: undefined,
      });
    return true;
  }

  render() {
    return (
      <DatePicker
        disabled={
          (!this.props.disableTimeFilter &&
            this.state.minDate === "" &&
            this.props.useMaxTime) ||
          this.props.disabled
        }
        onMonthChange={(date) => {
          const result = fixTimezoneOffset(date);
          this.props.onChange(result);
        }}
        className="react-datepicker-wrapper"
        selected={this.state.selected?.date}
        onChange={(date) => {
          const result = fixTimezoneOffset(date);
          this.props.onChange(result);
        }}
        showTimeSelect
        timeFormat="HH:mm"
        timeIntervals={this.props.timeIntervals}
        dateFormat="dd-MM-yyyy HH:mm"
        placeholderText={this.props.placeholderText}
        maxDate={this.state.maxDate.date}
        minDate={this.state.minDate.date}
        filterTime={
          this.props.useMinTime
            ? this.filterPassedMinTime
            : this.filterPassedMaxTime
        }
        peekNextMonth
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
      />
    );
  }
}

export default UtcDatePicker;
