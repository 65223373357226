import React, { useState, useEffect } from "react";
import Button from "../../../../components/common/Button";
import { toast } from "react-toastify";
import { Icons } from "../../../../constants/icons";
import {
  CHANGE_ALL_MY_OFFERS_DETAILS,
  MANAGEMENT_CREATE_PROMOTION_PRODUCT,
  MANAGEMENT_PROMOTION_UPDATE_FIELD,
} from "../../../../constants/actionTypes";
import { connect } from "react-redux";
import services from "../../../../api/index";
import settings from "../../../../settings.json";
import {
  defaultDateFormat,
  PromotionPopupSearchInput,
} from "../../../../constants/defaults";
import Loader from "../../../../components/Loader";
import moment from "moment";
import Pager from "../../../../components/Pager";
import Filter from "../../../../components/Filter";

const mapStateToProps = (state) => {
  return {
    promotions: state.promotions,
    OnlyActivePromotions: state.promotions.OnlyActivePromotions,
  };
};

const mapDispatchToProps = (dispatch) => ({
  productsOnLoad: (payload) =>
    dispatch({ type: MANAGEMENT_CREATE_PROMOTION_PRODUCT, payload }),

  onChangeProductSearchText: (value) =>
    dispatch({
      type: MANAGEMENT_PROMOTION_UPDATE_FIELD,
      key: "searchProductText",
      value,
    }),
  onSelectProduct: (payload) => {
    dispatch({
      type: MANAGEMENT_PROMOTION_UPDATE_FIELD,
      key: "selectedProduct",
      value: payload,
    });
  },
  onSetOnlyActivePromotions: (payload) => {
    dispatch({
      type: MANAGEMENT_PROMOTION_UPDATE_FIELD,
      key: "OnlyActivePromotions",
      value: payload,
    });
  },
});
let timer = null;

function ProductSelectionModal(props) {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    filterProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      setIsLoading(true);
      filterProducts();
    }, 1000); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.promotions.searchProductText, props.OnlyActivePromotions]);

  const filterProducts = (params = undefined) => {
    setIsLoading(true);
    const {
      OnlyActivePromotions = props.OnlyActivePromotions,
      store = "",
      pageindex = 0,
    } = params || {};
    services.product
      .getAllProductFromManagement(
        props.promotions.searchProductText,
        pageindex,
        10,
        "",
        OnlyActivePromotions,
        store,
        true
      )
      .then((payload) => {
        props.productsOnLoad(payload);
        setIsLoading(false);
      })
      .catch((err) => {});
  };

  return (
    <div className="container order-modal">
      <div className="d-flex my-4 mx-2 flex-column justify-content-start">
        <div className="go-back">
          <img src={Icons.goBackIcon} />
          <Button
            className="go-back-button"
            bindEvent={() => props.handleClose()}
            label="Go back"
          />
        </div>
        <div className="create-package-header">
          <span>Select Product</span>
        </div>
        <div>
          <div>
            <div className="row">
              <div className="col-md-9 ps-0">
                <div className="input-group">
                  <span className="input-group-text" id="basic-addon1">
                    <img
                      className="ms-1"
                      src={Icons.promotionSearchIcon}
                      width="100"
                    ></img>
                  </span>
                  <input
                    type="text"
                    id="promotion-search-input"
                    placeholder={PromotionPopupSearchInput}
                    aria-label="Search"
                    onChange={(ev) => {
                      props.onChangeProductSearchText(ev.target.value);
                    }}
                    value={props.promotions.searchProductText}
                    onKeyDown={(ev) => {
                      if (ev.keyCode === 13) filterProducts();
                    }}
                  />
                </div>
              </div>
              <div className="select-game-filter col-md-3 col-xs-12 ps-0">
                <Filter
                  onFilter={(ev) => filterProducts({ store: ev.filter.Store })}
                  isStoreAvailable
                  hideSearch
                />
              </div>
            </div>
            <div className="terms-check mb-2">
              <input
                className="form-check-input"
                type="checkbox"
                id="isActive"
                checked={props.OnlyActivePromotions}
                onChange={(ev) => {
                  props.onSetOnlyActivePromotions(ev.target.checked);
                }}
              />
              <label className="terms-check-label" htmlFor="isActive">
                Show only products in promotion by publisher or distributor
              </label>
            </div>
            {isLoading ? (
              <div className="d-flex justify-content-center align-items-center mt-9 mb-9">
                <Loader />
              </div>
            ) : (
              <div>
                <div className="table-responsive col-md-12">
                  <table className="table table-centered table-nowrap mb-0 rounded ">
                    <thead className="">
                      <tr className="border-0 table-sub-title">
                        <th></th>
                        <th className="w-25 ps-0">Name</th>
                        <th className="ps-0">Region</th>
                        <th className="ps-0">Store</th>
                        <th className="ps-0">Provider</th>
                        <th className="ps-0">SKU</th>
                        <th className="ps-0">Start Date</th>
                        <th className="ps-0">End Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(props.promotions.products
                        ? props.promotions.products
                        : undefined
                      )?.Items.map((item, key, arr) => {
                        return (
                          <tr
                            onClick={() => {
                              props.selectProduct(item);
                            }}
                            key={key}
                            className={
                              item.Id === props.promotions.selectedProduct?.Id
                                ? "bg-gray-200"
                                : ""
                            }
                          >
                            <td>
                              <input
                                className="form-check-input"
                                type="radio"
                                id={item.Id}
                                name="products"
                                value={item.Id}
                                checked={
                                  props.promotions.selectedProduct?.Id ===
                                  item.Id
                                }
                                onChange={() => {
                                  props.selectProduct(item);
                                }}
                              ></input>
                            </td>
                            <td className="ps-0">{item.Name}</td>
                            <td className="ps-0">{item.Region}</td>
                            <td className="ps-0">{item.Store}</td>
                            <td className="ps-0">{item.Provider.Name}</td>
                            <td className="ps-0">{item.Sku}</td>
                            <td className="ps-0">
                              {item.ValidFrom
                                ? moment(item.ValidFrom).format(
                                    defaultDateFormat + "\t-\tH:mm:ss"
                                  )
                                : " -"}
                            </td>
                            <td className="ps-0">
                              {item.ValidTo
                                ? moment(item.ValidTo).format(
                                    defaultDateFormat + "\t-\tH:mm:ss"
                                  )
                                : " -"}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductSelectionModal);
