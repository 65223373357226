export default (req) => ({
  getAll: (keywords, pageindex, pagesize) =>
    req.get("/publisher/management/GetAll", {
      params: {
        Keywords: keywords,
        PageIndex: pageindex,
        PageSize: pagesize,
      },
    }),
});
