import { OrderStatus } from "../../../../../../../constants/defaults";

import api from "../../../../../../../api";

const close = ({ Id, Status }) => ({
  isEnabled: [
    // OrderStatus.Completed,
    // OrderStatus.Refund,
    // OrderStatus.Refunded,
    // OrderStatus.PromotionExpired,
    // OrderStatus.Failed,
    // OrderStatus.OutOfStock,
  ].includes(Status),
  modal: {
    title: "Close Order",
    accessibility: {
      submit: {
        onClick: ({ sendBackOrderedKeysToInventory }) =>
          api.orders.close(Id, sendBackOrderedKeysToInventory),
        successMessage: "Order is closed, successfully",
        title: "Close",
        theme: "danger",
      },
    },
    trigger: {
      title: "Close",
      theme: "danger",
    },
    description: "Order will be closed.",
    form: [
      {
        defaultChecked: true,
        key: "sendBackOrderedKeysToInventory",
        title: "Send Back Ordered Keys To Inventory",
        type: "checkbox",
        map: (e) => e.checked,
      },
    ],
  },
});

export default close;
