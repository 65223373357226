import Auth from "./auth";
import Product from "./services/product";
import User from "./services/user";
import Orders from "./services/orders";
import Campaigns from "./services/campaigns";
import Publisher from "./services/publisher";
import Provider from "./services/provider";
import Invoice from "./services/invoice";
import Common from "./services/common";
import Report from "./services/report";
import Accounting from "./services/accounting";
import GoogleSheet from "./services/googleSheet";
import Allocations from "./services/allocations";
import Company from "./services/company";
import apiClient from "./apiClient";
import settingsJSON from "../settings.json";
import Settings from "./services/settings";
import Regions from "./services/regions";

const API_ROOT = settingsJSON["API_ROOT"];

const get = (URL, payload) => {
  return apiClient
    .get(`${API_ROOT}${URL}`, payload)
    .then((response) => response.data);
};

const post = (URL, payload) => {
  return apiClient
    .post(`${API_ROOT}${URL}`, payload)
    .then((response) => response.data);
};

const put = (URL, payload) => {
  return apiClient
    .put(`${API_ROOT}${URL}`, payload)
    .then((response) => response.data);
};

const del = (URL, payload) => {
  return apiClient
    .delete(`${API_ROOT}${URL}`, payload)
    .then((response) => response.data);
};

const requests = {
  del: (url, body) => del(`${url}`, body),
  get: (url, body) => get(`${url}`, body),
  put: (url, body) => put(`${url}`, body),
  post: (url, body) => post(`${url}`, body),
};

export default {
  auth: Auth(requests),
  product: Product(requests),
  user: User(requests),
  orders: Orders(requests),
  campaigns: Campaigns(requests),
  publisher: Publisher(requests),
  provider: Provider(requests),
  invoice: Invoice(requests),
  common: Common(requests),
  report: Report(requests),
  accounting: Accounting(requests),
  googleSheet: GoogleSheet(requests),
  allocations: Allocations(requests),
  company: Company(requests),
  settings: Settings(requests),
  regions: Regions(requests),
  API_ROOT: API_ROOT,
};
