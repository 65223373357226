import { OrderStatus } from "../../../../../../../constants/defaults";

import api from "../../../../../../../api";

const revoke = ({ Id, Status }) => ({
  isEnabled: [OrderStatus.Rebidding].includes(Status),
  modal: {
    title: "Refund Order",
    accessibility: {
      submit: {
        onClick: () => api.orders.revoke(Id),
        successMessage: "Order is revoked, successfully",
        title: "Revoke",
        theme: "warning",
      },
    },
    trigger: {
      title: "Revoke",
      theme: "warning",
    },
    description: "Order will be revoked.",
  },
});

export default revoke;
