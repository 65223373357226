import React from "react";
import moment from "moment";
import { OrderStatus } from "../../../../../constants/defaults";
import NumberFormatter from "../../../../../components/common/NumberFormatter";
import { Icons } from "../../../../../constants/icons";
import { OrderStatusDesc } from "../../../../../constants/types";
import { Endpoints } from "../../../../../constants/endpoints";
import { Link } from "react-router-dom";
import base64 from "base-64";

const OrderCollapseHeader = (props) => {
  const { item } = props;
  return (
    <div className="collapse-card-header" id={`heading${item.Id}`}>
      <div className="header-name d-flex flex-column gap-2 mb-3">
        <span>{item.Product.ProductName}</span>
        <span className="mango">{item.Product.VoiduPromotion?.Region}</span>
      </div>
      <div className="header-body" onClick={() => props.collapseShow()}>
        <div className="knowledge-container d-flex flex-column gap-3">
          <div className="order-container">
            <div className="order-no">
              {item.OrderNumber}
              {item.IsBilled !== false ? (
                <Link
                  to={Endpoints.Management.Invoices.url
                    .replace(
                      ":" + Endpoints.Management.Invoices.url.split(":")[1],
                      base64.encode(
                        JSON.stringify({
                          DateRange: {},
                          InvoiceStatus: [],
                          InvoicedStatus: "",
                          OnlyActivePromotion: "",
                          OperatingSystems: [],
                          PartnerId: [],
                          Platforms: [],
                          Price: {},
                          Providers: [],
                          Publishers: [],
                          Status: [],
                          Text: item.OrderNumber,
                        })
                      )
                    )
                    .replace(
                      ":" + Endpoints.Management.Invoices.url.split(":")[2],
                      "/0"
                    )
                    .replace(
                      ":" + Endpoints.Management.Invoices.url.split(":")[3],
                      "/0"
                    )}
                  className="view-detail-link"
                >
                  <img src={Icons.isBilledIcon} />
                </Link>
              ) : null}
            </div>
            <div className="order-date">
              Created Date <sup>UTC</sup> :{" "}
              {
                (moment.locale("en"),
                moment(item.CreatedAt).format("DD MMMM yyyy, H:mm:ss"))
              }
            </div>
          </div>
          <div className="status-container">
            <div className="status-horizontal-container">
              <div className="status-vertical-container">
                <img
                  src={OrderStatusDesc(item.Status)?.srcIcon}
                  className={OrderStatusDesc(item.Status)?.iconColor}
                ></img>
                <div className="order-status">
                  {OrderStatusDesc(item.Status)?.statusDesc}
                </div>
              </div>
              <div className="order-sub-status">
                {OrderStatusDesc(item.Status)?.subStatus ===
                  OrderStatus.Processing ||
                OrderStatusDesc(item.Status)?.subStatus ===
                  OrderStatus.Completed ||
                OrderStatusDesc(item.Status)?.subStatus ===
                  OrderStatus.Closed ? (
                  <span>
                    Available:{" "}
                    <b>{item.CompletedQuantity - item.DeliveredQuantity}</b> -
                    Remaining:{" "}
                    <b>{item.Offer.OfferQuantity - item.CompletedQuantity}</b> -
                    Delivered: <b>{item.DeliveredQuantity}</b>
                  </span>
                ) : (
                  OrderStatusDesc(item.Status)?.subStatus
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="price-container">
          <div className="demand-container">
            <label>Order</label>
            <div className="multiply">
              {item.Offer.OfferQuantity + " x"}&nbsp;
              <NumberFormatter offerPrice={item.Offer.OfferPrice} supVisible />
            </div>
          </div>
          <div className="total-container">
            <label>Total</label>
            <div className="total-amount">
              <NumberFormatter
                offerPrice={item.Offer.OfferPrice}
                offerQuantity={item.Offer.OfferQuantity}
                supVisible
              />
            </div>
          </div>
          <div className="ms-4 mt-4 pt-2">
            <img
              src={
                props.showCollapse && props.selectedItemId === item.Id
                  ? Icons.collapseArrowUpIcon
                  : Icons.collapseArrowDownIcon
              }
            ></img>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderCollapseHeader;
