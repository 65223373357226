import React, { useState, useEffect } from "react";
import Button from "../../../../components/common/Button";
import { toast } from "react-toastify";
import { Icons } from "../../../../constants/icons";
import { CHANGE_ALL_MY_OFFERS_DETAILS } from "../../../../constants/actionTypes";
import { connect } from "react-redux";
import services from "../../../../api/index";
import settings from "../../../../settings.json";

const SYSTEM_PARTNER_ID = settings["SYSTEM_PARTNER_ID"];
const mapStateToProps = (state) => {
  return {
    InventoryCheckResult: state.order.InventoryCheckResult,
    SelectedInventory: state.order.SelectedInventory,
  };
};

const mapDispatchToProps = (dispatch) => ({
  InventoriesLoad: (payload) => {
    dispatch({ type: CHANGE_ALL_MY_OFFERS_DETAILS, payload });
  },
  InventoryCheck: (value) => {
    dispatch({
      type: CHANGE_ALL_MY_OFFERS_DETAILS,
      key: "InventoryCheckResult",
      value,
    });
  },
});

function ListCreditNoteModal(props) {
  const [creditMemos, setCreditMemos] = useState(undefined);
  useEffect(() => {
    listCreditNotes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const listCreditNotes = () => {
    services.invoice
      .listCreditMemo(props.selectedInvoice.Id)
      .then((res) => {
        setCreditMemos(res);
      })
      .catch((err) => {
        if (err.response?.data?.message)
          toast.error(<div>{err.response?.data.message}</div>);
      });
  };

  return (
    <div className="container order-modal">
      <div className="d-flex my-4 mx-2 flex-column justify-content-start">
        <div className="go-back">
          <img src={Icons.goBackIcon} />
          <Button
            className="go-back-button"
            bindEvent={() => props.handleClose()}
            label="Go back"
          />
        </div>
        <div className="create-package-header">
          <span>Credit Notes</span>
        </div>
        <div className="table-responsive py-3 px-6">
          {creditMemos?.Items.length > 0 ? (
            <table
              className="table table-centered table-nowrap table-hover mb-0 rounded table-responsive cursor-pointer "
              id="datatable"
            >
              <thead className="thead-light d-short border-0">
                <tr>
                  <th className="bg-transparent border-0 px-1">
                    Invoice Number
                  </th>
                  <th className="bg-transparent border-0 px-1">Order Number</th>
                  <th className="bg-transparent border-0 px-4">Reason</th>
                  <th className="bg-transparent border-0 px-4">
                    Credit Amount
                  </th>
                  <th className="bg-transparent border-0 ">Status</th>
                </tr>
              </thead>
              <tbody>
                {creditMemos !== undefined &&
                  creditMemos.Items.map((item) => (
                    <tr className="rounded-1 mb-1 align-middle border-bottom">
                      <td className="px-2">
                        {item.Invoice.Accounting.ExternalInvoiceId}
                      </td>
                      <td className="px-3 text-center">
                        {item.Invoice.OrderNumber}
                      </td>
                      <td className="px-3 text-center">{item.Reason}</td>
                      <td className="px-3 text-center">
                        {item.TotalExcVatAmount}
                      </td>
                      <td className="px-2 text-center">{item.Status}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          ) : (
            <p className="w-100 px-7 px-md-0">There is no credit notes.</p>
          )}
        </div>
      </div>
    </div>
  );
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListCreditNoteModal);
