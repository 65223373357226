import React, { useState } from "react";
import { salesman, superadmin } from "../../../../../../constants/defaults";

import Collapse from "../../../../../../components/common/v2/collapse";
import BlendingTable from "./table";
import useBlending from "../../hooks/useBlending";

const BlendingHeader = ({ blending, order, userScope }) => {
  const { IsBlendingUpdated } = order;

  if (!userScope) {
    return null;
  }

  if (![salesman, superadmin].includes(userScope)) {
    return null;
  }

  if (!blending) {
    return null;
  }

  return (
    <div>
      <p
        className="mt-3 d-flex flex-column gap-2"
        style={{ fontSize: 12, opacity: 0.8 }}
      >
        <span hidden={!IsBlendingUpdated}>
          *Order is using <b>custom</b> blending settings. When the linked
          promotion blending settings is updated, settings below <b>WILL NOT</b>{" "}
          be affected automatically.
        </span>
        <span hidden={IsBlendingUpdated}>
          *Order is using <b>default</b> blending settings. When the linked
          promotion blending settings is updated, settings below <b>WILL</b> be
          affected automatically.
        </span>
        <span>
          **Only <b>Submitted</b> and <b>Paused</b> orders' blending settings
          can be updated.
        </span>
      </p>
    </div>
  );
};

const Blending = ({
  blending: actualBlending,
  order,
  promotion,
  userScope,
}) => {
  const { data, blending, total } = useBlending({
    actualBlending,
    order,
    promotion,
  });

  return (
    <Collapse title="Order Blending">
      <BlendingHeader blending={blending} order={order} userScope={userScope} />
      <BlendingTable
        data={data}
        order={order}
        promotion={promotion}
        total={total}
      />
    </Collapse>
  );
};

export default Blending;
