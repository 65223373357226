import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import Button from "../button";

const Dropdown = ({ trigger = {}, items = [] }) => {
  return (
    <div className="dropdown">
      <Button
        id="dropdownMenuButton"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        theme={trigger.theme}
        title={trigger.title}
      />
      <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
        {items.map((e) => (
          <li key={e.title}>
            <a class="dropdown-item h6 m-0" onClick={e.onClick}>
              {e.element || e.title}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

const propStyles = {};

const styles = {};

Dropdown.defaultProps = {};

Dropdown.propTypes = {};

export default Dropdown;
