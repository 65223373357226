import React from "react";

function OsFilter(props) {
  const { osList, filter, onOperatingSystemsClear, onOperatingSystemsDone } =
    props || {};

  return (
    <>
      {osList === undefined && <></>}
      {osList && (
        <>
          <div className="filter-price d-block">
            <div className="btn-group pe-2 pt-1 rounded-1 align-items-center">
              <button
                type="button"
                className="btn btn-transparent shadow-none dropdown-toggle mr-2 dropdown-toggle-split "
                data-bs-toggle="dropdown"
                aria-expanded="false"
                data-bs-auto-close="outside"
                id="OperatingSystems"
                style={
                  filter.OperatingSystems.length > 0
                    ? { color: "#264EA7" }
                    : { color: "#A4B9E8" }
                }
              >
                <span className="mx-1 text">Operating Systems</span>
                <span className="badge">
                  {filter.OperatingSystems.length > 0 &&
                    filter.OperatingSystems.length}
                </span>
              </button>

              <div className="dropdown-menu border-0 p-1 py-1">
                <div className="publisher-filter-list px-3 py-4 pb-3">
                  {osList.map((item, index) => {
                    return (
                      <div className="form-check" key={index}>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="flexcheckboxDefaultOs"
                          value={item.text}
                          checked={filter?.OperatingSystems.includes(item.text)}
                          id={`os_${index}`}
                          onChange={(ev) => props.onOsChange(ev)}
                        />
                        <label
                          className="form-check-label fw-filter"
                          htmlFor={`os_${index}`}
                        >
                          {item.text}
                        </label>
                      </div>
                    );
                  })}
                </div>
                <div className="d-flex flex-row m-2">
                  {filter.OperatingSystems.length > 0 && (
                    <button
                      onClick={onOperatingSystemsClear}
                      className="button filter-actions clear-all"
                    >
                      Clear
                    </button>
                  )}
                  <button
                    onClick={onOperatingSystemsDone}
                    className="button filter-actions done"
                  >
                    Done
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default OsFilter;
