import React, { useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Modal as BootstrapModal } from "react-bootstrap";

import Button from "../button";
import { toast } from "react-toastify";

const Modal = ({ accessibility, children, size, title, trigger }) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <>
      <Button
        disabled={trigger.disabled}
        onClick={() => setOpen(true)}
        theme={trigger.theme}
        title={trigger.title}
        type={trigger.type}
      />
      <BootstrapModal
        centered
        className="d-flex flex-row justify-content-center align-items-center"
        onHide={() => setOpen(false)}
        scrollable
        show={isOpen}
        size={size}
        // contentClassName="w-100"
        // dialogClassName="w-50"
      >
        <BootstrapModal.Header>
          <h5 className="m-0">{title}</h5>
        </BootstrapModal.Header>
        <BootstrapModal.Body>{children}</BootstrapModal.Body>
        <BootstrapModal.Footer>
          <Button
            onClick={() => setOpen(false)}
            theme={accessibility?.close?.theme || "light"}
            title={accessibility?.close?.title || "Close"}
            type={accessibility?.close?.type}
          />
          <Button
            disabled={accessibility?.submit?.disabled}
            hidden={!accessibility?.submit}
            onClick={async () => {
              try {
                await accessibility?.submit?.onClick();
                setOpen(false);

                if (accessibility?.submit?.successMessage) {
                  toast.success(
                    <div>
                      {accessibility?.submit?.successMessage ||
                        "Submitted successfully"}
                    </div>
                  );
                }
              } catch (error) {
                console.log(error);
                toast.error(
                  <div>
                    {error.response?.data?.message ||
                      error.message ||
                      "Unknown error"}
                  </div>
                );
              }
            }}
            theme={accessibility?.submit?.theme || "success"}
            title={accessibility?.submit?.title || "Submit"}
            type={accessibility?.submit?.type}
          />
        </BootstrapModal.Footer>
      </BootstrapModal>
    </>
  );
};

Modal.defaultProps = {
  accessibility: {},
  size: "xl",
};

Modal.propTypes = {
  accessibility: PropTypes.oneOfType([
    {
      close: PropTypes.oneOfType([
        {
          title: PropTypes.string,
          theme: PropTypes.string,
          type: PropTypes.string,
        },
      ]),
      submit: PropTypes.oneOfType([
        {
          disabled: PropTypes.bool,
          onClick: PropTypes.func,
          successMessage: PropTypes.string,
          title: PropTypes.string,
          theme: PropTypes.string,
          type: PropTypes.string,
        },
      ]),
    },
  ]),
  size: PropTypes.oneOf(["lg", "sm", "xl"]),
  title: PropTypes.string.isRequired,
  trigger: PropTypes.oneOfType([
    {
      disabled: PropTypes.bool,
      theme: PropTypes.string,
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
        .isRequired,
      type: PropTypes.string,
    },
  ]).isRequired,
};

export default Modal;
