import React, { useCallback, useEffect, useState } from "react";

import { OrderStatus } from "../../../../../../../constants/defaults";

import Modal from "../../../../../../../components/common/v2/modal";
import api from "../../../../../../../api";
import { toast } from "react-toastify";

const ModalCreateAllocation = ({ data, userScope }) => {
  const { order } = data || {};

  const [stocks, setStocks] = useState(null);
  const [allocationInfo, setValues] = useState(null);
  const [stockQuantity, setStockQuantity] = useState(() => {
    return parseInt(order.Offer.OfferQuantity - order.CompletedQuantity);
  });

  const onSubmit = useCallback(async () => {
    const results = await api.orders.GetStocksByOrderNumber(
      order.OrderNumber.replaceAll(" ", "")
    );

    let remainingQuantity = order.Quantity;
    let allocationQuantity = 0;

    if (remainingQuantity <= 0) {
      toast.info("The keys will buffered soon!");
      return;
    }

    if (results) {
      results.forEach((element) => {
        allocationQuantity += element.Quantity;
      });
    }

    if (allocationQuantity >= remainingQuantity) {
      toast.info("The keys will buffered soon!");
      return;
    }
    const requestAllocate = remainingQuantity - allocationQuantity;

    if (stockQuantity > requestAllocate) {
      toast.error(`You can allocate a maximum of ${requestAllocate} !`);
      return;
    }

    console.log("StockQuantity: " + stockQuantity);

    await api.orders.createAllocate(
      order.Product.ProductSku,
      order.OrderNumber.replaceAll(" ", ""),
      stockQuantity,
      order.Id
    );
  }, [order, stockQuantity]);

  useEffect(() => {
    const clearState = () => {
      setValues(null);
    };

    if (!order) return clearState;

    const fetchData = async () => {
      try {
        const result = await api.orders.allocateInfo(order.Product.ProductSku);
        setValues(result);
      } catch (error) {
        toast.error(
          <div>
            {error?.response?.data.message || error.message || "Unknown error"}
          </div>
        );
      }
    };
    fetchData();

    const fetchStockData = async () => {
      try {
        const result = await api.orders.GetStocksByOrderNumber(
          order.OrderNumber.replaceAll(" ", "")
        );
        setStocks(result);
      } catch (error) {
        toast.error(
          <div>
            {error?.response?.data.message || error.message || "Unknown error"}
          </div>
        );
      }
    };
    fetchStockData();

    return clearState;
  }, [order]);

  const onChange = (value) => {
    setStockQuantity(parseInt(value));
  };

  if (!order) {
    return <span>Order data is not provided.</span>;
  }

  if (order.IsBilled || order.CompletedQuantity == order.Quantity) {
    return null;
  }

  if (
    ![
      OrderStatus.Processing,
      OrderStatus.Paused,
      OrderStatus.Closed,
      OrderStatus.OutOfStock,
      OrderStatus.PromotionExpired,
    ].includes(order.Status)
  ) {
    return null;
  }

  return (
    <Modal
      accessibility={{
        submit: {
          onClick: onSubmit,
          successMessage: "Keys will be allocated!!",
          title: "Allocate",
        },
      }}
      trigger={{ theme: "info", title: "Request Allocations" }}
      title="Are you sure you want to proceed with allocate the order? "
    >
      <div className="d-flex flex-column gap-2">
        <div>
          <span>
            <div className="d-flex">
              <span className="w-50">
                <b>Allocation Quantity:</b>
              </span>
              <span className="w-50">
                <input
                  name="StockQuantity"
                  disabled={order.IsBilled}
                  defaultValue={
                    order.Offer?.OfferQuantity - order.CompletedQuantity
                  }
                  max={order.Offer?.OfferQuantity - order.CompletedQuantity}
                  min="1"
                  type="number"
                  onChange={(ev) => {
                    onChange(ev.target.value);
                  }}
                />
              </span>
            </div>
          </span>
        </div>

        {allocationInfo ? (
          <div className="d-flex">
            <span className="w-100">
              <b>General Allocation Info</b>
              <code>
                <pre>{JSON.stringify(allocationInfo ?? {}, null, 4)}</pre>
              </code>
            </span>
          </div>
        ) : null}

        <div className="d-flex">
          <span className="w-100">
            <b>Buffered Info</b>
            <code>
              <pre>
                {stocks ? (
                  JSON.stringify(stocks ?? {}, null, 4)
                ) : (
                  <div>Stock data not found</div>
                )}
              </pre>
            </code>
          </span>
        </div>

        <div className="d-flex">
          <span className="w-50">
            <b>Order Number:</b>
          </span>
          <span className="w-50">{order.OrderNumber}</span>

          <span className="w-50">
            <b>Status:</b>
          </span>
          <span className="w-50">{order.Status}</span>
        </div>

        <div className="d-flex">
          <span className="w-50">
            <b>Quantity:</b>
          </span>
          <span className="w-50">{order.Quantity}</span>
        </div>
        <div className="d-flex">
          <span className="w-50">
            <b>Completed Quantity:</b>
          </span>
          <span className="w-50">{order.CompletedQuantity}</span>
        </div>
        <div className="d-flex">
          <span className="w-50">
            <b>Delivered Quantity:</b>
          </span>
          <span className="w-50">{order.DeliveredQuantity}</span>
        </div>
      </div>
    </Modal>
  );
};

export default ModalCreateAllocation;
