import React from "react";
import { connect } from "react-redux";
import {
  UPDATE_FIELD_AUTH,
  LOGIN,
  RETURN_URL,
  REDIRECT,
} from "../constants/actionTypes";
import Button from "../components/common/Button";
import { toast } from "react-toastify";
import ToastMessages from "../components/ToastMessages";
import { Icons } from "../constants/icons";
import services from "../api/index";
import jwt_decode from "jwt-decode";
import { Endpoints } from "../constants/endpoints";

const mapStateToProps = (state) => ({
  ...state.auth,
  returnurl: state.common.returnurl,
  redirectTo: state.common.redirectTo,
});

const mapDispatchToProps = (dispatch) => ({
  onChangeEmail: (value) =>
    dispatch({ type: UPDATE_FIELD_AUTH, key: "email", value }),
  onChangePassword: (value) =>
    dispatch({ type: UPDATE_FIELD_AUTH, key: "password", value }),
  returnUrl: (returnurl) => dispatch({ type: RETURN_URL, returnurl }),
  onRedirect: (key) => dispatch({ type: REDIRECT, redirectTo: key }),
  onLogin: (payload) =>
    dispatch({
      type: LOGIN,
      payload,
    }),
});

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      btnEnable: false,
      loginCounter: 0,
    };
    this.changeEmail = (ev) => this.props.onChangeEmail(ev);
    this.changePassword = (ev) => this.props.onChangePassword(ev);

    this.submitForm = (email, password) => (ev) => {
      ev.preventDefault();
      this.setState({
        btnEnable: true,
        loginCounter: this.state.loginCounter + 1,
      });
      if (this.state.loginCounter > 4) {
        return;
      }
      services.auth
        .login(email, password)
        .then((res) => {
          localStorage.setItem("jwt", JSON.stringify(res));
          this.props.onLogin(res);
          const decoded_jwt = res ? jwt_decode(res.AccessToken) : null;
          if (decoded_jwt.scope.includes("Partner")) {
            this.props.onRedirect(
              this.props.returnurl && this.props.returnurl !== "/login"
                ? this.props.returnurl
                : Endpoints.Partner.Home.url
            );
          } else {
            this.props.onRedirect(
              this.props.returnurl &&
                this.props.returnurl !== "/login" &&
                this.props.returnurl !== "/login/"
                ? this.props.returnurl
                : Endpoints.Management.Home.url
            );
          }
        })
        .catch((err) => {
          if (err && err.status === 404) {
            toast.error(<div>Service unavailable</div>);
            this.setState({ btnEnable: false });
          } else {
            toast.error(<div>{err.response.data.message}</div>);
            this.setState({ btnEnable: false });
          }
        });
    };
  }

  render() {
    const { email, password, error } = this.props;
    return (
      <div className="bg-blue-gradient vh-100">
        <main className="bg-merkur-m vh-100 overflow-auto">
          <section className="d-flex align-items-center my-5 mt-lg-9 mb-lg-5">
            <div className="container">
              <div className="row justify-content-center form-bg-image">
                <div className="col-12 d-flex align-items-center justify-content-center">
                  <div className="rounded p-4 p-lg-6 w-100 fmxw-500">
                    <div className="col-4 mx-auto px-1">
                      <img
                        src={Icons.merkurLogoIcon}
                        className="w-100"
                        alt="Logo"
                      />
                    </div>

                    <div
                      className={`alert alert-danger ${
                        error ? "d-block" : "d-none"
                      }`}
                      role="alert"
                    >
                      Incorrect E-Mail or Password
                    </div>
                    <form
                      onSubmit={this.submitForm(email, password)}
                      className="mt-4"
                    >
                      <div className="form-group mb-4">
                        <div className="input-group-login">
                          <input
                            type="email"
                            className="form-control"
                            placeholder="example@company.com"
                            id="email"
                            value={email}
                            onChange={(ev) => this.changeEmail(ev.target.value)}
                            required
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="form-group mb-4">
                          <div className="input-group-login">
                            <input
                              type="password"
                              placeholder="Password"
                              className="form-control"
                              id="password"
                              value={password}
                              onChange={(ev) =>
                                this.changePassword(ev.target.value)
                              }
                              required
                            />
                          </div>
                        </div>
                        <div className="d-flex flex-row justify-content-end">
                          <Button
                            type="formButton"
                            className="sign-in-button"
                            label="SIGN IN"
                            disabled={this.state.btnEnable}
                            buttonIcon={Icons.rightArrowIcon}
                            iconAlign="right"
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <ToastMessages autoClose={2000} />
        </main>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
