import React, { useState } from "react";
import Button from "../../../components/common/Button";
import NumberInput from "../../../components/common/NumberInput";
import { toast } from "react-toastify";
import { Icons } from "../../../constants/icons";
import { CHANGE_ALL_MY_OFFERS_DETAILS } from "../../../constants/actionTypes";
import { connect } from "react-redux";
import services from "../../../api/index";

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({});

function UpdateCreateVendors(props) {
  const [InternalId, setInternalId] = useState(props.vendor.InternalId);
  const [Name, setName] = useState(props.vendor.Name);
  const [SearchValue, setSearchValue] = useState(props.vendor.SearchValue);

  return (
    <div className="container vendor-model">
      <div className="d-flex my-4 mx-2 flex-column justify-content-start">
        <div className="go-back">
          <img src={Icons.goBackIcon} />
          <Button
            className="go-back-button"
            bindEvent={() => props.handleClose()}
            label="Go back"
          />
        </div>
        <div className="vendor-header h4">
          <span>{props.modelType} Vendor</span>
        </div>
        <div>
          <div className="mb-3 user-input">
            <NumberInput
              notFlex
              visible
              value={InternalId}
              onChange={(ev, val) => {
                setInternalId(ev);
              }}
              label="Internal Id"
              intNumber
              minValue={0}
              maxValue={10000000000000}
            ></NumberInput>
          </div>
          <div className="mb-3 user-input">
            <label htmlFor="Name" class="col-sm-2 col-form-label">
              Name
            </label>
            <div>
              <input
                type="text"
                className="form-control"
                id="Name"
                value={Name}
                onChange={(ev) => setName(ev.target.value)}
              />
            </div>
          </div>
          <div className="mb-3 user-input">
            <label htmlFor="Name" class="col-sm-2 col-form-label">
              SearchValue
            </label>
            <div>
              <input
                type="text"
                className="form-control"
                id="SearchValue"
                value={SearchValue}
                onChange={(ev) => setSearchValue(ev.target.value)}
              />
            </div>
          </div>

          <Button
            className="confirm-button px-4 me-0 float-end"
            bindEvent={() => {
              if (props.modelType === "Create") {
                services.accounting
                  .create(InternalId, Name, SearchValue)
                  .then((res) => {
                    toast.success(<div>Vendor created!</div>);
                    props.handleClose();
                  })
                  .catch((err) => {
                    if (err.response?.data?.message)
                      toast.error(<div>{err.response?.data.message}</div>);
                  });
              } else {
                services.accounting
                  .update(InternalId, Name, SearchValue, props.vendor.Id)
                  .then((res) => {
                    toast.success(<div>Vendor updated!</div>);
                    props.handleClose();
                  })
                  .catch((err) => {
                    if (err.response?.data?.message)
                      toast.error(<div>{err.response?.data.message}</div>);
                  });
              }
            }}
            buttonIcon={Icons.thickIcon}
            label="Update Password"
          />
        </div>
      </div>
    </div>
  );
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateCreateVendors);
