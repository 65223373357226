import React from "react";
import moment from "moment";
import { OrderStatus, partner } from "../../../../../constants/defaults";
import Button from "../../../../../components/common/Button";
import MerModal from "../../../../../components/common/MerModal";
import OrderModalLeftSide from "../modal/OrderModalLeftSide";
import OrderModalRightSide from "../modal/OrderModalRightSide";
import CreatePackageModal from "../modal/CreatePackageModal";
import { connect } from "react-redux";
import {
  SELECT_OFFER_ALL_MY_OFFERS,
  CHANGE_ALL_MY_OFFERS_DETAILS,
  OFFER_DETAILS,
  REFRESH_ITEM,
} from "../../../../../constants/actionTypes";
import OrderConfirm from "../modal/OrderConfirm";
import { toast } from "react-toastify";
import SendPackageModal from "../modal/SendPackageModal";
import { getLatestOrder } from "../../../../../functions/orderValues";
import { convertLocalToUTCDate } from "../../../../../functions/dateConverter";
import { Icons } from "../../../../../constants/icons";
import services from "../../../../../api/index";
import CheckPermission, {
  StaticPermission,
} from "../../../../../constants/permission";

const mapStateToProps = (state) => {
  return { order: state.order, mypermission: state.permission.mypermission };
};

const mapDispatchToProps = (dispatch) => ({
  onChangeCustomPrice: (value) =>
    dispatch({ type: OFFER_DETAILS, key: "customPrice", value }),
  onSelectOrders: (offer) =>
    dispatch({ type: SELECT_OFFER_ALL_MY_OFFERS, offer }),
  onRefreshItem: (order) => dispatch({ type: REFRESH_ITEM, payload: order }),

  onChangeDemandQuantity: (value) =>
    dispatch({
      type: CHANGE_ALL_MY_OFFERS_DETAILS,
      key: "parentDemandQuantity",
      value,
    }),
  onChangeDemandPrice: (value) =>
    dispatch({
      type: CHANGE_ALL_MY_OFFERS_DETAILS,
      key: "parentDemandPrice",
      value,
    }),
});

class OrderCollapseBody extends React.Component {
  constructor() {
    super();
    this.state = {
      showModal: false,
      showSendPackageModal: false,
      showCreatePackageModal: false,
      showConfirm: false,
    };
  }
  handleClose = () => {
    this.setState({ showModal: false });
  };

  handleCreatePackageClose = () => {
    this.setState({ showCreatePackageModal: false });
  };

  handleSendPackageClose = () => {
    this.setState({ showSendPackageModal: false });
  };

  confirmModalClose = () => {
    this.setState({ showConfirm: false });
  };

  selectOrder = (offer) => {
    this.props.onChangeCustomPrice(false);
    const latestOrder = getLatestOrder(offer.History, true);
    this.props.onChangeDemandQuantity(latestOrder?.OfferQuantity);
    this.props.onChangeDemandPrice(latestOrder?.OfferPrice);
    this.props.onSelectOrders(offer);
  };

  withdraw = (orderid) => {
    services.orders
      .withdraw(orderid)
      .then(() => {
        toast.success(
          <div>Your transaction has been completed successfully.</div>
        );
        this.handleClose(false);
        this.refreshItem(orderid);
      })
      .catch((err) => {
        if (err.response?.data?.message)
          toast.error(<div>{err.response?.data.message}</div>);
      });
  };

  resubmit = (quantity, price, orderid) => {
    services.orders
      .resubmit(quantity, price, orderid)
      .then(() => {
        toast.success(
          <div>Your transaction has been completed successfully.</div>
        );
        this.handleClose(false);
        this.refreshItem(orderid);
      })
      .catch((err) => {
        if (err.response?.data?.message)
          toast.error(<div>{err.response?.data.message}</div>);
      });
  };

  // refund = (orderid) => {
  //   services.orders.refund(orderid)
  //     .then(() => {

  //       toast.success(
  //         <div>Your transaction has been completed successfully.</div>
  //       );
  //       this.handleClose(false);
  //       this.refreshItem(orderid);
  //     })
  //     .catch((err) => {
  //       if (err.response?.data?.message) toast.error(<div>{err.response?.data.message}</div>);
  //     });
  // };

  // cancel = (orderid) => {
  //   services.orders.cancel(orderid)
  //     .then(() => {
  //       toast.success(
  //         <div>Your transaction has been completed successfully.</div>
  //       );
  //       this.handleClose(false);
  //       this.refreshItem(orderid);
  //     })
  //     .catch((err) => {
  //       if (err.response?.data?.message) toast.error(<div>{err.response?.data.message}</div>);
  //     });
  // };

  confirm = (orderid) => {
    services.orders
      .confirm(orderid)
      .then(() => {
        toast.success(
          <div>Your transaction has been completed successfully.</div>
        );
        this.handleClose(false);
        this.refreshItem(orderid);
        this.confirmModalClose();
      })
      .catch((err) => {
        if (err.response?.data?.message)
          toast.error(<div>{err.response?.data.message}</div>);
      });
  };

  reject = (orderid) => {
    services.orders
      .reject(orderid)
      .then(() => {
        toast.success(
          <div>Your transaction has been completed successfully.</div>
        );
        this.handleClose(false);
        this.refreshItem(orderid);
        this.confirmModalClose();
      })
      .catch((err) => {
        if (err.response?.data?.message)
          toast.error(<div>{err.response?.data.message}</div>);
      });
  };

  refreshItem = (id) => {
    this.props.onRefreshItem(
      Promise.all([services.orders.getOrder(id)])
        .then((res) => {
          return res;
        })
        .catch((err) => {
          if (err.response?.data?.message)
            toast.error(<div>{err.response?.data.message}</div>);
          return undefined;
        })
    );
  };

  render() {
    return (
      <div className="card-body">
        <div className="order-buttons">
          <Button
            className="confirm-button px-4"
            bindEvent={() => this.setState({ showConfirm: true })}
            visible={
              this.props.item?.Status === OrderStatus.Rebidding &&
              CheckPermission(
                StaticPermission.Order.Approve,
                this.props.mypermission
              )
            }
            label="Approve Order"
            buttonIcon={Icons.thickIcon}
          />
          <Button
            className="order-detail-button px-4"
            bindEvent={() => [
              this.setState({ showModal: true }),
              this.selectOrder(this.props.item),
            ]}
            visible={
              this.props.item?.Status === OrderStatus.Rebidding ||
              this.props.item?.Status === OrderStatus.Withdraw
            }
            label=" See Details"
            buttonIcon={Icons.seeDetailIcon}
          />
          <Button
            className="withdraw-button px-4"
            bindEvent={() => this.withdraw(this.props.item?.Id)}
            visible={
              this.props.item?.Status === OrderStatus.Submitted &&
              CheckPermission(
                StaticPermission.Order.Withdraw,
                this.props.mypermission
              )
            }
            label="Withdraw"
            buttonIcon={Icons.rightArrow135Icon}
          />
          {/* <Button
            className="refund-button px-4"
            bindEvent={() => this.refund(this.props.item?.Id)}
            visible={this.props.item?.Status === OrderStatus.Completed}
            label="Refund"
            buttonIcon={Icons.dollarIcon}
          /> */}
          {/* <Button
            className="cancel-button px-4"
            bindEvent={() => this.cancel(this.props.item?.Id)}
            visible={this.props.item?.Status === OrderStatus.Processing}
            label="Cancel"
            buttonIcon={Icons.crossIcon}
          /> */}
          <Button
            className="create-package-button px-4"
            bindEvent={() => this.setState({ showCreatePackageModal: true })}
            visible={
              this.props.item?.CompletedQuantity -
                this.props.item?.DeliveredQuantity !==
                0 &&
              (this.props.item?.Status === OrderStatus.Processing ||
                this.props.item?.Status === OrderStatus.Completed ||
                this.props.item?.Status === OrderStatus.Closed) &&
              CheckPermission(
                StaticPermission.Order.CreatePackage,
                this.props.mypermission
              )
            }
            label=" Create Package"
            buttonIcon={Icons.createPackageIcon}
          />
          <Button
            className="send-package-button px-4"
            bindEvent={() => this.setState({ showSendPackageModal: true })}
            visible={
              (this.props.item?.Status === OrderStatus.Processing ||
                this.props.item?.Status === OrderStatus.Completed ||
                this.props.item?.Status === OrderStatus.Closed) &&
              this.props.item?.Packages.length > 0 &&
              CheckPermission(
                StaticPermission.Order.SendPackage,
                this.props.mypermission
              )
            }
            label=" Send Package"
            buttonIcon={Icons.sendPackageIcon}
          />
        </div>
        <div className="order-history">
          <div className="order-history-title">Order History</div>
          <div className="order-history-subtitle">
            DP: Demanded Price OP: Offered Price
          </div>
          <div className="order-history-body">
            {this.props.history
              .slice()
              .reverse()
              .map((val, index) => (
                <div className="history-item-horizontal" key={index}>
                  <div className="history-item-vertical">
                    <i className="far fa-circle" />
                    <div className="history-item">
                      {
                        (moment.locale("en"),
                        moment(convertLocalToUTCDate(val.ModifiedAt)).format(
                          "DD MMMM yyyy, hh:mm:ssA, "
                        ) +
                          val.OrderStatus +
                          " from " +
                          val.ModifiedByType +
                          ", ")
                      }
                      {" " +
                        (val.ModifiedByType === partner ? "DP " : "OP ") +
                        val.OfferPrice +
                        "€ x " +
                        val.OfferQuantity +
                        " =  " +
                        Math.round(val.OfferPrice * val.OfferQuantity * 100) /
                          100 +
                        "€"}
                    </div>
                  </div>
                  {this.props.history.length - 1 > index ? (
                    <i className="fas fa-ellipsis-v" />
                  ) : null}
                </div>
              ))}
          </div>
        </div>
        <MerModal
          id="order-modal"
          showModal={this.state.showModal}
          onHide={() => this.handleClose()}
        >
          <OrderModalLeftSide
            resubmit={(_demandQuantity, _demandPrice, _orderId) =>
              this.resubmit(_demandQuantity, _demandPrice, _orderId)
            }
            reject={(_orderId) => this.reject(_orderId)}
            confirm={(_orderId) => {
              this.handleClose();
              this.setState({ showConfirm: true });
            }}
            handleClose={() => this.handleClose()}
          />
          <OrderModalRightSide />
        </MerModal>
        <MerModal
          showModal={this.state.showCreatePackageModal}
          ariaLabelledby="contained-modal-title-vcenter"
          onHide={() => this.handleCreatePackageClose()}
        >
          <CreatePackageModal
            orderItem={this.props.item}
            orderId={this.props.item?.Id}
            handleClose={() => this.handleCreatePackageClose()}
            refreshItem={(id) => this.refreshItem(id)}
          />
        </MerModal>
        <MerModal
          showModal={this.state.showSendPackageModal}
          ariaLabelledby="contained-modal-title-vcenter"
          onHide={() => this.handleSendPackageClose()}
        >
          <SendPackageModal
            orderId={this.props.item?.Id}
            packages={this.props.item?.Packages}
            handleClose={() => this.handleSendPackageClose()}
            refreshItem={(id) => this.refreshItem(id)}
          />
        </MerModal>
        <MerModal
          id="order-confirm"
          showModal={this.state.showConfirm}
          ariaLabelledby="contained-modal-title-vcenter"
          onHide={() => this.confirmModalClose()}
        >
          <OrderConfirm
            handleClose={() => this.confirmModalClose()}
            approveOrder={() => this.confirm(this.props.item?.Id)}
          ></OrderConfirm>
        </MerModal>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderCollapseBody);
