import React from "react";
import classNames from "classnames";

import CollapseTab from "../../../../../../components/common/v2/collapse-tab";
import OrderItemsTab from "./order-items";
import OrderHistoryTab from "./order-history";

const Tabs = ({ order }) => {
  if (!order) {
    return <span>Order data is not provided</span>;
  }

  return (
    <CollapseTab
      tabs={[
        {
          key: "order-item",
          title: "Order Items",
          Item: <OrderItemsTab order={order} />,
        },
        {
          key: "history",
          title: "History",
          Item: <OrderHistoryTab order={order} />,
        },
      ]}
      disabled
    >
      <span>Tabs</span>
    </CollapseTab>
  );
};

export default Tabs;
