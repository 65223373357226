import { LOCATION_CHANGE } from "react-router-redux";
import {
  REDIRECT,
  RETURN_URL,
  PUBLISHER_ON_LOAD,
  PROVIDER_ON_LOAD,
  STORE_ON_LOAD,
  FILTER_DETAILS,
  PARTNER_ON_LOAD,
  MENU_STATUS_CHANGE,
  UPDATE_FIELD_COMMON,
  SELECTED_PAGESIZE,
} from "../constants/actionTypes";

const defaultState = {
  token: null,
  viewChangeCounter: 0,
  showModal: false,
  returnurl: window.location.pathname,
  publisherList: undefined,
  providerList: undefined,
  partnerList: undefined,
  storeList: undefined,
  publisherText: "",
  menuCollapse: true,
  deviceType: "",
  redirectTo: undefined,
  sizes: {
    xs: 320,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1400,
  },
  pageSize: localStorage.getItem("pagesize") ?? 10,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case LOCATION_CHANGE:
      return {
        ...state,
        returnurl: action.payload.pathname,
      };
    case REDIRECT:
      return {
        ...state,
        redirectTo: action.redirectTo,
        returnurl: action.redirectTo ?? state.returnurl,
      };
    case RETURN_URL:
      return {
        ...state,
        returnurl: action.returnurl,
      };
    case PUBLISHER_ON_LOAD:
      return {
        ...state,
        publisherList: action.payload,
      };
    case PROVIDER_ON_LOAD:
      return {
        ...state,
        providerList: action.payload,
      };
    case STORE_ON_LOAD:
      return {
        ...state,
        storeList: action.payload,
      };
    case FILTER_DETAILS:
      return {
        ...state,
        [action.key]: action.value,
      };
    case PARTNER_ON_LOAD:
      return {
        ...state,
        partnerList: action.payload,
      };
    case MENU_STATUS_CHANGE:
      return {
        ...state,
        menuCollapse: action.menuCollapse,
      };
    case UPDATE_FIELD_COMMON:
      return {
        ...state,
        [action.key]: action.value,
      };
    case SELECTED_PAGESIZE:
      return {
        ...state,
        pageSize: action.payload,
      };
    default:
      return state;
  }
};
