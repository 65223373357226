import React from "react";
import CollapseHeader from "./CollapseHeader";
import CollapseBody from "./CollapseBody";

const Collapse = (props) => {
  return (
    <div id="accordion">
      <div className="card order-collapse-card">
        <CollapseHeader
          item={props.item}
          index={props.index}
          onClick={() => props.collapseShow(props.item.Id)}
        ></CollapseHeader>
        <div
          id={`collapse${props.index}`}
          aria-labelledby={`heading${props.index}`}
          data-parent="#accordion"
        >
          <CollapseBody item={props.item} index={props.index}>
            {props.children}
          </CollapseBody>
        </div>
      </div>
    </div>
  );
};

export default Collapse;
