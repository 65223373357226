/* eslint-disable react/require-default-props */
import React, { useState } from "react";
import ReactTooltip from "react-tooltip";
import ConfirmModel from "./ConfirmModel";
import PropTypes, { string } from "prop-types";

const Button = (props) => {
  const [showModel, setShowModel] = useState(false);

  return props.visible || props.visible === undefined ? (
    <div>
      <button
        className={props.className}
        disabled={props.disabled}
        style={{ cursor: props.disabled ? "not-allowed" : "pointer" }}
        id={props.id}
        onClick={() => {
          if (props.confirmable) {
            setShowModel(true);
          } else
            props.type === "formButton" ? null : props.bindEvent(props.value);
        }}
        type={props.type}
        data-tip
        data-for={props.registerTip}
      >
        {props.buttonIcon &&
          (props.iconAlign === "left" || props.iconAlign === undefined) && (
            <img
              className="me-2"
              width={props.iconWidth ? props.iconWidth : 30}
              height={props.iconHeight ? props.iconHeight : 30}
              src={props.buttonIcon}
            />
          )}

        {props.label && props.label}

        {props.buttonIcon &&
          props.iconAlign === undefined &&
          props.label === undefined && (
            <img
              width={props.iconWidth ? props.iconWidth : 30}
              height={props.iconHeight ? props.iconHeight : 30}
              src={props.buttonIcon}
            />
          )}
        {props.buttonIcon && props.iconAlign === "right" && (
          <img
            className="ms-2"
            width={props.iconWidth ? props.iconWidth : 25}
            height={props.iconHeight ? props.iconHeight : 25}
            src={props.buttonIcon}
          />
        )}
        {props.children}
      </button>
      {props.tooltipDesc && (
        <ReactTooltip id={props.registerTip} place={props.place} effect="solid">
          {props.tooltipDesc}
        </ReactTooltip>
      )}
      {props.confirmable && (
        <ConfirmModel
          showModal={showModel}
          confirmFields={props.confirmFields}
          onHide={() => setShowModel(false)}
          callback={() => {
            setShowModel(false);
            props.type === "formButton" ? null : props.bindEvent();
          }}
        ></ConfirmModel>
      )}
    </div>
  ) : (
    <></>
  );
};

export default Button;

// eslint-disable-next-line no-undef
Button.propTypes = {
  visible: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  buttonIcon: PropTypes.string,
  type: PropTypes.string,
  bindEvent: PropTypes.func,
  confirmFields: PropTypes.oneOfType({
    Title: PropTypes.string,
  }),
  confirmable: PropTypes.bool,
  iconAlign: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
};
