import React from "react";
import { waitTimeOut } from "../../functions/FilterFunctions";

function PublisherFilter(props) {
  const {
    publishers,
    isPublisherAvailable,
    filter,
    onFilterPublisher,
    onPublisherChecked,
    onPublisherDone,
    onPublisherClear,
    publisherNames,
    RemovePublisherName,
    isPublisherSelectSingle,
    selectedPublisherItem,
  } = props;

  return (
    <>
      {isPublisherAvailable === true && (
        <div className="filter-publisher d-block">
          <div className="btn-group pe-2 pt-1 rounded-1 align-items-center">
            <button
              type="button"
              className="btn btn-transparent shadow-none dropdown-toggle mr-2 dropdown-toggle-split "
              data-bs-toggle="dropdown"
              aria-expanded="false"
              data-bs-auto-close="outside"
              id="publishers"
              style={
                filter.Publishers.length > 0
                  ? { color: "#264EA7" }
                  : { color: "#A4B9E8" }
              }
            >
              <span className="mx-1 text">Publisher</span>
              <span className="badge">
                {filter.Publishers.length > 0 && filter.Publishers.length}
              </span>
            </button>

            <div className="dropdown-menu border-0 p-1 py-1">
              <div className="input-group flex-nowrap align-items-center search px-4 pt-3">
                <input
                  onChange={(ev) => {
                    let keywords = ev.target.value;
                    waitTimeOut(() => {
                      onFilterPublisher({ keywords: keywords });
                    });
                  }}
                  type="text"
                  className="form-control bg-white border-0 rounded-0 shadow-none "
                  placeholder="Search"
                  aria-label="Publisher"
                  aria-describedby="addon-wrapping"
                  id="publisher-filter-search"
                />
              </div>
              {!isPublisherSelectSingle && publisherNames.length > 0 && (
                <div id="publisher-main">
                  {publisherNames?.map((item, key) => {
                    return (
                      <div key={key} className="publisher-tag px-2">
                        {item}
                        <i
                          onClick={() => RemovePublisherName(item)}
                          className="fas fa-times mx-2"
                        />
                      </div>
                    );
                  })}
                </div>
              )}
              <div className="px-3 py-3">
                {publishers?.Items?.map((item, key) => {
                  return (
                    <div className="form-check" key={key}>
                      <input
                        className="form-check-input"
                        type={isPublisherSelectSingle ? "radio" : "checkbox"}
                        value={item.Id}
                        id={"publisher" + item.Id}
                        onChange={(ev) => onPublisherChecked(ev, item.Name)}
                        checked={
                          isPublisherSelectSingle
                            ? selectedPublisherItem === item.Id
                            : filter.Publishers.includes(item.Id)
                        }
                      />
                      <label
                        className="form-check-label fw-filter"
                        htmlFor={"publisher" + item.Id}
                      >
                        {item.Name}
                      </label>
                    </div>
                  );
                })}
                {isPublisherSelectSingle ? (
                  filter.Publishers.length > 0 && (
                    <div className="m-2">
                      <button
                        onClick={onPublisherClear}
                        className="button filter-actions clear-all"
                      >
                        Clear
                      </button>
                    </div>
                  )
                ) : (
                  <div className="d-flex flex-row">
                    {filter.Publishers.length > 0 && (
                      <button
                        onClick={onPublisherClear}
                        className="button filter-actions clear-all"
                      >
                        Clear All
                      </button>
                    )}
                    <button
                      onClick={onPublisherDone}
                      className="button filter-actions done"
                    >
                      Done
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default PublisherFilter;
