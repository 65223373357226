import React from "react";
import Button from "../../../../components/common/Button";
import { Icons } from "../../../../constants/icons";

const GoogleSheetConfirm = (props) => {
  return (
    <div className="order-confirm-modal">
      <div className="order-confirm-icon">
        <img src={Icons.maskIcon}></img>
      </div>
      <div className="order-confirm-title">
        <label>Are you sure want to update Google sheet?</label>
      </div>
      <div className="order-confirm-description px-5">
        <span>
          If you close this popup, you will not be able to update Google sheet.
          Confirm to update Google sheet.
        </span>
      </div>
      <div className="d-flex justify-content-center">
        <Button
          className="order-confirm-yes-button"
          bindEvent={() => props.updateGoogleSheet()}
          label="Confirm"
          buttonIcon={Icons.thickIcon}
        />
      </div>
      <div className="d-flex justify-content-center">
        <Button
          className="order-confirm-no-button"
          bindEvent={() => props.handleClose()}
          label="Go Back"
          buttonIcon={Icons.leftArrowIcon}
        />
      </div>
    </div>
  );
};

export default GoogleSheetConfirm;
