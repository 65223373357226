import { OrderStatus } from "../../../../../../../constants/defaults";

import api from "../../../../../../../api";

const pause = ({ Id, Status }) => ({
  isEnabled: [OrderStatus.Processing].includes(Status),
  modal: {
    title: "Pause Order",
    accessibility: {
      submit: {
        onClick: () => api.orders.pause(Id),
        successMessage: "Order is paused, successfully",
        title: "Pause",
        theme: "dark",
      },
    },
    trigger: {
      title: "Pause",
      theme: "dark",
    },
    description: "Order will be paused.",
  },
});

export default pause;
