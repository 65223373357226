import React from "react";
import { Link } from "react-router-dom";
import { Icons } from "../constants/icons";
class PageNotFound extends React.Component {
  render() {
    return (
      <div className="page-wrap d-flex flex-row align-items-center">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 text-center">
              <span className="display-1 d-block">404</span>
              <div className="mb-4 lead">
                The page you are looking for was not found.
              </div>
              <div className="media d-flex justify-content-center align-items-center">
                <img
                  className="rounded-circle"
                  alt="Image placeholder"
                  src={Icons.maskIcon}
                />
              </div>
              <Link to="/" className="btn btn-link">
                Back to Home
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PageNotFound;
