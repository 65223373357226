import React from "react";
import {
  OrderItemStatusClass,
  OrderStatusClass,
} from "../../constants/defaults";

function OrderItemStatusFilter(props) {
  const { orderItemStatusList, filter, onStatusChecked, onStatusClear } = props;

  return (
    <>
      {orderItemStatusList === undefined && <></>}
      {orderItemStatusList && (
        <div className="filter-status">
          <div className="btn-group pe-2 pt-1 rounded-1 align-items-center">
            <button
              type="button"
              className="btn btn-transparent shadow-none dropdown-toggle mr-2 dropdown-toggle-split"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              data-bs-auto-close="outside"
              style={
                filter.Status.length > 0
                  ? { color: "#264EA7" }
                  : { color: "#A4B9E8" }
              }
            >
              <span className="mx-1 text">Order Item Status</span>
              <span className="badge">
                {filter.Status.length > 0 && filter.Status.length}
              </span>
            </button>
            <div className="dropdown-menu border-0 p-1 py-1">
              {orderItemStatusList?.map((item, index) => {
                return (
                  <div
                    className="publisher-filter-list px-4 py-2 pb-2 "
                    key={index}
                  >
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value={item}
                        id={`date_${index}`}
                        onChange={(ev) => onStatusChecked(ev)}
                        checked={filter.Status.includes(item)}
                      />
                      <label
                        className={`form-check-label fw-filter ${OrderItemStatusClass(
                          item
                        )}`}
                        htmlFor={`date_${index}`}
                      >
                        {item}
                      </label>
                    </div>
                  </div>
                );
              })}
              {filter.Status.length > 0 && (
                <div className="m-2">
                  <button
                    onClick={onStatusClear}
                    className="button filter-actions clear-all"
                  >
                    Clear
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default OrderItemStatusFilter;
