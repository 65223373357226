import React from "react";
import { connect } from "react-redux";
import {
  USERS_PAGE_LOADED,
  REDIRECT,
  USERS_SEARCH_ONCHANGE,
} from "../../../constants/actionTypes";
import Loader from "../../../components/Loader";
import { toast } from "react-toastify";
import Content from "../../../components/containers/Content";
import BlankPage from "../../../components/BlankPage";
import Button from "../../../components/common/Button";
import { Icons } from "../../../constants/icons";
import MerModal from "../../../components/common/MerModal";
import AddUserModal from "./components/AddUserModal";
import moment from "moment";
import { Endpoints } from "../../../constants/endpoints";
import services from "../../../api/index";

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    userList: state.management.userList,
    search: state.management.userSearchText,
    redirectTo: state.common.redirectTo,
    common: state.common,
  };
};

const mapDispatchToProps = (dispatch) => ({
  userListOnLoad: (payload) => dispatch({ type: USERS_PAGE_LOADED, payload }),
  onRedirect: (redirectTo) => {
    dispatch({ type: REDIRECT, redirectTo });
  },
  onChangeSearch: (value) => dispatch({ type: USERS_SEARCH_ONCHANGE, value }),
});

class Users extends React.Component {
  constructor() {
    super();
    this.state = {
      showModal: false,
    };
    this.changeSearch = (ev) => {
      let value = ev.target.value;
      this.props.onChangeSearch(value);
    };
  }

  componentDidMount() {
    document.title = "Users";
    this.getUsers();
  }

  getUsers() {
    this.props.userListOnLoad(
      Promise.all([services.user.getAll()])
        .then((res) => {
          return res;
        })
        .catch((err) => {
          if (err.response?.data?.message)
            toast.error(<div>{err.response?.data.message}</div>);
          return undefined;
        })
    );
  }

  onEditClick = (id) => {
    let url = Endpoints.Management.EditUser.url.replace(":userid", id);
    this.props.onRedirect(url);
  };

  render() {
    return (
      <Content pageTitle="Users">
        {this.props.userList === undefined ? (
          <Loader />
        ) : (
          <div>
            <div className="mb-3 mb-lg-0 d-flex justify-content-end">
              <Button
                className="add-user-button px-4"
                bindEvent={() => this.setState({ showModal: true })}
                label="Add User"
                buttonIcon={Icons.addUserIcon}
                iconWidth={23}
                iconHeight={23}
              />
            </div>
            {this.props.userList[0].length === 0 ? (
              <BlankPage description="There is no user." />
            ) : (
              <div>
                <div className="table-total-count mt-3">
                  Total Count: {this.props.userList[0].length}
                </div>
                <div className="table-responsive-xl">
                  <table
                    className="table table-centered table-nowrap table-hover mb-0 rounded table-responsive cursor-pointer"
                    id="datatable"
                  >
                    <thead className="thead-light d-short border-0">
                      <tr>
                        <th className="bg-transparent border-0 ">First Name</th>
                        <th className="bg-transparent border-0 ">Last Name</th>
                        <th className="bg-transparent border-0 ">Email</th>
                        <th className="bg-transparent border-0 ">Campaign</th>
                        <th className="bg-transparent border-0 ">User Role</th>
                        <th className="bg-transparent border-0 ">
                          Created Time
                        </th>
                        <th className="bg-transparent border-0 ">
                          Modifed Time
                        </th>
                        <th className="bg-transparent border-0 ">Status</th>
                        <th className="bg-transparent border-0 ps-4 text-center">
                          <i className="fas fa-cog"></i>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.userList[0]?.map((user, key) => {
                        return (
                          <tr
                            key={key}
                            className="rounded-1 mb-1 align-middle border-bottom"
                          >
                            <td className="ps-0">{user.FirstName}</td>
                            <td className="ps-0">{user.LastName}</td>
                            <td className="ps-0">{user.Email}</td>
                            <td className="ps-0">{user.CompanyInfo.Name}</td>
                            <td className="ps-0">{user.Roles.join(", ")}</td>
                            <td className="ps-0">
                              {user.CreatedAt &&
                                moment
                                  .utc(user.CreatedAt)
                                  .format("DD.MM.yyyy - HH:mm:ss")}
                            </td>
                            <td className="ps-0">
                              {user.ModifiedAt &&
                                moment
                                  .utc(user.ModifedAt)
                                  .format("DD.MM.yyyy - HH:mm:ss")}
                            </td>
                            <td className="ps-0 text-center">
                              <span
                                className={
                                  "fa fa-" +
                                  (user.IsActive === true
                                    ? "check text-success"
                                    : "times text-danger")
                                }
                              ></span>
                            </td>
                            <td className="ps-0 text-end">
                              <Button
                                className="confirm-button px-4 me-0"
                                bindEvent={() => this.onEditClick(user.Id)}
                                label="Edit"
                                buttonIcon={Icons.editUserIcon}
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
            <MerModal
              showModal={this.state.showModal}
              ariaLabelledby="contained-modal-title-vcenter"
              onHide={() => this.setState({ showModal: false })}
            >
              <AddUserModal
                handleClose={() => this.setState({ showModal: false })}
                getUsers={() => this.getUsers()}
              />
            </MerModal>
          </div>
        )}
      </Content>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Users);
