import React, { useEffect, useState } from "react";
import Content from "../../../components/containers/Content";
import services from "../../../api/index";
import Loader from "../../../components/Loader";

import {
  MANAGEMENT_VENDORS_PAGE_LOADED,
  REDIRECT,
} from "../../../constants/actionTypes";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import Pager from "../../../components/Pager";
import BlankPage from "../../../components/BlankPage";
import { Icons } from "../../../constants/icons";
import base64 from "base-64";
import MerModal from "../../../components/common/MerModal";
import Button from "../../../components/common/Button";
import Filter from "../../../components/Filter";
import UpdateCreateVendors from "./UpdateCreateVendors";

const mapStateToProps = (state) => {
  return {
    vendors: state.vendors.list,
    pageSize: state.common.pageSize,
  };
};

const mapDispatchToProps = (dispatch) => ({
  ManagmentOnLoad: (payload) => {
    dispatch({ type: MANAGEMENT_VENDORS_PAGE_LOADED, payload });
  },
  onRedirect: (redirectTo) => dispatch({ type: REDIRECT, redirectTo }),
});

function Vendors(props) {
  const [pgeIndex, setPgeIndex] = useState(
    props.match.params.page ? props.match.params.page : 0
  );
  const [myfilter, setFilter] = useState(undefined);
  const [showModal, setShowModal] = useState(false);
  const [modelType, setModelType] = useState();
  const [vendor, setVendor] = useState();

  useEffect(() => {
    document.title = "Vendors";
  }, []);

  useEffect(() => {
    let ev = myfilter;

    getAllManagement({
      keywords: ev?.Text,
      pageindex: 0,
      pagesize: props.pageSize,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.pageSize]);

  const getAllManagement = (params) => {
    const { keywords = "", pageindex = pgeIndex, pagesize = 10 } = params || {};
    setPgeIndex(pageindex);
    return props.ManagmentOnLoad(
      Promise.all([services.accounting.search(keywords, pageindex, pagesize)])
        .then((res) => {
          return res;
        })
        .catch((err) => {
          if (err.response?.data?.message)
            toast.error(<div>{err.response?.data.message}</div>);
          return undefined;
        })
    );
  };

  const onFilter = (params) => {
    let { filter = undefined, pageindex = 0 } = params;
    let ev = filter;
    if (ev !== undefined) setFilter(ev);
    else ev = myfilter;

    getAllManagement({
      keywords: ev?.Text,
      pageindex: pageindex,
      pagesize: props.pageSize,
    });
  };

  const onChangePageIndex = (pageindex) => {
    onFilter({ pageindex: pageindex });
  };

  return (
    <Content pageTitle="Vendors">
      {props.vendors === undefined ? (
        <Loader />
      ) : (
        <div>
          <Filter
            filter={myfilter}
            onFilter={(ev, sortBy) => onFilter(ev, sortBy)}
          ></Filter>
          {props.vendors[0]?.Items.length === 0 ? (
            <BlankPage description="There is no vendor." />
          ) : (
            <div>
              <div className="d-flex  justify-content-between">
                <Button
                  className="confirm-button px-3"
                  bindEvent={() => {
                    setShowModal(!showModal);
                    setModelType("Create");
                  }}
                  label="Create Vendor"
                  buttonIcon={Icons.thickIcon}
                />
              </div>

              <div className="table-responsive-xl p-0">
                <table
                  className="table table-centered table-nowrap table-hover mb-0 rounded table-responsive cursor-pointer"
                  style={{ minWidth: "1200px" }}
                  id="datatable"
                >
                  <thead className="thead-light d-short border-0">
                    <tr>
                      <th className="bg-transparent border-0 ps-2">
                        Vendor Id
                      </th>
                      <th className="bg-transparent border-0 ps-2">
                        Internal Id
                      </th>
                      <th className="bg-transparent border-0 ps-2">
                        Search Value
                      </th>
                      <th className="bg-transparent border-0 ps-2">Name</th>

                      <th className="bg-transparent border-0 ps-2"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.vendors.length > 0 ? (
                      props.vendors[0].Items.map((item, index) => {
                        return (
                          <tr
                            className="rounded-1 mb-1 align-middle border-bottom"
                            key={index}
                          >
                            <td className="ps-2">{item.Id}</td>
                            <td className="ps-2">{item.InternalId}</td>
                            <td className="ps-2">{item.Name}</td>
                            <td className="ps-2">{item.SearchValue}</td>

                            <td className="ps-2 ">
                              <Button
                                className="confirm-button px-3"
                                bindEvent={() => {
                                  setShowModal(!showModal);
                                  setModelType("Update");
                                  setVendor(item);
                                }}
                                label="Update Vendor"
                                buttonIcon={Icons.thickIcon}
                              />
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </tbody>
                </table>
              </div>
              <Pager
                pageIndex={props.vendors[0]?.PageIndex}
                totalPages={props.vendors[0]?.TotalPages}
                totalCount={props.vendors[0]?.TotalCount}
                onChange={(pageIndex) => onChangePageIndex(pageIndex)}
              />
              <MerModal
                showModal={showModal}
                ariaLabelledby="contained-modal-title-vcenter"
                onHide={() => setShowModal(false)}
              >
                <UpdateCreateVendors
                  handleClose={() => {
                    setShowModal(false);
                    getAllManagement({
                      keywords: "",
                      pageindex: pgeIndex,
                      pagesize: props.pageSize,
                    });
                  }}
                  modelType={modelType}
                  vendor={modelType === "Update" && vendor}
                />
              </MerModal>
            </div>
          )}
        </div>
      )}
    </Content>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Vendors);
