import {
  PERMISSIONS_LOADED,
  PERMISSION_LOADED,
  PERMISSIONS_CHANGED,
  ALL_PERMISSION_LOADED,
} from "../constants/actionTypes";

const defaultState = {
  mypermission: undefined,
  all: undefined,
  allpermissions: [],
  selectedGroup: {
    Permissions: [],
  },
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case PERMISSIONS_LOADED:
      return {
        ...state,
        all: action.payload,
      };
    case ALL_PERMISSION_LOADED:
      return {
        ...state,
        allpermissions: action.payload,
      };
    case PERMISSION_LOADED:
      return {
        ...state,
        mypermission: action.payload,
      };
    case PERMISSIONS_CHANGED:
      return {
        ...state,
        selectedGroup: action.payload,
      };
    default:
      return state;
  }
};
