import React from "react";
import { waitTimeOut } from "../../functions/FilterFunctions";

function ProviderFilter(props) {
  const {
    providers,
    isProviderAvailable,
    filter,
    onFilterProvider,
    onProviderChecked,
    onProviderDone,
    onProviderClear,
    providerNames,
    RemoveProviderName,
  } = props;

  return (
    <>
      {isProviderAvailable === true && (
        <div className="filter-provider d-block">
          <div className="btn-group pe-2 pt-1 rounded-1 align-items-center">
            <button
              type="button"
              className="btn btn-transparent shadow-none dropdown-toggle mr-2 dropdown-toggle-split "
              data-bs-toggle="dropdown"
              aria-expanded="false"
              data-bs-auto-close="outside"
              id="providers"
              style={
                filter.Providers.length > 0
                  ? { color: "#264EA7" }
                  : { color: "#A4B9E8" }
              }
            >
              <span className="mx-1 text">Provider</span>
              <span className="badge">
                {filter.Providers.length > 0 && filter.Providers.length}
              </span>
            </button>

            <div className="dropdown-menu border-0 p-1 py-1">
              <div className="input-group flex-nowrap align-items-center search px-4 pt-3">
                <input
                  onChange={(ev) => {
                    let keywords = ev.target.value;
                    waitTimeOut(() => {
                      onFilterProvider({ keywords: keywords });
                    });
                  }}
                  type="text"
                  className="form-control bg-white border-0 rounded-0 shadow-none "
                  placeholder="Search"
                  aria-label="Provider"
                  aria-describedby="addon-wrapping"
                  id="provider-filter-search"
                />
              </div>
              <div id="provider-main">
                {providerNames?.map((item, key) => {
                  return (
                    <div key={key} className="provider-tag px-2">
                      {item}
                      <i
                        onClick={() => RemoveProviderName(item)}
                        className="fas fa-times mx-2"
                      />
                    </div>
                  );
                })}
              </div>
              <div className="px-3 py-4 pb-3">
                {providers?.Items?.map((item, key) => {
                  return (
                    <div className="form-check" key={key}>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value={item.Id}
                        id={"provider" + item.Id}
                        onChange={(ev) => onProviderChecked(ev, item.Name)}
                        checked={filter.Providers.includes(item.Id)}
                      />
                      <label
                        className="form-check-label fw-filter"
                        htmlFor={"provider" + item.Id}
                      >
                        {item.Name}
                      </label>
                    </div>
                  );
                })}
                <div className="d-flex flex-row">
                  {filter.Providers.length > 0 && (
                    <button
                      onClick={onProviderClear}
                      className="button filter-actions clear-all"
                    >
                      Clear All
                    </button>
                  )}
                  <button
                    onClick={onProviderDone}
                    className="button filter-actions done"
                  >
                    Done
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ProviderFilter;
