import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Button from "../../../../components/common/Button";
import { partner, salesman, superadmin } from "../../../../constants/defaults";
import { Icons } from "../../../../constants/icons";
import services from "../../../../api/index";
import SelectList from "../../../../components/common/SelectList";

const AddUserModal = (props) => {
  const [companies, setCompanies] = useState();
  const [permissionGroups, setPermissionGroups] = useState([]);
  const [isActiveValue, setIsActiveValue] = useState();
  const [userValues, setUserValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    userRole: partner,
    companyInfoId: "",
    selectedPermissionGroups: [],
  });

  const changeHandler = (e) => {
    setUserValues({ ...userValues, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    getCompanies();
    searchPermissionGroup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const searchPermissionGroup = (params) => {
    const { keywords = "", pageindex = 0, pagesize = 10 } = params || {};
    services.user
      .searchPermissionGroup(pageindex, pagesize, keywords)
      .then((res) => {
        setPermissionGroups(res.Items);
      });
  };
  const getCompanies = (params) => {
    const { keywords = "", pageindex = 0, pagesize = 50 } = params || {};
    Promise.all([services.company.getCompanies(keywords, pageindex, pagesize)])
      .then((res) => {
        setCompanies(res);
        setUserValues({ ...userValues, companyInfoId: res[0].Items[0].Id });

        return res;
      })
      .catch((err) => {
        if (err.response?.data?.message)
          toast.error(<div>{err.response?.data.message}</div>);
        return undefined;
      });
  };

  const createUser = (params) => {
    const {
      firstName = userValues.firstName,
      lastName = userValues.lastName,
      email = userValues.email,
      password = userValues.password,
      role = userValues.userRole,
      isActive = isActiveValue,
      companyInfoId = userValues.companyInfoId,
      permissionGroup = userValues.selectedPermissionGroups.map((m) => m.Id),
    } = params || {};

    Promise.all([
      services.user.create(
        firstName,
        lastName,
        email,
        password,
        role,
        isActive,
        companyInfoId,
        permissionGroup
      ),
    ])
      .then((res) => {
        toast.success(<div>The user was successfully created.</div>);
        props.handleClose();
        props.getUsers();
        return res;
      })
      .catch((err) => {
        if (err.response?.data?.message)
          toast.error(<div>{err.response?.data.message}</div>);
        return undefined;
      });
  };

  return (
    <div className="container p-5">
      <div className="d-flex flex-row justify-content-between">
        <div className="go-back mb-3">
          <img src={Icons.goBackIcon} />
          <Button
            className="go-back-button"
            bindEvent={() => props.handleClose()}
            label="Go back"
          />
        </div>
        <div className="form-check mb-3">
          <input
            className="form-check-input"
            type="checkbox"
            id="isActiveValue"
            name="isActiveValue"
            value={isActiveValue}
            onChange={(ev) => setIsActiveValue(ev.target.checked)}
          />
          <label className="form-check-label ps-2" htmlFor="isActive">
            Is Active
          </label>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="mb-3 user-input">
            <label htmlFor="firstname" className="form-label">
              First Name
            </label>
            <input
              type="text"
              id="firstname"
              name="firstName"
              placeholder=""
              value={userValues.firstName}
              onChange={changeHandler}
            />
          </div>
        </div>
        <div className="col">
          <div className="mb-3 user-input">
            <label htmlFor="lastname" className="form-label">
              Last Name
            </label>
            <input
              type="text"
              id="lastname"
              name="lastName"
              placeholder=""
              value={userValues.lastName}
              onChange={changeHandler}
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="mb-3 user-input">
              <label htmlFor="userrole" className="form-label">
                Role
              </label>
              <select
                className="form-control"
                aria-label="User Role"
                id="userrole"
                name="userRole"
                value={userValues.userRole}
                onChange={changeHandler}
              >
                <option value={partner}>Partner</option>
                <option value={salesman}>Salesman</option>
                <option value={superadmin}>SuperAdmin</option>
              </select>
            </div>
          </div>
          <div className="col">
            <SelectList
              Title="Permission Group"
              disable={false}
              list={permissionGroups}
              onFilter={(ev) => searchPermissionGroup(ev)}
              listOnChecked={(ev, name) => {
                let selected = userValues.selectedPermissionGroups;
                if (ev.target.checked) {
                  selected.push({ Id: ev.target.value, Name: name });
                } else {
                  selected.splice(selected.indexOf(ev.target.value), 1);
                }
                changeHandler({
                  target: {
                    name: "selectedPermissionGroups",
                    value: selected,
                  },
                });
              }}
              onRemoveName={(item) => {
                let selected = userValues.selectedPermissionGroups;
                selected.splice(selected.indexOf(item), 1);
                changeHandler({
                  target: {
                    name: "selectedPermissionGroups",
                    value: selected,
                  },
                });
              }}
              selectedList={userValues.selectedPermissionGroups}
            ></SelectList>
          </div>
        </div>
      </div>
      <div className="mb-3 user-input">
        <label htmlFor="email" className="form-label">
          Email address
        </label>
        <input
          type="email"
          id="email"
          name="email"
          placeholder="name@example.com"
          value={userValues.email}
          onChange={changeHandler}
        />
      </div>
      <div className="mb-3 user-input">
        <label htmlFor="password" className="form-label">
          Password
        </label>
        <input
          type="password"
          id="password"
          name="password"
          placeholder=""
          value={userValues.password}
          onChange={changeHandler}
        />
      </div>
      <div className="mb-3 user-input">
        <label htmlFor="Company" className="form-label">
          Company
        </label>
        <select
          aria-label="Company"
          id="company"
          name="companyInfoId"
          value={userValues.companyInfoId}
          placeholder="select your company"
          onChange={changeHandler}
        >
          {companies !== undefined &&
            companies[0]?.Items?.map((item, index) => {
              return (
                <option key={index} value={item.Id}>
                  {item.Name}
                </option>
              );
            })}
        </select>
      </div>
      <div className="text-end pt-3">
        <Button
          className="confirm-button px-3 me-0"
          bindEvent={() => createUser()}
          label="Add User"
          buttonIcon={Icons.thickIcon}
        />
      </div>
    </div>
  );
};

export default AddUserModal;
