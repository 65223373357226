import React, { useEffect, useState } from "react";
import Content from "../../components/containers/Content";
import services from "../../api/index";
import Loader from "../../components/Loader";
import Filter from "../../components/Filter";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import Pager from "../../components/Pager";
import BlankPage from "../../components/BlankPage";
import Button from "../../components/common/Button";
import { Icons } from "../../constants/icons";

const mapStateToProps = (state) => {
  return {
    pageSize: state.common.pageSize,
  };
};

const mapDispatchToProps = (dispatch) => ({});

function InventoryReposts(props) {
  const [pageIndex, setPageIndex] = useState(0);
  const [inventories, setInventories] = useState(undefined);
  const [selectedInventory, setSelectedInventory] = useState(undefined);
  const [inventoryDetail, setInventoryDetail] = useState(undefined);
  const [searchInventory, setSearchInventory] = useState("");

  useEffect(() => {
    getInventoryReports("");
  }, []);
  useEffect(() => {
    if (selectedInventory) {
      getReport(selectedInventory, "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.pageSize, selectedInventory, pageIndex]);

  const getInventoryReports = (keywords) => {
    services.report
      .GetInventoryReports(keywords, 0, 50, "finance")
      .then((res) => {
        setSelectedInventory(res.Items[0]);
        setInventories(res);
      })
      .catch((err) => {
        if (err.response?.data?.message)
          toast.error(<div>{err.response?.data.message}</div>);
      });
  };

  const getReport = (selected_Inventory, keywords) => {
    services.report
      .GetInventoryReportDetails(
        selected_Inventory,
        keywords,
        pageIndex,
        props.pageSize,
        "finance"
      )
      .then((res) => {
        setInventoryDetail(res);
      })
      .catch((err) => {
        if (err.response?.data?.message)
          toast.error(<div>{err.response?.data.message}</div>);
      });
  };

  const onFilter = (ev) => {
    const { filter } = ev;
    if (
      filter.SelectedInventory !== selectedInventory &&
      filter.SelectedInventory !== ""
    ) {
      setSelectedInventory(filter.SelectedInventory);
    }
    if (searchInventory !== filter.Text) {
      setSearchInventory(filter.Text);
      getReport(selectedInventory, filter.Text);
    }
  };

  return (
    <Content>
      <div className="d-flex flex-row">
        <h1>Inventory Reports</h1>

        <Button
          className="add-update-from-google-sheet"
          label=""
          bindEvent={() => {}}
          buttonIcon={Icons.addFromGoogleSheetIcon}
          iconWidth={20}
          iconHeight={20}
          registerTip="create-promotion-from-google-sheet"
          place="right"
          tooltipDesc="Create Promotion From Google Sheet"
        />
      </div>
      {inventories === undefined ? (
        <Loader />
      ) : (
        <div>
          <Filter
            inventories={inventories.Items}
            onFilterInventories={(params) => {
              const { keywords } = params;
              getInventoryReports(keywords);
            }}
            onFilter={(ev) => onFilter(ev)}
            SelectedInventory={selectedInventory}
          ></Filter>

          {inventoryDetail !== undefined && selectedInventory !== "" ? (
            <div>
              {inventoryDetail.Items.length === 0 ? (
                <BlankPage description="There is no invoice." />
              ) : (
                <div>
                  <div className="table-responsive-xl p-0">
                    <table
                      className="table table-centered table-nowrap table-hover mb-0 rounded table-responsive cursor-pointer"
                      style={{ minWidth: "1200px" }}
                      id="datatable"
                    >
                      <thead className="thead-light d-short border-0">
                        <tr>
                          <th className="bg-transparent border-0 ps-2">
                            Store
                          </th>
                          <th className="bg-transparent border-0 ps-2">
                            Provider
                          </th>
                          <th className="bg-transparent border-0 ps-2">Sku</th>
                          <th className="bg-transparent border-0 ps-2">Name</th>

                          <th className="bg-transparent border-0 ps-2">
                            TotalQuantity
                          </th>
                          <th className="bg-transparent border-0 ps-2">
                            TotalCost
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {inventoryDetail.Items.map((item, index) => {
                          return (
                            <tr
                              className="rounded-1 mb-1 align-middle border-bottom"
                              key={index}
                            >
                              <td className="ps-2">{item.Store}</td>
                              <td className="ps-2">{item.Provider}</td>
                              <td className="ps-2">{item.Sku}</td>
                              <td className="ps-2">{item.Name}</td>

                              <td className="ps-2">{item.TotalQuantity}</td>
                              <td className="ps-2">{item.TotalCost}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  <Pager
                    pageIndex={inventoryDetail.PageIndex}
                    totalPages={inventoryDetail.TotalPages}
                    totalCount={inventoryDetail.TotalCount}
                    onChange={(pgindex) => setPageIndex(pgindex)}
                  />
                </div>
              )}
            </div>
          ) : (
            <div></div>
          )}
        </div>
      )}
    </Content>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(InventoryReposts);
