import React, { useEffect, useState } from "react";
import api from "../../../api";
import { toast } from "react-toastify";
import Content from "../../../components/containers/Content";
import Loader from "../../../components/Loader";
import { Icons } from "../../../constants/icons";
import Button from "../../../components/common/Button";

const ManagementRegionPage = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const regions = await api.regions.getRegions();
        setData(regions);
      } catch (err) {
        if (err?.response?.text) {
          toast.error(<div>{JSON.parse(err?.response?.text)?.message}</div>);
          return;
        }

        toast.error(<div>{err.message}</div>);
      }
    })();
  }, []);

  if (!data) {
    return (
      <Content>
        <div className="d-flex flex-row">
          <h1>Merkur Regions</h1>
          <Loader />
        </div>
      </Content>
    );
  }

  return (
    <Content>
      <div className="d-flex flex-row align-items-center mb-3">
        <h1 className="m-0">Merkur Regions</h1>
        {/* <Button
          className="bulk_buttons mx-3 px-4"
          label="Create new region"
          bindEvent={() => null}
          iconAlign="right"
          buttonIcon={Icons.nullIcon}
          disabled={true}
        /> */}
      </div>
      <div className="d-flex flex-column">
        <table className="table table-centered table-nowrap table-hover mb-0 rounded table-responsive cursor-pointer">
          <thead className="thead-light d-short border-0">
            <tr>
              <th>Region</th>
              {/* <th>Actions</th> */}
            </tr>
          </thead>
          <tbody className="rounded-1 mb-1 align-middle border-bottom">
            {data.map((e) => (
              <tr key={e.Region}>
                <td>
                  <span>{e.Region}</span>
                </td>
                {/* <td></td> */}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Content>
  );
};

export default ManagementRegionPage;
