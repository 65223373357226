import React from "react";
import classNames from "classnames";
import moment from "moment";
import { Link } from "react-router-dom";
import OrderStatusText from "../../../../../../components/common/v2/status/order-status";

const DetailDateColumn = ({ data, title }) => (
  <span className="d-flex flex-column gap-2" style={{ fontSize: 14 }}>
    <b className="dark-slate-blue">
      {title}
      <sup>UTC</sup>
    </b>
    <span className="cloudy-blue">
      {data ? moment.utc(data).format("DD.MM.yyyy HH:mm:ss") : "-"}
    </span>
  </span>
);

const DetailDataColumn = ({ data, title }) => (
  <span className="d-flex flex-column gap-2" style={{ fontSize: 14 }}>
    <b className="dark-slate-blue">{title}</b>
    <span className="cloudy-blue">{data || "-"}</span>
  </span>
);

const DetailLinkColumn = ({ data, href, title }) => (
  <span className="d-flex flex-column gap-2" style={{ fontSize: 14 }}>
    <b className="dark-slate-blue">{title}</b>
    <Link
      className="link-success"
      to={href}
      target="_blank"
      rel="noopener noreferrer"
    >
      {data || "-"}
      <i className="ms-2 fas fa-external-link-alt"></i>
    </Link>
  </span>
);

const Detail = ({ order, promotion }) => {
  if (!order) {
    return <span>Order data is not provided.</span>;
  }

  return (
    <div className="card p-3">
      <div className="d-flex flex-column gap-4">
        <div className="w-100 d-flex flex-row justify-content-between align-items-center gap-4">
          <h4 className="m-0 border-bottom text-nowrap">{order.OrderNumber}</h4>
          <OrderStatusText classname="h4" data={order} />
        </div>
        <div className="w-100 d-flex flex-row align-items-top gap-4 h6 m-0">
          <div className="flex-grow-1 text-left">
            <DetailDateColumn
              data={order.ProcessingStartOnUtc}
              title="Process Start Date"
            />
          </div>
          <div className="flex-grow-1 text-center">
            <DetailDateColumn
              data={order.ProcessingEndOnUtc}
              title="Process End Date"
            />
          </div>
          <div className="flex-grow-1 text-right">
            <DetailDateColumn
              data={order.EstimatedFinishDate}
              title="Est. Finish Date"
            />
          </div>
          <div className="flex-grow-1 text-right">
            <DetailDataColumn
              data={order.IsFastBot ? "Experimental" : "Default"}
              title="Purchase System"
            />
          </div>
        </div>
        <div className="w-100 d-flex flex-row align-items-top gap-4 h6 m-0">
          <div className="flex-grow-1 d-flex flex-column gap-4">
            <DetailDateColumn data={order.CreatedAt} title="Ordered At" />
            <DetailLinkColumn
              href={`/management/account/${order.Partner.PartnerId}`}
              data={order.Partner.Name}
              title="Partner"
            />
          </div>
          <div className="flex-grow-1 d-flex flex-column gap-4">
            <DetailLinkColumn
              href={`/management/promotion-detail/${promotion?.Id}`}
              data={promotion?.Id}
              title="Promotion ID"
            />
            <DetailDateColumn
              data={promotion?.ValidFrom}
              title="Promotion Valid From"
            />
            <DetailDateColumn
              data={promotion?.ValidTo}
              title="Promotion Valid To"
            />
          </div>
          <div className="flex-grow-1 d-flex flex-column gap-4">
            <DetailDateColumn
              data={order.Product.ProductReleaseDate}
              title="Released At"
            />
            <DetailDataColumn data={order.Product.ProductSku} title="SKU" />
            <DetailDataColumn data={promotion?.Product.Region} title="Region" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Detail;
