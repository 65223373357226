import React, { useState } from "react";
import { toast } from "react-toastify";
import Button from "../../../../components/common/Button";
import NumberInput from "../../../../components/common/NumberInput";
import {
  gold,
  platinum,
  standard,
  CompanyInvoicePeriod,
} from "../../../../constants/defaults";
import { Icons } from "../../../../constants/icons";
import services from "../../../../api/index";
import _set from "lodash.set";

const CreateorUpdateCompanyModal = ({ regions = [], ...props }) => {
  const [companyValues, setCompanyValues] = useState({
    companyId:
      props.selectedCompany !== undefined ? props.selectedCompany.Id : "",
    name: props.selectedCompany !== undefined ? props.selectedCompany.Name : "",
    email:
      props.selectedCompany !== undefined ? props.selectedCompany.Email : "",
    type:
      props.selectedCompany !== undefined
        ? props.selectedCompany.Type
        : standard,
    address:
      props.selectedCompany !== undefined ? props.selectedCompany.Address : "",
    zipCode:
      props.selectedCompany !== undefined ? props.selectedCompany.ZipCode : "",
    state:
      props.selectedCompany !== undefined ? props.selectedCompany.State : "",
    city: props.selectedCompany !== undefined ? props.selectedCompany.City : "",
    country:
      props.selectedCompany !== undefined ? props.selectedCompany.Country : "",
    countryCode:
      props.selectedCompany !== undefined
        ? props.selectedCompany.CountryCode
        : "",
    accountNumber:
      props.selectedCompany !== undefined
        ? props.selectedCompany.AccountingInfo.AccountNumber
        : "",
    vatRate:
      props.selectedCompany !== undefined
        ? props.selectedCompany.AccountingInfo.VatRate
        : "",
    totalCreditLimit:
      props.selectedCompany !== undefined
        ? props.selectedCompany.AccountingInfo.TotalCreditLimit
        : "",
    balance:
      props.selectedCompany !== undefined
        ? props.selectedCompany.AccountingInfo.Balance
        : "",
    packageLimit:
      props.selectedCompany !== undefined
        ? props.selectedCompany.AccountingInfo.PackageLimit
        : "",

    invoicePeriod:
      props.selectedCompany !== undefined
        ? props.selectedCompany.InvoicePeriod
        : CompanyInvoicePeriod.Weekly,

    paidRequired:
      props.selectedCompany !== undefined
        ? props.selectedCompany.PaidRequired
        : false,
    keyQuantity:
      props.selectedCompany !== undefined
        ? props.selectedCompany?.UnPaidKeyQuantity
        : 0,
    orderTotalAmount:
      props.selectedCompany !== undefined
        ? props.selectedCompany?.OrderTotalAmount
        : 0,
    regionIncluded: props.selectedCompany?.RegionSettings?.Included || false,
    regionList:
      props.selectedCompany?.RegionSettings?.Regions?.reduce(
        (prev, curr) => ({ ...prev, [curr]: true }),
        {}
      ) || {},
  });

  const changeHandler = (e) => {
    const target = e.target;
    if (!target) return;

    setCompanyValues((v) => {
      const oldValue = JSON.parse(JSON.stringify(v));
      return _set(oldValue, target.name, target.value);
    });
  };

  const createCompany = (params) => {
    const {
      name = companyValues.name,
      email = companyValues.email,
      type = companyValues.type,
      address = companyValues.address,
      zipCode = companyValues.zipCode,
      state = companyValues.state,
      city = companyValues.city,
      country = companyValues.country,
      countryCode = companyValues.countryCode,
      accountNumber = companyValues.accountNumber,
      vatRate = companyValues.vatRate,
      totalCreditLimit = companyValues.totalCreditLimit,
      balance = companyValues.balance,
      packageLimit = companyValues.packageLimit,
      invoicePeriod = companyValues.invoicePeriod,
      paidRequired = companyValues.paidRequired,
      keyQuantity = companyValues.keyQuantity,
      regionIncluded = companyValues.regionIncluded || false,
      regionList = Object.keys(companyValues.regionList || {}).filter(
        (e) => companyValues.regionList?.[e]
      ) || [],
    } = params || {};
    Promise.all([
      services.company.createCompany(
        name,
        email,
        type,
        address,
        zipCode,
        state,
        city,
        country,
        countryCode,
        accountNumber,
        vatRate,
        totalCreditLimit,
        balance,
        packageLimit,
        invoicePeriod,
        paidRequired,
        keyQuantity,
        regionIncluded,
        regionList
      ),
    ])
      .then((res) => {
        toast.success(<div>The company was successfully created.</div>);
        props.handleClose();
        props.companiesRefresh();
        return res;
      })
      .catch((err) => {
        if (err.response?.data?.message)
          toast.error(<div>{err.response?.data.message}</div>);
        return undefined;
      });
  };

  const updateCompany = (params) => {
    const {
      companyId = companyValues.companyId,
      name = companyValues.name,
      email = companyValues.email,
      type = companyValues.type,
      address = companyValues.address,
      zipCode = companyValues.zipCode,
      state = companyValues.state,
      city = companyValues.city,
      country = companyValues.country,
      countryCode = companyValues.countryCode,
      accountNumber = companyValues.accountNumber,
      vatRate = companyValues.vatRate,
      totalCreditLimit = companyValues.totalCreditLimit,
      balance = companyValues.balance,
      packageLimit = companyValues.packageLimit,
      invoicePeriod = companyValues.invoicePeriod,
      paidRequired = companyValues.paidRequired,
      keyQuantity = companyValues.keyQuantity ? companyValues.keyQuantity : 0,
      orderTotalAmount = companyValues.orderTotalAmount
        ? companyValues.orderTotalAmount
        : 0,
      regionIncluded = companyValues.regionIncluded || false,
      regionList = Object.keys(companyValues.regionList || {}).filter(
        (e) => companyValues.regionList?.[e]
      ) || [],
    } = params || {};

    Promise.all([
      services.company.updateCompany(
        companyId,
        name,
        email,
        type,
        address,
        zipCode,
        state,
        city,
        country,
        countryCode,
        accountNumber,
        vatRate,
        totalCreditLimit,
        balance,
        packageLimit,
        invoicePeriod,
        paidRequired,
        keyQuantity,
        orderTotalAmount,
        regionIncluded,
        regionList
      ),
    ])
      .then((res) => {
        toast.success(<div>The company was successfully updated.</div>);
        props.handleClose();
        props.companiesRefresh();
        return res;
      })
      .catch((err) => {
        if (err.response?.data?.message)
          toast.error(<div>{err.response?.data.message}</div>);
        return undefined;
      });
  };

  return (
    <div className="p-5">
      <div className="row">
        <div className="col form-check mb-3 d-flex flex-row justify-content-end">
          <input
            className="form-check-input"
            type="checkbox"
            id="PaidRequired"
            name="PaidRequired"
            checked={companyValues.paidRequired}
            onChange={(ev) =>
              changeHandler({
                target: {
                  name: "paidRequired",
                  value: ev.target.checked,
                },
              })
            }
          />
          <label className="form-check-label ps-2" htmlFor="paidRequired">
            Payment required for creating package
          </label>
        </div>
      </div>
      <div className="row">
        <div className="mb-3 user-input">
          <label htmlFor="name" className="form-label">
            Company Name
          </label>
          <input
            type="text"
            id="name"
            name="name"
            placeholder=""
            value={companyValues.name}
            onChange={changeHandler}
          />
        </div>
        <div className="row">
          <div className="col">
            <div className="mb-3 user-input">
              <label htmlFor="email" className="form-label">
                Company Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="name@example.com"
                value={companyValues.email}
                onChange={changeHandler}
              />
            </div>
          </div>
          <div className="col">
            <div className="mb-3 user-input">
              <label htmlFor="type" className="form-label">
                Company Type
              </label>
              <select
                aria-label="Company Type"
                id="type"
                name="type"
                value={companyValues.type}
                onChange={changeHandler}
              >
                <option value={standard}>Standard</option>
                <option value={gold}>Gold</option>
                <option value={platinum}>Platinum</option>
              </select>
            </div>
          </div>
          <div className="col">
            <div className="mb-3 user-input">
              <label htmlFor="invoicePeriod" className="form-label">
                Invoicing Period
              </label>
              <select
                aria-label="Invoice Period"
                id="invoicePeriod"
                name="invoicePeriod"
                value={companyValues.invoicePeriod}
                onChange={changeHandler}
              >
                <option value={CompanyInvoicePeriod.Weekly}>Weekly</option>
                <option value={CompanyInvoicePeriod.Immediately}>
                  Immediately
                </option>
                <option value={CompanyInvoicePeriod.KeyQuantity}>
                  KeyQuantity
                </option>
                <option value={CompanyInvoicePeriod.KeyAmount}>
                  KeyAmount
                </option>
              </select>
            </div>
          </div>
          {companyValues.invoicePeriod == CompanyInvoicePeriod.KeyQuantity && (
            <div className="col">
              <div className="mb-3 user-input">
                <label htmlFor="keyQuantity" className="form-label">
                  Key Quantity
                </label>
                <input
                  type="keyQuantity"
                  id="keyQuantity"
                  name="keyQuantity"
                  placeholder="0"
                  value={companyValues.keyQuantity}
                  visible
                  intNumber
                  onChange={changeHandler}
                />
              </div>
            </div>
          )}
          {companyValues.invoicePeriod == CompanyInvoicePeriod.KeyAmount && (
            <div className="col">
              <div className="mb-3 user-input">
                <label htmlFor="orderTotalAmount" className="form-label">
                  Order Total Amount
                </label>
                <input
                  type="orderTotalAmount"
                  id="orderTotalAmount"
                  name="orderTotalAmount"
                  placeholder="0"
                  value={companyValues.orderTotalAmount}
                  onChange={changeHandler}
                  visible
                  intNumber
                  maxValue={100000000000}
                />
              </div>
            </div>
          )}
        </div>
        <div className="mb-3 user-input">
          <label htmlFor="Address" className="form-label">
            Address
          </label>
          <textarea
            id="address"
            name="address"
            placeholder=""
            value={companyValues.address}
            onChange={changeHandler}
          ></textarea>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="mb-3 user-input">
            <label htmlFor="ZipCode" className="form-label">
              Zip Code
            </label>
            <input
              type="number"
              id="ZipCode"
              name="zipCode"
              placeholder=""
              value={companyValues.zipCode}
              onChange={changeHandler}
            />
          </div>
        </div>
        <div className="col">
          <div className="mb-3 user-input">
            <label htmlFor="State" className="form-label">
              State
            </label>
            <input
              type="text"
              id="State"
              name="state"
              placeholder=""
              value={companyValues.state}
              onChange={changeHandler}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="mb-3 user-input">
            <label htmlFor="City" className="form-label">
              City
            </label>
            <input
              type="text"
              id="City"
              name="city"
              placeholder=""
              value={companyValues.city}
              onChange={changeHandler}
            />
          </div>
        </div>
        <div className="col">
          <div className="mb-3 user-input">
            <label htmlFor="Country" className="form-label">
              Country
            </label>
            <input
              type="text"
              id="Country"
              name="country"
              placeholder=""
              value={companyValues.country}
              onChange={changeHandler}
            />
          </div>
        </div>
        <div className="col">
          <div className="mb-3 user-input">
            <label htmlFor="CountryCode" className="form-label">
              Country Code
            </label>
            <input
              type="text"
              id="CountryCode"
              name="countryCode"
              placeholder=""
              value={companyValues.countryCode}
              onChange={changeHandler}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="mb-3 user-input">
            <NumberInput
              name="accountNumber"
              label="Account Number"
              value={companyValues.accountNumber}
              onChange={(ev) =>
                changeHandler({
                  target: {
                    name: "accountNumber",
                    value: ev,
                  },
                })
              }
              visible
              maxValue={100000000000}
              intNumber
              notFlex
            ></NumberInput>
          </div>
        </div>
        <div className="col">
          <div className="mb-3 user-input">
            <NumberInput
              name="vatRate"
              label="VatRate"
              value={companyValues.vatRate}
              onChange={(ev) =>
                changeHandler({
                  target: {
                    name: "vatRate",
                    value: ev,
                  },
                })
              }
              visible
              maxValue={100000000000}
              intNumber
              notFlex
            ></NumberInput>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="mb-3 user-input">
            <NumberInput
              name="totalCreditLimit"
              centerAlign
              label="Total Credit Limit"
              value={companyValues.totalCreditLimit}
              onChange={(ev) =>
                changeHandler({
                  target: {
                    name: "totalCreditLimit",
                    value: ev,
                  },
                })
              }
              visible
              intNumber
            ></NumberInput>
          </div>
        </div>
        <div className="col">
          <div className="mb-3 user-input">
            <NumberInput
              name="packageLimit"
              centerAlign
              label="Package Limit"
              value={companyValues.packageLimit}
              onChange={(ev) =>
                changeHandler({
                  target: {
                    name: "packageLimit",
                    value: ev,
                  },
                })
              }
              visible
              maxValue={100000000000}
            ></NumberInput>
          </div>
        </div>
        <div className="col">
          <div className="mb-3 user-input">
            <NumberInput
              name="balance"
              centerAlign
              label="Balance"
              value={companyValues.balance}
              onChange={(ev) =>
                changeHandler({
                  target: {
                    name: "balance",
                    value: ev,
                  },
                })
              }
              visible
              maxValue={100000000000}
              intNumber
            ></NumberInput>
          </div>
        </div>
        <div className="row">
          <h6>Region Settings</h6>
          <hr />
          <div className="col">
            <div className="d-flex flex-column user-input">
              <div
                className="d-flex flex-row align-items-center"
                style={{
                  opacity: companyValues.regionIncluded ? 1 : 0.65,
                }}
              >
                <label className="mx-2">Whitelist</label>
                <input
                  name="regionIncluded"
                  type="checkbox"
                  checked={companyValues.regionIncluded}
                  onChange={(e) =>
                    changeHandler({
                      target: {
                        name: e.target.name,
                        value: true,
                      },
                    })
                  }
                />
              </div>
              <div
                className="d-flex flex-row align-items-center"
                style={{
                  opacity: companyValues.regionIncluded ? 0.5 : 1,
                }}
              >
                <label className="mx-2">Blacklist</label>
                <input
                  name="regionIncluded"
                  type="checkbox"
                  checked={!companyValues.regionIncluded}
                  onChange={(e) =>
                    changeHandler({
                      target: {
                        name: e.target.name,
                        value: false,
                      },
                    })
                  }
                />
              </div>
            </div>
          </div>
          <div className="col">
            <div className="d-flex flex-column user-input">
              <label>
                {companyValues.regionIncluded ? "Whitelisted" : "Blacklisted"}{" "}
                Regions
              </label>
              <ul>
                {regions?.map((e) => (
                  <div key={e.Region} className="d-flex">
                    <input
                      name={`regionList.${e.Region}`}
                      style={{ marginRight: 24 }}
                      type="checkbox"
                      checked={companyValues.regionList?.[e.Region] || false}
                      onChange={({ target }) =>
                        changeHandler({
                          target: {
                            name: target.name,
                            value: target.checked,
                          },
                        })
                      }
                    />
                    <span>{e.Region}</span>
                  </div>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="text-end">
        <Button
          className="confirm-button px-4 me-0"
          bindEvent={() =>
            props.selectedCompany === undefined
              ? createCompany()
              : updateCompany()
          }
          buttonIcon={Icons.thickIcon}
          label={props.selectedCompany === undefined ? "Create" : "Update"}
        />
      </div>
    </div>
  );
};

export default CreateorUpdateCompanyModal;
