import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Content from "../../components/containers/Content";
import { connect } from "react-redux";
import services from "../../api/index";

import Button from "../../components/common/Button";
import NumberInput from "../../components/common/NumberInput";
import ReactTooltip from "react-tooltip";

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({});

function Settings(props) {
  const [orderSettings, setOrderSettings] = useState({});
  const [selectedGroupId, setSelectedGroupId] = useState("order");

  const getSettings = () => {
    services.settings.getOrderSettings().then((res) => {
      setOrderSettings(res);
    });
  };

  useEffect(() => {
    document.title = "Settings";
    getSettings();
  }, []);

  return (
    <Content pageTitle="Settings">
      <div className="permission-container">
        <div className="d-flex  justify-content-between">
          <ul className="list-group list-group-flush permission_groups">
            <li
              className={
                "list-group-item p-4 cursor-pointer	permission-list-item " +
                (selectedGroupId === "order" && "list-active")
              }
            >
              Order Settings
            </li>
          </ul>
          <div className="w-100">
            <div className="d-flex p-3 permission_conten permission-background mx-4 ">
              <ul className="d-flex">
                <li className="form-check m-2">
                  <div className="form-group user-input">
                    <label className="form-check-label  ">
                      Profit Factor{" "}
                      <a type="button" data-tip data-for="tooltip">
                        <i className="fas fa-info-circle"></i>{" "}
                      </a>
                      <ReactTooltip id="tooltip" place="right" effect="solid">
                        Minimum profit factor for Orders
                      </ReactTooltip>
                    </label>
                    <NumberInput
                      value={orderSettings.ProfitFactor}
                      onChange={async (ev, val) => {
                        let newSettings = { ...orderSettings };
                        newSettings.ProfitFactor = ev;
                        await setOrderSettings(newSettings);
                      }}
                      visible
                      maxValue={100}
                      minValue={0}
                      intNumber
                      notPadding
                      centerAlign
                      percentage
                    ></NumberInput>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <Button
          className="permission-button  m-4 float-end"
          bindEvent={() => {
            if (selectedGroupId === "order") {
              services.settings
                .updateOrderSettings(orderSettings)
                .then((res) => {
                  toast.success(<div>Settings saved successfuly!</div>);
                })
                .catch((err) => {
                  if (err.response?.data?.message)
                    toast.error(<div>{err.response?.data.message}</div>);
                });
            }
          }}
          label="Update"
        />
      </div>
    </Content>
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(Settings);
