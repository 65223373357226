import React, { useEffect, useState } from "react";
import Content from "../../../components/containers/Content";
import Loader from "../../../components/Loader";
import base64 from "base-64";
import {
  REPORT_MONTHLY_REVENUE_PAGE_LOADED,
  REDIRECT,
} from "../../../constants/actionTypes";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import Pager from "../../../components/Pager";
import BlankPage from "../../../components/BlankPage";
import Filter from "../../../components/Filter";
import { dateTypes } from "../../../constants/defaults";
import { Endpoints } from "../../../constants/endpoints";
import { getLocalAccessToken } from "../../../api/services/token";
import { Icons } from "../../../constants/icons";
import Button from "../../../components/common/Button";
import JsFileDownloader from "js-file-downloader";
import services from "../../../api/index";

const mapStateToProps = (state) => {
  return {
    reportMonthlyRevenuies: state.report.reportMonthlyRevenuies,
  };
};

const mapDispatchToProps = (dispatch) => ({
  PageOnLoad: (payload) => {
    dispatch({ type: REPORT_MONTHLY_REVENUE_PAGE_LOADED, payload });
  },
  onRedirect: (redirectTo) => dispatch({ type: REDIRECT, redirectTo }),
});

function MonthlyRevenueReport(props) {
  const [pageIndex, setPgeIndex] = useState(0);
  const [myfilter, setFilter] = useState(
    props.match.params.filter
      ? JSON.parse(base64.decode(props.match.params.filter))
      : null
  );
  const [downloadUrl, setDownloadUrl] = useState("");
  useEffect(() => {
    document.title = "Monthly Revenue Reports";
  }, []);
  useEffect(() => {
    let ev = myfilter;

    getMonthluRevenuies({
      pageindex: ev?.pageindex,
      startDate: ev?.DateRange?.min,
      endDate: ev?.DateRange?.max,
    }); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getMonthluRevenuies = (params) => {
    const { pageindex, startDate, endDate } = params || {};
    setPgeIndex(pageindex);

    let downUrl = `${services.API_ROOT}/report/management/DownloadMonthlyRevenueOrders?PageSize=1000`;
    if (startDate && endDate) {
      downUrl = `${services.API_ROOT}/report/management/DownloadMonthlyRevenueOrders?StartDate=#startDate&EndDate=#endDate&PageIndex=0&PageSize=1000`;
      downUrl = downUrl
        .replace("#startDate", startDate)
        .replace("#endDate", endDate);
    }

    setDownloadUrl(downUrl);

    return props.PageOnLoad(
      Promise.all([
        services.report.getOrderMonthlyRevenue(
          startDate,
          endDate,
          pageindex,
          50
        ),
      ])
        .then((res) => {
          return res;
        })
        .catch((err) => {
          if (err.response?.data?.message)
            toast.error(<div>{err.response?.data.message}</div>);
          return undefined;
        })
    );
  };

  const onChangePageIndex = (pageindex) => {
    onFilter({ pageindex: pageindex });
  };

  const onFilter = (params) => {
    let { filter = myfilter, sortBy = undefined, pageindex = 0 } = params;
    let ev = filter;
    if (ev !== undefined) setFilter(ev);
    else ev = myfilter;

    let url = Endpoints.Management.MonthlyRevenue.url
      .replace(":filter?", base64.encode(ev ? JSON.stringify(ev) : "0"))
      .replace(":sort?", sortBy ? sortBy : "0")
      .replace(":page?", pageindex);
    props.onRedirect(url);

    getMonthluRevenuies({
      pageindex: pageindex,
      startDate: ev?.DateRange?.min,
      endDate: ev?.DateRange?.max,
    });
  };

  const downloadFile = (filename) => {
    const token = getLocalAccessToken();
    console.log("downloadUrl:");
    console.log(downloadUrl);
    let download = new JsFileDownloader({
      url: downloadUrl,
      headers: [{ name: "Authorization", value: "Bearer " + token }],
      autoStart: false,
      nameCallback: () => {
        return filename;
      },
    });
    download
      .start()
      .then(() => {})
      .catch(function (error) {
        // handle errors
      });
  };

  return (
    <Content pageTitle="">
      <div className="d-flex flex-row justify-content-between">
        <h1>Monthly Revenue Reports</h1>
        <Button
          className="add-update-from-google-sheet ps-3 float-end"
          label="Download Report"
          bindEvent={() => {
            downloadFile("report.csv");
          }}
          buttonIcon={Icons.excelIcon}
          iconWidth={30}
          iconHeight={30}
          registerTip="create-promotion-from-google-sheet"
          place="right"
          tooltipDesc="Download Report (.csv)"
        />
      </div>
      {props.reportMonthlyRevenuies === undefined ? (
        <Loader />
      ) : (
        <div>
          <Filter
            filter={myfilter}
            onFilter={(ev) => onFilter(ev)}
            dateList={[dateTypes.LASTMONTH, dateTypes.LAST2MONTH]}
            hideSearch
          ></Filter>
          {props.reportMonthlyRevenuies[0]?.Items.length === 0 ? (
            <BlankPage description="There is no sellers." />
          ) : (
            <div>
              <div className="table-total-count">
                Total Count: {props.reportMonthlyRevenuies[0]?.TotalCount}
              </div>
              <div className="table-responsive-xl p-0">
                <table
                  className="table table-centered table-nowrap table-hover mb-0 rounded table-responsive cursor-pointer"
                  id="datatable"
                >
                  <thead className="thead-light d-short border-0">
                    <tr>
                      <th className="bg-transparent border-0">Store</th>
                      <th className="bg-transparent border-0">Partner</th>
                      <th className="bg-transparent border-0">OrderNumber</th>
                      <th className="bg-transparent border-0">Status</th>
                      <th className="bg-transparent border-0">Name</th>
                      <th className="bg-transparent border-0">Publisher</th>
                      <th className="bg-transparent border-0">Of. Quantity</th>
                      <th className="bg-transparent border-0">Of. Price</th>
                      <th className="bg-transparent border-0">
                        Comp. Quantity
                      </th>
                      <th className="bg-transparent border-0">CostOfSales</th>
                      <th className="bg-transparent border-0">
                        EstCostOfSales
                      </th>
                      <th className="bg-transparent border-0">Revenue</th>
                      <th className="bg-transparent border-0">CreatedAt</th>
                      <th className="bg-transparent border-0">Invoice ID</th>
                      <th className="bg-transparent border-0">Del. Quantity</th>
                      <th className="bg-transparent border-0">Ava. Quantity</th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.reportMonthlyRevenuies.length > 0 ? (
                      props.reportMonthlyRevenuies[0].Items.map(
                        (item, index) => {
                          return (
                            <tr
                              className="rounded-1 mb-1 align-middle border-bottom"
                              key={index}
                            >
                              <td className="ps-0">{item.Store}</td>
                              <td className="ps-0">{item.PartnerName}</td>
                              <td className="ps-0">{item.OrderNumber}</td>
                              <td className="ps-0">{item.Status}</td>
                              <td className="ps-0">{item.ProductName}</td>
                              <td className="ps-0">{item.ProductPublisher}</td>
                              <td className="ps-0">{item.OfferQuantity}</td>
                              <td className="ps-0">{item.OfferPrice}</td>
                              <td className="ps-0">{item.CompletedQuantity}</td>
                              <td className="ps-0">{item.CostOfSales}</td>
                              <td className="ps-0">{item.EstCostOfSales}</td>
                              <td className="ps-0">{item.Revenue}</td>
                              <td className="ps-0">{item.CreatedAt}</td>
                              <td className="ps-0">{item.InvoiceNumber}</td>
                              <td className="ps-0">{item.DeliveredQuantity}</td>
                              <td className="ps-0">{item.AvailableQuantity}</td>
                            </tr>
                          );
                        }
                      )
                    ) : (
                      <></>
                    )}
                  </tbody>
                </table>
              </div>
              <Pager
                pageIndex={props.reportMonthlyRevenuies[0]?.PageIndex}
                totalPages={props.reportMonthlyRevenuies[0]?.TotalPages}
                totalCount={props.reportMonthlyRevenuies[0]?.TotalCount}
                onChange={(pageindex) => onChangePageIndex(pageindex)}
              />
            </div>
          )}
        </div>
      )}
    </Content>
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MonthlyRevenueReport);
