import React from "react";
import classNames from "classnames";
import moment from "moment";

import { OrderItemStatus } from "../../../../../../constants/defaults";

const LatestStatus = ({ order }) => {
  if (!order) {
    return <span>Order data is not provided</span>;
  }

  if (!order.LastBuyKeyStatus) {
    return null;
  }

  return (
    <div className="d-flex gap-2 mb-2" style={{ fontSize: 14 }}>
      <span>Latest Purchase Status:</span>
      <span>
        {order.LastBuyKeyStatus === OrderItemStatus.Completed
          ? "Received"
          : order.LastBuyKeyStatus}
      </span>
      <span>{moment(order.LastKeyTime).format("DD MMMM yyyy, H:mm:ss")}</span>
    </div>
  );
};

export default LatestStatus;
