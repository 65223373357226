import React from "react";
import Button from "./Button";

const PromotionHeader = (props) => {
  return (
    <div className="promotion-header">
      <label>{props.title}</label>
      {props.product && props.title !== "Select Game" && (
        <div className="promotion-product">
          <label>Product:</label>
          <p>&nbsp;{props.product}</p>
          <Button
            className="promotion-header-change-button"
            label="Change"
            bindEvent={() => props.goToSelectGame()}
          />
        </div>
      )}
    </div>
  );
};

export default PromotionHeader;
