import React from "react";
import Loader from "../../components/Loader";
import ProgressBar from "../../components/ProgressBar";
import NumberFormat from "react-number-format";
import { toast } from "react-toastify";
import BlankPage from "../../components/BlankPage";
import Content from "../../components/containers/Content";
import { connect } from "react-redux";
import { COMPANIES_PAGE_LOADED } from "../../constants/actionTypes";
import Pager from "../../components/Pager";
import { getSorting } from "../../functions/FilterFunctions";
import base64 from "base-64";
import Filter from "../../components/Filter";
import services from "../../api/index";

const mapStateToProps = (state) => {
  return {
    companies: state.companies,
    redirectTo: state.common.redirectTo,
    pageSize: state.common.pageSize,
  };
};

const mapDispatchToProps = (dispatch) => ({
  companiesOnLoad: (payload) => {
    dispatch({ type: COMPANIES_PAGE_LOADED, payload });
  },
});

class CreditLimits extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: this.props.match.params.filter
        ? JSON.parse(base64.decode(this.props.match.params.filter))
        : null,
      sortBy: null,
      pageindex: 0,
      tableIsLoading: false,
    };
  }
  componentDidMount() {
    document.title = "Credit Limit (Monthly)";
    this.getCompanies();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.pageSize !== this.props.pageSize) {
      this.onFilter({ pageindex: 0 });
    }
  }

  getCompanies = (params) => {
    this.setState({ tableIsLoading: true });
    const {
      keywords = "",
      pageindex = this.state.pageindex,
      pagesize = this.props.pageSize,
    } = params || {};
    this.props.companiesOnLoad(
      Promise.all([
        services.company.getCompanies(keywords, pageindex, pagesize),
      ])
        .then((res) => {
          this.setState({ tableIsLoading: false });
          return res;
        })
        .catch((err) => {
          if (err.response?.data?.message)
            toast.error(<div>{err.response?.data.message}</div>);
          return undefined;
        })
    );
  };

  onFilter = (params) => {
    let { filter, sortBy = undefined, pageindex = 0 } = params || {};
    let ev = filter;
    if (ev !== undefined) this.setState({ filter: ev });
    else ev = this.state.filter;
    sortBy = getSorting(sortBy, this.state.sortBy);
    this.setState({ sortBy: sortBy });

    this.getCompanies({
      keywords: ev?.Text,
      pageindex: pageindex,
      SortBy: sortBy,
    });
  };

  onChangePageIndex = (pageindex) => {
    this.setState({ tableIsLoading: true });
    this.onFilter({ pageindex: pageindex });
  };

  render() {
    const { companyList } = this.props.companies;
    return (
      <Content pageTitle="Credit Limit (Monthly)">
        {companyList == undefined ? (
          <Loader />
        ) : (
          <div>
            <Filter
              filter={this.state.filter}
              onFilter={(ev) => this.onFilter(ev)}
            ></Filter>
            {this.state.tableIsLoading ? (
              <Loader />
            ) : companyList[0]?.Items.length === 0 ? (
              <BlankPage description="There is no credit limit." />
            ) : (
              <div>
                <div className="table-total-count">
                  Total Count: {companyList[0]?.TotalCount}
                </div>
                <div className="table-responsive-xl">
                  <table
                    className="table table-centered table-nowrap table-hover mb-0 rounded table-responsive cursor-pointer table-width-100"
                    id="datatable"
                  >
                    <thead className="thead-light d-short border-0">
                      <tr>
                        <th className="bg-transparent border-0 ">Company</th>
                        <th className="bg-transparent border-0 ">
                          Total Credit Limit
                        </th>
                        <th className="bg-transparent border-0 ">
                          Package Limit
                        </th>
                        <th className="bg-transparent border-0 ">Balance</th>
                        <th className="bg-transparent border-0 ">
                          Remaining Percentage
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {companyList[0]?.Items.map((item, index) => {
                        return (
                          <tr
                            className="rounded-1 mb-1 align-middle border-bottom"
                            key={index}
                          >
                            <td>
                              <a href={`mailto:${item.Email}`}>{item.Name}</a>
                            </td>
                            <td>
                              <NumberFormat
                                className="text-primary"
                                value={item.AccountingInfo.TotalCreditLimit}
                                thousandSeparator
                                displayType="text"
                              ></NumberFormat>
                              €
                            </td>
                            <td>
                              <NumberFormat
                                className="text-primary"
                                value={item.AccountingInfo.PackageLimit}
                                thousandSeparator
                                displayType="text"
                              ></NumberFormat>
                              €
                            </td>
                            <td>
                              <NumberFormat
                                className="text-primary"
                                value={item.AccountingInfo.Balance}
                                thousandSeparator
                                displayType="text"
                              ></NumberFormat>
                              €
                            </td>
                            <td>
                              <ProgressBar
                                tableProgressBar
                                percentage={
                                  item.AccountingInfo.TotalCreditLimit ===
                                    undefined ||
                                  item.AccountingInfo.TotalCreditLimit === 0
                                    ? 100
                                    : (
                                        ((item.AccountingInfo.TotalCreditLimit -
                                          item.AccountingInfo.Balance) *
                                          100) /
                                        item.AccountingInfo.TotalCreditLimit
                                      ).toFixed(2)
                                }
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <Pager
                  pageIndex={companyList[0]?.PageIndex}
                  totalPages={companyList[0]?.TotalPages}
                  totalCount={companyList[0]?.TotalCount}
                  onChange={(pageIndex) => this.onChangePageIndex(pageIndex)}
                />
              </div>
            )}
          </div>
        )}
      </Content>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreditLimits);
