import React from "react";
import ToastMessages from "../ToastMessages";

export default function Content(props) {
  return (
    <div className={"content " + (props.classes ? props.classes : "")}>
      <div className="row">
        <div className="col-12 col-sm-12 content__container">
          {props.pageTitle && <h1>{props.pageTitle}</h1>}
          {props.children}
        </div>
      </div>
      <ToastMessages autoClose={2000} />
    </div>
  );
}
