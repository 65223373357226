import React from "react";
import { connect } from "react-redux";
import { REPORT_ORDER_ITEMS_COUNT_BY_STATE } from "../../constants/actionTypes";
import { toast } from "react-toastify";
import moment from "moment";
import Loader from "../../components/Loader";
import Pager from "../../components/Pager";
import Content from "../../components/containers/Content";
import "react-datepicker/dist/react-datepicker.css";
import services from "../../api/index";

const mapStateToProps = (state) => {
  return { report: state.report, pageSize: state.common.pageSize };
};

const mapDispatchToProps = (dispatch) => ({
  orderItemsCountByStateOnLoad: (payload) =>
    dispatch({ type: REPORT_ORDER_ITEMS_COUNT_BY_STATE, payload }),
});

class Index extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      orderItemsCountByStatePageindex: 0,
      orderItemsCountByStateStatus: "",
      validDateFrom: undefined,
      validDateTo: undefined,
      activeTab: "OutOfStock",
      monthlyProfit: {
        Profit: 0.0,
        Percentage: 0.0,
      },
    };
    this.getMonthlyProfits = this.getMonthlyProfits.bind(this);
  }

  componentDidMount() {
    document.title = "Merkur";

    this.getOrderItemsCountByState();
  }

  getOrderItemsCountByState(params) {
    const {
      pageIndex = 0,
      pageSize = this.props.pageSize,
      state = "OutOfStock",
    } = params || {};
    this.setState({
      orderItemsCountByStateStatus: state,
      activeTab: state,
    });
    this.props.orderItemsCountByStateOnLoad(
      services.report
        .getOrderItemsCountByState(pageIndex, pageSize, state)
        .then((res) => {
          return res;
        })
        .catch((err) => {
          if (err.response?.data?.message)
            toast.error(<div>{err.response?.data.message}</div>);
          return undefined;
        })
    );
  }

  getMonthlyProfits() {
    services.report
      .GetMonthlyProfit()
      .then((res) => {
        this.setState({
          ...this.state,
          monthlyProfit: res,
        });

        return res;
      })
      .catch((err) => {
        if (err.response?.data?.message)
          toast.error(<div>{err.response?.data.message}</div>);
        return undefined;
      });
  }

  render() {
    const filterPassedMinTime = (time) => {
      const currentDate =
        this.state.validDateTo !== undefined
          ? new Date(this.state.validDateTo)
          : new Date();
      const selectedDate = new Date(time);
      return currentDate.getTime() > selectedDate.getTime();
    };
    const filterPassedMaxTime = (time) => {
      const currentDate =
        this.state.validDateFrom !== undefined
          ? new Date(this.state.validDateFrom)
          : new Date(time);
      const selectedDate =
        this.state.validDateFrom !== undefined ? new Date(time) : new Date();
      return currentDate.getTime() < selectedDate.getTime();
    };
    return (
      <Content pageTitle="Overview">
        <div className="row">
          <div className="card px-5 py-5">
            <div className="d-flex monthly-profit">
              <div className="d-inline-flex fs-6 me-4 w-50">
                <span className="order-history mt-0 me-2">
                  Monthly Profit Amount:
                </span>
                <div className="d-inline-flex flex-row justify-content-end">
                  <strong
                    className={
                      "order-history mt-0" +
                      (this.state.monthlyProfit.Profit < 0
                        ? " text-danger"
                        : " text-success")
                    }
                  >
                    {`${this.state.monthlyProfit.Profit.toFixed(2)}`}
                  </strong>
                  {this.state.monthlyProfit.Profit > 0 && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="#0a9396"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M2.25 18L9 11.25l4.306 4.307a11.95 11.95 0 015.814-5.519l2.74-1.22m0 0l-5.94-2.28m5.94 2.28l-2.28 5.941"
                      />
                    </svg>
                  )}
                  {this.state.monthlyProfit.Profit < 0 && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="#ae2012"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M2.25 6L9 12.75l4.286-4.286a11.948 11.948 0 014.306 6.43l.776 2.898m0 0l3.182-5.511m-3.182 5.51l-5.511-3.181"
                      />
                    </svg>
                  )}
                </div>
              </div>

              <div className="d-inline-flex fs-6 w-50">
                <span className="order-history mt-0 me-2">
                  Monthly Profit Margin:
                </span>
                <div className="d-inline-flex flex-row justify-content-end">
                  <strong
                    className={
                      "order-history mt-0" +
                      (this.state.monthlyProfit.Percentage < 0
                        ? " text-danger"
                        : " text-success")
                    }
                  >
                    {`${this.state.monthlyProfit.Percentage.toFixed(2)}%`}
                  </strong>
                  {this.state.monthlyProfit.Percentage > 0 && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="#0a9396"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M2.25 18L9 11.25l4.306 4.307a11.95 11.95 0 015.814-5.519l2.74-1.22m0 0l-5.94-2.28m5.94 2.28l-2.28 5.941"
                      />
                    </svg>
                  )}
                  {this.state.monthlyProfit.Percentage < 0 && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="#ae2012"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M2.25 6L9 12.75l4.286-4.286a11.948 11.948 0 014.306 6.43l.776 2.898m0 0l3.182-5.511m-3.182 5.51l-5.511-3.181"
                      />
                    </svg>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="card px-5 py-5">
            <div className="p-0">
              <label className="dashboard-title-text">
                <b>Order Item Count by Status</b>
              </label>
            </div>
            <div className="d-flex flex-row justify-content-between border-bottom">
              <ul className="nav nav-tabs ">
                <li className="nav-item">
                  <a
                    className={
                      "nav-link " +
                      (this.state.activeTab == "OutOfStock" ? "active" : "")
                    }
                    href="#"
                    onClick={() =>
                      this.getOrderItemsCountByState({
                        pageindex: this.state.orderItemsCountByStatePageindex,
                        state: "OutOfStock",
                      })
                    }
                  >
                    Out of Stock
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={
                      "nav-link " +
                      (this.state.activeTab == "Error" ? "active" : "")
                    }
                    aria-current="page"
                    href="#"
                    onClick={() =>
                      this.getOrderItemsCountByState({
                        pageindex: this.state.orderItemsCountByStatePageindex,
                        state: "Error",
                      })
                    }
                  >
                    Error
                  </a>
                </li>

                <li className="nav-item">
                  <a
                    className={
                      "nav-link " +
                      (this.state.activeTab == "Refunded" ? "active" : "")
                    }
                    aria-current="page"
                    href="#"
                    onClick={() =>
                      this.getOrderItemsCountByState({
                        pageindex: this.state.orderItemsCountByStatePageindex,
                        state: "Refunded",
                      })
                    }
                  >
                    Refunded
                  </a>
                </li>
              </ul>
              <div className="table-total-count mt-3">
                Total Count:
                {this.props.report?.reportOrderItemsCountByState &&
                  this.props.report?.reportOrderItemsCountByState?.TotalCount}
              </div>
            </div>

            {this.props.report?.reportOrderItemsCountByState === undefined ? (
              <Loader />
            ) : (
              <div className="table-responsive-xl p-0">
                <table className="table table-centered table-nowrap table-hover mb-0 rounded table-responsive cursor-pointer card-table">
                  <thead>
                    <tr>
                      <th>
                        <p>Product SKU</p>
                      </th>
                      <th>
                        <p>Product Name</p>
                      </th>
                      <th>
                        <p>Count</p>
                      </th>
                      <th>
                        <p>Last Trying Date</p>
                      </th>

                      {/* <th></th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.report?.reportOrderItemsCountByState?.Items
                      .length === 0 ? (
                      <label className="order-detail-table-no-item">
                        There is no available key !
                      </label>
                    ) : (
                      this.props.report?.reportOrderItemsCountByState?.Items.map(
                        (item, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                <p>{item.ProductSku ?? "-"}</p>
                              </td>
                              <td>
                                <p>{item.ProductName ?? "-"}</p>
                              </td>
                              <td>
                                <p>{item.Count ?? "-"}</p>
                              </td>
                              <td>
                                <p>
                                  {moment
                                    .utc(item?.LastTryingDate)
                                    .format("DD.MM.yyyy hh:mm:ss")}
                                </p>
                              </td>
                            </tr>
                          );
                        }
                      )
                    )}
                  </tbody>
                </table>
                <Pager
                  pageIndex={
                    this.props.report?.reportOrderItemsCountByState?.PageIndex
                  }
                  totalPages={
                    this.props.report?.reportOrderItemsCountByState?.TotalPages
                  }
                  totalCount={
                    this.props.report?.reportOrderItemsCountByState?.TotalCount
                  }
                  onChange={(pageIndex) =>
                    this.getOrderItemsCountByState({
                      pageindex: pageIndex,
                      state: this.state.orderItemsCountByStateStatus,
                    })
                  }
                />
              </div>
            )}
          </div>
          <iframe
            title="dash"
            width="960"
            height="720"
            src="https://eu-central-1.quicksight.aws.amazon.com/sn/embed/share/accounts/011758244598/dashboards/fd1e4d42-bbde-4c6e-9116-467c8af432a3?directory_alias=merkurhub"
          ></iframe>
        </div>
      </Content>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Index);
