import { Endpoints } from "./endpoints";
// eslint-disable-next-line import/no-cycle
import { ButtonType, FontWeight } from "./types";

export const superadmin = "SuperAdmin";
export const salesman = "Salesman";
export const partner = "Partner";
export const common = "common";
export const finance = "Finance";

export const standard = "Standard";
export const gold = "Gold";
export const platinum = "Platinum";

export const CompanyInvoicePeriod = {
  Weekly: "Weekly",
  Immediately: "Immediately",
  KeyQuantity: "KeyQuantity",
  KeyAmount: "KeyAmount",
};

export const OrderStatus = {
  Refund: "Refund",
  Draft: "Draft",
  Submitted: "Submitted",
  Rebidding: "Rebidding",
  Confirmed: "Confirmed",
  Processing: "Processing",
  Completed: "Completed",
  Cancelled: "Cancelled",
  Refused: "Refused",
  Failed: "Failed",
  Refunded: "Refunded",
  Withdraw: "Withdraw",
  Revoked: "Revoked",
  Rejected: "Rejected",
  Closed: "Closed",
  Transferred: "Transferred",
  SalesmanApproved: "SalesmanApproved",
  Level1Approved: "Level1Approved",
  Level2Approved: "Level2Approved",
  AdminRefused: "AdminRefused",
  Paused: "Paused",
  OutOfStock: "OutOfStock",
  PromotionExpired: "PromotionExpired",
};

export const OrderItemStatus = {
  Draft: "Draft",
  Processing: "Processing",
  PreCompleted: "PreCompleted",
  Completed: "Completed",
  OutOfStock: "OutOfStock",
  Error: "Error",
  Buffered: "Buffered",
  BackOrdered: "BackOrdered",
};

export const InvoiceStatus = {
  Draft: "Draft",
  Paid: "Paid",
  Unpaid: "Unpaid",
  PartialPaid: "PartialPaid",
};

export const OrderPaidStatus = {
  Paid: "Paid",
  UnPaid: "UnPaid",
  PartyPaid: "PartyPaid",
};

export const OrderStatusClass = (statusname) => {
  switch (statusname) {
    case OrderStatus.Draft:
      return "bg-gray-600 text-white";
    case OrderStatus.Submitted:
      return "text-primary";
    case OrderStatus.Rebidding:
      return " text-indigo";
    case OrderStatus.Withdraw:
      return "text-gray-600";
    case OrderStatus.Confirmed:
      return "text-success";
    case OrderStatus.Processing:
      return "text-warning";
    case OrderStatus.Completed:
      return "text-success";
    case OrderStatus.Cancelled:
      return "text-danger";
    case OrderStatus.Refused:
      return "text-danger";
    case OrderStatus.Failed:
      return "text-danger";
    case OrderStatus.Refund:
      return "bg-white text-danger fw-bold";
    case OrderStatus.Refunded:
      return "text-purple";
    case OrderStatus.Rejected:
      return "text-danger";
    case OrderStatus.Revoked:
      return "bg-white text-secondary fw-bold";
    case OrderStatus.Closed:
      return "bg-white text-danger fw-bold";
    case OrderStatus.Paused:
      return "text-warning text-danger fw-bold";
    default:
      return "";
  }
};

export const OrderItemStatusClass = (statusname) => {
  switch (statusname) {
    case OrderItemStatus.Draft:
      return "cloudy-blue";
    case OrderItemStatus.Processing:
      return "mango";
    case OrderItemStatus.PreCompleted:
      return "mango";
    case OrderItemStatus.BackOrdered:
      return "mango";
    case OrderItemStatus.Completed:
      return "yellowish-green";
    case OrderItemStatus.OutOfStock:
      return "dark-slate-blue";
    case OrderItemStatus.Error:
      return "carnation";
    default:
      return "cloudy-blue";
  }
};

export const InvoiceStatusClass = (statusname) => {
  switch (statusname) {
    case InvoiceStatus.Draft:
      return "bg-white text-draft";
    case InvoiceStatus.Paid:
      return "bg-white text-paid";
    case InvoiceStatus.Unpaid:
      return "bg-white text-unpaid";
    case InvoiceStatus.PartialPaid:
      return "text-partiallypaid";
    default:
      return "";
  }
};

export const getColor = (itemStatus) => {
  switch (itemStatus) {
    case OrderStatus.Refund:
      return "#0BC621";
    case OrderStatus.Draft:
      return "#AAB6D6";
    case OrderStatus.Cancelled:
      return "#A6CEE3";
    case OrderStatus.Submitted:
      return "#16254E";
    case OrderStatus.Rebidding:
      return "#FFA729";
    case OrderStatus.Confirmed:
      return "#62A349";
    case OrderStatus.Processing:
      return "#BFEFCA";
    case OrderStatus.Completed:
      return "#B3D515";
    case OrderStatus.Refused:
      return "#FA758A";
    case OrderStatus.Failed:
      return "#F52D19";
    case OrderStatus.Refunded:
      return "#6AC4BD";
    case OrderStatus.Withdraw:
      return "#D38C24";
    case OrderStatus.Revoked:
      return "#FFD800";
    case OrderStatus.Rejected:
      return "#FF365A";
    case OrderStatus.Closed:
      return "#69AFE3";
    case OrderStatus.Transferred:
      return "#AEAEAE";
    default:
      return null;
  }
};

export const GetFontWeight = (fw) => {
  switch (fw) {
    case FontWeight.bold:
      return "fw-bold";
    case FontWeight.bolder:
      return "fw-bolder";
    case FontWeight.normal:
      return "fw-normal";
    case FontWeight.light:
      return "fw-light";
    case FontWeight.lighter:
      return "fw-lighter";
    case FontWeight.italic:
      return "fw-italic";
    default:
      return "fw-normal";
  }
};

export const GetButtonClass = (type) => {
  switch (type) {
    case ButtonType.Info:
      return "btn-info";
    case ButtonType.Primary:
      return "btn-primary";
    case ButtonType.Warning:
      return "btn-warning";
    case ButtonType.Secondary:
      return "btn-secondary";
    case ButtonType.Danger:
      return "btn-danger";
    case ButtonType.Success:
      return "btn-success";
    default:
      return "btn-primary";
  }
};

export const getFontSize = (params) => {
  let {
    textLength,
    defaultFontSize = 1.5,
    threshold = 6,
    maxSize = 2.5,
    minSize = 0.6,
    unitSize = 0.08,
    sizeType = "vw",
  } = params;

  let fontSize =
    textLength > threshold ? maxSize - textLength * unitSize : defaultFontSize;
  fontSize = fontSize < minSize ? minSize : fontSize;
  return `${fontSize}${sizeType}`;
};

export const FilterURLShorten = {
  Text: "t",
  Publishers: "p",
  Status: "s",
  Price: "pr",
  DateRange: "d",
  Platforms: "pf",
  OperatingSystems: "os",
  PartnerId: "pid",
  InvoicedStatus: "ids",
  InvoiceStatus: "is",
  partnerList: "pl",
  publisherNames: "pn",
  customDate: "cd",
  selectedDateItem: "sdi",
  minPrice: "mp",
  maxPrice: "mxp",
};

export const Countries_Regions = [
  {
    name: "Afghanistan",
    alpha2: "AF",
    alpha3: "AFG",
    countrycode: "004",
    iso31662: "ISO 3166-2:AF",
    region: "Asia",
    subregion: "Southern Asia",
    intermediateregion: "",
    regioncode: "142",
    subregioncode: "034",
    intermediateregioncode: "",
  },
  {
    name: "Åland Islands",
    alpha2: "AX",
    alpha3: "ALA",
    countrycode: "248",
    iso31662: "ISO 3166-2:AX",
    region: "Europe",
    subregion: "Northern Europe",
    intermediateregion: "",
    regioncode: "150",
    subregioncode: "154",
    intermediateregioncode: "",
  },
  {
    name: "Albania",
    alpha2: "AL",
    alpha3: "ALB",
    countrycode: "008",
    iso31662: "ISO 3166-2:AL",
    region: "Europe",
    subregion: "Southern Europe",
    intermediateregion: "",
    regioncode: "150",
    subregioncode: "039",
    intermediateregioncode: "",
  },
  {
    name: "Algeria",
    alpha2: "DZ",
    alpha3: "DZA",
    countrycode: "012",
    iso31662: "ISO 3166-2:DZ",
    region: "Africa",
    subregion: "Northern Africa",
    intermediateregion: "",
    regioncode: "002",
    subregioncode: "015",
    intermediateregioncode: "",
  },
  {
    name: "American Samoa",
    alpha2: "AS",
    alpha3: "ASM",
    countrycode: "016",
    iso31662: "ISO 3166-2:AS",
    region: "Oceania",
    subregion: "Polynesia",
    intermediateregion: "",
    regioncode: "009",
    subregioncode: "061",
    intermediateregioncode: "",
  },
  {
    name: "Andorra",
    alpha2: "AD",
    alpha3: "AND",
    countrycode: "020",
    iso31662: "ISO 3166-2:AD",
    region: "Europe",
    subregion: "Southern Europe",
    intermediateregion: "",
    regioncode: "150",
    subregioncode: "039",
    intermediateregioncode: "",
  },
  {
    name: "Angola",
    alpha2: "AO",
    alpha3: "AGO",
    countrycode: "024",
    iso31662: "ISO 3166-2:AO",
    region: "Africa",
    subregion: "Sub-Saharan Africa",
    intermediateregion: "Middle Africa",
    regioncode: "002",
    subregioncode: "202",
    intermediateregioncode: "017",
  },
  {
    name: "Anguilla",
    alpha2: "AI",
    alpha3: "AIA",
    countrycode: "660",
    iso31662: "ISO 3166-2:AI",
    region: "Americas",
    subregion: "Latin America and the Caribbean",
    intermediateregion: "Caribbean",
    regioncode: "019",
    subregioncode: "419",
    intermediateregioncode: "029",
  },
  {
    name: "Antarctica",
    alpha2: "AQ",
    alpha3: "ATA",
    countrycode: "010",
    iso31662: "ISO 3166-2:AQ",
    region: "",
    subregion: "",
    intermediateregion: "",
    regioncode: "",
    subregioncode: "",
    intermediateregioncode: "",
  },
  {
    name: "Antigua and Barbuda",
    alpha2: "AG",
    alpha3: "ATG",
    countrycode: "028",
    iso31662: "ISO 3166-2:AG",
    region: "Americas",
    subregion: "Latin America and the Caribbean",
    intermediateregion: "Caribbean",
    regioncode: "019",
    subregioncode: "419",
    intermediateregioncode: "029",
  },
  {
    name: "Argentina",
    alpha2: "AR",
    alpha3: "ARG",
    countrycode: "032",
    iso31662: "ISO 3166-2:AR",
    region: "Americas",
    subregion: "Latin America and the Caribbean",
    intermediateregion: "South America",
    regioncode: "019",
    subregioncode: "419",
    intermediateregioncode: "005",
  },
  {
    name: "Armenia",
    alpha2: "AM",
    alpha3: "ARM",
    countrycode: "051",
    iso31662: "ISO 3166-2:AM",
    region: "Asia",
    subregion: "Western Asia",
    intermediateregion: "",
    regioncode: "142",
    subregioncode: "145",
    intermediateregioncode: "",
  },
  {
    name: "Aruba",
    alpha2: "AW",
    alpha3: "ABW",
    countrycode: "533",
    iso31662: "ISO 3166-2:AW",
    region: "Americas",
    subregion: "Latin America and the Caribbean",
    intermediateregion: "Caribbean",
    regioncode: "019",
    subregioncode: "419",
    intermediateregioncode: "029",
  },
  {
    name: "Australia",
    alpha2: "AU",
    alpha3: "AUS",
    countrycode: "036",
    iso31662: "ISO 3166-2:AU",
    region: "Oceania",
    subregion: "Australia and New Zealand",
    intermediateregion: "",
    regioncode: "009",
    subregioncode: "053",
    intermediateregioncode: "",
  },
  {
    name: "Austria",
    alpha2: "AT",
    alpha3: "AUT",
    countrycode: "040",
    iso31662: "ISO 3166-2:AT",
    region: "Europe",
    subregion: "Western Europe",
    intermediateregion: "",
    regioncode: "150",
    subregioncode: "155",
    intermediateregioncode: "",
  },
  {
    name: "Azerbaijan",
    alpha2: "AZ",
    alpha3: "AZE",
    countrycode: "031",
    iso31662: "ISO 3166-2:AZ",
    region: "Asia",
    subregion: "Western Asia",
    intermediateregion: "",
    regioncode: "142",
    subregioncode: "145",
    intermediateregioncode: "",
  },
  {
    name: "Bahamas",
    alpha2: "BS",
    alpha3: "BHS",
    countrycode: "044",
    iso31662: "ISO 3166-2:BS",
    region: "Americas",
    subregion: "Latin America and the Caribbean",
    intermediateregion: "Caribbean",
    regioncode: "019",
    subregioncode: "419",
    intermediateregioncode: "029",
  },
  {
    name: "Bahrain",
    alpha2: "BH",
    alpha3: "BHR",
    countrycode: "048",
    iso31662: "ISO 3166-2:BH",
    region: "Asia",
    subregion: "Western Asia",
    intermediateregion: "",
    regioncode: "142",
    subregioncode: "145",
    intermediateregioncode: "",
  },
  {
    name: "Bangladesh",
    alpha2: "BD",
    alpha3: "BGD",
    countrycode: "050",
    iso31662: "ISO 3166-2:BD",
    region: "Asia",
    subregion: "Southern Asia",
    intermediateregion: "",
    regioncode: "142",
    subregioncode: "034",
    intermediateregioncode: "",
  },
  {
    name: "Barbados",
    alpha2: "BB",
    alpha3: "BRB",
    countrycode: "052",
    iso31662: "ISO 3166-2:BB",
    region: "Americas",
    subregion: "Latin America and the Caribbean",
    intermediateregion: "Caribbean",
    regioncode: "019",
    subregioncode: "419",
    intermediateregioncode: "029",
  },
  {
    name: "Belarus",
    alpha2: "BY",
    alpha3: "BLR",
    countrycode: "112",
    iso31662: "ISO 3166-2:BY",
    region: "Europe",
    subregion: "Eastern Europe",
    intermediateregion: "",
    regioncode: "150",
    subregioncode: "151",
    intermediateregioncode: "",
  },
  {
    name: "Belgium",
    alpha2: "BE",
    alpha3: "BEL",
    countrycode: "056",
    iso31662: "ISO 3166-2:BE",
    region: "Europe",
    subregion: "Western Europe",
    intermediateregion: "",
    regioncode: "150",
    subregioncode: "155",
    intermediateregioncode: "",
  },
  {
    name: "Belize",
    alpha2: "BZ",
    alpha3: "BLZ",
    countrycode: "084",
    iso31662: "ISO 3166-2:BZ",
    region: "Americas",
    subregion: "Latin America and the Caribbean",
    intermediateregion: "Central America",
    regioncode: "019",
    subregioncode: "419",
    intermediateregioncode: "013",
  },
  {
    name: "Benin",
    alpha2: "BJ",
    alpha3: "BEN",
    countrycode: "204",
    iso31662: "ISO 3166-2:BJ",
    region: "Africa",
    subregion: "Sub-Saharan Africa",
    intermediateregion: "Western Africa",
    regioncode: "002",
    subregioncode: "202",
    intermediateregioncode: "011",
  },
  {
    name: "Bermuda",
    alpha2: "BM",
    alpha3: "BMU",
    countrycode: "060",
    iso31662: "ISO 3166-2:BM",
    region: "Americas",
    subregion: "Northern America",
    intermediateregion: "",
    regioncode: "019",
    subregioncode: "021",
    intermediateregioncode: "",
  },
  {
    name: "Bhutan",
    alpha2: "BT",
    alpha3: "BTN",
    countrycode: "064",
    iso31662: "ISO 3166-2:BT",
    region: "Asia",
    subregion: "Southern Asia",
    intermediateregion: "",
    regioncode: "142",
    subregioncode: "034",
    intermediateregioncode: "",
  },
  {
    name: "Bolivia (Plurinational State of)",
    alpha2: "BO",
    alpha3: "BOL",
    countrycode: "068",
    iso31662: "ISO 3166-2:BO",
    region: "Americas",
    subregion: "Latin America and the Caribbean",
    intermediateregion: "South America",
    regioncode: "019",
    subregioncode: "419",
    intermediateregioncode: "005",
  },
  {
    name: "Bonaire, Sint Eustatius and Saba",
    alpha2: "BQ",
    alpha3: "BES",
    countrycode: "535",
    iso31662: "ISO 3166-2:BQ",
    region: "Americas",
    subregion: "Latin America and the Caribbean",
    intermediateregion: "Caribbean",
    regioncode: "019",
    subregioncode: "419",
    intermediateregioncode: "029",
  },
  {
    name: "Bosnia and Herzegovina",
    alpha2: "BA",
    alpha3: "BIH",
    countrycode: "070",
    iso31662: "ISO 3166-2:BA",
    region: "Europe",
    subregion: "Southern Europe",
    intermediateregion: "",
    regioncode: "150",
    subregioncode: "039",
    intermediateregioncode: "",
  },
  {
    name: "Botswana",
    alpha2: "BW",
    alpha3: "BWA",
    countrycode: "072",
    iso31662: "ISO 3166-2:BW",
    region: "Africa",
    subregion: "Sub-Saharan Africa",
    intermediateregion: "Southern Africa",
    regioncode: "002",
    subregioncode: "202",
    intermediateregioncode: "018",
  },
  {
    name: "Bouvet Island",
    alpha2: "BV",
    alpha3: "BVT",
    countrycode: "074",
    iso31662: "ISO 3166-2:BV",
    region: "Americas",
    subregion: "Latin America and the Caribbean",
    intermediateregion: "South America",
    regioncode: "019",
    subregioncode: "419",
    intermediateregioncode: "005",
  },
  {
    name: "Brazil",
    alpha2: "BR",
    alpha3: "BRA",
    countrycode: "076",
    iso31662: "ISO 3166-2:BR",
    region: "Americas",
    subregion: "Latin America and the Caribbean",
    intermediateregion: "South America",
    regioncode: "019",
    subregioncode: "419",
    intermediateregioncode: "005",
  },
  {
    name: "British Indian Ocean Territory",
    alpha2: "IO",
    alpha3: "IOT",
    countrycode: "086",
    iso31662: "ISO 3166-2:IO",
    region: "Africa",
    subregion: "Sub-Saharan Africa",
    intermediateregion: "Eastern Africa",
    regioncode: "002",
    subregioncode: "202",
    intermediateregioncode: "014",
  },
  {
    name: "Brunei Darussalam",
    alpha2: "BN",
    alpha3: "BRN",
    countrycode: "096",
    iso31662: "ISO 3166-2:BN",
    region: "Asia",
    subregion: "South-eastern Asia",
    intermediateregion: "",
    regioncode: "142",
    subregioncode: "035",
    intermediateregioncode: "",
  },
  {
    name: "Bulgaria",
    alpha2: "BG",
    alpha3: "BGR",
    countrycode: "100",
    iso31662: "ISO 3166-2:BG",
    region: "Europe",
    subregion: "Eastern Europe",
    intermediateregion: "",
    regioncode: "150",
    subregioncode: "151",
    intermediateregioncode: "",
  },
  {
    name: "Burkina Faso",
    alpha2: "BF",
    alpha3: "BFA",
    countrycode: "854",
    iso31662: "ISO 3166-2:BF",
    region: "Africa",
    subregion: "Sub-Saharan Africa",
    intermediateregion: "Western Africa",
    regioncode: "002",
    subregioncode: "202",
    intermediateregioncode: "011",
  },
  {
    name: "Burundi",
    alpha2: "BI",
    alpha3: "BDI",
    countrycode: "108",
    iso31662: "ISO 3166-2:BI",
    region: "Africa",
    subregion: "Sub-Saharan Africa",
    intermediateregion: "Eastern Africa",
    regioncode: "002",
    subregioncode: "202",
    intermediateregioncode: "014",
  },
  {
    name: "Cabo Verde",
    alpha2: "CV",
    alpha3: "CPV",
    countrycode: "132",
    iso31662: "ISO 3166-2:CV",
    region: "Africa",
    subregion: "Sub-Saharan Africa",
    intermediateregion: "Western Africa",
    regioncode: "002",
    subregioncode: "202",
    intermediateregioncode: "011",
  },
  {
    name: "Cambodia",
    alpha2: "KH",
    alpha3: "KHM",
    countrycode: "116",
    iso31662: "ISO 3166-2:KH",
    region: "Asia",
    subregion: "South-eastern Asia",
    intermediateregion: "",
    regioncode: "142",
    subregioncode: "035",
    intermediateregioncode: "",
  },
  {
    name: "Cameroon",
    alpha2: "CM",
    alpha3: "CMR",
    countrycode: "120",
    iso31662: "ISO 3166-2:CM",
    region: "Africa",
    subregion: "Sub-Saharan Africa",
    intermediateregion: "Middle Africa",
    regioncode: "002",
    subregioncode: "202",
    intermediateregioncode: "017",
  },
  {
    name: "Canada",
    alpha2: "CA",
    alpha3: "CAN",
    countrycode: "124",
    iso31662: "ISO 3166-2:CA",
    region: "Americas",
    subregion: "Northern America",
    intermediateregion: "",
    regioncode: "019",
    subregioncode: "021",
    intermediateregioncode: "",
  },
  {
    name: "Cayman Islands",
    alpha2: "KY",
    alpha3: "CYM",
    countrycode: "136",
    iso31662: "ISO 3166-2:KY",
    region: "Americas",
    subregion: "Latin America and the Caribbean",
    intermediateregion: "Caribbean",
    regioncode: "019",
    subregioncode: "419",
    intermediateregioncode: "029",
  },
  {
    name: "Central African Republic",
    alpha2: "CF",
    alpha3: "CAF",
    countrycode: "140",
    iso31662: "ISO 3166-2:CF",
    region: "Africa",
    subregion: "Sub-Saharan Africa",
    intermediateregion: "Middle Africa",
    regioncode: "002",
    subregioncode: "202",
    intermediateregioncode: "017",
  },
  {
    name: "Chad",
    alpha2: "TD",
    alpha3: "TCD",
    countrycode: "148",
    iso31662: "ISO 3166-2:TD",
    region: "Africa",
    subregion: "Sub-Saharan Africa",
    intermediateregion: "Middle Africa",
    regioncode: "002",
    subregioncode: "202",
    intermediateregioncode: "017",
  },
  {
    name: "Chile",
    alpha2: "CL",
    alpha3: "CHL",
    countrycode: "152",
    iso31662: "ISO 3166-2:CL",
    region: "Americas",
    subregion: "Latin America and the Caribbean",
    intermediateregion: "South America",
    regioncode: "019",
    subregioncode: "419",
    intermediateregioncode: "005",
  },
  {
    name: "China",
    alpha2: "CN",
    alpha3: "CHN",
    countrycode: "156",
    iso31662: "ISO 3166-2:CN",
    region: "Asia",
    subregion: "Eastern Asia",
    intermediateregion: "",
    regioncode: "142",
    subregioncode: "030",
    intermediateregioncode: "",
  },
  {
    name: "Christmas Island",
    alpha2: "CX",
    alpha3: "CXR",
    countrycode: "162",
    iso31662: "ISO 3166-2:CX",
    region: "Oceania",
    subregion: "Australia and New Zealand",
    intermediateregion: "",
    regioncode: "009",
    subregioncode: "053",
    intermediateregioncode: "",
  },
  {
    name: "Cocos (Keeling) Islands",
    alpha2: "CC",
    alpha3: "CCK",
    countrycode: "166",
    iso31662: "ISO 3166-2:CC",
    region: "Oceania",
    subregion: "Australia and New Zealand",
    intermediateregion: "",
    regioncode: "009",
    subregioncode: "053",
    intermediateregioncode: "",
  },
  {
    name: "Colombia",
    alpha2: "CO",
    alpha3: "COL",
    countrycode: "170",
    iso31662: "ISO 3166-2:CO",
    region: "Americas",
    subregion: "Latin America and the Caribbean",
    intermediateregion: "South America",
    regioncode: "019",
    subregioncode: "419",
    intermediateregioncode: "005",
  },
  {
    name: "Comoros",
    alpha2: "KM",
    alpha3: "COM",
    countrycode: "174",
    iso31662: "ISO 3166-2:KM",
    region: "Africa",
    subregion: "Sub-Saharan Africa",
    intermediateregion: "Eastern Africa",
    regioncode: "002",
    subregioncode: "202",
    intermediateregioncode: "014",
  },
  {
    name: "Congo",
    alpha2: "CG",
    alpha3: "COG",
    countrycode: "178",
    iso31662: "ISO 3166-2:CG",
    region: "Africa",
    subregion: "Sub-Saharan Africa",
    intermediateregion: "Middle Africa",
    regioncode: "002",
    subregioncode: "202",
    intermediateregioncode: "017",
  },
  {
    name: "Congo, Democratic Republic of the",
    alpha2: "CD",
    alpha3: "COD",
    countrycode: "180",
    iso31662: "ISO 3166-2:CD",
    region: "Africa",
    subregion: "Sub-Saharan Africa",
    intermediateregion: "Middle Africa",
    regioncode: "002",
    subregioncode: "202",
    intermediateregioncode: "017",
  },
  {
    name: "Cook Islands",
    alpha2: "CK",
    alpha3: "COK",
    countrycode: "184",
    iso31662: "ISO 3166-2:CK",
    region: "Oceania",
    subregion: "Polynesia",
    intermediateregion: "",
    regioncode: "009",
    subregioncode: "061",
    intermediateregioncode: "",
  },
  {
    name: "Costa Rica",
    alpha2: "CR",
    alpha3: "CRI",
    countrycode: "188",
    iso31662: "ISO 3166-2:CR",
    region: "Americas",
    subregion: "Latin America and the Caribbean",
    intermediateregion: "Central America",
    regioncode: "019",
    subregioncode: "419",
    intermediateregioncode: "013",
  },
  {
    name: "Côte d'Ivoire",
    alpha2: "CI",
    alpha3: "CIV",
    countrycode: "384",
    iso31662: "ISO 3166-2:CI",
    region: "Africa",
    subregion: "Sub-Saharan Africa",
    intermediateregion: "Western Africa",
    regioncode: "002",
    subregioncode: "202",
    intermediateregioncode: "011",
  },
  {
    name: "Croatia",
    alpha2: "HR",
    alpha3: "HRV",
    countrycode: "191",
    iso31662: "ISO 3166-2:HR",
    region: "Europe",
    subregion: "Southern Europe",
    intermediateregion: "",
    regioncode: "150",
    subregioncode: "039",
    intermediateregioncode: "",
  },
  {
    name: "Cuba",
    alpha2: "CU",
    alpha3: "CUB",
    countrycode: "192",
    iso31662: "ISO 3166-2:CU",
    region: "Americas",
    subregion: "Latin America and the Caribbean",
    intermediateregion: "Caribbean",
    regioncode: "019",
    subregioncode: "419",
    intermediateregioncode: "029",
  },
  {
    name: "Curaçao",
    alpha2: "CW",
    alpha3: "CUW",
    countrycode: "531",
    iso31662: "ISO 3166-2:CW",
    region: "Americas",
    subregion: "Latin America and the Caribbean",
    intermediateregion: "Caribbean",
    regioncode: "019",
    subregioncode: "419",
    intermediateregioncode: "029",
  },
  {
    name: "Cyprus",
    alpha2: "CY",
    alpha3: "CYP",
    countrycode: "196",
    iso31662: "ISO 3166-2:CY",
    region: "Asia",
    subregion: "Western Asia",
    intermediateregion: "",
    regioncode: "142",
    subregioncode: "145",
    intermediateregioncode: "",
  },
  {
    name: "Czechia",
    alpha2: "CZ",
    alpha3: "CZE",
    countrycode: "203",
    iso31662: "ISO 3166-2:CZ",
    region: "Europe",
    subregion: "Eastern Europe",
    intermediateregion: "",
    regioncode: "150",
    subregioncode: "151",
    intermediateregioncode: "",
  },
  {
    name: "Denmark",
    alpha2: "DK",
    alpha3: "DNK",
    countrycode: "208",
    iso31662: "ISO 3166-2:DK",
    region: "Europe",
    subregion: "Northern Europe",
    intermediateregion: "",
    regioncode: "150",
    subregioncode: "154",
    intermediateregioncode: "",
  },
  {
    name: "Djibouti",
    alpha2: "DJ",
    alpha3: "DJI",
    countrycode: "262",
    iso31662: "ISO 3166-2:DJ",
    region: "Africa",
    subregion: "Sub-Saharan Africa",
    intermediateregion: "Eastern Africa",
    regioncode: "002",
    subregioncode: "202",
    intermediateregioncode: "014",
  },
  {
    name: "Dominica",
    alpha2: "DM",
    alpha3: "DMA",
    countrycode: "212",
    iso31662: "ISO 3166-2:DM",
    region: "Americas",
    subregion: "Latin America and the Caribbean",
    intermediateregion: "Caribbean",
    regioncode: "019",
    subregioncode: "419",
    intermediateregioncode: "029",
  },
  {
    name: "Dominican Republic",
    alpha2: "DO",
    alpha3: "DOM",
    countrycode: "214",
    iso31662: "ISO 3166-2:DO",
    region: "Americas",
    subregion: "Latin America and the Caribbean",
    intermediateregion: "Caribbean",
    regioncode: "019",
    subregioncode: "419",
    intermediateregioncode: "029",
  },
  {
    name: "Ecuador",
    alpha2: "EC",
    alpha3: "ECU",
    countrycode: "218",
    iso31662: "ISO 3166-2:EC",
    region: "Americas",
    subregion: "Latin America and the Caribbean",
    intermediateregion: "South America",
    regioncode: "019",
    subregioncode: "419",
    intermediateregioncode: "005",
  },
  {
    name: "Egypt",
    alpha2: "EG",
    alpha3: "EGY",
    countrycode: "818",
    iso31662: "ISO 3166-2:EG",
    region: "Africa",
    subregion: "Northern Africa",
    intermediateregion: "",
    regioncode: "002",
    subregioncode: "015",
    intermediateregioncode: "",
  },
  {
    name: "El Salvador",
    alpha2: "SV",
    alpha3: "SLV",
    countrycode: "222",
    iso31662: "ISO 3166-2:SV",
    region: "Americas",
    subregion: "Latin America and the Caribbean",
    intermediateregion: "Central America",
    regioncode: "019",
    subregioncode: "419",
    intermediateregioncode: "013",
  },
  {
    name: "Equatorial Guinea",
    alpha2: "GQ",
    alpha3: "GNQ",
    countrycode: "226",
    iso31662: "ISO 3166-2:GQ",
    region: "Africa",
    subregion: "Sub-Saharan Africa",
    intermediateregion: "Middle Africa",
    regioncode: "002",
    subregioncode: "202",
    intermediateregioncode: "017",
  },
  {
    name: "Eritrea",
    alpha2: "ER",
    alpha3: "ERI",
    countrycode: "232",
    iso31662: "ISO 3166-2:ER",
    region: "Africa",
    subregion: "Sub-Saharan Africa",
    intermediateregion: "Eastern Africa",
    regioncode: "002",
    subregioncode: "202",
    intermediateregioncode: "014",
  },
  {
    name: "Estonia",
    alpha2: "EE",
    alpha3: "EST",
    countrycode: "233",
    iso31662: "ISO 3166-2:EE",
    region: "Europe",
    subregion: "Northern Europe",
    intermediateregion: "",
    regioncode: "150",
    subregioncode: "154",
    intermediateregioncode: "",
  },
  {
    name: "Eswatini",
    alpha2: "SZ",
    alpha3: "SWZ",
    countrycode: "748",
    iso31662: "ISO 3166-2:SZ",
    region: "Africa",
    subregion: "Sub-Saharan Africa",
    intermediateregion: "Southern Africa",
    regioncode: "002",
    subregioncode: "202",
    intermediateregioncode: "018",
  },
  {
    name: "Ethiopia",
    alpha2: "ET",
    alpha3: "ETH",
    countrycode: "231",
    iso31662: "ISO 3166-2:ET",
    region: "Africa",
    subregion: "Sub-Saharan Africa",
    intermediateregion: "Eastern Africa",
    regioncode: "002",
    subregioncode: "202",
    intermediateregioncode: "014",
  },
  {
    name: "Falkland Islands (Malvinas)",
    alpha2: "FK",
    alpha3: "FLK",
    countrycode: "238",
    iso31662: "ISO 3166-2:FK",
    region: "Americas",
    subregion: "Latin America and the Caribbean",
    intermediateregion: "South America",
    regioncode: "019",
    subregioncode: "419",
    intermediateregioncode: "005",
  },
  {
    name: "Faroe Islands",
    alpha2: "FO",
    alpha3: "FRO",
    countrycode: "234",
    iso31662: "ISO 3166-2:FO",
    region: "Europe",
    subregion: "Northern Europe",
    intermediateregion: "",
    regioncode: "150",
    subregioncode: "154",
    intermediateregioncode: "",
  },
  {
    name: "Fiji",
    alpha2: "FJ",
    alpha3: "FJI",
    countrycode: "242",
    iso31662: "ISO 3166-2:FJ",
    region: "Oceania",
    subregion: "Melanesia",
    intermediateregion: "",
    regioncode: "009",
    subregioncode: "054",
    intermediateregioncode: "",
  },
  {
    name: "Finland",
    alpha2: "FI",
    alpha3: "FIN",
    countrycode: "246",
    iso31662: "ISO 3166-2:FI",
    region: "Europe",
    subregion: "Northern Europe",
    intermediateregion: "",
    regioncode: "150",
    subregioncode: "154",
    intermediateregioncode: "",
  },
  {
    name: "France",
    alpha2: "FR",
    alpha3: "FRA",
    countrycode: "250",
    iso31662: "ISO 3166-2:FR",
    region: "Europe",
    subregion: "Western Europe",
    intermediateregion: "",
    regioncode: "150",
    subregioncode: "155",
    intermediateregioncode: "",
  },
  {
    name: "French Guiana",
    alpha2: "GF",
    alpha3: "GUF",
    countrycode: "254",
    iso31662: "ISO 3166-2:GF",
    region: "Americas",
    subregion: "Latin America and the Caribbean",
    intermediateregion: "South America",
    regioncode: "019",
    subregioncode: "419",
    intermediateregioncode: "005",
  },
  {
    name: "French Polynesia",
    alpha2: "PF",
    alpha3: "PYF",
    countrycode: "258",
    iso31662: "ISO 3166-2:PF",
    region: "Oceania",
    subregion: "Polynesia",
    intermediateregion: "",
    regioncode: "009",
    subregioncode: "061",
    intermediateregioncode: "",
  },
  {
    name: "French Southern Territories",
    alpha2: "TF",
    alpha3: "ATF",
    countrycode: "260",
    iso31662: "ISO 3166-2:TF",
    region: "Africa",
    subregion: "Sub-Saharan Africa",
    intermediateregion: "Eastern Africa",
    regioncode: "002",
    subregioncode: "202",
    intermediateregioncode: "014",
  },
  {
    name: "Gabon",
    alpha2: "GA",
    alpha3: "GAB",
    countrycode: "266",
    iso31662: "ISO 3166-2:GA",
    region: "Africa",
    subregion: "Sub-Saharan Africa",
    intermediateregion: "Middle Africa",
    regioncode: "002",
    subregioncode: "202",
    intermediateregioncode: "017",
  },
  {
    name: "Gambia",
    alpha2: "GM",
    alpha3: "GMB",
    countrycode: "270",
    iso31662: "ISO 3166-2:GM",
    region: "Africa",
    subregion: "Sub-Saharan Africa",
    intermediateregion: "Western Africa",
    regioncode: "002",
    subregioncode: "202",
    intermediateregioncode: "011",
  },
  {
    name: "Georgia",
    alpha2: "GE",
    alpha3: "GEO",
    countrycode: "268",
    iso31662: "ISO 3166-2:GE",
    region: "Asia",
    subregion: "Western Asia",
    intermediateregion: "",
    regioncode: "142",
    subregioncode: "145",
    intermediateregioncode: "",
  },
  {
    name: "Germany",
    alpha2: "DE",
    alpha3: "DEU",
    countrycode: "276",
    iso31662: "ISO 3166-2:DE",
    region: "Europe",
    subregion: "Western Europe",
    intermediateregion: "",
    regioncode: "150",
    subregioncode: "155",
    intermediateregioncode: "",
  },
  {
    name: "Ghana",
    alpha2: "GH",
    alpha3: "GHA",
    countrycode: "288",
    iso31662: "ISO 3166-2:GH",
    region: "Africa",
    subregion: "Sub-Saharan Africa",
    intermediateregion: "Western Africa",
    regioncode: "002",
    subregioncode: "202",
    intermediateregioncode: "011",
  },
  {
    name: "Gibraltar",
    alpha2: "GI",
    alpha3: "GIB",
    countrycode: "292",
    iso31662: "ISO 3166-2:GI",
    region: "Europe",
    subregion: "Southern Europe",
    intermediateregion: "",
    regioncode: "150",
    subregioncode: "039",
    intermediateregioncode: "",
  },
  {
    name: "Greece",
    alpha2: "GR",
    alpha3: "GRC",
    countrycode: "300",
    iso31662: "ISO 3166-2:GR",
    region: "Europe",
    subregion: "Southern Europe",
    intermediateregion: "",
    regioncode: "150",
    subregioncode: "039",
    intermediateregioncode: "",
  },
  {
    name: "Greenland",
    alpha2: "GL",
    alpha3: "GRL",
    countrycode: "304",
    iso31662: "ISO 3166-2:GL",
    region: "Americas",
    subregion: "Northern America",
    intermediateregion: "",
    regioncode: "019",
    subregioncode: "021",
    intermediateregioncode: "",
  },
  {
    name: "Grenada",
    alpha2: "GD",
    alpha3: "GRD",
    countrycode: "308",
    iso31662: "ISO 3166-2:GD",
    region: "Americas",
    subregion: "Latin America and the Caribbean",
    intermediateregion: "Caribbean",
    regioncode: "019",
    subregioncode: "419",
    intermediateregioncode: "029",
  },
  {
    name: "Guadeloupe",
    alpha2: "GP",
    alpha3: "GLP",
    countrycode: "312",
    iso31662: "ISO 3166-2:GP",
    region: "Americas",
    subregion: "Latin America and the Caribbean",
    intermediateregion: "Caribbean",
    regioncode: "019",
    subregioncode: "419",
    intermediateregioncode: "029",
  },
  {
    name: "Guam",
    alpha2: "GU",
    alpha3: "GUM",
    countrycode: "316",
    iso31662: "ISO 3166-2:GU",
    region: "Oceania",
    subregion: "Micronesia",
    intermediateregion: "",
    regioncode: "009",
    subregioncode: "057",
    intermediateregioncode: "",
  },
  {
    name: "Guatemala",
    alpha2: "GT",
    alpha3: "GTM",
    countrycode: "320",
    iso31662: "ISO 3166-2:GT",
    region: "Americas",
    subregion: "Latin America and the Caribbean",
    intermediateregion: "Central America",
    regioncode: "019",
    subregioncode: "419",
    intermediateregioncode: "013",
  },
  {
    name: "Guernsey",
    alpha2: "GG",
    alpha3: "GGY",
    countrycode: "831",
    iso31662: "ISO 3166-2:GG",
    region: "Europe",
    subregion: "Northern Europe",
    intermediateregion: "Channel Islands",
    regioncode: "150",
    subregioncode: "154",
    intermediateregioncode: "830",
  },
  {
    name: "Guinea",
    alpha2: "GN",
    alpha3: "GIN",
    countrycode: "324",
    iso31662: "ISO 3166-2:GN",
    region: "Africa",
    subregion: "Sub-Saharan Africa",
    intermediateregion: "Western Africa",
    regioncode: "002",
    subregioncode: "202",
    intermediateregioncode: "011",
  },
  {
    name: "Guinea-Bissau",
    alpha2: "GW",
    alpha3: "GNB",
    countrycode: "624",
    iso31662: "ISO 3166-2:GW",
    region: "Africa",
    subregion: "Sub-Saharan Africa",
    intermediateregion: "Western Africa",
    regioncode: "002",
    subregioncode: "202",
    intermediateregioncode: "011",
  },
  {
    name: "Guyana",
    alpha2: "GY",
    alpha3: "GUY",
    countrycode: "328",
    iso31662: "ISO 3166-2:GY",
    region: "Americas",
    subregion: "Latin America and the Caribbean",
    intermediateregion: "South America",
    regioncode: "019",
    subregioncode: "419",
    intermediateregioncode: "005",
  },
  {
    name: "Haiti",
    alpha2: "HT",
    alpha3: "HTI",
    countrycode: "332",
    iso31662: "ISO 3166-2:HT",
    region: "Americas",
    subregion: "Latin America and the Caribbean",
    intermediateregion: "Caribbean",
    regioncode: "019",
    subregioncode: "419",
    intermediateregioncode: "029",
  },
  {
    name: "Heard Island and McDonald Islands",
    alpha2: "HM",
    alpha3: "HMD",
    countrycode: "334",
    iso31662: "ISO 3166-2:HM",
    region: "Oceania",
    subregion: "Australia and New Zealand",
    intermediateregion: "",
    regioncode: "009",
    subregioncode: "053",
    intermediateregioncode: "",
  },
  {
    name: "Holy See",
    alpha2: "VA",
    alpha3: "VAT",
    countrycode: "336",
    iso31662: "ISO 3166-2:VA",
    region: "Europe",
    subregion: "Southern Europe",
    intermediateregion: "",
    regioncode: "150",
    subregioncode: "039",
    intermediateregioncode: "",
  },
  {
    name: "Honduras",
    alpha2: "HN",
    alpha3: "HND",
    countrycode: "340",
    iso31662: "ISO 3166-2:HN",
    region: "Americas",
    subregion: "Latin America and the Caribbean",
    intermediateregion: "Central America",
    regioncode: "019",
    subregioncode: "419",
    intermediateregioncode: "013",
  },
  {
    name: "Hong Kong",
    alpha2: "HK",
    alpha3: "HKG",
    countrycode: "344",
    iso31662: "ISO 3166-2:HK",
    region: "Asia",
    subregion: "Eastern Asia",
    intermediateregion: "",
    regioncode: "142",
    subregioncode: "030",
    intermediateregioncode: "",
  },
  {
    name: "Hungary",
    alpha2: "HU",
    alpha3: "HUN",
    countrycode: "348",
    iso31662: "ISO 3166-2:HU",
    region: "Europe",
    subregion: "Eastern Europe",
    intermediateregion: "",
    regioncode: "150",
    subregioncode: "151",
    intermediateregioncode: "",
  },
  {
    name: "Iceland",
    alpha2: "IS",
    alpha3: "ISL",
    countrycode: "352",
    iso31662: "ISO 3166-2:IS",
    region: "Europe",
    subregion: "Northern Europe",
    intermediateregion: "",
    regioncode: "150",
    subregioncode: "154",
    intermediateregioncode: "",
  },
  {
    name: "India",
    alpha2: "IN",
    alpha3: "IND",
    countrycode: "356",
    iso31662: "ISO 3166-2:IN",
    region: "Asia",
    subregion: "Southern Asia",
    intermediateregion: "",
    regioncode: "142",
    subregioncode: "034",
    intermediateregioncode: "",
  },
  {
    name: "Indonesia",
    alpha2: "ID",
    alpha3: "IDN",
    countrycode: "360",
    iso31662: "ISO 3166-2:ID",
    region: "Asia",
    subregion: "South-eastern Asia",
    intermediateregion: "",
    regioncode: "142",
    subregioncode: "035",
    intermediateregioncode: "",
  },
  {
    name: "Iran (Islamic Republic of)",
    alpha2: "IR",
    alpha3: "IRN",
    countrycode: "364",
    iso31662: "ISO 3166-2:IR",
    region: "Asia",
    subregion: "Southern Asia",
    intermediateregion: "",
    regioncode: "142",
    subregioncode: "034",
    intermediateregioncode: "",
  },
  {
    name: "Iraq",
    alpha2: "IQ",
    alpha3: "IRQ",
    countrycode: "368",
    iso31662: "ISO 3166-2:IQ",
    region: "Asia",
    subregion: "Western Asia",
    intermediateregion: "",
    regioncode: "142",
    subregioncode: "145",
    intermediateregioncode: "",
  },
  {
    name: "Ireland",
    alpha2: "IE",
    alpha3: "IRL",
    countrycode: "372",
    iso31662: "ISO 3166-2:IE",
    region: "Europe",
    subregion: "Northern Europe",
    intermediateregion: "",
    regioncode: "150",
    subregioncode: "154",
    intermediateregioncode: "",
  },
  {
    name: "Isle of Man",
    alpha2: "IM",
    alpha3: "IMN",
    countrycode: "833",
    iso31662: "ISO 3166-2:IM",
    region: "Europe",
    subregion: "Northern Europe",
    intermediateregion: "",
    regioncode: "150",
    subregioncode: "154",
    intermediateregioncode: "",
  },
  {
    name: "Israel",
    alpha2: "IL",
    alpha3: "ISR",
    countrycode: "376",
    iso31662: "ISO 3166-2:IL",
    region: "Asia",
    subregion: "Western Asia",
    intermediateregion: "",
    regioncode: "142",
    subregioncode: "145",
    intermediateregioncode: "",
  },
  {
    name: "Italy",
    alpha2: "IT",
    alpha3: "ITA",
    countrycode: "380",
    iso31662: "ISO 3166-2:IT",
    region: "Europe",
    subregion: "Southern Europe",
    intermediateregion: "",
    regioncode: "150",
    subregioncode: "039",
    intermediateregioncode: "",
  },
  {
    name: "Jamaica",
    alpha2: "JM",
    alpha3: "JAM",
    countrycode: "388",
    iso31662: "ISO 3166-2:JM",
    region: "Americas",
    subregion: "Latin America and the Caribbean",
    intermediateregion: "Caribbean",
    regioncode: "019",
    subregioncode: "419",
    intermediateregioncode: "029",
  },
  {
    name: "Japan",
    alpha2: "JP",
    alpha3: "JPN",
    countrycode: "392",
    iso31662: "ISO 3166-2:JP",
    region: "Asia",
    subregion: "Eastern Asia",
    intermediateregion: "",
    regioncode: "142",
    subregioncode: "030",
    intermediateregioncode: "",
  },
  {
    name: "Jersey",
    alpha2: "JE",
    alpha3: "JEY",
    countrycode: "832",
    iso31662: "ISO 3166-2:JE",
    region: "Europe",
    subregion: "Northern Europe",
    intermediateregion: "Channel Islands",
    regioncode: "150",
    subregioncode: "154",
    intermediateregioncode: "830",
  },
  {
    name: "Jordan",
    alpha2: "JO",
    alpha3: "JOR",
    countrycode: "400",
    iso31662: "ISO 3166-2:JO",
    region: "Asia",
    subregion: "Western Asia",
    intermediateregion: "",
    regioncode: "142",
    subregioncode: "145",
    intermediateregioncode: "",
  },
  {
    name: "Kazakhstan",
    alpha2: "KZ",
    alpha3: "KAZ",
    countrycode: "398",
    iso31662: "ISO 3166-2:KZ",
    region: "Asia",
    subregion: "Central Asia",
    intermediateregion: "",
    regioncode: "142",
    subregioncode: "143",
    intermediateregioncode: "",
  },
  {
    name: "Kenya",
    alpha2: "KE",
    alpha3: "KEN",
    countrycode: "404",
    iso31662: "ISO 3166-2:KE",
    region: "Africa",
    subregion: "Sub-Saharan Africa",
    intermediateregion: "Eastern Africa",
    regioncode: "002",
    subregioncode: "202",
    intermediateregioncode: "014",
  },
  {
    name: "Kiribati",
    alpha2: "KI",
    alpha3: "KIR",
    countrycode: "296",
    iso31662: "ISO 3166-2:KI",
    region: "Oceania",
    subregion: "Micronesia",
    intermediateregion: "",
    regioncode: "009",
    subregioncode: "057",
    intermediateregioncode: "",
  },
  {
    name: "Korea (Democratic People's Republic of)",
    alpha2: "KP",
    alpha3: "PRK",
    countrycode: "408",
    iso31662: "ISO 3166-2:KP",
    region: "Asia",
    subregion: "Eastern Asia",
    intermediateregion: "",
    regioncode: "142",
    subregioncode: "030",
    intermediateregioncode: "",
  },
  {
    name: "Korea, Republic of",
    alpha2: "KR",
    alpha3: "KOR",
    countrycode: "410",
    iso31662: "ISO 3166-2:KR",
    region: "Asia",
    subregion: "Eastern Asia",
    intermediateregion: "",
    regioncode: "142",
    subregioncode: "030",
    intermediateregioncode: "",
  },
  {
    name: "Kuwait",
    alpha2: "KW",
    alpha3: "KWT",
    countrycode: "414",
    iso31662: "ISO 3166-2:KW",
    region: "Asia",
    subregion: "Western Asia",
    intermediateregion: "",
    regioncode: "142",
    subregioncode: "145",
    intermediateregioncode: "",
  },
  {
    name: "Kyrgyzstan",
    alpha2: "KG",
    alpha3: "KGZ",
    countrycode: "417",
    iso31662: "ISO 3166-2:KG",
    region: "Asia",
    subregion: "Central Asia",
    intermediateregion: "",
    regioncode: "142",
    subregioncode: "143",
    intermediateregioncode: "",
  },
  {
    name: "Lao People's Democratic Republic",
    alpha2: "LA",
    alpha3: "LAO",
    countrycode: "418",
    iso31662: "ISO 3166-2:LA",
    region: "Asia",
    subregion: "South-eastern Asia",
    intermediateregion: "",
    regioncode: "142",
    subregioncode: "035",
    intermediateregioncode: "",
  },
  {
    name: "Latvia",
    alpha2: "LV",
    alpha3: "LVA",
    countrycode: "428",
    iso31662: "ISO 3166-2:LV",
    region: "Europe",
    subregion: "Northern Europe",
    intermediateregion: "",
    regioncode: "150",
    subregioncode: "154",
    intermediateregioncode: "",
  },
  {
    name: "Lebanon",
    alpha2: "LB",
    alpha3: "LBN",
    countrycode: "422",
    iso31662: "ISO 3166-2:LB",
    region: "Asia",
    subregion: "Western Asia",
    intermediateregion: "",
    regioncode: "142",
    subregioncode: "145",
    intermediateregioncode: "",
  },
  {
    name: "Lesotho",
    alpha2: "LS",
    alpha3: "LSO",
    countrycode: "426",
    iso31662: "ISO 3166-2:LS",
    region: "Africa",
    subregion: "Sub-Saharan Africa",
    intermediateregion: "Southern Africa",
    regioncode: "002",
    subregioncode: "202",
    intermediateregioncode: "018",
  },
  {
    name: "Liberia",
    alpha2: "LR",
    alpha3: "LBR",
    countrycode: "430",
    iso31662: "ISO 3166-2:LR",
    region: "Africa",
    subregion: "Sub-Saharan Africa",
    intermediateregion: "Western Africa",
    regioncode: "002",
    subregioncode: "202",
    intermediateregioncode: "011",
  },
  {
    name: "Libya",
    alpha2: "LY",
    alpha3: "LBY",
    countrycode: "434",
    iso31662: "ISO 3166-2:LY",
    region: "Africa",
    subregion: "Northern Africa",
    intermediateregion: "",
    regioncode: "002",
    subregioncode: "015",
    intermediateregioncode: "",
  },
  {
    name: "Liechtenstein",
    alpha2: "LI",
    alpha3: "LIE",
    countrycode: "438",
    iso31662: "ISO 3166-2:LI",
    region: "Europe",
    subregion: "Western Europe",
    intermediateregion: "",
    regioncode: "150",
    subregioncode: "155",
    intermediateregioncode: "",
  },
  {
    name: "Lithuania",
    alpha2: "LT",
    alpha3: "LTU",
    countrycode: "440",
    iso31662: "ISO 3166-2:LT",
    region: "Europe",
    subregion: "Northern Europe",
    intermediateregion: "",
    regioncode: "150",
    subregioncode: "154",
    intermediateregioncode: "",
  },
  {
    name: "Luxembourg",
    alpha2: "LU",
    alpha3: "LUX",
    countrycode: "442",
    iso31662: "ISO 3166-2:LU",
    region: "Europe",
    subregion: "Western Europe",
    intermediateregion: "",
    regioncode: "150",
    subregioncode: "155",
    intermediateregioncode: "",
  },
  {
    name: "Macao",
    alpha2: "MO",
    alpha3: "MAC",
    countrycode: "446",
    iso31662: "ISO 3166-2:MO",
    region: "Asia",
    subregion: "Eastern Asia",
    intermediateregion: "",
    regioncode: "142",
    subregioncode: "030",
    intermediateregioncode: "",
  },
  {
    name: "Madagascar",
    alpha2: "MG",
    alpha3: "MDG",
    countrycode: "450",
    iso31662: "ISO 3166-2:MG",
    region: "Africa",
    subregion: "Sub-Saharan Africa",
    intermediateregion: "Eastern Africa",
    regioncode: "002",
    subregioncode: "202",
    intermediateregioncode: "014",
  },
  {
    name: "Malawi",
    alpha2: "MW",
    alpha3: "MWI",
    countrycode: "454",
    iso31662: "ISO 3166-2:MW",
    region: "Africa",
    subregion: "Sub-Saharan Africa",
    intermediateregion: "Eastern Africa",
    regioncode: "002",
    subregioncode: "202",
    intermediateregioncode: "014",
  },
  {
    name: "Malaysia",
    alpha2: "MY",
    alpha3: "MYS",
    countrycode: "458",
    iso31662: "ISO 3166-2:MY",
    region: "Asia",
    subregion: "South-eastern Asia",
    intermediateregion: "",
    regioncode: "142",
    subregioncode: "035",
    intermediateregioncode: "",
  },
  {
    name: "Maldives",
    alpha2: "MV",
    alpha3: "MDV",
    countrycode: "462",
    iso31662: "ISO 3166-2:MV",
    region: "Asia",
    subregion: "Southern Asia",
    intermediateregion: "",
    regioncode: "142",
    subregioncode: "034",
    intermediateregioncode: "",
  },
  {
    name: "Mali",
    alpha2: "ML",
    alpha3: "MLI",
    countrycode: "466",
    iso31662: "ISO 3166-2:ML",
    region: "Africa",
    subregion: "Sub-Saharan Africa",
    intermediateregion: "Western Africa",
    regioncode: "002",
    subregioncode: "202",
    intermediateregioncode: "011",
  },
  {
    name: "Malta",
    alpha2: "MT",
    alpha3: "MLT",
    countrycode: "470",
    iso31662: "ISO 3166-2:MT",
    region: "Europe",
    subregion: "Southern Europe",
    intermediateregion: "",
    regioncode: "150",
    subregioncode: "039",
    intermediateregioncode: "",
  },
  {
    name: "Marshall Islands",
    alpha2: "MH",
    alpha3: "MHL",
    countrycode: "584",
    iso31662: "ISO 3166-2:MH",
    region: "Oceania",
    subregion: "Micronesia",
    intermediateregion: "",
    regioncode: "009",
    subregioncode: "057",
    intermediateregioncode: "",
  },
  {
    name: "Martinique",
    alpha2: "MQ",
    alpha3: "MTQ",
    countrycode: "474",
    iso31662: "ISO 3166-2:MQ",
    region: "Americas",
    subregion: "Latin America and the Caribbean",
    intermediateregion: "Caribbean",
    regioncode: "019",
    subregioncode: "419",
    intermediateregioncode: "029",
  },
  {
    name: "Mauritania",
    alpha2: "MR",
    alpha3: "MRT",
    countrycode: "478",
    iso31662: "ISO 3166-2:MR",
    region: "Africa",
    subregion: "Sub-Saharan Africa",
    intermediateregion: "Western Africa",
    regioncode: "002",
    subregioncode: "202",
    intermediateregioncode: "011",
  },
  {
    name: "Mauritius",
    alpha2: "MU",
    alpha3: "MUS",
    countrycode: "480",
    iso31662: "ISO 3166-2:MU",
    region: "Africa",
    subregion: "Sub-Saharan Africa",
    intermediateregion: "Eastern Africa",
    regioncode: "002",
    subregioncode: "202",
    intermediateregioncode: "014",
  },
  {
    name: "Mayotte",
    alpha2: "YT",
    alpha3: "MYT",
    countrycode: "175",
    iso31662: "ISO 3166-2:YT",
    region: "Africa",
    subregion: "Sub-Saharan Africa",
    intermediateregion: "Eastern Africa",
    regioncode: "002",
    subregioncode: "202",
    intermediateregioncode: "014",
  },
  {
    name: "Mexico",
    alpha2: "MX",
    alpha3: "MEX",
    countrycode: "484",
    iso31662: "ISO 3166-2:MX",
    region: "Americas",
    subregion: "Latin America and the Caribbean",
    intermediateregion: "Central America",
    regioncode: "019",
    subregioncode: "419",
    intermediateregioncode: "013",
  },
  {
    name: "Micronesia (Federated States of)",
    alpha2: "FM",
    alpha3: "FSM",
    countrycode: "583",
    iso31662: "ISO 3166-2:FM",
    region: "Oceania",
    subregion: "Micronesia",
    intermediateregion: "",
    regioncode: "009",
    subregioncode: "057",
    intermediateregioncode: "",
  },
  {
    name: "Moldova, Republic of",
    alpha2: "MD",
    alpha3: "MDA",
    countrycode: "498",
    iso31662: "ISO 3166-2:MD",
    region: "Europe",
    subregion: "Eastern Europe",
    intermediateregion: "",
    regioncode: "150",
    subregioncode: "151",
    intermediateregioncode: "",
  },
  {
    name: "Monaco",
    alpha2: "MC",
    alpha3: "MCO",
    countrycode: "492",
    iso31662: "ISO 3166-2:MC",
    region: "Europe",
    subregion: "Western Europe",
    intermediateregion: "",
    regioncode: "150",
    subregioncode: "155",
    intermediateregioncode: "",
  },
  {
    name: "Mongolia",
    alpha2: "MN",
    alpha3: "MNG",
    countrycode: "496",
    iso31662: "ISO 3166-2:MN",
    region: "Asia",
    subregion: "Eastern Asia",
    intermediateregion: "",
    regioncode: "142",
    subregioncode: "030",
    intermediateregioncode: "",
  },
  {
    name: "Montenegro",
    alpha2: "ME",
    alpha3: "MNE",
    countrycode: "499",
    iso31662: "ISO 3166-2:ME",
    region: "Europe",
    subregion: "Southern Europe",
    intermediateregion: "",
    regioncode: "150",
    subregioncode: "039",
    intermediateregioncode: "",
  },
  {
    name: "Montserrat",
    alpha2: "MS",
    alpha3: "MSR",
    countrycode: "500",
    iso31662: "ISO 3166-2:MS",
    region: "Americas",
    subregion: "Latin America and the Caribbean",
    intermediateregion: "Caribbean",
    regioncode: "019",
    subregioncode: "419",
    intermediateregioncode: "029",
  },
  {
    name: "Morocco",
    alpha2: "MA",
    alpha3: "MAR",
    countrycode: "504",
    iso31662: "ISO 3166-2:MA",
    region: "Africa",
    subregion: "Northern Africa",
    intermediateregion: "",
    regioncode: "002",
    subregioncode: "015",
    intermediateregioncode: "",
  },
  {
    name: "Mozambique",
    alpha2: "MZ",
    alpha3: "MOZ",
    countrycode: "508",
    iso31662: "ISO 3166-2:MZ",
    region: "Africa",
    subregion: "Sub-Saharan Africa",
    intermediateregion: "Eastern Africa",
    regioncode: "002",
    subregioncode: "202",
    intermediateregioncode: "014",
  },
  {
    name: "Myanmar",
    alpha2: "MM",
    alpha3: "MMR",
    countrycode: "104",
    iso31662: "ISO 3166-2:MM",
    region: "Asia",
    subregion: "South-eastern Asia",
    intermediateregion: "",
    regioncode: "142",
    subregioncode: "035",
    intermediateregioncode: "",
  },
  {
    name: "Namibia",
    alpha2: "NA",
    alpha3: "NAM",
    countrycode: "516",
    iso31662: "ISO 3166-2:NA",
    region: "Africa",
    subregion: "Sub-Saharan Africa",
    intermediateregion: "Southern Africa",
    regioncode: "002",
    subregioncode: "202",
    intermediateregioncode: "018",
  },
  {
    name: "Nauru",
    alpha2: "NR",
    alpha3: "NRU",
    countrycode: "520",
    iso31662: "ISO 3166-2:NR",
    region: "Oceania",
    subregion: "Micronesia",
    intermediateregion: "",
    regioncode: "009",
    subregioncode: "057",
    intermediateregioncode: "",
  },
  {
    name: "Nepal",
    alpha2: "NP",
    alpha3: "NPL",
    countrycode: "524",
    iso31662: "ISO 3166-2:NP",
    region: "Asia",
    subregion: "Southern Asia",
    intermediateregion: "",
    regioncode: "142",
    subregioncode: "034",
    intermediateregioncode: "",
  },
  {
    name: "Netherlands",
    alpha2: "NL",
    alpha3: "NLD",
    countrycode: "528",
    iso31662: "ISO 3166-2:NL",
    region: "Europe",
    subregion: "Western Europe",
    intermediateregion: "",
    regioncode: "150",
    subregioncode: "155",
    intermediateregioncode: "",
  },
  {
    name: "New Caledonia",
    alpha2: "NC",
    alpha3: "NCL",
    countrycode: "540",
    iso31662: "ISO 3166-2:NC",
    region: "Oceania",
    subregion: "Melanesia",
    intermediateregion: "",
    regioncode: "009",
    subregioncode: "054",
    intermediateregioncode: "",
  },
  {
    name: "New Zealand",
    alpha2: "NZ",
    alpha3: "NZL",
    countrycode: "554",
    iso31662: "ISO 3166-2:NZ",
    region: "Oceania",
    subregion: "Australia and New Zealand",
    intermediateregion: "",
    regioncode: "009",
    subregioncode: "053",
    intermediateregioncode: "",
  },
  {
    name: "Nicaragua",
    alpha2: "NI",
    alpha3: "NIC",
    countrycode: "558",
    iso31662: "ISO 3166-2:NI",
    region: "Americas",
    subregion: "Latin America and the Caribbean",
    intermediateregion: "Central America",
    regioncode: "019",
    subregioncode: "419",
    intermediateregioncode: "013",
  },
  {
    name: "Niger",
    alpha2: "NE",
    alpha3: "NER",
    countrycode: "562",
    iso31662: "ISO 3166-2:NE",
    region: "Africa",
    subregion: "Sub-Saharan Africa",
    intermediateregion: "Western Africa",
    regioncode: "002",
    subregioncode: "202",
    intermediateregioncode: "011",
  },
  {
    name: "Nigeria",
    alpha2: "NG",
    alpha3: "NGA",
    countrycode: "566",
    iso31662: "ISO 3166-2:NG",
    region: "Africa",
    subregion: "Sub-Saharan Africa",
    intermediateregion: "Western Africa",
    regioncode: "002",
    subregioncode: "202",
    intermediateregioncode: "011",
  },
  {
    name: "Niue",
    alpha2: "NU",
    alpha3: "NIU",
    countrycode: "570",
    iso31662: "ISO 3166-2:NU",
    region: "Oceania",
    subregion: "Polynesia",
    intermediateregion: "",
    regioncode: "009",
    subregioncode: "061",
    intermediateregioncode: "",
  },
  {
    name: "Norfolk Island",
    alpha2: "NF",
    alpha3: "NFK",
    countrycode: "574",
    iso31662: "ISO 3166-2:NF",
    region: "Oceania",
    subregion: "Australia and New Zealand",
    intermediateregion: "",
    regioncode: "009",
    subregioncode: "053",
    intermediateregioncode: "",
  },
  {
    name: "North Macedonia",
    alpha2: "MK",
    alpha3: "MKD",
    countrycode: "807",
    iso31662: "ISO 3166-2:MK",
    region: "Europe",
    subregion: "Southern Europe",
    intermediateregion: "",
    regioncode: "150",
    subregioncode: "039",
    intermediateregioncode: "",
  },
  {
    name: "Northern Mariana Islands",
    alpha2: "MP",
    alpha3: "MNP",
    countrycode: "580",
    iso31662: "ISO 3166-2:MP",
    region: "Oceania",
    subregion: "Micronesia",
    intermediateregion: "",
    regioncode: "009",
    subregioncode: "057",
    intermediateregioncode: "",
  },
  {
    name: "Norway",
    alpha2: "NO",
    alpha3: "NOR",
    countrycode: "578",
    iso31662: "ISO 3166-2:NO",
    region: "Europe",
    subregion: "Northern Europe",
    intermediateregion: "",
    regioncode: "150",
    subregioncode: "154",
    intermediateregioncode: "",
  },
  {
    name: "Oman",
    alpha2: "OM",
    alpha3: "OMN",
    countrycode: "512",
    iso31662: "ISO 3166-2:OM",
    region: "Asia",
    subregion: "Western Asia",
    intermediateregion: "",
    regioncode: "142",
    subregioncode: "145",
    intermediateregioncode: "",
  },
  {
    name: "Pakistan",
    alpha2: "PK",
    alpha3: "PAK",
    countrycode: "586",
    iso31662: "ISO 3166-2:PK",
    region: "Asia",
    subregion: "Southern Asia",
    intermediateregion: "",
    regioncode: "142",
    subregioncode: "034",
    intermediateregioncode: "",
  },
  {
    name: "Palau",
    alpha2: "PW",
    alpha3: "PLW",
    countrycode: "585",
    iso31662: "ISO 3166-2:PW",
    region: "Oceania",
    subregion: "Micronesia",
    intermediateregion: "",
    regioncode: "009",
    subregioncode: "057",
    intermediateregioncode: "",
  },
  {
    name: "Palestine, State of",
    alpha2: "PS",
    alpha3: "PSE",
    countrycode: "275",
    iso31662: "ISO 3166-2:PS",
    region: "Asia",
    subregion: "Western Asia",
    intermediateregion: "",
    regioncode: "142",
    subregioncode: "145",
    intermediateregioncode: "",
  },
  {
    name: "Panama",
    alpha2: "PA",
    alpha3: "PAN",
    countrycode: "591",
    iso31662: "ISO 3166-2:PA",
    region: "Americas",
    subregion: "Latin America and the Caribbean",
    intermediateregion: "Central America",
    regioncode: "019",
    subregioncode: "419",
    intermediateregioncode: "013",
  },
  {
    name: "Papua New Guinea",
    alpha2: "PG",
    alpha3: "PNG",
    countrycode: "598",
    iso31662: "ISO 3166-2:PG",
    region: "Oceania",
    subregion: "Melanesia",
    intermediateregion: "",
    regioncode: "009",
    subregioncode: "054",
    intermediateregioncode: "",
  },
  {
    name: "Paraguay",
    alpha2: "PY",
    alpha3: "PRY",
    countrycode: "600",
    iso31662: "ISO 3166-2:PY",
    region: "Americas",
    subregion: "Latin America and the Caribbean",
    intermediateregion: "South America",
    regioncode: "019",
    subregioncode: "419",
    intermediateregioncode: "005",
  },
  {
    name: "Peru",
    alpha2: "PE",
    alpha3: "PER",
    countrycode: "604",
    iso31662: "ISO 3166-2:PE",
    region: "Americas",
    subregion: "Latin America and the Caribbean",
    intermediateregion: "South America",
    regioncode: "019",
    subregioncode: "419",
    intermediateregioncode: "005",
  },
  {
    name: "Philippines",
    alpha2: "PH",
    alpha3: "PHL",
    countrycode: "608",
    iso31662: "ISO 3166-2:PH",
    region: "Asia",
    subregion: "South-eastern Asia",
    intermediateregion: "",
    regioncode: "142",
    subregioncode: "035",
    intermediateregioncode: "",
  },
  {
    name: "Pitcairn",
    alpha2: "PN",
    alpha3: "PCN",
    countrycode: "612",
    iso31662: "ISO 3166-2:PN",
    region: "Oceania",
    subregion: "Polynesia",
    intermediateregion: "",
    regioncode: "009",
    subregioncode: "061",
    intermediateregioncode: "",
  },
  {
    name: "Poland",
    alpha2: "PL",
    alpha3: "POL",
    countrycode: "616",
    iso31662: "ISO 3166-2:PL",
    region: "Europe",
    subregion: "Eastern Europe",
    intermediateregion: "",
    regioncode: "150",
    subregioncode: "151",
    intermediateregioncode: "",
  },
  {
    name: "Portugal",
    alpha2: "PT",
    alpha3: "PRT",
    countrycode: "620",
    iso31662: "ISO 3166-2:PT",
    region: "Europe",
    subregion: "Southern Europe",
    intermediateregion: "",
    regioncode: "150",
    subregioncode: "039",
    intermediateregioncode: "",
  },
  {
    name: "Puerto Rico",
    alpha2: "PR",
    alpha3: "PRI",
    countrycode: "630",
    iso31662: "ISO 3166-2:PR",
    region: "Americas",
    subregion: "Latin America and the Caribbean",
    intermediateregion: "Caribbean",
    regioncode: "019",
    subregioncode: "419",
    intermediateregioncode: "029",
  },
  {
    name: "Qatar",
    alpha2: "QA",
    alpha3: "QAT",
    countrycode: "634",
    iso31662: "ISO 3166-2:QA",
    region: "Asia",
    subregion: "Western Asia",
    intermediateregion: "",
    regioncode: "142",
    subregioncode: "145",
    intermediateregioncode: "",
  },
  {
    name: "Réunion",
    alpha2: "RE",
    alpha3: "REU",
    countrycode: "638",
    iso31662: "ISO 3166-2:RE",
    region: "Africa",
    subregion: "Sub-Saharan Africa",
    intermediateregion: "Eastern Africa",
    regioncode: "002",
    subregioncode: "202",
    intermediateregioncode: "014",
  },
  {
    name: "Romania",
    alpha2: "RO",
    alpha3: "ROU",
    countrycode: "642",
    iso31662: "ISO 3166-2:RO",
    region: "Europe",
    subregion: "Eastern Europe",
    intermediateregion: "",
    regioncode: "150",
    subregioncode: "151",
    intermediateregioncode: "",
  },
  {
    name: "Russian Federation",
    alpha2: "RU",
    alpha3: "RUS",
    countrycode: "643",
    iso31662: "ISO 3166-2:RU",
    region: "Europe",
    subregion: "Eastern Europe",
    intermediateregion: "",
    regioncode: "150",
    subregioncode: "151",
    intermediateregioncode: "",
  },
  {
    name: "Rwanda",
    alpha2: "RW",
    alpha3: "RWA",
    countrycode: "646",
    iso31662: "ISO 3166-2:RW",
    region: "Africa",
    subregion: "Sub-Saharan Africa",
    intermediateregion: "Eastern Africa",
    regioncode: "002",
    subregioncode: "202",
    intermediateregioncode: "014",
  },
  {
    name: "Saint Barthélemy",
    alpha2: "BL",
    alpha3: "BLM",
    countrycode: "652",
    iso31662: "ISO 3166-2:BL",
    region: "Americas",
    subregion: "Latin America and the Caribbean",
    intermediateregion: "Caribbean",
    regioncode: "019",
    subregioncode: "419",
    intermediateregioncode: "029",
  },
  {
    name: "Saint Helena, Ascension and Tristan da Cunha",
    alpha2: "SH",
    alpha3: "SHN",
    countrycode: "654",
    iso31662: "ISO 3166-2:SH",
    region: "Africa",
    subregion: "Sub-Saharan Africa",
    intermediateregion: "Western Africa",
    regioncode: "002",
    subregioncode: "202",
    intermediateregioncode: "011",
  },
  {
    name: "Saint Kitts and Nevis",
    alpha2: "KN",
    alpha3: "KNA",
    countrycode: "659",
    iso31662: "ISO 3166-2:KN",
    region: "Americas",
    subregion: "Latin America and the Caribbean",
    intermediateregion: "Caribbean",
    regioncode: "019",
    subregioncode: "419",
    intermediateregioncode: "029",
  },
  {
    name: "Saint Lucia",
    alpha2: "LC",
    alpha3: "LCA",
    countrycode: "662",
    iso31662: "ISO 3166-2:LC",
    region: "Americas",
    subregion: "Latin America and the Caribbean",
    intermediateregion: "Caribbean",
    regioncode: "019",
    subregioncode: "419",
    intermediateregioncode: "029",
  },
  {
    name: "Saint Martin (French part)",
    alpha2: "MF",
    alpha3: "MAF",
    countrycode: "663",
    iso31662: "ISO 3166-2:MF",
    region: "Americas",
    subregion: "Latin America and the Caribbean",
    intermediateregion: "Caribbean",
    regioncode: "019",
    subregioncode: "419",
    intermediateregioncode: "029",
  },
  {
    name: "Saint Pierre and Miquelon",
    alpha2: "PM",
    alpha3: "SPM",
    countrycode: "666",
    iso31662: "ISO 3166-2:PM",
    region: "Americas",
    subregion: "Northern America",
    intermediateregion: "",
    regioncode: "019",
    subregioncode: "021",
    intermediateregioncode: "",
  },
  {
    name: "Saint Vincent and the Grenadines",
    alpha2: "VC",
    alpha3: "VCT",
    countrycode: "670",
    iso31662: "ISO 3166-2:VC",
    region: "Americas",
    subregion: "Latin America and the Caribbean",
    intermediateregion: "Caribbean",
    regioncode: "019",
    subregioncode: "419",
    intermediateregioncode: "029",
  },
  {
    name: "Samoa",
    alpha2: "WS",
    alpha3: "WSM",
    countrycode: "882",
    iso31662: "ISO 3166-2:WS",
    region: "Oceania",
    subregion: "Polynesia",
    intermediateregion: "",
    regioncode: "009",
    subregioncode: "061",
    intermediateregioncode: "",
  },
  {
    name: "San Marino",
    alpha2: "SM",
    alpha3: "SMR",
    countrycode: "674",
    iso31662: "ISO 3166-2:SM",
    region: "Europe",
    subregion: "Southern Europe",
    intermediateregion: "",
    regioncode: "150",
    subregioncode: "039",
    intermediateregioncode: "",
  },
  {
    name: "Sao Tome and Principe",
    alpha2: "ST",
    alpha3: "STP",
    countrycode: "678",
    iso31662: "ISO 3166-2:ST",
    region: "Africa",
    subregion: "Sub-Saharan Africa",
    intermediateregion: "Middle Africa",
    regioncode: "002",
    subregioncode: "202",
    intermediateregioncode: "017",
  },
  {
    name: "Saudi Arabia",
    alpha2: "SA",
    alpha3: "SAU",
    countrycode: "682",
    iso31662: "ISO 3166-2:SA",
    region: "Asia",
    subregion: "Western Asia",
    intermediateregion: "",
    regioncode: "142",
    subregioncode: "145",
    intermediateregioncode: "",
  },
  {
    name: "Senegal",
    alpha2: "SN",
    alpha3: "SEN",
    countrycode: "686",
    iso31662: "ISO 3166-2:SN",
    region: "Africa",
    subregion: "Sub-Saharan Africa",
    intermediateregion: "Western Africa",
    regioncode: "002",
    subregioncode: "202",
    intermediateregioncode: "011",
  },
  {
    name: "Serbia",
    alpha2: "RS",
    alpha3: "SRB",
    countrycode: "688",
    iso31662: "ISO 3166-2:RS",
    region: "Europe",
    subregion: "Southern Europe",
    intermediateregion: "",
    regioncode: "150",
    subregioncode: "039",
    intermediateregioncode: "",
  },
  {
    name: "Seychelles",
    alpha2: "SC",
    alpha3: "SYC",
    countrycode: "690",
    iso31662: "ISO 3166-2:SC",
    region: "Africa",
    subregion: "Sub-Saharan Africa",
    intermediateregion: "Eastern Africa",
    regioncode: "002",
    subregioncode: "202",
    intermediateregioncode: "014",
  },
  {
    name: "Sierra Leone",
    alpha2: "SL",
    alpha3: "SLE",
    countrycode: "694",
    iso31662: "ISO 3166-2:SL",
    region: "Africa",
    subregion: "Sub-Saharan Africa",
    intermediateregion: "Western Africa",
    regioncode: "002",
    subregioncode: "202",
    intermediateregioncode: "011",
  },
  {
    name: "Singapore",
    alpha2: "SG",
    alpha3: "SGP",
    countrycode: "702",
    iso31662: "ISO 3166-2:SG",
    region: "Asia",
    subregion: "South-eastern Asia",
    intermediateregion: "",
    regioncode: "142",
    subregioncode: "035",
    intermediateregioncode: "",
  },
  {
    name: "Sint Maarten (Dutch part)",
    alpha2: "SX",
    alpha3: "SXM",
    countrycode: "534",
    iso31662: "ISO 3166-2:SX",
    region: "Americas",
    subregion: "Latin America and the Caribbean",
    intermediateregion: "Caribbean",
    regioncode: "019",
    subregioncode: "419",
    intermediateregioncode: "029",
  },
  {
    name: "Slovakia",
    alpha2: "SK",
    alpha3: "SVK",
    countrycode: "703",
    iso31662: "ISO 3166-2:SK",
    region: "Europe",
    subregion: "Eastern Europe",
    intermediateregion: "",
    regioncode: "150",
    subregioncode: "151",
    intermediateregioncode: "",
  },
  {
    name: "Slovenia",
    alpha2: "SI",
    alpha3: "SVN",
    countrycode: "705",
    iso31662: "ISO 3166-2:SI",
    region: "Europe",
    subregion: "Southern Europe",
    intermediateregion: "",
    regioncode: "150",
    subregioncode: "039",
    intermediateregioncode: "",
  },
  {
    name: "Solomon Islands",
    alpha2: "SB",
    alpha3: "SLB",
    countrycode: "090",
    iso31662: "ISO 3166-2:SB",
    region: "Oceania",
    subregion: "Melanesia",
    intermediateregion: "",
    regioncode: "009",
    subregioncode: "054",
    intermediateregioncode: "",
  },
  {
    name: "Somalia",
    alpha2: "SO",
    alpha3: "SOM",
    countrycode: "706",
    iso31662: "ISO 3166-2:SO",
    region: "Africa",
    subregion: "Sub-Saharan Africa",
    intermediateregion: "Eastern Africa",
    regioncode: "002",
    subregioncode: "202",
    intermediateregioncode: "014",
  },
  {
    name: "South Africa",
    alpha2: "ZA",
    alpha3: "ZAF",
    countrycode: "710",
    iso31662: "ISO 3166-2:ZA",
    region: "Africa",
    subregion: "Sub-Saharan Africa",
    intermediateregion: "Southern Africa",
    regioncode: "002",
    subregioncode: "202",
    intermediateregioncode: "018",
  },
  {
    name: "South Georgia and the South Sandwich Islands",
    alpha2: "GS",
    alpha3: "SGS",
    countrycode: "239",
    iso31662: "ISO 3166-2:GS",
    region: "Americas",
    subregion: "Latin America and the Caribbean",
    intermediateregion: "South America",
    regioncode: "019",
    subregioncode: "419",
    intermediateregioncode: "005",
  },
  {
    name: "South Sudan",
    alpha2: "SS",
    alpha3: "SSD",
    countrycode: "728",
    iso31662: "ISO 3166-2:SS",
    region: "Africa",
    subregion: "Sub-Saharan Africa",
    intermediateregion: "Eastern Africa",
    regioncode: "002",
    subregioncode: "202",
    intermediateregioncode: "014",
  },
  {
    name: "Spain",
    alpha2: "ES",
    alpha3: "ESP",
    countrycode: "724",
    iso31662: "ISO 3166-2:ES",
    region: "Europe",
    subregion: "Southern Europe",
    intermediateregion: "",
    regioncode: "150",
    subregioncode: "039",
    intermediateregioncode: "",
  },
  {
    name: "Sri Lanka",
    alpha2: "LK",
    alpha3: "LKA",
    countrycode: "144",
    iso31662: "ISO 3166-2:LK",
    region: "Asia",
    subregion: "Southern Asia",
    intermediateregion: "",
    regioncode: "142",
    subregioncode: "034",
    intermediateregioncode: "",
  },
  {
    name: "Sudan",
    alpha2: "SD",
    alpha3: "SDN",
    countrycode: "729",
    iso31662: "ISO 3166-2:SD",
    region: "Africa",
    subregion: "Northern Africa",
    intermediateregion: "",
    regioncode: "002",
    subregioncode: "015",
    intermediateregioncode: "",
  },
  {
    name: "Suriname",
    alpha2: "SR",
    alpha3: "SUR",
    countrycode: "740",
    iso31662: "ISO 3166-2:SR",
    region: "Americas",
    subregion: "Latin America and the Caribbean",
    intermediateregion: "South America",
    regioncode: "019",
    subregioncode: "419",
    intermediateregioncode: "005",
  },
  {
    name: "Svalbard and Jan Mayen",
    alpha2: "SJ",
    alpha3: "SJM",
    countrycode: "744",
    iso31662: "ISO 3166-2:SJ",
    region: "Europe",
    subregion: "Northern Europe",
    intermediateregion: "",
    regioncode: "150",
    subregioncode: "154",
    intermediateregioncode: "",
  },
  {
    name: "Sweden",
    alpha2: "SE",
    alpha3: "SWE",
    countrycode: "752",
    iso31662: "ISO 3166-2:SE",
    region: "Europe",
    subregion: "Northern Europe",
    intermediateregion: "",
    regioncode: "150",
    subregioncode: "154",
    intermediateregioncode: "",
  },
  {
    name: "Switzerland",
    alpha2: "CH",
    alpha3: "CHE",
    countrycode: "756",
    iso31662: "ISO 3166-2:CH",
    region: "Europe",
    subregion: "Western Europe",
    intermediateregion: "",
    regioncode: "150",
    subregioncode: "155",
    intermediateregioncode: "",
  },
  {
    name: "Syrian Arab Republic",
    alpha2: "SY",
    alpha3: "SYR",
    countrycode: "760",
    iso31662: "ISO 3166-2:SY",
    region: "Asia",
    subregion: "Western Asia",
    intermediateregion: "",
    regioncode: "142",
    subregioncode: "145",
    intermediateregioncode: "",
  },
  {
    name: "Taiwan, Province of China",
    alpha2: "TW",
    alpha3: "TWN",
    countrycode: "158",
    iso31662: "ISO 3166-2:TW",
    region: "Asia",
    subregion: "Eastern Asia",
    intermediateregion: "",
    regioncode: "142",
    subregioncode: "030",
    intermediateregioncode: "",
  },
  {
    name: "Tajikistan",
    alpha2: "TJ",
    alpha3: "TJK",
    countrycode: "762",
    iso31662: "ISO 3166-2:TJ",
    region: "Asia",
    subregion: "Central Asia",
    intermediateregion: "",
    regioncode: "142",
    subregioncode: "143",
    intermediateregioncode: "",
  },
  {
    name: "Tanzania, United Republic of",
    alpha2: "TZ",
    alpha3: "TZA",
    countrycode: "834",
    iso31662: "ISO 3166-2:TZ",
    region: "Africa",
    subregion: "Sub-Saharan Africa",
    intermediateregion: "Eastern Africa",
    regioncode: "002",
    subregioncode: "202",
    intermediateregioncode: "014",
  },
  {
    name: "Thailand",
    alpha2: "TH",
    alpha3: "THA",
    countrycode: "764",
    iso31662: "ISO 3166-2:TH",
    region: "Asia",
    subregion: "South-eastern Asia",
    intermediateregion: "",
    regioncode: "142",
    subregioncode: "035",
    intermediateregioncode: "",
  },
  {
    name: "Timor-Leste",
    alpha2: "TL",
    alpha3: "TLS",
    countrycode: "626",
    iso31662: "ISO 3166-2:TL",
    region: "Asia",
    subregion: "South-eastern Asia",
    intermediateregion: "",
    regioncode: "142",
    subregioncode: "035",
    intermediateregioncode: "",
  },
  {
    name: "Togo",
    alpha2: "TG",
    alpha3: "TGO",
    countrycode: "768",
    iso31662: "ISO 3166-2:TG",
    region: "Africa",
    subregion: "Sub-Saharan Africa",
    intermediateregion: "Western Africa",
    regioncode: "002",
    subregioncode: "202",
    intermediateregioncode: "011",
  },
  {
    name: "Tokelau",
    alpha2: "TK",
    alpha3: "TKL",
    countrycode: "772",
    iso31662: "ISO 3166-2:TK",
    region: "Oceania",
    subregion: "Polynesia",
    intermediateregion: "",
    regioncode: "009",
    subregioncode: "061",
    intermediateregioncode: "",
  },
  {
    name: "Tonga",
    alpha2: "TO",
    alpha3: "TON",
    countrycode: "776",
    iso31662: "ISO 3166-2:TO",
    region: "Oceania",
    subregion: "Polynesia",
    intermediateregion: "",
    regioncode: "009",
    subregioncode: "061",
    intermediateregioncode: "",
  },
  {
    name: "Trinidad and Tobago",
    alpha2: "TT",
    alpha3: "TTO",
    countrycode: "780",
    iso31662: "ISO 3166-2:TT",
    region: "Americas",
    subregion: "Latin America and the Caribbean",
    intermediateregion: "Caribbean",
    regioncode: "019",
    subregioncode: "419",
    intermediateregioncode: "029",
  },
  {
    name: "Tunisia",
    alpha2: "TN",
    alpha3: "TUN",
    countrycode: "788",
    iso31662: "ISO 3166-2:TN",
    region: "Africa",
    subregion: "Northern Africa",
    intermediateregion: "",
    regioncode: "002",
    subregioncode: "015",
    intermediateregioncode: "",
  },
  {
    name: "Turkey",
    alpha2: "TR",
    alpha3: "TUR",
    countrycode: "792",
    iso31662: "ISO 3166-2:TR",
    region: "Asia",
    subregion: "Western Asia",
    intermediateregion: "",
    regioncode: "142",
    subregioncode: "145",
    intermediateregioncode: "",
  },
  {
    name: "Turkmenistan",
    alpha2: "TM",
    alpha3: "TKM",
    countrycode: "795",
    iso31662: "ISO 3166-2:TM",
    region: "Asia",
    subregion: "Central Asia",
    intermediateregion: "",
    regioncode: "142",
    subregioncode: "143",
    intermediateregioncode: "",
  },
  {
    name: "Turks and Caicos Islands",
    alpha2: "TC",
    alpha3: "TCA",
    countrycode: "796",
    iso31662: "ISO 3166-2:TC",
    region: "Americas",
    subregion: "Latin America and the Caribbean",
    intermediateregion: "Caribbean",
    regioncode: "019",
    subregioncode: "419",
    intermediateregioncode: "029",
  },
  {
    name: "Tuvalu",
    alpha2: "TV",
    alpha3: "TUV",
    countrycode: "798",
    iso31662: "ISO 3166-2:TV",
    region: "Oceania",
    subregion: "Polynesia",
    intermediateregion: "",
    regioncode: "009",
    subregioncode: "061",
    intermediateregioncode: "",
  },
  {
    name: "Uganda",
    alpha2: "UG",
    alpha3: "UGA",
    countrycode: "800",
    iso31662: "ISO 3166-2:UG",
    region: "Africa",
    subregion: "Sub-Saharan Africa",
    intermediateregion: "Eastern Africa",
    regioncode: "002",
    subregioncode: "202",
    intermediateregioncode: "014",
  },
  {
    name: "Ukraine",
    alpha2: "UA",
    alpha3: "UKR",
    countrycode: "804",
    iso31662: "ISO 3166-2:UA",
    region: "Europe",
    subregion: "Eastern Europe",
    intermediateregion: "",
    regioncode: "150",
    subregioncode: "151",
    intermediateregioncode: "",
  },
  {
    name: "United Arab Emirates",
    alpha2: "AE",
    alpha3: "ARE",
    countrycode: "784",
    iso31662: "ISO 3166-2:AE",
    region: "Asia",
    subregion: "Western Asia",
    intermediateregion: "",
    regioncode: "142",
    subregioncode: "145",
    intermediateregioncode: "",
  },
  {
    name: "United Kingdom of Great Britain and Northern Ireland",
    alpha2: "GB",
    alpha3: "GBR",
    countrycode: "826",
    iso31662: "ISO 3166-2:GB",
    region: "Europe",
    subregion: "Northern Europe",
    intermediateregion: "",
    regioncode: "150",
    subregioncode: "154",
    intermediateregioncode: "",
  },
  {
    name: "United States of America",
    alpha2: "US",
    alpha3: "USA",
    countrycode: "840",
    iso31662: "ISO 3166-2:US",
    region: "Americas",
    subregion: "Northern America",
    intermediateregion: "",
    regioncode: "019",
    subregioncode: "021",
    intermediateregioncode: "",
  },
  {
    name: "United States Minor Outlying Islands",
    alpha2: "UM",
    alpha3: "UMI",
    countrycode: "581",
    iso31662: "ISO 3166-2:UM",
    region: "Oceania",
    subregion: "Micronesia",
    intermediateregion: "",
    regioncode: "009",
    subregioncode: "057",
    intermediateregioncode: "",
  },
  {
    name: "Uruguay",
    alpha2: "UY",
    alpha3: "URY",
    countrycode: "858",
    iso31662: "ISO 3166-2:UY",
    region: "Americas",
    subregion: "Latin America and the Caribbean",
    intermediateregion: "South America",
    regioncode: "019",
    subregioncode: "419",
    intermediateregioncode: "005",
  },
  {
    name: "Uzbekistan",
    alpha2: "UZ",
    alpha3: "UZB",
    countrycode: "860",
    iso31662: "ISO 3166-2:UZ",
    region: "Asia",
    subregion: "Central Asia",
    intermediateregion: "",
    regioncode: "142",
    subregioncode: "143",
    intermediateregioncode: "",
  },
  {
    name: "Vanuatu",
    alpha2: "VU",
    alpha3: "VUT",
    countrycode: "548",
    iso31662: "ISO 3166-2:VU",
    region: "Oceania",
    subregion: "Melanesia",
    intermediateregion: "",
    regioncode: "009",
    subregioncode: "054",
    intermediateregioncode: "",
  },
  {
    name: "Venezuela (Bolivarian Republic of)",
    alpha2: "VE",
    alpha3: "VEN",
    countrycode: "862",
    iso31662: "ISO 3166-2:VE",
    region: "Americas",
    subregion: "Latin America and the Caribbean",
    intermediateregion: "South America",
    regioncode: "019",
    subregioncode: "419",
    intermediateregioncode: "005",
  },
  {
    name: "Viet Nam",
    alpha2: "VN",
    alpha3: "VNM",
    countrycode: "704",
    iso31662: "ISO 3166-2:VN",
    region: "Asia",
    subregion: "South-eastern Asia",
    intermediateregion: "",
    regioncode: "142",
    subregioncode: "035",
    intermediateregioncode: "",
  },
  {
    name: "Virgin Islands (British)",
    alpha2: "VG",
    alpha3: "VGB",
    countrycode: "092",
    iso31662: "ISO 3166-2:VG",
    region: "Americas",
    subregion: "Latin America and the Caribbean",
    intermediateregion: "Caribbean",
    regioncode: "019",
    subregioncode: "419",
    intermediateregioncode: "029",
  },
  {
    name: "Virgin Islands (U.S.)",
    alpha2: "VI",
    alpha3: "VIR",
    countrycode: "850",
    iso31662: "ISO 3166-2:VI",
    region: "Americas",
    subregion: "Latin America and the Caribbean",
    intermediateregion: "Caribbean",
    regioncode: "019",
    subregioncode: "419",
    intermediateregioncode: "029",
  },
  {
    name: "Wallis and Futuna",
    alpha2: "WF",
    alpha3: "WLF",
    countrycode: "876",
    iso31662: "ISO 3166-2:WF",
    region: "Oceania",
    subregion: "Polynesia",
    intermediateregion: "",
    regioncode: "009",
    subregioncode: "061",
    intermediateregioncode: "",
  },
  {
    name: "Western Sahara",
    alpha2: "EH",
    alpha3: "ESH",
    countrycode: "732",
    iso31662: "ISO 3166-2:EH",
    region: "Africa",
    subregion: "Northern Africa",
    intermediateregion: "",
    regioncode: "002",
    subregioncode: "015",
    intermediateregioncode: "",
  },
  {
    name: "Yemen",
    alpha2: "YE",
    alpha3: "YEM",
    countrycode: "887",
    iso31662: "ISO 3166-2:YE",
    region: "Asia",
    subregion: "Western Asia",
    intermediateregion: "",
    regioncode: "142",
    subregioncode: "145",
    intermediateregioncode: "",
  },
  {
    name: "Zambia",
    alpha2: "ZM",
    alpha3: "ZMB",
    countrycode: "894",
    iso31662: "ISO 3166-2:ZM",
    region: "Africa",
    subregion: "Sub-Saharan Africa",
    intermediateregion: "Eastern Africa",
    regioncode: "002",
    subregioncode: "202",
    intermediateregioncode: "014",
  },
  {
    name: "Zimbabwe",
    alpha2: "ZW",
    alpha3: "ZWE",
    countrycode: "716",
    iso31662: "ISO 3166-2:ZW",
    region: "Africa",
    subregion: "Sub-Saharan Africa",
    intermediateregion: "Eastern Africa",
    regioncode: "002",
    subregioncode: "202",
    intermediateregioncode: "014",
  },
];

export const dateTypes = {
  LASTMONTH: { name: "LASTMONTH", text: "Last Month" },
  THISMONTH: { name: "THISMONTH", text: "This Month" },
  TODAY: { name: "TODAY", text: "Today" },
  YESTERDAY: { name: "YESTERDAY", text: "Yesterday" },
  TOMORROW: { name: "TOMORROW", text: "Tomorrow" },
  LASTWEEK: { name: "LASTWEEK", text: "Last Week" },
  THISWEEK: { name: "THISWEEK", text: "This Week" },
  ALLTIME: { name: "ALLTIME", text: "All Time" },
  LAST2MONTH: { name: "LAST2MONTH", text: "Last 2 Month" },
};

export const defaultDateFormat = "DD-MM-YYYY";

export const PromotionPopupSearchInput = "Search by name or sku";

export const PromotionPopupCoution = "Please fill in the required fields";

export const PromotionPopupHeader = {
  SelectGame: "Select The Game",
  SelectRegion: "Select The Region",
  PriceRestriction: "Price Restriction",
  PromotionDetail: "Promotion Detail",
};

export const platformTypes = {
  EPIC: { name: "EPIC", text: "Epic" },
  STEAM: { name: "STEAM", text: "Steam" },
};

export const osTypes = {
  WINDOWS: { name: "WINDOWS", text: "Windows" },
  LINUX: { name: "LINUX", text: "Linux" },
  MACOS: { name: "MACOS", text: "MacOS" },
};

export const PromotionPopUpCheckBox = {
  CheckBox1: "Publish now",
  CheckBox2: "I have checked the campaign timeline & Quantity on CRM.",
};

export const ManagementOrderLinkList = [Endpoints.Management.Orders];

export const PartnerOrderLinkList = [Endpoints.Partner.MyOrders];

export const TabName = {
  Management: {
    sendToConfirmation: "sendToConfirmation",
    waitingMyApproval: "waitingMyApproval",
    allOrders: "allOrders",
    salesmanApproved: "salesmanApproved",
    level1Approved: "level1Approved",
  },
  Partner: {
    sendToApproval: "sendToApproval",
    waitingMyConfirmation: "waitingMyConfirmation",
    allOrders: "allOrders",
  },
};
