import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

import Loader from "../../../Loader";
import useTable from "./hooks/useTable";
import Button from "../button";

const Table = ({
  columns = [],
  data: _data,
  fetchData,
  idKey,
  mapper,
  ActionColumn,
  ActionItem,
}) => {
  const { data, loading, page, pagination } = useTable({
    data: _data,
    fetchData,
    mapper,
  });

  if (loading) {
    return (
      <div className="position-relative">
        <Loader />
      </div>
    );
  }

  return (
    <div className="table-responsive">
      <table className="table table-centered table-nowrap table-hover mb-0 rounded table-responsive card-table">
        <thead>
          <tr>
            {columns.map((e) => (
              <th key={e.key}>{e.title}</th>
            ))}
            {ActionItem && <th>{ActionColumn}</th>}
          </tr>
        </thead>
        <tbody>
          {data?.map((item, i) => (
            <tr key={item[idKey] || item.Id || i}>
              {columns.map((col) => (
                <td key={col.key}>{item[col.key]}</td>
              ))}
              {ActionItem && (
                <td>
                  <ActionItem data={item} />
                </td>
              )}
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={columns.length + (ActionItem ? 1 : 0)}>
              <div className="w-100 d-flex align-items-center">
                <div
                  className="d-flex justify-content-start gap-2"
                  style={{ flex: 1 }}
                >
                  <b>Total Results:</b>
                  <span>{page?.TotalCount || 0}</span>
                </div>
                <div
                  className="d-flex justify-content-center gap-2"
                  style={{ flex: 1 }}
                >
                  <span>{page?.PageIndex + 1}</span>
                  <b>/</b>
                  <span>{page?.TotalPages || 0}</span>
                </div>
                <div
                  className="d-flex justify-content-end gap-4"
                  style={{ flex: 1 }}
                >
                  <select
                    value={page.PageSize}
                    onChange={(e) => pagination.onPageSize(e.target.value)}
                  >
                    <option value={10}>10</option>
                    <option value={15}>15</option>
                    <option value={25}>25</option>
                    <option value={50}>50</option>
                  </select>
                  <div className="d-flex gap-2">
                    <Button
                      disabled={page.PageIndex === 0}
                      onClick={pagination.onFirst}
                      size="default"
                      title={<i className="fas fa-step-backward"></i>}
                    />
                    <Button
                      disabled={!page.HasPreviousPage}
                      onClick={pagination.onPrev}
                      size="default"
                      title="Prev"
                    />
                    <Button
                      disabled={!page.HasNextPage}
                      onClick={pagination.onNext}
                      size="default"
                      title="Next"
                    />
                    <Button
                      disabled={
                        page.PageIndex === Math.max(page.TotalPages - 1, 0)
                      }
                      onClick={pagination.onLast}
                      size="default"
                      title=<i className="fas fa-step-forward"></i>
                    />
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

const propStyles = {};

const styles = {};

Table.defaultProps = {};

Table.propTypes = {};

export default Table;
