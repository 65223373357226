import React, { useCallback, useState } from "react";

import api from "../../../../../../../api";
import {
  OrderStatus,
  salesman,
  superadmin,
} from "../../../../../../../constants/defaults";

import Modal from "../../../../../../../components/common/v2/modal";
import useBlending from "../../../hooks/useBlending";

const ModalUpdateBlending = ({ data, userScope }) => {
  const { blending: actualBlending, order, promotion } = data || {};
  const { blending } = useBlending({ actualBlending, order, promotion });

  const [values, setValues] = useState(() => blending);

  const currTotalQuantity = Object.values(values || {}).reduce(
    (prev, curr) => prev + curr.Quantity,
    0
  );

  const isTotalQuantityEquals = currTotalQuantity === order.Quantity;

  const currSumPrice =
    Object.values(values || {}).reduce(
      (prev, curr) => prev + curr.Cost * curr.Quantity,
      0
    ) || 0;

  const currAvgPrice = (currSumPrice / currTotalQuantity).toFixed(2);

  const onChange = useCallback((e) => {
    const { target: { name, value } = {} } = e;

    setValues((prev) => {
      const temp = JSON.parse(JSON.stringify(prev));
      temp[name].Quantity = parseInt(value);
      return temp;
    });
  }, []);

  const onSubmit = useCallback(async () => {
    const countries = Object.values(values || {}).reduce(
      (prev, curr) => [
        ...prev,
        {
          Code: curr.Code,
          Quantity: curr.Quantity,
        },
      ],
      []
    );

    await api.orders.updateManagementOrderBlending(order.Id, countries);

    setTimeout(() => {
      window.location.reload();
    }, 500);
  }, [order, values]);

  if (!order) {
    return <span>Order data is not provided.</span>;
  }

  if (![salesman, superadmin].includes(userScope)) {
    return null;
  }

  if (![OrderStatus.Submitted, OrderStatus.Paused].includes(order.Status)) {
    return null;
  }

  return (
    <Modal
      accessibility={{
        submit: {
          onClick: onSubmit,
          successMessage: "The order blending is updated, successfully!",
          title: "Confirm",
        },
      }}
      trigger={{ theme: "warning", title: "Update blending" }}
      title="Update blending settings"
    >
      <form>
        <table className="table table-centered table-nowrap table-hover mb-0 rounded table-responsive cursor-pointer">
          <thead>
            <tr>
              <th>Country Code</th>
              <th>Quantity</th>
              <th>Price</th>
            </tr>
          </thead>
          <tbody>
            {Object.values(values || {}).map((e) => (
              <tr key={e.Code} className="my-3">
                <td>
                  <span>{e.Code}</span>
                </td>
                <td>
                  <input
                    name={e.Code}
                    value={e.Quantity || 0}
                    type="number"
                    step={1}
                    min={0}
                    max={order.Quantity || undefined}
                    onChange={onChange}
                  />
                </td>
                <td>
                  <span>€{e.Cost}</span>
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr
              style={{
                backgroundColor: !isTotalQuantityEquals
                  ? "#ff0000cc"
                  : "transparent",
              }}
            >
              <td>Total Quantity</td>
              <td>
                {currTotalQuantity}
                <span className="mx-1">/</span>
                {order.Quantity}
              </td>
              <td>
                €{currAvgPrice}
                <span className="mx-1">/</span>€{order.Offer?.OfferPrice}
              </td>
            </tr>
          </tfoot>
        </table>
      </form>
    </Modal>
  );
};

export default ModalUpdateBlending;
