/* eslint-disable react/no-unused-state */
import React from "react";
import { Modal } from "react-bootstrap";
import RegionContent from "./RegionContent";
import { Countries_Regions } from "../constants/defaults";

class RegionTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      region_country: [],
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.setShow = this.setShow.bind(this);
  }

  handleClose() {
    this.setShow(false);
  }

  handleShow() {
    this.setState({
      region_country: Countries_Regions.filter((i, n) => {
        return this.props.regions.find(
          (o) => (typeof o === "object" ? o.Code : o) === i.alpha2
        );
      }),
    });
    this.setShow(true);
  }

  setShow(status) {
    this.setState({ show: status });
  }

  render() {
    const regionsList = this.props.regions.filter((f) => f !== "");
    return (
      <div className="region-table-container">
        {regionsList.slice(0, this.props.size).map((item, index) => {
          return (
            <div className="region-table" key={index}>
              {item.Code ? item.Code : item}
            </div>
          );
        })}
        <>
          {regionsList.length > 3 && (
            <div className="region-table-count">+{regionsList.length - 3}</div>
          )}
          {regionsList.length !== 0 ? (
            <div className="d-inline" onClick={() => this.handleShow()}>
              View All
            </div>
          ) : (
            <></>
          )}
        </>

        <Modal
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.show}
          onHide={this.handleClose}
          size="lg"
          className="region-modal"
        >
          <Modal.Header className="border-0 justify-content-center">
            <Modal.Title className="text-center">
              {this.props.title ?? "Available Regions"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <RegionContent
                region_country={this.state.region_country}
              ></RegionContent>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default RegionTable;
