import React, { useEffect, useState } from "react";
import {
  MANAGEMENT_PROMOTION_UPDATE_FIELD,
  MANUEL_STOCK_PAGE_DETAILS,
} from "../../constants/actionTypes";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import services from "../../api/index";

import Filter from "../../components/Filter";
import Loader from "../../components/Loader";
import Pager from "../../components/Pager";
import Content from "../../components/containers/Content";
import MerModal from "../../components/common/MerModal";
import ManualInventoryModal from "./inventories/components/ManualInventoryModal";
import Button from "../../components/common/Button";
import ProductSelectionModal from "./inventories/components/ProductSelectionModal";

const mapStateToProps = (state) => {
  return {
    manualStockList: state.order.manualStockList,
    searchManuelInventoryText: state.order.searchManuelInventoryText,
    pageSize: state.common.pageSize,
  };
};

const mapDispatchToProps = (dispatch) => ({
  ManagmentOnLoad: (payload) =>
    dispatch({
      type: MANUEL_STOCK_PAGE_DETAILS,
      key: "manualStockList",
      value: payload,
    }),
  ChangeCostPerItem: (payload) =>
    dispatch({
      type: MANUEL_STOCK_PAGE_DETAILS,
      key: "CostPerItemForManualStock",
      value: payload,
    }),
  ChangeQuantity: (payload) =>
    dispatch({
      type: MANUEL_STOCK_PAGE_DETAILS,
      key: "QuantityForManualStock",
      value: payload,
    }),
  ChangeProductId: (payload) =>
    dispatch({
      type: MANUEL_STOCK_PAGE_DETAILS,
      key: "ProductIdForManualStock",
      value: payload,
    }),
  onChangeProductSearchText: (value) =>
    dispatch({
      type: MANAGEMENT_PROMOTION_UPDATE_FIELD,
      key: "searchProductText",
      value,
    }),
});
let timer = null;

function ManualInventory(props) {
  const [isLoading, setIsLoading] = useState(props.match.params.sort);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [myfilter, setFilter] = useState(false);
  const [pgeIndex, setPgeIndex] = useState(
    props.match.params.page ? props.match.params.page : 0
  );

  useEffect(() => {
    document.title = "Manual Inventory";
  }, []);
  useEffect(() => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      setIsLoading(true);
    }, 1000); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.searchManuelInventoryText, props.OnlyActivePromotions]);

  useEffect(() => {
    onFilter({ pageindex: 0, pagesize: props.pageSize });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.pageSize]);

  const getAllManagement = (params) => {
    const {
      keywords = "",
      pageindex = pgeIndex,
      pagesize = 10,
      store = "",
    } = params || {};
    setPgeIndex(pageindex);
    services.orders
      .manualInventorySearch(pagesize, pageindex, keywords, store)
      .then((res) => {
        return props.ManagmentOnLoad(res);
      })
      .catch((err) => {
        if (err.response?.data?.message)
          toast.error(<div>{err.response?.data.message}</div>);
        return undefined;
      });
  };

  const onFilter = (params) => {
    let {
      filter = undefined,
      pageindex = 0,
      pagesize = props.pageSize,
    } = params || {};
    let ev = filter;
    if (ev !== undefined) setFilter(ev);
    else ev = myfilter;

    getAllManagement({
      keywords: ev?.Text,
      pageindex: pageindex,
      pagesize: pagesize,
      store: ev?.Store,
    });
  };

  const onChangePageIndex = (pageindex) => {
    onFilter({ pageindex: pageindex });
  };

  const changeManualStock = (params) => {
    const { CostPerItem, Quantity, ProductId } = params || {};

    if (ProductId !== undefined) props.ChangeProductId(ProductId);
    if (CostPerItem !== undefined) props.ChangeCostPerItem(CostPerItem);
    if (Quantity !== undefined) props.ChangeQuantity(Quantity);
  };

  return (
    <Content pageTitle="Manual Inventory">
      {!props.manualStockList ? (
        <div></div>
      ) : (
        <div>
          <Filter
            filter={myfilter}
            isStoreAvailable
            onFilter={(ev, sortBy) => onFilter(ev, sortBy)}
          ></Filter>
          <Button
            className="confirm-button px-4 float-end"
            bindEvent={() => {
              setShowAddModal(!showAddModal);
            }}
            label="Add Manual Stock"
          />
          <div className="table-responsive col-md-12">
            <table className="table table-centered table-nowrap mb-0 rounded ">
              <thead className="">
                <tr className="border-0 table-sub-title">
                  <th className="w-25 ps-0">Name</th>
                  <th className="ps-0">Id</th>
                  <th className="ps-0">Sku</th>
                  <th className="ps-0">CostPerItem</th>
                  <th className="ps-0">Quantity</th>
                  <th className="ps-0">Store</th>
                  <th className="ps-0">Edit</th>
                </tr>
              </thead>
              <tbody>
                {props.manualStockList?.Items?.map((item, index) => {
                  return (
                    <tr
                      className="rounded-1 mb-1 align-middle border-bottom"
                      key={index}
                    >
                      <td className="ps-2">{item.Name}</td>
                      <td className="ps-2">{item.Id}</td>
                      <td className="ps-2">{item.Sku}</td>
                      <td className="ps-2">{item.CostPerItem}</td>
                      <td className="ps-2">{item.Quantity}</td>
                      <td className="ps-2">{item.Store}</td>
                      <td className="ps-2">
                        <Button
                          className="resubmit-button"
                          bindEvent={() => {
                            setShowEditModal(!showEditModal);
                            changeManualStock({
                              CostPerItem: item.CostPerItem,
                              Quantity: item.Quantity,
                              ProductId: item.ProductId,
                            });
                          }}
                          label="Update"
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <Pager
            pageIndex={props.manualStockList?.PageIndex}
            totalPages={props.manualStockList?.TotalPages}
            totalCount={props.manualStockList?.TotalCount}
            onChange={(pageIndex) => onChangePageIndex(pageIndex)}
          />
          <MerModal
            showModal={showAddModal}
            ariaLabelledby="contained-modal-title-vcenter"
            onHide={() => setShowAddModal(false)}
          >
            <ProductSelectionModal
              handleClose={() => setShowAddModal(false)}
              selectProduct={(item) => {
                setShowAddModal(false);
                setShowEditModal(true);
                changeManualStock({
                  CostPerItem: "",
                  Quantity: "",
                  ProductId: item.Id,
                });
              }}
            />
          </MerModal>

          <MerModal
            showModal={showEditModal}
            ariaLabelledby="contained-modal-title-vcenter"
            onHide={() => setShowEditModal(false)}
          >
            <ManualInventoryModal
              refreshList={() => {
                onFilter();
                setShowEditModal(false);
                props.onChangeProductSearchText("");
              }}
              handleClose={() => setShowEditModal(false)}
              selectedInvoice={undefined}
              changeManualStock={(params) => {
                changeManualStock(params);
              }}
            />
          </MerModal>
        </div>
      )}
    </Content>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ManualInventory);
