import React, { useEffect, useState } from "react";
import classNames from "classnames";
import moment from "moment";

import { partner, salesman } from "../../../../../../constants/defaults";

const HistoryItem = ({ data }) => (
  <div className="d-flex cloudy-blue">
    <span style={{ flex: 1 }}>
      {moment.utc(data.ModifiedAt).format("DD.MM.yyyy HH:mm:ss")}
    </span>
    <div className="d-flex gap-1" style={{ flex: 1 }}>
      <span>{data.OrderStatus}</span>
      <span>by</span>
      <span>
        {data.ModifiedByType === salesman
          ? data.ModifiedByName
          : data.ModifiedByType}
      </span>
    </div>
    <div className="d-flex gap-1" style={{ flex: 1 }}>
      <span>{data.ModifiedByType === partner ? "DP" : "OP"}</span>
      <span>
        €{data.OfferPrice} x {data.OfferQuantity} = €
        {data.OfferPrice * data.OfferQuantity}
      </span>
    </div>
  </div>
);

const OrderHistoryTab = ({ order = {} }) => {
  return (
    <div className="d-flex flex-column gap-2">
      <div className="d-flex gap-2 font-bolder" style={{ fontSize: "0.85rem" }}>
        <span>
          <sup>*</sup>DP: Demanded Price
        </span>
        <span>
          <sup>*</sup>OP: Offered Price
        </span>
      </div>
      <div className="overflow-auto" style={{ maxHeight: 300 }}>
        {order.History.slice()
          .reverse()
          .map((e) => (
            <HistoryItem key={e.Id} data={e} />
          ))}
      </div>
    </div>
  );
};

export default OrderHistoryTab;
