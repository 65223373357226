export default (req) => ({
  search: (keywords, pageIndex, pageSize) =>
    req.get("/vendor/management/search", {
      params: {
        keywords,
        PageIndex: pageIndex,
        PageSize: pageSize,
      },
    }),
  update: (InternalId, Name, SearchValue, vendorId) =>
    req.put(`/vendor/management/${vendorId}/update`, {
      InternalId: InternalId,
      Name: Name,
      SearchValue: SearchValue,
    }),
  create: (InternalId, Name, SearchValue) =>
    req.post(`/vendor/management/create`, {
      InternalId: InternalId,
      Name: Name,
      SearchValue: SearchValue,
    }),

  paymentplanSearch: (pageIndex, pageSize, partnerId, keywords, sortby) =>
    req.get("/paymentplan/management/search", {
      params: {
        Keywords: keywords,
        PageIndex: pageIndex,
        PageSize: pageSize,
        PartnerId: partnerId,
        SortBy: sortby,
      },
    }),
  paymentplanMarkasPaid: (paymentPlanId, date) =>
    req.put(`/paymentplan/management/${paymentPlanId}/markaspaid`, {
      paymentDate: date,
    }),

  paymentplanCreate: (params) => {
    const {
      partnerId,
      amount,
      isPaid,
      paymentDate = undefined,
      plannedPaymentDate = undefined,
      note,
    } = params;
    req.post(`/paymentplan/management/create`, {
      partnerId: partnerId,
      amount: amount,
      isPaid: isPaid,
      paymentDate: paymentDate,
      plannedPaymentDate: plannedPaymentDate,
      note: note,
    });
  },

  paymentplanUpdate: (params) => {
    const {
      id,
      amount,
      isPaid,
      paymentDate = undefined,
      plannedPaymentDate = undefined,
      note,
    } = params;
    req.put(`/paymentplan/management/${id}/update`, {
      amount: amount,
      isPaid: isPaid,
      paymentDate: paymentDate,
      plannedPaymentDate: plannedPaymentDate,
      note: note,
    });
  },

  partnerSummary: (partnerId) =>
    req.get(`/report/management/PartnerSummary/${partnerId}`),
  paymentplanDelete: (id) => req.put(`/paymentplan/management/${id}/delete`),
});
