import React from "react";
import Loader from "../../components/Loader";
import { toast } from "react-toastify";
import BlankPage from "../../components/BlankPage";
import Content from "../../components/containers/Content";
import { connect } from "react-redux";
import { UPCOMING_PROMOTION } from "../../constants/actionTypes";
import Pager from "../../components/Pager";
import base64 from "base-64";
import Filter from "../../components/Filter";
import { defaultDateFormat } from "../../constants/defaults";
import moment from "moment";
import services from "../../api/index";

const mapStateToProps = (state) => {
  return {
    promotions: state.promotions,
    pageSize: state.common.pageSize,
  };
};

const mapDispatchToProps = (dispatch) => ({
  upcomingPromotionsOnLoad: (payload) => {
    dispatch({ type: UPCOMING_PROMOTION, payload });
  },
});

class UpcomingPromotionsPartner extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: this.props.match.params.filter
        ? JSON.parse(base64.decode(this.props.match.params.filter))
        : null,
      pageindex: 0,
      tableIsLoading: false,
    };
  }
  componentDidMount() {
    this.getUpcomingPromotions();
  }

  componentDidUpdate(prevProps) {
    if (this.props.pageSize !== prevProps.pageSize) {
      this.onFilter({ pageindex: 0 });
    }
  }

  getUpcomingPromotions = (params) => {
    this.setState({ tableIsLoading: true });
    const { pageindex = this.state.pageindex, pagesize = this.props.pageSize } =
      params || {};
    this.props.upcomingPromotionsOnLoad(
      Promise.all([
        services.campaigns.upcomingPromotionsPartner(pageindex, pagesize),
      ])
        .then((res) => {
          this.setState({ tableIsLoading: false });
          return res;
        })
        .catch((err) => {
          if (err.response?.data?.message)
            toast.error(<div>{err.response?.data.message}</div>);
          return undefined;
        })
    );
  };

  onFilter = (params) => {
    let { filter, pageindex = 0 } = params || {};
    let ev = filter;
    if (ev !== undefined) this.setState({ filter: ev });
    else ev = this.state.filter;

    this.getUpcomingPromotions({
      pageindex: pageindex,
    });
  };

  onChangePageIndex = (pageindex) => {
    this.setState({ tableIsLoading: true });
    this.onFilter({ pageindex: pageindex });
  };

  render() {
    const { upcomingPromotions } = this.props.promotions;
    return (
      <Content pageTitle="Upcoming Promotions">
        {upcomingPromotions == undefined ? (
          <Loader />
        ) : (
          <div>
            <Filter
              filter={this.state.filter}
              hideSearch
              onFilter={(ev) => this.onFilter(ev)}
            ></Filter>
            {this.state.tableIsLoading ? (
              <Loader />
            ) : upcomingPromotions[0]?.Items.length === 0 ? (
              <BlankPage description="There is no upcoming promotion." />
            ) : (
              <div>
                <div className="table-total-count">
                  Total Count: {upcomingPromotions[0]?.TotalCount}
                </div>
                <div className="table-responsive-xl">
                  <table
                    className="table table-centered table-nowrap table-hover mb-0 rounded table-responsive cursor-pointer table-width-100"
                    id="datatable"
                  >
                    <thead className="thead-light d-short border-0">
                      <tr>
                        <th className="bg-transparent border-0 ">
                          Publisher Name
                        </th>
                        <th className="bg-transparent border-0 ">Start Date</th>
                        <th className="bg-transparent border-0 ">End Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {upcomingPromotions[0]?.Items.map((item, index) => {
                        return (
                          <tr
                            className="rounded-1 mb-1 align-middle border-bottom"
                            key={index}
                          >
                            <td>
                              <p>{item.PublisherName}</p>
                            </td>
                            <td>
                              <p>
                                {moment(item.ValidFrom).format(
                                  defaultDateFormat + "\tH:mm:ss"
                                )}
                              </p>
                            </td>
                            <td>
                              <p>
                                {moment(item.ValidTo).format(
                                  defaultDateFormat + "\tH:mm:ss"
                                )}
                              </p>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <Pager
                  pageIndex={upcomingPromotions[0]?.PageIndex}
                  totalPages={upcomingPromotions[0]?.TotalPages}
                  totalCount={upcomingPromotions[0]?.TotalCount}
                  onChange={(pageIndex) => this.onChangePageIndex(pageIndex)}
                />
              </div>
            )}
          </div>
        )}
      </Content>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpcomingPromotionsPartner);
