import React from "react";
import { osTypes } from "../../../../../constants/defaults";
import PromotionHeader from "../../../../../components/common/PromotionHeader";
import { connect } from "react-redux";
import { MANAGEMENT_PROMOTION_UPDATE_FIELD } from "../../../../../constants/actionTypes";
import Loader from "../../../../../components/Loader";
import { toast } from "react-toastify";
import { Icons } from "../../../../../constants/icons";
import services from "../../../../../api/index";

const mapStateToProps = (state) => {
  return {
    promotions: state.promotions,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onSetPlatforms: (price) => {
    dispatch({
      type: MANAGEMENT_PROMOTION_UPDATE_FIELD,
      key: "setPlatforms",
      value: price,
    });
  },
  onSetOS: (price) => {
    dispatch({
      type: MANAGEMENT_PROMOTION_UPDATE_FIELD,
      key: "setOS",
      value: price,
    });
  },
});

class SelectOsPlatform extends React.Component {
  constructor(props) {
    super(props);
    this.state = { platformList: [], isLoading: true };
    this.fetchData();
  }

  fetchData() {
    services.common
      .ManagementPlatforms()
      .then((res) => {
        this.setState({ platformList: res });
        return res;
      })
      .catch((err) => {
        if (err.response?.data?.message)
          toast.error(<div>{err.response?.data.message}</div>);
        return undefined;
      });
  }
  componentDidMount() {
    this.setState({ isLoading: false });
  }

  render() {
    return (
      <>
        {this.state.isLoading ? (
          <Loader />
        ) : (
          <>
            <div className="container">
              <div className="row justify-content-md-center">
                <PromotionHeader
                  title={this.props.title}
                  product={this.props.promotions.selectedProduct?.Name}
                  goToSelectGame={() => this.props.GoToSelectGame()}
                />
                <div className="row availables justify-content-md-center">
                  <div className="col available-item">
                    <label>
                      Available OS and Platforms from Publisher: &nbsp;
                    </label>
                    {this.props.promotions.selectedProduct.Systems.map(
                      (item, index) => {
                        return (
                          <span className="comma" key={index}>
                            {item}{" "}
                          </span>
                        );
                      }
                    )}
                  </div>
                </div>
                <div className="row os_platform_container">
                  <div className="col">
                    <div className="row">
                      <div className="col-xl-3  operating-system-text">
                        Select Operating System:
                      </div>
                      <div className="col-xl-3 col-sm-6 col-6">
                        <div
                          className={
                            "Select_OS " +
                            (this.props.promotions.setOS.includes(
                              osTypes.WINDOWS.text
                            )
                              ? "selected_os"
                              : "")
                          }
                          onClick={() => this.selectOS(osTypes.WINDOWS.text)}
                        >
                          <img src={Icons.Windows}></img>
                          <span>{osTypes.WINDOWS.text}</span>
                        </div>
                      </div>
                      <div className="col-xl-3 col-sm-6 col-6">
                        <div
                          className={
                            "Select_OS " +
                            (this.props.promotions.setOS.includes(
                              osTypes.MACOS.text
                            )
                              ? "selected_os"
                              : "")
                          }
                          onClick={() => this.selectOS(osTypes.MACOS.text)}
                        >
                          <img src={Icons.Macos}></img>
                          <span>{osTypes.MACOS.text}</span>
                        </div>
                      </div>
                      <div className="col-xl-3  col-sm-6 col-6">
                        <div
                          className={
                            "Select_OS " +
                            (this.props.promotions.setOS.includes(
                              osTypes.LINUX.text
                            )
                              ? "selected_os"
                              : "")
                          }
                          onClick={() => this.selectOS(osTypes.LINUX.text)}
                        >
                          <img src={Icons.Linux}></img>
                          <span>{osTypes.LINUX.text}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-3 available-platform-text">
                      Available Platforms:
                    </div>
                    <ul className="col-md-9 checkboxes">
                      {this.state.platformList?.map((item, index) => {
                        return (
                          <li className="form-check" key={index}>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value={item}
                              onChange={(ev) =>
                                this.selectPlatform(
                                  ev.target.value,
                                  ev.target.checked
                                )
                              }
                              defaultChecked={this.props.promotions.setPlatforms.includes(
                                item
                              )}
                              id={"chk_" + index}
                            />
                            <label htmlFor={"chk_" + index}>{item}</label>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </>
    );
  }

  selectPlatform(value, checked) {
    let arr = this.props.promotions.setPlatforms;
    if (checked) {
      arr.push(value);
    } else {
      arr = this.props.promotions.setPlatforms.filter((item) => {
        return item !== value;
      });
    }
    this.props.onSetPlatforms(arr);
  }

  selectOS(os) {
    let arr = this.props.promotions.setOS;
    if (arr.includes(os)) {
      arr = this.props.promotions.setOS.filter((item) => {
        return item !== os;
      });
    } else {
      arr.push(os);
    }
    this.props.onSetOS(arr);
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectOsPlatform);
