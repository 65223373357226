import React, { useEffect, useState } from "react";
import NumberFormat from "react-number-format";

const NumberFormatter = (props) => {
  const [offerPrice, setOfferPrice] = useState();
  const [offerQuantity, setOfferQuantity] = useState();

  useEffect(() => {
    if (props.offerQuantity) {
      setOfferPrice(props.offerPrice);
      setOfferQuantity(props.offerQuantity);
    } else {
      setOfferPrice(props.offerPrice);
      setOfferQuantity(1);
    }
  }, [props.offerPrice, props.offerQuantity]);

  return (
    <span>
      <NumberFormat
        thousandSeparator=" "
        value={(Math.round(offerPrice * offerQuantity * 100) / 100)
          .toString()
          .replace(".", ",")
          .replace(/(,[^,]*$)/, "")}
        displayType="text"
      />
      {props.supVisible && (
        <sup>
          {(Math.round(offerPrice * offerQuantity * 100) / 100)
            .toString()
            .replace(".", ",")
            .replace(/^[^,]*/, "")}
          {(Math.round(offerPrice * offerQuantity * 100) / 100)
            .toString()
            .replace(".", ",")
            .replace(/^[^,]*/, "").length < 1
            ? ",00"
            : (Math.round(offerPrice * offerQuantity * 100) / 100)
                .toString()
                .replace(".", ",")
                .replace(/^[^,]*/, "").length > 2
            ? ""
            : "0"}
          €
        </sup>
      )}
    </span>
  );
};

export default NumberFormatter;
