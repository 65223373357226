import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

import Modal from "../../../../../../../components/common/v2/modal";
import { OrderItemStatus } from "../../../../../../../constants/defaults";

import useOrderItem from "../../../hooks/useOrderItem";

const ModalOrderItemDelete = ({ data, userScope }) => {
  const { Id, Status } = data || {};
  const { onDeleteError } = useOrderItem({ data, userScope });

  if (![OrderItemStatus.Error].includes(Status)) {
    return null;
  }

  return (
    <Modal
      trigger={{ theme: "light", title: <i className="fas fa-trash"></i> }}
      accessibility={{
        close: {
          theme: "light",
        },
        submit: {
          onClick: onDeleteError,
          title: "Delete",
          theme: "danger",
        },
      }}
      title="Delete Error Item"
    >
      <div
        className="d-flex flex-column align-items-center gap-4"
        style={{ fontSize: 14 }}
      >
        <div className="d-flex flex-column align-items-center gap-2">
          <h6 className="m-0">Id</h6>
          <span>{Id}</span>
        </div>
        <div className="d-flex flex-column align-items-center gap-2">
          <h6 className="m-0">Status</h6>
          <span>{Status}</span>
        </div>
      </div>
    </Modal>
  );
};

export default ModalOrderItemDelete;
