import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

import { OrderStatus } from "../../../../constants/defaults";

const OrderStatusText = ({ classname, data }) => {
  const color =
    {
      [OrderStatus.Processing]: "yellowish-green",
      [OrderStatus.Confirmed]: "yellowish-green",
      [OrderStatus.Completed]: "yellowish-green",
      [OrderStatus.Refunded]: "yellowish-green",
      [OrderStatus.Refund]: "yellowish-green",
      [OrderStatus.Rebidding]: "mango",
      [OrderStatus.Revoked]: "mango",
      [OrderStatus.Withdraw]: "mango",
      [OrderStatus.OutOfStock]: "mango",
      [OrderStatus.Refused]: "carnation",
      [OrderStatus.Rejected]: "carnation",
      [OrderStatus.Failed]: "carnation",
      [OrderStatus.PromotionExpired]: "carnation",
      [OrderStatus.Submitted]: "dark-slate-blue",
      [OrderStatus.Closed]: "dark-slate-blue",
    }[data.Status] || "cloudy-blue";

  return (
    <span className={classNames("m-0 text-no-wrap", classname, color)}>
      {data.Status}
    </span>
  );
};

const propStyles = {};

const styles = {};

OrderStatusText.defaultProps = {};

OrderStatusText.propTypes = {};

export default OrderStatusText;
