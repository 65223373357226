import React from "react";
import { connect } from "react-redux";
import {
  MANAGEMENT_PROMOTION_PAGE_LOADED,
  REDIRECT,
} from "../../../constants/actionTypes";
import Pager from "../../../components/Pager";
import "react-datepicker/dist/react-datepicker.css";
import Loader from "../../../components/Loader";
import Filter from "../../../components/Filter";
import {
  dateTypes,
  defaultDateFormat,
  osTypes,
} from "../../../constants/defaults";
import { Endpoints } from "../../../constants/endpoints";
import { toast } from "react-toastify";
import { fixTimezoneOffset } from "../../../functions/dateConverter";
import Content from "../../../components/containers/Content";
import Button from "../../../components/common/Button";
import BlankPage from "../../../components/BlankPage";
import { getSorting } from "../../../functions/FilterFunctions";
import MerModal from "../../../components/common/MerModal";
import GoogleSheetConfirm from "./components/GoogleSheetConfirm";
import base64 from "base-64";
import { Icons } from "../../../constants/icons";
import moment from "moment";
import { Link } from "react-router-dom";
import services from "../../../api/index";

const mapStateToProps = (state) => {
  return {
    promotions: state.promotions,
    search: state.management.managementProductSearchText,
    redirectTo: state.common.redirectTo,
    pageSize: state.common.pageSize,
  };
};

const mapDispatchToProps = (dispatch) => ({
  promotionOnLoad: (payload) => {
    dispatch({ type: MANAGEMENT_PROMOTION_PAGE_LOADED, payload });
  },
  onRedirect: (redirectTo) => dispatch({ type: REDIRECT, redirectTo }),
});

class Promotions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: this.props.match.params.filter
        ? JSON.parse(base64.decode(this.props.match.params.filter))
        : null,
      sortBy: null,
      pageindex: 0,
      managementAllPlatforms: [],
      tableIsLoading: false,
      showConfirmModal: false,
    };
    this.timer = null;
  }

  componentDidMount() {
    document.title = "All Promotions";
    this.getAllPlatforms();
    this.selectPromotions();
  }

  shouldComponentUpdate() {
    let filter = this.props.match.params.filter;
    if (filter !== undefined && filter !== "MA==")
      if (base64.encode(JSON.stringify(this.state.filter)) !== filter) {
        this.setState({ filter: JSON.parse(base64.decode(filter)) });
      }
    return true;
  }

  selectPromotions() {
    let ev = this.state.filter;

    this.getCampaignsFromManagement({
      keywords: ev?.Text,
      PublisherIds: ev?.Publishers?.join(","),
      MinPrice: ev?.Price?.down,
      MaxPrice: ev?.Price?.up,
      validfrom: ev?.DateRange?.min,
      validto: ev?.DateRange?.max,
      operatingSystems: ev?.OperatingSystems?.join(","),
      platforms: ev?.Platforms?.join(","),
      pageindex: this.state.pageindex,
      SortBy: this.state.sortBy,
      providerIds: ev?.Providers?.join(","),
      Regions: ev?.Regions,
    });
  }

  getCampaignsFromManagement(params) {
    this.setState({ tableIsLoading: true });
    const {
      keywords = "",
      validfrom = "",
      validto = "",
      pageindex = this.state.pageindex,
      pagesize = this.props.pageSize,
      name = "",
      sku = "",
      mSku = "",
      ReleaseDateFrom = "",
      ReleaseDateTo = "",
      PublisherIds = "",
      SortBy = "",
      MinPrice = undefined,
      MaxPrice = undefined,
      operatingSystems = "",
      platforms = "",
      providerIds = "",
      Regions = [],
    } = params || {};
    this.setState({ pageindex: pageindex });
    return this.props.promotionOnLoad(
      Promise.all([
        services.campaigns.getCampaignsFromManagement(
          keywords,
          validfrom,
          validto,
          pageindex,
          pagesize,
          name,
          sku,
          mSku,
          ReleaseDateFrom,
          ReleaseDateTo,
          PublisherIds,
          MinPrice,
          MaxPrice,
          SortBy,
          operatingSystems,
          platforms,
          null,
          null,
          providerIds,
          Regions
        ),
      ])
        .then((res) => {
          this.setState({ tableIsLoading: false });
          return res[0];
        })
        .catch((err) => {
          if (err.response?.data?.message)
            toast.error(<div>{err.response?.data.message}</div>);
        })
    );
  }

  getAllPlatforms = () => {
    services.common
      .getManagementPlatforms()
      .then((response) => {
        this.setState({ managementAllPlatforms: response });
      })
      .catch((err) => {
        if (err.response?.data?.message)
          toast.error(<div>{err.response?.data.message}</div>);
        return undefined;
      });
    ("");
  };

  componentDidUpdate(prevProps) {
    if (prevProps.pageSize !== this.props.pageSize) {
      this.onFilter({ pageindex: 0 });
    }
  }

  createPromotionFromGoogleSheet = () => {
    services.googleSheet
      .createPromotionFromGoogleSheet()
      .then((res) => {
        toast.success(<div>Google sheet created!</div>);
        this.setState({ showConfirmModal: false });
        return res;
      })
      .catch((err) => {
        if (err.response?.data?.message)
          toast.error(<div>{err.response?.data.message}</div>);
      });
  };

  onFilter(params) {
    let { filter, sortBy = undefined, pageindex = 0 } = params || {};
    let ev = filter;
    if (ev !== undefined) this.setState({ filter: ev });
    else ev = this.state.filter;
    sortBy = getSorting(sortBy, this.state.sortBy);
    this.setState({ sortBy: sortBy });
    let url = Endpoints.Management.Promotions.url
      .replace(":filter?", base64.encode(ev ? JSON.stringify(ev) : "0"))
      .replace(":sort?", sortBy ? sortBy : "0")
      .replace(":page?", pageindex);
    this.props.onRedirect(url);

    this.getCampaignsFromManagement({
      keywords: ev?.Text,
      PublisherIds: ev?.Publishers?.join(","),
      MinPrice: ev?.Price?.down,
      MaxPrice: ev?.Price?.up,
      validfrom: ev?.DateRange?.min,
      validto: ev?.DateRange?.max,
      operatingSystems: ev?.OperatingSystems?.join(","),
      platforms: ev?.Platforms?.join(","),
      pageindex: pageindex,
      SortBy: sortBy,
      providerIds: ev?.Providers?.join(","),
      Regions: ev?.Regions,
    });
  }

  onChangePageIndex(pageindex) {
    this.setState({ tableIsLoading: true });
    this.onFilter({ pageindex: pageindex });
  }

  changePublishStatus = (promotionId) => {
    services.campaigns
      .changePublishStatus(promotionId)
      .then((res) => {
        toast.success(<div>Promotion status changed!</div>);
        return res;
      })
      .catch((err) => {
        if (err.response?.data?.message)
          toast.error(<div>{err.response?.data.message}</div>);
        return null;
      });
  };

  render() {
    const { promotions_management } = this.props.promotions;
    return (
      <Content>
        <div className="d-flex flex-row">
          <h1>Promotions</h1>
          <Button
            className="create_promotion_button mx-3 px-md-4"
            label="Create New Promotion"
            bindEvent={() => {
              this.props.onRedirect(Endpoints.Management.NewPromotions.url);
            }}
            buttonIcon={Icons.nullIcon}
            iconWidth={17}
            iconHeight={17}
          />
          <Button
            className="add-update-from-google-sheet"
            label=""
            bindEvent={() => this.setState({ showConfirmModal: true })}
            buttonIcon={Icons.addFromGoogleSheetIcon}
            iconWidth={20}
            iconHeight={20}
            registerTip="create-promotion-from-google-sheet"
            place="right"
            tooltipDesc="Create Promotion From Google Sheet"
          />
        </div>

        {promotions_management === undefined ? (
          <Loader />
        ) : (
          <div>
            <Filter
              filter={this.state.filter}
              onFilter={(ev) => this.onFilter(ev)}
              isPublisherAvailable
              // isRegionAvailable
              isProviderAvailable
              priceList={[
                { down: undefined, up: 25 },
                { down: 25, up: 50 },
                { down: 50, up: 100 },
                { down: 100, up: 200 },
                { down: 300, up: undefined },
              ]}
              dateList={[
                dateTypes.ALLTIME,
                dateTypes.THISWEEK,
                dateTypes.THISMONTH,
                dateTypes.LAST2MONTH,
              ]}
              platformList={this.state.managementAllPlatforms.map(
                (element) => element
              )}
              osList={[osTypes.WINDOWS, osTypes.LINUX, osTypes.MACOS]}
            ></Filter>
            {this.state.tableIsLoading ? (
              <Loader />
            ) : promotions_management.Items?.length === 0 ? (
              <BlankPage description="There is no promotion." />
            ) : (
              <div>
                <div className="table-total-count">
                  Total Count: {promotions_management.TotalCount}
                </div>
                <div className="table-responsive-xl">
                  <table
                    className="table table-centered table-nowrap table-hover mb-0 rounded table-responsive cursor-pointer"
                    id="datatable"
                  >
                    <thead className="thead-light d-short border-0">
                      <tr>
                        <th className="bg-transparent border-0 ">Publish</th>
                        <th
                          className="bg-transparent border-0  w-25"
                          onClick={() => {
                            this.setState({
                              sortName:
                                this.state.sortName === "up" ? "down" : "up",
                            });
                            this.onFilter({ sortBy: "Name" });
                          }}
                        >
                          Name - Msku
                          <i
                            className={`fas fa-sort-amount-${this.state.sortName} px-2`}
                          ></i>
                        </th>

                        <th className="bg-transparent border-0 ">
                          Provider Region
                        </th>
                        <th className="bg-transparent border-0 ">
                          Merkur Region
                        </th>
                        <th className="bg-transparent border-0 ">Publisher</th>
                        <th
                          className="bg-transparent border-0 "
                          onClick={() => {
                            this.setState({
                              startDate:
                                this.state.startDate === "up" ? "down" : "up",
                            });
                            this.onFilter({ sortBy: "ValidFrom" });
                          }}
                        >
                          Start Date
                          <i
                            className={`fas fa-sort-amount-${this.state.startDate} px-2`}
                          ></i>
                        </th>
                        <th
                          className="bg-transparent border-0 "
                          onClick={() => {
                            this.setState({
                              endDate:
                                this.state.endDate === "up" ? "down" : "up",
                            });
                            this.onFilter({ sortBy: "ValidTo" });
                          }}
                        >
                          End Date
                          <i
                            className={`fas fa-sort-amount-${this.state.endDate} px-2`}
                          ></i>
                        </th>
                        <th className="bg-transparent border-0 ">OS</th>
                        <th className="bg-transparent border-0 ">Platform</th>
                        <th className="bg-transparent border-0 ">Price</th>
                        <th className="bg-transparent border-0 ">
                          Stock Quantity
                        </th>
                        <th className="bg-transparent border-0 "></th>
                      </tr>
                    </thead>
                    <tbody>
                      {promotions_management.Items?.map((item, index) => {
                        return (
                          <tr
                            className="rounded-1 mb-1 align-middle border-bottom"
                            key={index}
                          >
                            <td className="ps-0">
                              <input
                                key={item.Id}
                                className="form-check-input"
                                type="checkbox"
                                defaultChecked={item.Published}
                                onChange={() =>
                                  this.changePublishStatus(item.Id)
                                }
                              />
                            </td>
                            <td className="ps-0 w-25">
                              <Link
                                to={Endpoints.Management.PromotionDetail.url.replace(
                                  ":" +
                                    Endpoints.Management.PromotionDetail.url.split(
                                      ":"
                                    )[1],
                                  item?.Id
                                )}
                                className="view-detail-link text-decoration-underline"
                              >
                                <p className="mb-0">
                                  {item.Product.Name ?? "-"}
                                </p>
                                <p className="mb-0">
                                  {item.Product.MSku ?? "-"}
                                </p>{" "}
                              </Link>
                            </td>
                            <td className="ps-0">
                              {item.Product.Region ?? "-"}
                            </td>
                            <td className="ps-0">{item.Region ?? "-"}</td>
                            <td className="ps-0">
                              {item.Product.Publisher ?? "-"}
                            </td>

                            <td className="ps-0">
                              {fixTimezoneOffset(
                                item.ValidFrom,
                                false
                              ).date.getFullYear() <= 1970 ? (
                                <p className="mb-0 text-center">-</p>
                              ) : (
                                <div>
                                  <p className="mb-0">
                                    {moment(
                                      fixTimezoneOffset(item.ValidFrom, false)
                                        .date
                                    ).format(defaultDateFormat)}
                                  </p>
                                  <p className="mb-0">
                                    {moment(
                                      fixTimezoneOffset(item.ValidFrom, false)
                                        .date
                                    ).format("\tH:mm:ss")}
                                  </p>
                                </div>
                              )}
                            </td>
                            <td className="ps-0">
                              {fixTimezoneOffset(
                                item.ValidTo,
                                false
                              ).date.getFullYear() <= 1970 ? (
                                <p className="mb-0 text-center">-</p>
                              ) : (
                                <div>
                                  <p className="mb-0">
                                    {moment(
                                      fixTimezoneOffset(item.ValidTo, false)
                                        .date
                                    ).format(defaultDateFormat)}
                                  </p>
                                  <p className="mb-0">
                                    {moment(
                                      fixTimezoneOffset(item.ValidTo, false)
                                        .date
                                    ).format("\tH:mm:ss")}
                                  </p>
                                </div>
                              )}
                            </td>
                            <td className="ps-0">
                              {item.OperatingSystems.join(", ") ?? "-"}
                            </td>
                            <td className="ps-0">
                              {item.Platforms.join(", ") ?? "-"}
                            </td>
                            <td className="ps-0">
                              <div className="d-flex flex-column">
                                <p className="mb-0">
                                  S: {item.Tier1Price ?? "-"}
                                </p>
                                <p className="mb-0">
                                  G: {item.Tier2Price ?? "-"}
                                </p>
                                <p className="mb-0">
                                  P: {item.Tier3Price ?? "-"}
                                </p>
                              </div>
                            </td>
                            <td>{item.Product.StockQuantity}</td>
                            <td className="ps-0">
                              <div className="pe-2">
                                <Button
                                  className="detail-button px-3"
                                  bindEvent={() => {
                                    this.props.onRedirect(
                                      Endpoints.Management.EditPromotion.url.replace(
                                        ":promotionid",
                                        item.Id
                                      )
                                    );
                                  }}
                                  label="Edit"
                                  buttonIcon={Icons.nullIcon}
                                  iconWidth={20}
                                  iconHeight={20}
                                ></Button>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <Pager
                  pageIndex={promotions_management.PageIndex}
                  totalPages={promotions_management.TotalPages}
                  totalCount={promotions_management?.TotalCount}
                  onChange={(pageIndex) => this.onChangePageIndex(pageIndex)}
                />
              </div>
            )}
            <MerModal
              id="order-confirm"
              showModal={this.state.showConfirmModal}
              ariaLabelledby="contained-modal-title-vcenter"
              onHide={() => this.setState({ showConfirmModal: false })}
            >
              <GoogleSheetConfirm
                handleClose={() => this.setState({ showConfirmModal: false })}
                createPromotionFromGoogleSheet={() =>
                  this.createPromotionFromGoogleSheet()
                }
              ></GoogleSheetConfirm>
            </MerModal>
          </div>
        )}
      </Content>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Promotions);
