import React, { useState } from "react";
import Button from "../../../../components/common/Button";
import { toast } from "react-toastify";
import { Icons } from "../../../../constants/icons";
import { CHANGE_ALL_MY_OFFERS_DETAILS } from "../../../../constants/actionTypes";
import { connect } from "react-redux";
import services from "../../../../api/index";
import settings from "../../../../settings.json";
import UtcDatePicker from "../../../../components/common/UtcDatePicker";
import moment from "moment";
import { fixTimezoneOffset } from "../../../../functions/dateConverter";

function MarkAsPaidModal(props) {
  const [selectedDate, setSelectedDate] = useState(undefined);
  return (
    <div className="container create-package-modal">
      <div className="d-flex my-4 mx-2 flex-column justify-content-start">
        <div className="go-back">
          <img src={Icons.goBackIcon} />
          <Button
            className="go-back-button"
            bindEvent={() => props.handleClose()}
            label="Go back"
          />
        </div>

        <div className="form-group m-auto py-3" style={{ width: "250px" }}>
          <UtcDatePicker
            disableTimeFilter={false}
            useMinTime
            selected={selectedDate?.date}
            timeIntervals={1}
            maxDate={fixTimezoneOffset(new Date(), true).date}
            onChange={(date) => {
              setSelectedDate(date);
            }}
            placeholderText="Payment Date"
          />
        </div>

        <Button
          disabled={selectedDate === undefined}
          bindEvent={() => {
            services.accounting
              .paymentplanMarkasPaid(
                props.item.Id,
                moment(selectedDate.date).format("YYYY-MM-DD HH:mm")
              )
              .then((res) => {
                props.handleClose();
              })
              .catch((err) => {
                if (err.response?.data?.message)
                  toast.error(<div>{err.response?.data.message}</div>);
                if (err.message) toast.error(<div>{err.message}</div>);
              });
          }}
          className="create-button mt-3"
          label="Mark as Paid"
          buttonIcon={Icons.bigThickIcon}
          iconWidth={20}
          iconHeight={20}
        />
      </div>
    </div>
  );
}
export default MarkAsPaidModal;
