import { COMPANIES_PAGE_LOADED } from "../constants/actionTypes";

const defaultState = {
  companyList: undefined,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case COMPANIES_PAGE_LOADED:
      return {
        ...state,
        companyList: action.payload,
      };
    default:
      return state;
  }
};
