import {
  MANAGEMENT_INVIOCE_PAGE_LOADED,
  PARTNER_INVIOCE_PAGE_LOADED,
  MANAGEMENT_CREDITNOTE_PAGE_LOADED,
} from "../constants/actionTypes";

const defaultState = {
  management_invoice: undefined,
  partner_invoice: undefined,
  credit_notes: undefined,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case MANAGEMENT_INVIOCE_PAGE_LOADED:
      return {
        ...state,
        management_invoice: action.payload,
      };

    case PARTNER_INVIOCE_PAGE_LOADED:
      return {
        ...state,
        partner_invoice: action.payload,
      };
    case MANAGEMENT_CREDITNOTE_PAGE_LOADED:
      return {
        ...state,
        credit_notes: action.payload,
      };
  }

  return state;
};
