import { OrderStatus } from "../constants/defaults";

export const getLatestOrder = (history, ispartner = true) => {
  let index = findLastIndex(
    history,
    "OrderStatus",
    ispartner ? OrderStatus.Submitted : OrderStatus.Rebidding
  );
  return index >= 0 ? history[index] : undefined;
};

export const getDemandedOrder = (history, ispartner = true) => {
  let index = findLastIndex(
    history,
    "OrderStatus",
    ispartner === false ? OrderStatus.Submitted : OrderStatus.Rebidding
  );
  return index >= 0 ? history[index] : undefined;
};

function findLastIndex(array, searchKey, searchValue) {
  let index = array
    ?.slice()
    .reverse()
    .findIndex((x) => x[searchKey] === searchValue);
  let count = array?.length - 1;
  let finalIndex = index >= 0 ? count - index : index;
  return finalIndex;
}
