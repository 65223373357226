/* eslint-disable no-underscore-dangle */
import {
  getLocalAccessToken,
  getLocalRefreshToken,
  setJwt,
} from "./services/token";
import axiosClient from "./axios";
import { store } from "../store";
import { LOGIN, LOGOUT, REDIRECT } from "../constants/actionTypes";
import settings from "../settings.json";

const API_ROOT = settings["API_ROOT"];

axiosClient.defaults.baseURL = API_ROOT;
axiosClient.defaults.headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
};
axiosClient.defaults.withCredentials = false;

axiosClient.interceptors.request.use(
  (config) => {
    const token = getLocalAccessToken();
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const { dispatch } = store;
axiosClient.interceptors.response.use(
  (res) => {
    if (res.status === 200) {
      return res;
    }
    return Promise.reject(res);
  },
  async (err) => {
    const originalConfig = err.config;

    if (originalConfig?.url !== "/auth/login" && err.response) {
      const localRefreshTokenExpireDate = parseInt(
        JSON.parse(localStorage.getItem("jwt"))?.RefreshTokenExpires
      );
      let currentDate = new Date().getTime() / 1000;

      // Access Token was expired
      if (
        err.response.status === 401 &&
        !originalConfig._retry &&
        localRefreshTokenExpireDate - currentDate > 0
      ) {
        originalConfig._retry = true;

        try {
          const localRefreshToken = getLocalRefreshToken();
          const rs = await axiosClient.post("/auth/refreshtoken", {
            RefreshToken: localRefreshToken,
          });
          const jwt = rs?.data;

          dispatch({ type: LOGIN, payload: jwt });
          setJwt(rs.data);

          return axiosClient(originalConfig);
        } catch (_error) {
          logout();
        }
      }

      // Access and Refresh tokens were expired
      else if (
        err.response.status === 401 &&
        localRefreshTokenExpireDate - currentDate <= 0
      ) {
        logout();
      }
    }

    return Promise.reject(err);
  }
);

const logout = () => {
  dispatch({ type: LOGOUT });
  dispatch({ type: REDIRECT, redirectTo: "/login" });
  localStorage.removeItem("jwt");
};

export default axiosClient;
