import React from "react";

const OrderBlendingTable = ({ data = [], order = {}, total = {} }) => {
  if (!order?.Blending || !data) return null;

  return (
    <>
      <table
        className="table table-centered table-nowrap table-hover mb-0 rounded table-responsive cursor-pointer"
        id="datatable"
      >
        <thead className="thead-light d-short border-0">
          <tr>
            <th className="bg-transparent border-0">Country Code</th>
            <th className="bg-transparent border-0">Planned Quantity</th>
            <th className="bg-transparent border-0">Planned Percentage</th>
            <th className="bg-transparent border-0">Promotion Percentage</th>
            <th className="bg-transparent border-0">Actual Percentage</th>
            <th className="bg-transparent border-0">Planned Cost</th>
            <th className="bg-transparent border-0">Actual Cost</th>
            <th className="bg-transparent border-0">Actual Count</th>
            <th className="bg-transparent border-0">Actual Total Cost</th>
          </tr>
        </thead>
        <tbody>
          {data.map((e) => {
            return (
              <tr key={e.Code}>
                <td className="border-end">{e.Code}</td>
                <td>{e.Quantity}</td>
                <td>{e.Percentage}%</td>
                <td>{e.Promotion?.Percentage}%</td>
                <td className="border-end">{e.Actual?.Percentage}%</td>
                <td>
                  {e.Currency}
                  {e.Cost}
                </td>
                <td className="border-end">
                  {e.Currency}
                  {e.Actual?.Cost}
                </td>
                <td>{e.Actual?.Count}</td>
                <td>
                  {e.Currency}
                  {e.Actual?.TotalCost}
                </td>
              </tr>
            );
          })}
        </tbody>
        <tfoot className="border-top">
          <tr>
            <td className="border-end"></td>
            <td className="fw-bold">Planned Quantity Total</td>
            <td className="fw-bold">Planned Percentage Total</td>
            <td className="fw-bold">Promotion Percentage Total</td>
            <td className="border-end fw-bold">Actual Percentage Total</td>
            <td className="fw-bold">Avg. Planned Cost</td>
            <td className="border-end fw-bold">Avg. Actual Cost</td>
            <td className="fw-bold">Actual Count Total</td>
            <td className="fw-bold">Actual Cost Total</td>
          </tr>
          <tr>
            <td className="border-end"></td>
            <td>{total.Quantity}</td>
            <td>{total.Percentage}%</td>
            <td>{total.Promotion?.Percentage}%</td>
            <td className="border-end">{total.Actual?.Percentage}%</td>
            <td>
              {total.Currency}
              {total.AvgCost}
            </td>
            <td className="border-end">
              {total.Currency}
              {total.Actual?.AvgCost}
            </td>
            <td>{total.Actual?.Count}</td>
            <td>
              {total.Currency}
              {total.Actual?.TotalCost}
            </td>
          </tr>
        </tfoot>
      </table>
    </>
  );
};

const PromotionBlendingTable = ({ data = [], order = {}, total = {} }) => {
  if (order?.Blending || !data) return null;

  return (
    <table
      className="table table-centered table-nowrap table-hover mb-0 rounded table-responsive cursor-pointer"
      id="datatable"
    >
      <thead className="thead-light d-short border-0">
        <tr>
          <th className="bg-transparent border-0">Country Code</th>
          <th className="bg-transparent border-0">Estimated Percentage</th>
          <th className="bg-transparent border-0">Actual Percentage</th>
          <th className="bg-transparent border-0">Estimated Cost</th>
          <th className="bg-transparent border-0">Actual Cost</th>
          <th className="bg-transparent border-0">Actual Count</th>
          <th className="bg-transparent border-0">Actual Total Cost</th>
        </tr>
      </thead>
      <tbody>
        {data.map((e) => {
          return (
            <tr key={e.Code}>
              <td className="border-end">{e.Code}</td>
              <td>{e.Promotion?.Percentage}%</td>
              <td className="border-end">{e.Actual?.Percentage}%</td>
              <td>
                {e.Currency}
                {e.Promotion?.Cost}
              </td>
              <td className="border-end">
                {e.Currency}
                {e.Actual?.Cost}
              </td>
              <td>{e.Actual?.Count}</td>
              <td>
                {e.Currency}
                {e.Actual?.TotalCost}
              </td>
            </tr>
          );
        })}
      </tbody>
      <tfoot className="border-top">
        <tr>
          <td className="border-end"></td>
          <td className="fw-bold">Estimated Percentage Total</td>
          <td className="border-end fw-bold">Actual Percentage Total</td>
          <td className="fw-bold">Avg. Estimated Cost</td>
          <td className="border-end fw-bold">Avg. Actual Cost</td>
          <td className="fw-bold">Actual Count Total</td>
          <td className="fw-bold">Actual Cost Total</td>
        </tr>
        <tr>
          <td className="border-end"></td>
          <td>{total.Promotion?.Percentage}%</td>
          <td className="border-end">{total.Actual?.Percentage}%</td>
          <td>
            {total.Currency}
            {total.Promotion?.AvgCost}
          </td>
          <td className="border-end">
            {total.Currency}
            {total.Actual?.AvgCost}
          </td>
          <td>{total.Actual?.Count}</td>
          <td>
            {total.Currency}
            {total.Actual?.TotalCost}
          </td>
        </tr>
      </tfoot>
    </table>
  );
};

const BlendingTable = ({ data, order, promotion, total }) => {
  if (!data) {
    return <span>Blending data is not provided</span>;
  }

  if (!promotion) {
    return <span>Promotion data is not provided</span>;
  }

  return (
    <div className="table-responsive">
      <OrderBlendingTable data={data} order={order} total={total} />
      <PromotionBlendingTable data={data} order={order} total={total} />
    </div>
  );
};

export default BlendingTable;
