import React, { useState } from "react";
import classNames from "classnames";
import Collapse from "../collapse";

const CollapseTabHeader = ({ selected, onSelect, tabs = [] }) => (
  <div
    className="d-flex gap-2"
    // style={{ borderRadius: "0.5rem", overflow: "hidden" }}
  >
    {tabs?.map((item, i) => (
      <span
        className={classNames("button-v2 px-2 py-1 cursor-pointer")}
        key={item.key}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          onSelect(i);
        }}
        style={{
          borderBottom: "2px solid",
          ...(selected === i
            ? {
                // backgroundColor: "#3a6fffbb",
                borderColor: "#3a6fff",
                color: "#3a6fff",
              }
            : {
                // backgroundColor: "#6c757dbb",
                borderColor: "#6c757d",
                color: "#6c757d",
              }),
        }}
      >
        {item.title}
      </span>
    ))}
  </div>
);

const CollapseTab = ({ disabled, tabs = [] }) => {
  const [tab, setTab] = useState(() => 0);

  if (!tabs?.length) {
    return <span>Tabs data is not provided</span>;
  }

  return (
    <Collapse
      disabled={disabled}
      header={{ selected: tab, onSelect: (i) => setTab(i), tabs }}
      HeaderItem={CollapseTabHeader}
    >
      {tabs[tab]?.Item}
    </Collapse>
  );
};

export default CollapseTab;
