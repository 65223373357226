import React from "react";
import Button from "../../../../components/common/Button";
import NumberInput from "../../../../components/common/NumberInput";
import { toast } from "react-toastify";
import { Icons } from "../../../../constants/icons";
import { CHANGE_ALL_MY_OFFERS_DETAILS } from "../../../../constants/actionTypes";
import { connect } from "react-redux";
import services from "../../../../api/index";
import Loader from "../../../../components/Loader";
import settings from "../../../../settings.json";

const SYSTEM_PARTNER_ID = settings["SYSTEM_PARTNER_ID"];
const mapStateToProps = (state) => {
  return {
    InventoryCheckResult: state.order.InventoryCheckResult,
    SelectedInventory: state.order.SelectedInventory,
  };
};

const mapDispatchToProps = (dispatch) => ({
  InventoriesLoad: (payload) => {
    dispatch({ type: CHANGE_ALL_MY_OFFERS_DETAILS, payload });
  },
  InventoryCheck: (value) => {
    dispatch({
      type: CHANGE_ALL_MY_OFFERS_DETAILS,
      key: "InventoryCheckResult",
      value,
    });
  },
});

const orderTypes = {
  Inventory: "Inventory",
  InventoryStandard: "InventoryStandard",
};

class InventoryOrderSubmitModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      quantity: undefined,
      price: undefined,
      selectedPartner: undefined,
      selectedPartnerName: undefined,
      partners: [],
      loader: false,
      type:
        this.props.SelectedInventory?.InventoryItems?.filter(
          (f) => f.Type === orderTypes.Inventory
        ).length != 0
          ? orderTypes.Inventory
          : orderTypes.InventoryStandard,
    };
  }

  componentDidMount() {
    this.getPartners();
    this.InventoryCheck();
  }

  getPartners = (params) => {
    const { keywords = "", pageindex = 0, pagesize = 50 } = params || {};
    Promise.all([services.company.getCompanies(keywords, pageindex, pagesize)])
      .then((res) => {
        this.setState({
          partners: res[0].Items.filter((f) => f.Id !== SYSTEM_PARTNER_ID),
        });
        return res;
      })
      .catch((err) => {
        if (err.response?.data?.message)
          toast.error(<div>{err.response?.data.message}</div>);
        return undefined;
      });
  };

  submitOrder = () => {
    this.setState({ loader: true });
    if (!this.state.selectedPartner) {
      toast.error(<div>You must select partner !</div>);
    } else {
      Promise.all([
        services.orders.submitOrderFromInventory(
          this.state.selectedPartner,
          this.props.selectedItem.ProductSku,
          this.state.price,
          this.state.quantity,
          this.props.selectedItem.ProductStore,
          this.state.type
        ),
      ])
        .then((res) => {
          this.setState({ loader: false });

          this.props.handleClose();
          toast.success(
            <div>The inventory order was successfully created.</div>
          );
          return res;
        })
        .catch((err) => {
          this.setState({ loader: false });

          if (err.response?.data?.message)
            toast.error(<div>{err.response?.data.message}</div>);
          return undefined;
        });
    }
  };

  InventoryCheck = (type = this.state.type) => {
    Promise.all([
      services.orders.inventoryCheck(this.props.selectedItem.ProductSku, type),
    ]).then((res) => {
      this.props.InventoryCheck(res[0].Items);
    });
  };

  render() {
    return (
      <div className="container create-package-modal">
        <div className="d-flex my-4 mx-2 flex-column justify-content-start">
          <div className="go-back">
            <img src={Icons.goBackIcon} />
            <Button
              className="go-back-button"
              bindEvent={() => this.props.handleClose()}
              label="Go back"
            />
          </div>
          <div className="create-package-header">
            <span>Inventory Order</span>
          </div>
          {this.state.loader ? <Loader></Loader> : <></>}
          <div className="col filter-publisher filter-partner-select-box">
            <div className="btn-group pe-2 pt-1 rounded-1 align-items-center d-block">
              <button
                type="button"
                className="btn btn-transparent shadow-none dropdown-toggle mr-2 dropdown-toggle-split"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                data-bs-auto-close="outside"
                id="users"
                style={
                  this.state.selectedPartner !== undefined
                    ? { color: "#264EA7" }
                    : { color: "#A4B9E8" }
                }
              >
                <span className="mx-1">
                  {this.state.selectedPartnerName ?? "Partner"}
                </span>
              </button>

              <div className="radio-filter">
                <div className="form-check form-check-inline d-inline-block">
                  <input
                    className="form-check-input"
                    name="type"
                    type="radio"
                    value={orderTypes.Inventory}
                    id="type_1"
                    onChange={() => {
                      this.setState({ type: orderTypes.Inventory });
                      this.InventoryCheck(orderTypes.Inventory);
                    }}
                    checked={this.state.type === orderTypes.Inventory}
                    disabled={
                      this.props.SelectedInventory?.InventoryItems?.filter(
                        (f) => f.Type === orderTypes.Inventory
                      ).length === 0
                    }
                  />
                  <label
                    className="form-check-label fw-filter"
                    htmlFor="type_1"
                  >
                    Inventory
                  </label>
                </div>

                <div className="form-check form-check-inline d-inline-block">
                  <input
                    className="form-check-input"
                    name="type"
                    type="radio"
                    value={orderTypes.InventoryStandard}
                    id="type_2"
                    onChange={() => {
                      this.setState({ type: orderTypes.InventoryStandard });
                      this.InventoryCheck(orderTypes.InventoryStandard);
                    }}
                    checked={this.state.type === orderTypes.InventoryStandard}
                    disabled={
                      this.props.SelectedInventory?.InventoryItems?.filter(
                        (f) => f.Type === orderTypes.InventoryStandard
                      ).length === 0
                    }
                  />
                  <label
                    className="form-check-label fw-filter"
                    htmlFor="type_2"
                  >
                    Standart
                  </label>
                </div>
              </div>
              <div className="dropdown-menu border-0" aria-labelledby="Users">
                <div className="input-group flex-nowrap align-items-center search px-3">
                  <input
                    onChange={(ev) => {
                      this.getPartners({ keywords: ev.target.value });
                    }}
                    type="text"
                    className="form-control bg-white border-0 rounded-0 shadow-none "
                    placeholder="Search"
                    aria-label="Publisher"
                    aria-describedby="addon-wrapping"
                  />
                </div>
                <div className="partner-filter-list p-2">
                  {this.state.partners &&
                    this.state.partners?.map((item, key) => {
                      return (
                        <div className="form-check" key={key}>
                          <input
                            className="form-check-input"
                            name="flexRadioDefaultUser"
                            type="checkbox"
                            value={item.Id}
                            id={"user" + item.Id}
                            key={key}
                            onChange={() =>
                              this.setState({
                                selectedPartner: item.Id,
                                selectedPartnerName: item.Name,
                              })
                            }
                            checked={this.state.selectedPartner === item.Id}
                          />
                          <label
                            className="form-check-label fw-filter"
                            htmlFor={"user" + item.Id}
                          >
                            {`${item.Name}`}
                          </label>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>

          <div className="modal-number-inputs">
            <NumberInput
              label="Quantity"
              centerAlign
              value={this.state.quantity}
              onChange={(ev) => this.setState({ quantity: ev })}
              visible
              intNumber
              maxValue={
                this.props.SelectedInventory?.InventoryItems?.filter(
                  (f) => f.Type === this.state.type
                )[0]?.AvailableCount
              }
              minValue={0}
            />
          </div>
          <div className="create-package-sub-description">
            <span>
              Please enter an amount that is{" "}
              {
                this.props.SelectedInventory?.InventoryItems?.filter(
                  (f) => f.Type === this.state.type
                )[0]?.AvailableCount
              }{" "}
              QTY or less than.
            </span>
          </div>

          <div className="modal-number-inputs">
            <NumberInput
              label="Price"
              centerAlign
              value={this.state.price}
              onChange={(ev) => this.setState({ price: ev })}
              visible
              maxValue={10000}
            />
          </div>
          <div className="create-package-sub-description">
            <span>
              <label>Avg. Cost: </label>
              {
                this.props.SelectedInventory?.InventoryItems?.filter(
                  (f) => f.Type === this.state.type
                )[0]?.AvgCost
              }{" "}
            </span>
          </div>

          {this.props.InventoryCheckResult != undefined && (
            <>
              <div className="pb-3">
                <div>
                  <label>Total Qnt:</label>{" "}
                  {
                    this.props.SelectedInventory?.InventoryItems?.filter(
                      (f) => f.Type === this.state.type
                    )[0]?.AvailableCount
                  }
                </div>
                <div>
                  <label>Available Qnt:</label>{" "}
                  {this.props.SelectedInventory?.InventoryItems?.filter(
                    (f) => f.Type === this.state.type
                  )[0]?.AvailableCount -
                    this.props.InventoryCheckResult?.map(
                      (inv) => inv.History[0].OfferQuantity
                    ).reduce((previousValue, currentValue) => {
                      return previousValue + currentValue;
                    }, 0)}
                </div>
              </div>
              <div>
                {this.props.InventoryCheckResult?.map((inv, index) => {
                  return (
                    <div key={index}>
                      {inv.History[0].ModifiedByName}:{" "}
                      {inv.History[0].OfferQuantity} x{" "}
                      {inv.History[0].OfferPrice}€{" - "}
                      {inv.OrderNumber}
                    </div>
                  );
                })}
              </div>
            </>
          )}

          <Button
            bindEvent={() => this.submitOrder()}
            className="create-button mt-3"
            label="Submit Order"
            disabled={
              this.state.price === undefined ||
              !this.state.quantity ||
              this.state.selectedPartner === undefined ||
              this.props.selectedItem.Count < this.state.quantity
            }
            buttonIcon={Icons.bigThickIcon}
            iconWidth={20}
            iconHeight={20}
          />
        </div>
      </div>
    );
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InventoryOrderSubmitModal);
