import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

import { OrderItemStatus } from "../../../../constants/defaults";

const OrderItemStatusText = ({ classname, data }) => {
  const color =
    {
      [OrderItemStatus.Draft]: "cloudy-blue",
      [OrderItemStatus.Processing]: "mango",
      [OrderItemStatus.PreCompleted]: "mango",
      [OrderItemStatus.BackOrdered]: "mango",
      [OrderItemStatus.Completed]: "yellowish-green",
      [OrderItemStatus.OutOfStock]: "dark-slate-blue",
      [OrderItemStatus.Error]: "carnation",
    }[data.Status] || "cloudy-blue";

  return (
    <span className={classNames("m-0 text-no-wrap", classname, color)}>
      {data.Status}
    </span>
  );
};

const propStyles = {};

const styles = {};

OrderItemStatusText.defaultProps = {};

OrderItemStatusText.propTypes = {};

export default OrderItemStatusText;
