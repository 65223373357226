import React from "react";
import { waitTimeOut } from "../../functions/FilterFunctions";

function PartnerFilter(props) {
  const { partners, filter, onPartnerClear, onFilterPartner } = props || {};
  return (
    <>
      {props.isPartnerAvailable === true && (
        <div className="filter-price d-block">
          <div className="btn-group pe-2 pt-1 rounded-1 align-items-center">
            <button
              type="button"
              className="btn btn-transparent shadow-none dropdown-toggle mr-2 dropdown-toggle-split"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              data-bs-auto-close="outside"
              id="users"
              style={
                props.filter.PartnerId.length > 0
                  ? { color: "#264EA7" }
                  : { color: "#A4B9E8" }
              }
            >
              <span className="mx-1 text">Partner</span>
              <span className="badge">
                {props.filter.PartnerId.length > 0 && "1"}
              </span>
            </button>
            <div className="dropdown-menu border-0" aria-labelledby="Users">
              <div className="input-group flex-nowrap align-items-center search px-3">
                <input
                  onChange={(ev) => {
                    const keywords = ev.target.value;
                    waitTimeOut(() => {
                      onFilterPartner({ keywords: keywords });
                    });
                  }}
                  type="text"
                  className="form-control bg-white border-0 rounded-0 shadow-none "
                  placeholder="Search"
                  aria-label="Publisher"
                  aria-describedby="addon-wrapping"
                />
              </div>
              <div className="partner-filter-list p-2">
                {partners &&
                  partners
                    ?.filter((f) => f.Status === "Active")
                    .map((item, key) => {
                      return (
                        <div className="form-check" key={key}>
                          <input
                            className="form-check-input"
                            name="flexRadioDefaultUser"
                            type={
                              props.singlePartnerFilter ? "radio" : "checkbox"
                            }
                            value={item.Id}
                            id={"user" + item.Id}
                            onChange={(ev) => props.onPartnerChecked(ev)}
                            checked={props.filter.PartnerId.includes(item.Id)}
                          />
                          <label
                            className="form-check-label fw-filter"
                            htmlFor={"user" + item.Id}
                          >
                            {`${item.Name}`}
                          </label>
                        </div>
                      );
                    })}
              </div>
              {filter.PartnerId.length > 0 && (
                <div className="m-2">
                  <button
                    onClick={onPartnerClear}
                    className="button filter-actions clear-all"
                  >
                    Clear
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default PartnerFilter;
