import React, { useState } from "react";
import Button from "../../../../components/common/Button";
import NumberInput from "../../../../components/common/NumberInput";
import { toast } from "react-toastify";
import { Icons } from "../../../../constants/icons";
import services from "../../../../api/index";
import UtcDatePicker from "../../../../components/common/UtcDatePicker";
import moment from "moment";
import { fixTimezoneOffset } from "../../../../functions/dateConverter";

function CreatePaymentPlanModal(props) {
  const [amount, setAmount] = useState(props.item ? props.item.Amount : 0);
  const [isPaid, setIsPaid] = useState(
    props.item ? props.item.Status === "Paid" : false
  );
  const [note, setNote] = useState(props.item ? props.item.Note : "");
  const [paymentDate, setPaymentDate] = useState(
    props.item
      ? props.item.PaymentDate
        ? fixTimezoneOffset(new Date(props.item.PaymentDate))
        : undefined
      : undefined
  );
  const [plannedPaymentDate, setPlannedPaymentDate] = useState(
    props.item
      ? props.item.PlannedPaymentDate
        ? fixTimezoneOffset(new Date(props.item.PlannedPaymentDate))
        : undefined
      : undefined
  );
  return (
    <div className="container order-confirm-modal">
      <div className="d-flex my-4 mx-2 flex-column justify-content-start">
        <div className="go-back">
          <img src={Icons.goBackIcon} />
          <Button
            className="go-back-button"
            bindEvent={() => props.handleClose()}
            label="Go back"
          />
        </div>
        <div className="my-5">
          <div className="d-flex">
            <div className="form-group user-input">
              <label for="exampleFormControlTextarea1">Payment Date:</label>
              <UtcDatePicker
                useMinTime
                selected={paymentDate?.date}
                timeIntervals={1}
                onChange={(date) => {
                  setPaymentDate(date);
                  setIsPaid(true);
                }}
                maxDate={fixTimezoneOffset(new Date(), true).date}
                placeholderText="Payment Date"
                disableTimeFilter={false}
              />
            </div>
            <div className="form-group user-input">
              <label>Planned Payment Date:</label>
              <UtcDatePicker
                useMaxTime
                selected={plannedPaymentDate?.date}
                timeIntervals={1}
                onChange={(date) => {
                  setPlannedPaymentDate(date);
                }}
                minDate={fixTimezoneOffset(new Date(), true).date}
                placeholderText="Planned Payment Date"
                disableTimeFilter={false}
              />
            </div>
          </div>
          <div className="d-flex ">
            <div className="form-group user-input w-50">
              <div style={{ width: "250px" }}>
                {" "}
                <NumberInput
                  name="Amount"
                  label="Amount"
                  value={amount}
                  onChange={(ev) => setAmount(ev)}
                  visible
                  maxValue={100000000000}
                  minValue={0}
                  intNumber
                  notPadding
                  centerAlign
                ></NumberInput>
              </div>
            </div>
            <div className=" w-50">
              {paymentDate !== undefined && (
                <div
                  className="form-check user-input  py-2 px-0 m-0"
                  style={{ width: "210px" }}
                >
                  <input
                    id="ispaid"
                    type="checkbox"
                    className="m-1"
                    checked={isPaid}
                    disabled={paymentDate === undefined}
                    onChange={(ev) => {
                      if (!ev.target.checked) {
                        setIsPaid(false);
                        setPaymentDate(undefined);
                      } else setIsPaid(true);
                    }}
                  ></input>
                  <label htmlFor="ispaid">Is Paid?</label>
                </div>
              )}
            </div>
          </div>
          <div className="form-group mx-6">
            <label for="exampleFormControlTextarea1">Note:</label>
            <textarea
              className="form-control"
              rows="3"
              onChange={(ev) => setNote(ev.target.value)}
            >
              {note}
            </textarea>
          </div>
        </div>
        <Button
          bindEvent={async () => {
            const params = {
              id: props.item?.Id,
              partnerId: props.partnerId,
              amount: amount,
              isPaid: paymentDate ? true : isPaid,
              paymentDate:
                paymentDate &&
                moment(paymentDate.date).format("YYYY-MM-DD HH:mm"),
              plannedPaymentDate:
                plannedPaymentDate &&
                moment(plannedPaymentDate.date).format("YYYY-MM-DD HH:mm"),
              note: note,
            };
            if (!props.item) {
              Promise.all([await services.accounting.paymentplanCreate(params)])
                .then((res) => {
                  props.handleClose();
                })
                .catch((err) => {
                  if (err.response?.data?.message)
                    toast.error(<div>{err.response?.data.message}</div>);
                  if (err.message) toast.error(<div>{err.message}</div>);
                });
            } else {
              Promise.all([await services.accounting.paymentplanUpdate(params)])
                .then((res) => {
                  props.handleClose();
                })
                .catch((err) => {
                  if (err.response?.data?.message)
                    toast.error(<div>{err.response?.data.message}</div>);
                  if (err.message) toast.error(<div>{err.message}</div>);
                });
            }
          }}
          className="create-button mt-3"
          label={props.item ? "Update" : "Create"}
          buttonIcon={Icons.bigThickIcon}
          iconWidth={20}
          iconHeight={20}
          disabled={
            paymentDate === undefined && plannedPaymentDate === undefined
          }
        />
      </div>
    </div>
  );
}
export default CreatePaymentPlanModal;
