import React, { useState } from "react";
import Button from "../../../../../components/common/Button";
import NumberInput from "../../../../../components/common/NumberInput";
import { toast } from "react-toastify";
import { Icons } from "../../../../../constants/icons";
import services from "../../../../../api/index";

const CreatePackageModal = (props) => {
  const [availableQuantity, setAvailableQuantity] = useState(
    props.orderItem?.CompletedQuantity - props.orderItem?.DeliveredQuantity
  );
  const [packageQuantity, setPackageQuantity] = useState(
    availableQuantity >= 1000 ? 1000 : availableQuantity
  );
  const createPackage = (orderId) => {
    services.orders
      .createPackage(orderId, packageQuantity)
      .then(() => {
        toast.success(<div>Packages create successfully</div>);
        props.refreshItem(props.orderItem?.Id);
        props.handleClose();
      })
      .catch((err) => {
        if (err.response?.data?.message)
          toast.error(<div>{err.response?.data.message}</div>);
      });
  };
  return (
    <div className="container create-package-modal">
      <div className="d-flex my-4 mx-2 flex-column justify-content-start">
        <div className="go-back">
          <img src={Icons.goBackIcon} />
          <Button
            className="go-back-button"
            bindEvent={() => props.handleClose()}
            label="Go back"
          />
        </div>
        <div className="create-package-header">
          <span>Create Package</span>
        </div>
        <div className="create-package-description">
          <span>
            You can use the Create Package process to get the product keys part
            by part.
          </span>
        </div>
        <div className="modal-number-inputs">
          <NumberInput
            label="Quantity"
            value={packageQuantity}
            onChange={(ev) => {
              if (ev > 1000) return;
              setPackageQuantity(ev);
            }}
            visible
            intNumber
            maxValue={1000}
            minValue={0}
          />
        </div>
        <div className="create-package-sub-description">
          <span>
            Please enter an amount that is {availableQuantity} QTY or less than.
          </span>
        </div>
        <Button
          bindEvent={() => [
            createPackage(props.orderId),
            setAvailableQuantity(availableQuantity - packageQuantity),
          ]}
          className="create-button"
          label="Create Package"
          disabled={packageQuantity === ""}
          buttonIcon={Icons.bigThickIcon}
          iconWidth={20}
          iconHeight={20}
        />
      </div>
    </div>
  );
};

export default CreatePackageModal;
