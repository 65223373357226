import React from "react";
import { Modal } from "react-bootstrap";

const MerModal = (props) => {
  return (
    <Modal
      className="d-flex flex-row justify-content-center"
      size={props.size ?? "lg"}
      id={props.id}
      show={props.showModal}
      onHide={props.onHide}
      aria-labelledby={props.ariaLabelledby}
      centered
      scrollable={props.scrollable}
    >
      <Modal.Body className="p-0">{props.children}</Modal.Body>
    </Modal>
  );
};

export default MerModal;
