import React from "react";
import NumberInput from "../../../../../components/common/NumberInput";
import { MANAGEMENT_PROMOTION_UPDATE_FIELD } from "../../../../../constants/actionTypes";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import PromotionHeader from "../../../../../components/common/PromotionHeader";
import Loader from "../../../../../components/Loader";
import { fixTimezoneOffset } from "../../../../../functions/dateConverter";
import { defaultDateFormat } from "../../../../../constants/defaults";
import moment from "moment";
import UtcDatePicker from "../../../../../components/common/UtcDatePicker";

const mapStateToProps = (state) => {
  return {
    promotions: state.promotions,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onChangeMinQuantity: (value) =>
    dispatch({
      type: MANAGEMENT_PROMOTION_UPDATE_FIELD,
      key: "minQuantity",
      value,
    }),
  onChangeMaxQuantity: (value) =>
    dispatch({
      type: MANAGEMENT_PROMOTION_UPDATE_FIELD,
      key: "maxQuantity",
      value,
    }),
  onValidTo: (value) => {
    dispatch({
      type: MANAGEMENT_PROMOTION_UPDATE_FIELD,
      key: "validTo",
      value,
    });
  },
  onValidFrom: (value) => {
    dispatch({
      type: MANAGEMENT_PROMOTION_UPDATE_FIELD,
      key: "validFrom",
      value,
    });
  },
});

class SetPromotion extends React.Component {
  constructor() {
    super();
    this.state = {
      isLoading: true,
    };
  }

  componentDidMount() {
    this.setState({ isLoading: false });
  }

  render() {
    let { minQuantity, maxQuantity, validTo, validFrom } =
      this.props.promotions;
    validFrom = validFrom.date.getFullYear() < 2000 ? "" : validFrom;
    validTo = validTo.date.getFullYear() < 2000 ? "" : validTo;
    return (
      <>
        {this.state.isLoading ? (
          <Loader />
        ) : (
          <>
            <PromotionHeader
              product={this.props.promotions.selectedProduct?.Name}
              title={this.props.title}
              goToSelectGame={() => this.props.GoToSelectGame()}
            />
            <div className="container mb-5">
              <div className="set-promotion-number-inputs d-flex flex-row justify-content-center pb-4">
                <div className="min-quantity d-flex flex-column align-items-center">
                  <NumberInput
                    id="promotion-detail"
                    visible
                    value={minQuantity}
                    label="Min Quantity"
                    onChange={(ev) => {
                      this.props.onChangeMinQuantity(ev);
                      parseInt(minQuantity) > parseInt(maxQuantity) ||
                      ev === undefined
                        ? this.props.onChangeMaxQuantity(parseInt(ev) + 1)
                        : parseInt(maxQuantity);
                    }}
                    intNumber
                    minValue={1}
                    maxValue={10000}
                    centerAlign
                  ></NumberInput>
                  <div className="min-max-description text-center w-50">
                    {minQuantity < 1 ? (
                      <span>
                        Minimum order quantity should be equal or more than 10.
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="max-quantity d-flex flex-column align-items-center">
                  <NumberInput
                    id="promotion-detail"
                    visible
                    value={parseInt(maxQuantity)}
                    label="Max Quantity"
                    onChange={(ev) => this.props.onChangeMaxQuantity(ev)}
                    intNumber
                    minValue={parseInt(minQuantity) + 1}
                    maxValue={10000}
                    centerAlign
                  ></NumberInput>
                  <div className="min-max-description text-center w-75">
                    {minQuantity > maxQuantity ? (
                      <span>
                        The maximum order quantity should not be less than the
                        minimum quantity.
                      </span>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="d-flex flex-column align-items-center justify-content-center flex-lg-row pb-5">
                <div className="d-flex flex-column date-input-left-label align-items-center ">
                  <label htmlFor="validFrom">
                    {this.props.promotions.selectedProduct.Provider.Name + " "}
                    Start Date <sup>UTC</sup>
                  </label>
                  <div className="time-info d-flex flex-row mx-4 mx-md-0">
                    <span>
                      {this.props.promotions.selectedProduct.ValidFrom
                        ? moment(
                            fixTimezoneOffset(
                              this.props.promotions.selectedProduct.ValidFrom,
                              true
                            ).date
                          ).format(defaultDateFormat + "\tHH:mm:ss")
                        : "00-00-0000 00:00:00"}
                    </span>
                  </div>
                </div>
                <div className="d-flex flex-column date-input-right-label align-items-center ">
                  <label htmlFor="validTo">
                    {this.props.promotions.selectedProduct.Provider.Name + " "}
                    End Date <sup>UTC</sup>
                  </label>

                  <div className="time-info d-flex flex-row mx-4 mx-md-0 ">
                    <span>
                      {this.props.promotions.selectedProduct.ValidTo
                        ? moment(
                            fixTimezoneOffset(
                              this.props.promotions.selectedProduct.ValidTo,
                              true
                            ).date
                          ).format(defaultDateFormat + "\tHH:mm:ss")
                        : "00-00-0000 00:00:00"}
                    </span>
                  </div>
                </div>
                <div className="d-flex flex-column date-input-right-label align-items-center ">
                  <label htmlFor="releaseDate">
                    Game Release Date <sup>UTC</sup>
                  </label>
                  <div className="time-info d-flex flex-row mx-4 mx-md-0">
                    <span>
                      {moment(
                        fixTimezoneOffset(
                          this.props.promotions.selectedProduct.ReleaseDate,
                          true
                        ).date
                      ).format(defaultDateFormat + "\tHH:mm:ss")}
                    </span>
                  </div>
                </div>
              </div>

              <div className="d-flex flex-column flex-lg-row  align-items-center justify-content-between justify-content-lg-center">
                <div className="d-flex flex-column  date-input-left-label align-items-center ">
                  <label htmlFor="validFrom">
                    Merkur Start Date <sup>UTC</sup>
                  </label>
                  <div className="d-flex flex-row mx-3">
                    <UtcDatePicker
                      useMinTime
                      maxDate={new Date(validTo.date).getTime()}
                      selected={validFrom.date}
                      timeIntervals={30}
                      onChange={(date) => this.props.onValidFrom(date)}
                      placeholderText="Start Date"
                      disableTimeFilter={validTo.date ? false : true}
                    />
                  </div>
                </div>
                <div className="d-flex flex-column date-input-right-label align-items-center ">
                  <label htmlFor="validTo">
                    Merkur End Date <sup>UTC</sup>
                  </label>

                  <div className="d-flex flex-row mx-3">
                    <UtcDatePicker
                      useMaxTime
                      minDate={validFrom.date}
                      selected={validTo.date}
                      timeIntervals={30}
                      onChange={(date) => {
                        if (validFrom?.date < date?.date)
                          this.props.onValidTo(date);
                        else {
                          this.props.onValidTo(
                            fixTimezoneOffset(
                              new Date(validFrom?.date).getTime() +
                                1000 * 60 * 30
                            )
                          );
                        }
                      }}
                      placeholderText="End Date"
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(SetPromotion);
