export default (req) => ({
  getAllFromManagement: (keywords, pageindex, pagesize) =>
    req.get("/provider/management/Search", {
      params: {
        Keywords: keywords,
        PageIndex: pageindex,
        PageSize: pagesize,
      },
    }),
  getFromManagement: (id) => req.get("/provider/management/" + id),
});
