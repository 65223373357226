import React from "react";
import Button from "../../../../../components/common/Button";
import { Icons } from "../../../../../constants/icons";

const OrderConfirm = (props) => {
  return (
    <div className="order-confirm-modal">
      <div className="order-confirm-icon">
        <img src={Icons.maskIcon}></img>
      </div>
      <div className="order-confirm-title">
        <label>Are you sure want to aprove this order?</label>
      </div>
      <div className="order-confirm-description">
        <span>If you close this popup, your order status is not change.</span>
      </div>
      <div>
        <Button
          className="order-confirm-yes-button"
          bindEvent={() => props.approveOrder()}
          label="Approve Order"
          buttonIcon={Icons.thickIcon}
        />
      </div>
      <div>
        <Button
          className="order-confirm-no-button"
          bindEvent={() => props.handleClose()}
          label="Go Back"
          buttonIcon={Icons.leftArrowIcon}
        />
      </div>
    </div>
  );
};

export default OrderConfirm;
