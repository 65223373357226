import React, { useEffect, useState } from "react";
import Content from "../../components/containers/Content";
import services from "../../api/index";
import Loader from "../../components/Loader";
import Filter from "../../components/Filter";
import { OrderStatus } from "../../constants/defaults";
import { GET_ORDERS } from "../../constants/actionTypes";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import Pager from "../../components/Pager";
import { Endpoints } from "../../constants/endpoints";
import { getSorting } from "../../functions/FilterFunctions";
import base64 from "base-64";
import Button from "../../components/common/Button";
import { Icons } from "../../constants/icons";
import MerModal from "../../components/common/MerModal";
import moment from "moment";

const mapStateToProps = (state) => {
  return {
    pageSize: state.common.pageSize,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onRedirect: (redirectTo) => dispatch({ type: GET_ORDERS, redirectTo }),
});

function UnInvoicedOrders(props) {
  const [pageIndex, setPageIndex] = useState(
    props.match.params.page ? props.match.params.page : 0
  );
  const [resultpageIndex, setResultpageIndex] = useState(0);
  const [myfilter, setFilter] = useState(
    props.match.params.filter
      ? JSON.parse(base64.decode(props.match.params.filter))
      : null
  );
  const [sortby, setSortBy] = useState(props.match.params.sort);
  const [selectedOrderIds, setSelectedOrderIds] = useState([]);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [showConfirm, setShowConfirm] = useState(false);
  const [orderList, setOrderList] = useState(null);

  useEffect(() => {
    document.title = "Uninvoiced Orders";
  }, []);

  useEffect(() => {
    let ev = myfilter;

    getAllManagement({
      keywords: ev?.Text,
      pageindex: pageIndex,
      pagesize: props.pageSize,
      partnerIds: ev?.PartnerId?.length !== 0 ? ev?.PartnerId?.join(",") : "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.pageSize]);

  const getAllManagement = (params) => {
    const {
      keywords = "",
      pageindex = pageIndex,
      pagesize = 10,
      partnerIds = "",
      orderStatus = "",
    } = params || {};
    setPageIndex(pageindex);
    services.orders
      .getPartnerUnInvoicedOrders(
        pageindex,
        pagesize,
        orderStatus.length <= 0
          ? `${OrderStatus.Closed}, ${OrderStatus.Completed}`
          : orderStatus,
        keywords,
        "",
        "",
        "",
        "",
        "",
        "",
        false,
        partnerIds
      )
      .then((res) => {
        return setOrderList(res);
      })
      .catch((err) => {
        if (err.response?.data?.message)
          toast.error(<div>{err.response?.data.message}</div>);
        return undefined;
      });
  };

  const onFilter = (params) => {
    let { filter = undefined, sortBy = undefined, pageindex = 0 } = params;
    let ev = filter;
    if (ev !== undefined) setFilter(ev);
    else ev = myfilter;
    sortBy = getSorting(sortBy, sortby);
    setSortBy(sortBy);

    let url = Endpoints.Management.UnInvoicedOrders.url
      .replace(":filter?", base64.encode(ev ? JSON.stringify(ev) : "0"))
      .replace(":sort?", sortBy ? sortBy : "0")
      .replace(":page?", pageindex);
    props.onRedirect(url);

    getAllManagement({
      keywords: ev?.Text,
      pageindex: pageindex,
      pagesize: props.pageSize,
      partnerIds: ev?.PartnerId?.length !== 0 ? ev?.PartnerId?.join(",") : "",
      orderStatus: ev?.Status?.length !== 0 ? ev?.Status?.join(",") : "",
    });
  };

  const onChangePageIndex = (pageindex) => {
    onFilter({ pageindex: pageindex });
  };

  const selectOrder = (item) => {
    if (selectedOrderIds.filter((o) => o === item.Id).length === 0) {
      setSelectedOrderIds((current) => [...current, item.Id]);
      setSelectedOrders((current) => [...current, item]);
    } else {
      let selectedorderids = selectedOrderIds.filter((o) => o !== item.Id);
      let selectedorders = selectedOrders.filter((o) => o.Id !== item.Id);
      setSelectedOrders(selectedorders);
      setSelectedOrderIds(selectedorderids);
      if (selectedorders.length === 0) {
        setShowConfirm(false);
      }
    }
  };

  return (
    <Content pageTitle="My Uninvoiced Orders">
      {orderList === undefined ? (
        <Loader />
      ) : (
        <div>
          <Filter
            filter={myfilter}
            onFilter={(ev, sortBy) => onFilter(ev, sortBy)}
            removeSystemUser
            statusList={[
              OrderStatus.Completed,
              OrderStatus.Closed,
              OrderStatus.Processing,
            ]}
            statusVisible
          ></Filter>

          <div>
            <div className="table-responsive-xl p-0">
              <table
                className="table table-centered table-nowrap mb-0 rounded table-responsive cursor-pointer"
                style={{ minWidth: "1200px" }}
                id="datatable"
              >
                <thead className="thead-light d-short border-0">
                  <tr>
                    <th className="bg-transparent border-0 ps-2"></th>
                    <th className="bg-transparent border-0 ps-2">Order No</th>
                    <th className="bg-transparent border-0 ps-2">
                      Order Status
                    </th>
                    <th className="bg-transparent border-0 ps-2">Order Date</th>
                    <th className="bg-transparent border-0 ps-2">
                      Customer Name
                    </th>
                    <th className="bg-transparent border-0 ps-2">Product</th>
                    <th className="bg-transparent border-0 ps-2">Quantity</th>
                    <th className="bg-transparent border-0 ps-2">
                      Total Amount
                    </th>
                    <th className="bg-transparent border-0 ps-2">SKU</th>
                  </tr>
                </thead>
                <tbody>
                  {orderList &&
                    orderList.Items.map((item, index) => {
                      return (
                        <tr
                          className={
                            "rounded-1 mb-1 align-middle border-bottom not-use-hover " +
                            (selectedOrderIds.filter((o) => o === item.Id)
                              .length > 0
                              ? "selected-row"
                              : "")
                          }
                          onClick={() => selectOrder(item)}
                          key={index}
                        >
                          <td>
                            <input
                              type="checkbox"
                              checked={
                                selectedOrderIds.filter((o) => o === item.Id)
                                  .length > 0
                              }
                              onClick={(event) => {
                                event.stopPropagation();
                              }}
                              onChange={(event) => {
                                event.stopPropagation();
                                selectOrder(item);
                              }}
                            />
                          </td>
                          <td className="ps-2">{item.OrderNumber}</td>
                          <td className="ps-2">{item.Status}</td>
                          <td className="ps-2">
                            {moment
                              .utc(item.CreatedAt)
                              .format("DD/MM/YYYY - HH:mm:ss")}
                          </td>
                          <td className="ps-2">{item.Partner.Name}</td>
                          <td className="ps-2">{item.Product.ProductName}</td>
                          <td className="ps-2">{item.Quantity}</td>
                          <td className="ps-2">{item.TotalIncVatAmount}</td>
                          <td className="ps-2">{item.Product.ProductSku}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
            <Pager
              pageIndex={orderList?.PageIndex}
              totalPages={orderList?.TotalPages}
              totalCount={orderList?.TotalCount}
              onChange={(pageindex) => onChangePageIndex(pageindex)}
            />
          </div>

          <Button
            className="confirm-button px-3 float-end"
            bindEvent={() => {
              setShowConfirm(true);
            }}
            label={"Create Invoice for " + selectedOrderIds.length + " Orders"}
            disabled={
              selectedOrders.length === 0 || selectedOrders.length >= 100
                ? true
                : false
            }
          />
        </div>
      )}
      <MerModal
        id="order-confirm"
        showModal={showConfirm}
        ariaLabelledby="contained-modal-title-vcenter"
        onHide={() => setShowConfirm(false)}
        scrollable
        size="xl"
      >
        <div className="container order-modal">
          <div className="d-flex my-4 mx-2 flex-column justify-content-start">
            <div className="go-back">
              <img src={Icons.goBackIcon} />
              <Button
                className="go-back-button"
                bindEvent={() => setShowConfirm(false)}
                label="Go back"
              />
            </div>
            <div className="text-center">
              <h2>Selected Orders</h2>
            </div>
            <div className="table-responsive-xl px-5 py-4 h-100">
              <table
                className="table table-centered table-nowrap table-hover mb-0 rounded table-responsive cursor-pointer "
                id="datatable"
              >
                <thead className="thead-light d-short border-0">
                  <tr>
                    <th className="bg-transparent border-0 ps-2">Order No</th>
                    <th className="bg-transparent border-0 ps-2">
                      Customer Name
                    </th>
                    <th className="bg-transparent border-0 ps-2">Product</th>
                    <th className="bg-transparent border-0 ps-2">Quantity</th>
                    <th className="bg-transparent border-0 ps-2">
                      Total Amount
                    </th>
                    <th className="bg-transparent border-0 ps-2">SKU</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedOrderIds !== undefined &&
                    selectedOrderIds
                      .slice(
                        resultpageIndex * props.pageSize,
                        (resultpageIndex + 1) * props.pageSize
                      )
                      .map((order, index) => {
                        let item = selectedOrders.filter(
                          (f) => f.Id === order
                        )[0];
                        return (
                          <tr key={index}>
                            <td className="ps-2">{item.OrderNumber}</td>
                            <td className="ps-2">{item.Partner.Name}</td>
                            <td className="ps-2">{item.Product.ProductName}</td>
                            <td className="ps-2">{item.Quantity}</td>
                            <td className="ps-2">{item.TotalIncVatAmount}</td>
                            <td className="ps-2">{item.Status}</td>
                            <td className="ps-2">
                              <a
                                type="button"
                                className="button text px-2 remove-bulk-order"
                                onClick={() => {
                                  selectOrder(item);
                                }}
                              >
                                <span className="mx-1">Remove</span>
                              </a>
                            </td>
                          </tr>
                        );
                      })}
                </tbody>
              </table>
              <Pager
                pageIndex={resultpageIndex}
                totalPages={Math.round(selectedOrders.length / props.pageSize)}
                totalCount={selectedOrders.length}
                onChange={(pageindex) => setResultpageIndex(pageindex)}
              />
            </div>
            <div>
              <button
                className="order-confirm-yes-button float-end"
                onClick={() => {
                  services.orders
                    .myCreateInvoice(selectedOrderIds.join(","))
                    .then((res) => {
                      setSelectedOrderIds([]);
                      setSelectedOrders([]);
                      setShowConfirm(false);
                      toast.success(
                        <div>
                          Your transaction has been completed successfully.
                        </div>
                      );
                      let ev = myfilter;

                      getAllManagement({
                        keywords: ev?.Text,
                        pageindex: pageIndex,
                        pagesize: props.pageSize,
                        partnerIds:
                          ev?.PartnerId?.length !== 0
                            ? ev?.PartnerId?.join(",")
                            : "",
                      });
                    })
                    .catch((err) => {
                      if (err.response?.data?.message)
                        toast.error(<div>{err.response?.data.message}</div>);
                    });
                }}
              >
                Approve
              </button>
            </div>
          </div>
        </div>
      </MerModal>
    </Content>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(UnInvoicedOrders);
