import React, { useEffect, useState } from "react";
import { common } from "../constants/defaults";
import { connect } from "react-redux";
const mapStateToProps = (state) => {
  return {
    userScope: state.auth.currentUser.scope,
  };
};

function NavMenu(props) {
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    let isative = false;
    for (let i = 0; i < props.children?.length; i++) {
      isative = props.children[i].props.url === window.location.pathname;
      if (isative) break;
    }
    setIsActive(isative);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log("test 1");
  console.log(props);
  console.log(props.userScope[0]);
  console.log(props.accessScope?.includes(props.userScope[0]));
  console.log(common);
  if (
    props.accessScope?.includes(props.userScope[0]) ||
    props.accessScope === common
  )
    console.log("test 2");

  if (
    props.accessScope?.includes(props.userScope[0]) ||
    props.accessScope === common
  )
    return (
      <li className="nav-item">
        <div
          className={
            "nav-link-container " + (isActive === false ? "collapsed " : "")
          }
          data-bs-toggle="collapse"
          data-bs-target={"#" + props.MenuId}
          aria-expanded={isActive}
          onClick={() => {
            setIsActive(!isActive);
          }}
        >
          <div className="nav-link d-flex align-items-center ">
            <span className="sidebar-icon">
              <img src={props.icon} alt={props.item} width={props.width} />
            </span>
            <span className="mt-1 ms-1 sidebar-text p-0 m-0 d-flex align-items-center">
              {props.text}
            </span>

            <span className="link-arrow px-4 pt-1">
              <span
                className={
                  !isActive ? "fas fa-chevron-right" : "fas fa-chevron-down"
                }
              ></span>
            </span>
          </div>
        </div>

        <div
          className={"multi-level collapse " + (isActive ? "show" : "")}
          role="list"
          id={props.MenuId}
          aria-expanded="false"
        >
          <ul className="flex-column nav ms-2">{props.children}</ul>
        </div>
      </li>
    );
  else return <></>;
}

export default connect(mapStateToProps)(NavMenu);
