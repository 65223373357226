/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Content from "../../components/containers/Content";
import { connect } from "react-redux";
import services from "../../api/index";
import { Tab, Tabs } from "react-bootstrap";
import {
  PERMISSIONS_CHANGED,
  PERMISSIONS_LOADED,
  ALL_PERMISSION_LOADED,
} from "../../constants/actionTypes";
import { Icons } from "../../constants/icons";
import Button from "../../components/common/Button";
import { waitTimeOut } from "../../functions/FilterFunctions";

const mapStateToProps = (state) => {
  return {
    permissions: state.permission.all,
    allpermissions: state.permission.allpermissions,
    selectedGroup: state.permission.selectedGroup,
  };
};

const mapDispatchToProps = (dispatch) => ({
  PermissionOnLoad: (payload) => {
    dispatch({ type: PERMISSIONS_LOADED, payload });
  },
  AllPermissionOnLoad: (payload) => {
    dispatch({ type: ALL_PERMISSION_LOADED, payload });
  },
  ChangedPermissionGroup: (payload) => {
    dispatch({ type: PERMISSIONS_CHANGED, payload });
  },
});

function PermissionGroup(props) {
  const [permissionGroups, setPermissionGroups] = useState([]);
  const [selectedGroupId, setSelectedGroupId] = useState("-1");
  const [permissionList, setPermissionList] = useState([]);
  const [contenteditable, setContenteditable] = useState(false);
  const [searchGroup, setSearchGroup] = useState("");

  useEffect(() => {
    document.title = "Permission Group";
  }, []);

  let nameInput;
  const searchPermissionGroup = (params) => {
    const {
      keywords = searchGroup,
      pageindex = 0,
      pagesize = 10,
    } = params || {};
    services.user
      .searchPermissionGroup(pageindex, pagesize, keywords)
      .then((res) => {
        setPermissionGroups(res.Items);
        if (selectedGroupId === "-1") GetPermissionGroup(res.Items[0]?.Id);
      });
  };

  const getPermissions = () => {
    services.user.allPermissions().then((res) => {
      let permissions = res.reduce((result, word) => {
        const letter = word.split(".")[0];
        result[letter] = result[letter] || [];
        result[letter].push(word);

        return result;
      }, {});

      props.AllPermissionOnLoad(res);
      props.PermissionOnLoad(permissions);
    });
  };

  useEffect(() => {
    getPermissions();
    searchPermissionGroup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ChangedPermissionGroup = (pGroup) => {
    setPermissionList(pGroup.Permissions);
    props.ChangedPermissionGroup(pGroup);
  };

  const GetPermissionGroup = (Id) => {
    setSelectedGroupId(Id);

    services.user.getByIdPermissionGroup(Id).then((res) => {
      ChangedPermissionGroup(res);
    });
  };

  return (
    <Content pageTitle="Permission Group">
      <div className="permission-container">
        <div className="d-flex">
          <ul className="list-group list-group-flush permission_groups">
            <li className="list-group-item permission_search">
              <div className="d-flex flex-wrap justify-content-end">
                <div className="form-search btn-group rounded-1 align-items-center">
                  <input
                    className="form-control bg-light border-0 shadow-none"
                    type="search"
                    placeholder="Search"
                    onChange={(ev) => {
                      setSearchGroup();
                      let keywords = ev.target.value;

                      waitTimeOut(() => {
                        searchPermissionGroup({ keywords: keywords });
                      }, 300);
                    }}
                    aria-label="Search"
                    value={searchGroup}
                  />
                  <i className="fa-icon">
                    <img src={Icons.filterSearchIcon} />
                  </i>
                </div>
              </div>
            </li>
            {permissionGroups?.map((m, index) => (
              // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
              <li
                value={m.Id}
                className={
                  "list-group-item p-4 cursor-pointer	permission-list-item " +
                  (selectedGroupId === m.Id && "list-active")
                }
                onClick={() => {
                  GetPermissionGroup(m.Id);
                }}
              >
                {m.Name}
              </li>
            ))}
          </ul>
          <div>
            <div className="d-flex justify-content-between">
              <Button
                className="permission-button ms-4 mt-0"
                bindEvent={() => {
                  setSelectedGroupId("-1");
                  ChangedPermissionGroup({
                    Name: "New Permission Group",
                    Permissions: [],
                  });
                }}
                label="Create New Group"
              />
              <div
                className={
                  "d-flex align-items-stretch p-2 " +
                  (permissionGroups.length === 0 && "disabledbutton ")
                }
              >
                <p
                  onKeyPress={(ev) => {
                    let selectedGroup = props.selectedGroup;
                    if (ev.target.innerHTML.length <= 25) {
                      selectedGroup.Name = ev.target.innerHTML;
                      props.ChangedPermissionGroup(selectedGroup);
                    } else {
                      setContenteditable(false);
                    }
                    if (ev.charCode == 13) {
                      ev.preventDefault();
                    }
                  }}
                  contentEditable={contenteditable}
                  className="h3"
                  onPaste={(ev) => {
                    ev.preventDefault();
                  }}
                  onBlur={(ev) => {
                    setContenteditable(false);
                  }}
                  ref={(input) => {
                    nameInput = input;
                  }}
                >
                  {props.selectedGroup.Name}
                </p>

                <div
                  onClick={() => {
                    setContenteditable(true);
                    nameInput?.focus();
                  }}
                >
                  <img
                    src={Icons.permissionEditIcon}
                    width="25"
                    height="25"
                    className="m-1 ms-3 edit-group-name"
                  ></img>
                </div>
              </div>

              <div
                className={
                  "d-flex " +
                  (permissionGroups.length === 0 && "disabledbutton ")
                }
              >
                <ul className="d-flex flex-column me-2">
                  <li
                    className="d-flex permission-background permission-select-all"
                    style={{ listStyle: "none" }}
                  >
                    <label
                      className="form-check-input-permission m-0  cursor-pointer	"
                      htmlFor="selectall"
                    >
                      Select All
                    </label>
                    <input
                      className="form-check-input form-check-input-reverse"
                      type="checkbox"
                      checked={
                        props.allpermissions.filter((p) =>
                          props.selectedGroup.Permissions.includes(p)
                        ).length === props.allpermissions.length
                      }
                      onChange={(ev) => {
                        let sGroup = { ...props.selectedGroup };

                        if (ev.target.checked) {
                          Object.keys(props.permissions || {}).map((group) =>
                            props.permissions[group].forEach((element) => {
                              if (!sGroup.Permissions.includes(element))
                                sGroup.Permissions.push(element);
                            })
                          );
                        } else {
                          Object.keys(props.permissions || {}).map((group) =>
                            props.permissions[group].forEach((element) => {
                              if (sGroup.Permissions.includes(element))
                                sGroup.Permissions.splice(
                                  sGroup.Permissions.indexOf(element),
                                  1
                                );
                            })
                          );
                        }
                        ChangedPermissionGroup(sGroup);
                      }}
                      id="selectall"
                    />
                  </li>
                </ul>
                <div
                  className={
                    "permission-thrash me-4 " +
                    (selectedGroupId === "-1" && "disabledbutton")
                  }
                  onClick={() => {
                    services.user
                      .deletePermission(selectedGroupId)
                      .then((res) => {
                        setSelectedGroupId("-1");
                        toast.success(
                          <div>PermissionGroup deleted successfuly!</div>
                        );
                        searchPermissionGroup();
                      })
                      .catch((err) => {
                        if (err.response?.data?.message)
                          toast.error(<div>{err.response?.data.message}</div>);
                      });
                  }}
                >
                  <img
                    src={Icons.permissionThrashIcon}
                    width="25"
                    height="25"
                  ></img>
                </div>
              </div>
            </div>
            {Object.keys(props.permissions || {}).map((group, groupindex) => (
              <div
                className={
                  "d-flex p-3 permission_conten permission-background m-4 " +
                  (permissionGroups.length === 0 && "disabledbutton ")
                }
              >
                <div className="permission_title">
                  <p class="h4">{group}</p>
                </div>
                <ul className="checkboxes_permission w-100">
                  {props.permissions &&
                    props.permissions[group].map((m, index) => (
                      <li className="form-check" key={index + m}>
                        <input
                          id={m}
                          className="form-check-input"
                          type="checkbox"
                          value={m}
                          checked={permissionList.includes(m)}
                          onChange={(ev) => {
                            let sGroup = { ...props.selectedGroup };

                            if (ev.target.checked) {
                              sGroup.Permissions.push(ev.target.value);
                            } else
                              sGroup.Permissions.splice(
                                sGroup.Permissions.indexOf(ev.target.value),
                                1
                              );

                            ChangedPermissionGroup(sGroup);
                          }}
                        />
                        <label className="form-check-label" htmlFor={m}>
                          {m.split(".")[1]}
                        </label>
                      </li>
                    ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
        <Button
          className="permission-button  m-4 float-end"
          bindEvent={() => {
            if (selectedGroupId === "-1") {
              services.user
                .createPermissionGroup({
                  Name: props.selectedGroup.Name,
                  Permissions: permissionList,
                })
                .then((res) => {
                  toast.success(<div>PermissionGroup added successfuly!</div>);
                  searchPermissionGroup();
                })
                .catch((err) => {
                  if (err.response?.data?.message)
                    toast.error(<div>{err.response?.data.message}</div>);
                });
            } else {
              services.user
                .updatePermissionGroup(selectedGroupId, {
                  Name: props.selectedGroup.Name,
                  Permissions: permissionList,
                })
                .then((res) => {
                  toast.success(
                    <div>PermissionGroup updated successfuly!</div>
                  );
                  searchPermissionGroup();
                })
                .catch((err) => {
                  if (err.response?.data?.message)
                    toast.error(<div>{err.response?.data.message}</div>);
                });
            }
          }}
          label={selectedGroupId === "-1" ? "Create New Group" : "Update Group"}
        />
      </div>
    </Content>
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(PermissionGroup);
