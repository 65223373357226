import React from "react";

const PriceModal = (props) => {
  return (
    <div className="table-responsive-xl px-5 py-4 h-100">
      <table className="table table-centered table-nowrap table-hover mb-0 rounded table-responsive cursor-pointer card-table">
        <thead>
          <tr>
            <th>
              <p>Region</p>
            </th>
            <th>
              <p>Local Cost</p>
            </th>
            <th>
              <p>Local Price</p>
            </th>
            <th>
              <p>Cost (€)</p>
            </th>
            <th>
              <p>List Price (€)</p>
            </th>
            <th>
              <p>Sale Price (€)</p>
            </th>
            <th>
              <p>Discount Rate</p>
            </th>
            <th>
              <p>Start</p>
            </th>
            <th>
              <p>End</p>
            </th>
          </tr>
        </thead>
        <tbody>
          {props.selectedProduct.length === 0 ? (
            <label className="order-detail-table-no-item">
              There is no available price !
            </label>
          ) : (
            props.selectedProduct.map((item, index) => {
              return (
                <tr key={index}>
                  <td>
                    <p>{item.OriginalCurrency ?? "-"}</p>
                  </td>
                  <td>
                    <p>
                      {item.OriginalCost + "(" + item.OriginalCurrency + ")" ??
                        "-"}
                    </p>
                  </td>
                  <td>
                    <p>
                      {item.OriginalPrice + "(" + item.OriginalCurrency + ")" ??
                        "-"}
                    </p>
                  </td>
                  <td>
                    <p>{item.Cost ?? "-"}</p>
                  </td>
                  <td>
                    <p>{item.OldPrice ?? "-"}</p>
                  </td>
                  <td>
                    <p>{item.Price ?? "-"}</p>
                  </td>
                  <td>
                    <p>{item.DiscountRate ?? "-"}</p>
                  </td>
                  <td>
                    <p>{item.ValidFrom ?? "-"}</p>
                  </td>
                  <td>
                    <p>{item.ValidTo ?? "-"}</p>
                  </td>
                </tr>
              );
            })
          )}
        </tbody>
      </table>
    </div>
  );
};

export default PriceModal;
