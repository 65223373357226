import React, { useState } from "react";
import { waitTimeOut } from "../../functions/FilterFunctions";

function InventoryFilter(props) {
  const [searchText, setSearchText] = useState("");
  const {
    inventories,
    filter,
    onIventoriesClear,
    onIventoryChecked,
    onFilterInventories,
  } = props || {};

  if (inventories === undefined) return <></>;

  if (filter?.SelectedInventory === "") {
    filter.SelectedInventory = inventories[0];
  }
  return (
    <>
      {inventories === undefined && <></>}
      {inventories && (
        <div className="filter-price d-block">
          <div className="btn-group pe-2 pt-1 rounded-1 align-items-center">
            <button
              type="button"
              className="btn btn-transparent shadow-none dropdown-toggle mr-2 dropdown-toggle-split "
              data-bs-toggle="dropdown"
              aria-expanded="false"
              data-bs-auto-close="outside"
              id="Inventories"
              style={
                filter.SelectedInventory !== ""
                  ? { color: "#264EA7" }
                  : { color: "#ff0000" }
              }
            >
              <span className="mx-1 text">
                {filter.SelectedInventory === ""
                  ? "Inventories"
                  : filter.SelectedInventory}
              </span>
            </button>

            <div className="dropdown-menu border-0 p-1 py-1">
              <div className="input-group flex-nowrap align-items-center search px-3">
                <input
                  onChange={(ev) => {
                    const keywords = ev.target.value;
                    setSearchText(keywords);
                    waitTimeOut(() => {
                      onFilterInventories({ keywords: keywords });
                    });
                  }}
                  type="text"
                  className="form-control bg-white border-0 rounded-0 shadow-none "
                  placeholder="Search"
                  aria-label="Publisher"
                  aria-describedby="addon-wrapping"
                  value={searchText}
                />
              </div>
              <div className="Inventories-filter-list px-3 py-4 pb-3">
                <div className="d-flex justify-content-between"></div>
                <div className="platform-list overflow-auto">
                  {inventories.map((item, index) => {
                    return (
                      <div className="form-check" key={index}>
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexCheckboxDefaultPlatform"
                          value={item}
                          checked={filter?.SelectedInventory === item}
                          id={`inventory_${index}`}
                          onChange={(ev) => {
                            onIventoryChecked(ev);
                          }}
                        />
                        <label
                          className="form-check-label fw-filter"
                          htmlFor={`inventory_${index}`}
                        >
                          {item}
                        </label>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="d-flex flex-row m-2">
                {filter.Inventories !== "" && (
                  <button
                    onClick={() => {
                      setSearchText("");
                      onIventoriesClear();
                      onFilterInventories({ keywords: "" });
                    }}
                    className="button filter-actions clear-all"
                  >
                    Clear
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default InventoryFilter;
