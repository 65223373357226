import {
  CHANGE_PRICE_DETAILS_BASKET,
  ADD_TO_BASKET,
  GET_BASKET,
  SELECT_BASKET_ITEM,
  SET_BASKET_DETAILS,
} from "../constants/actionTypes";

const defaultState = {
  selectedItem: undefined,
  basket: undefined,
  Quantity: 0,
  DemandPrice: 0,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case CHANGE_PRICE_DETAILS_BASKET:
      return {
        ...state,
        [action.key]: action.value,
      };
    case ADD_TO_BASKET:
      return {
        ...state,
        add_to_cart: action.payload,
      };
    case GET_BASKET:
      return {
        ...state,
        basket: action.payload,
      };
    case SELECT_BASKET_ITEM:
      return {
        ...state,
        selectedItem: action.value,
      };
    case SET_BASKET_DETAILS:
      return {
        ...state,
        [action.key]: action.value,
      };
  }
  return state;
};

/**
 *  return {
                ...state,
                basket: action.key === "    "
                    ? state
                        .basket
                        .map(basket => basket = {
                            ...basket,
                            Items: basket
                                .Items
                                .map(basketItem => basketItem.ProductId === action.ProductId
                                    ? {
                                        ...basketItem,
                                        Units: action.value
                                    }
                                    : basketItem)
                        })
                    : (action.key === "DemandPrice"
                        ? state.basket.map(basket => basket = {
                            ...basket,
                            Items: basket
                                .Items
                                .map(basketItem => basketItem.ProductId === action.ProductId
                                    ? {
                                        ...basketItem,
                                        DemandPrice: action.value
                                    }
                                    : basketItem)
                        })
                        : state.basket)
            };
 */
