import React, { useCallback } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { toast } from "react-toastify";

import Button from "../../../../../../../components/common/v2/button";
import api from "../../../../../../../api";

const ModalCheckKeys = ({ data }) => {
  const { order } = data || {};

  const onSubmit = useCallback(async () => {
    try {
      toast.info(<div>Syncing keys...</div>);
      await api.orders.updateQuantityWithCompletedKeys(order.Id);

      toast.success(<div>Quantities are synced.</div>);

      setTimeout(() => {
        window.location.reload();
      }, 500);
    } catch (error) {
      toast.error(<div>{error.message || "Unknown message"}</div>);
    }
  }, [order]);

  if (!order) {
    return <span>Order data is not provided.</span>;
  }

  return <Button onClick={onSubmit} theme="success" title="Check Keys" />;
};

export default ModalCheckKeys;
