import React, { useEffect, useState } from "react";
import Content from "../../components/containers/Content";
import services from "../../api/index";
import Loader from "../../components/Loader";
import Filter from "../../components/Filter";
import { dateTypes } from "../../constants/defaults";
import {
  MANAGEMENT_CREDITNOTE_PAGE_LOADED,
  REDIRECT,
} from "../../constants/actionTypes";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import Pager from "../../components/Pager";
import moment from "moment";
import BlankPage from "../../components/BlankPage";
import { Link } from "react-router-dom";
import { Endpoints } from "../../constants/endpoints";
import { getSorting } from "../../functions/FilterFunctions";
import base64 from "base-64";

const mapStateToProps = (state) => {
  return {
    credit_notes: state.invoice.credit_notes,
    pageSize: state.common.pageSize,
  };
};

const mapDispatchToProps = (dispatch) => ({
  ManagmentOnLoad: (payload) => {
    dispatch({ type: MANAGEMENT_CREDITNOTE_PAGE_LOADED, payload });
  },
  onRedirect: (redirectTo) => dispatch({ type: REDIRECT, redirectTo }),
});

function CreditNotes(props) {
  const [pgeIndex, setPgeIndex] = useState(
    props.match.params.page ? props.match.params.page : 0
  );
  const [myfilter, setFilter] = useState(
    props.match.params.filter
      ? JSON.parse(base64.decode(props.match.params.filter))
      : null
  );
  const [sortby, setSortBy] = useState(props.match.params.sort);
  const [sortTypeDate, setSortTypeDate] = useState("down");

  useEffect(() => {
    document.title = "Credit Notes";
  }, []);
  useEffect(() => {
    getAllManagement({
      invoiceId: "",
      keywords: "",
      pageindex: 0,
      pagesize: 10,
      priceFrom: "",
      priceTo: "",
      customerIds: "",
      customerName: "",
      statuses: "",
      startDate: "",
      finishDate: "",
      sortBy: "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.pageSize]);

  const getAllManagement = (params) => {
    const {
      invoiceId,
      keywords = "",
      pageindex = pgeIndex,
      pagesize = 10,
      priceFrom = "",
      priceTo = "",
      customerIds = "",
      customerName = "",
      statuses = "",
      startDate = "",
      finishDate = "",
      sortBy = "",
    } = params || {};
    setPgeIndex(pageindex);
    return props.ManagmentOnLoad(
      Promise.all([
        services.invoice.listCreditMemo(
          invoiceId,
          keywords,
          pageindex,
          pagesize,
          priceFrom,
          priceTo,
          customerIds,
          customerName,
          statuses,
          startDate,
          finishDate,
          sortBy
        ),
      ])
        .then((res) => {
          return res;
        })
        .catch((err) => {
          if (err.response?.data?.message)
            toast.error(<div>{err.response?.data.message}</div>);
          return undefined;
        })
    );
  };

  const onFilter = (params) => {
    let { filter = undefined, sortBy = undefined, pageindex = 0 } = params;
    let ev = filter;
    if (ev !== undefined) setFilter(ev);
    else ev = myfilter;
    sortBy = getSorting(sortBy, sortby);
    setSortBy(sortBy);

    let url = Endpoints.Management.CreditNotes.url
      .replace(":filter?", base64.encode(ev ? JSON.stringify(ev) : "0"))
      .replace(":sort?", sortBy ? sortBy : "0")
      .replace(":page?", pageindex);
    props.onRedirect(url);

    getAllManagement({
      invoiceId: "",
      keywords: ev?.Text,
      pageindex: pageindex,
      pagesize: 10,
      priceFrom: "",
      priceTo: "",
      customerIds: ev?.PartnerId?.length !== 0 ? ev?.PartnerId?.join(",") : "",
      customerName: "",
      statuses: ev?.InvoiceStatus?.join(","),
      startDate: ev?.DateRange?.min,
      finishDate: ev?.DateRange?.max,
      sortBy: "",
    });
  };

  const onChangePageIndex = (pageindex) => {
    onFilter({ pageindex: pageindex });
  };

  return (
    <Content pageTitle="Credit Notes">
      {props.credit_notes === undefined ? (
        <Loader />
      ) : (
        <div>
          <Filter
            filter={myfilter}
            onFilter={(ev, sortBy) => onFilter(ev, sortBy)}
            isPartnerAvailable
            selectedDateItem={-1}
            dateList={[
              dateTypes.THISWEEK,
              dateTypes.THISMONTH,
              dateTypes.LAST2MONTH,
            ]}
            invoicesStatusVisible
            statusTitle="Status"
            invoicesStatusList={["Sent", "Draft"]}
          ></Filter>
          {props.credit_notes[0]?.Items.length === 0 ? (
            <BlankPage description="There is no invoice." />
          ) : (
            <div>
              <div className="table-responsive-xl p-0">
                <table
                  className="table table-centered table-nowrap table-hover mb-0 rounded table-responsive cursor-pointer"
                  style={{ minWidth: "1200px" }}
                  id="datatable"
                >
                  <thead className="thead-light d-short border-0">
                    <tr>
                      <th className="bg-transparent border-0 ps-2">Customer</th>
                      <th className="bg-transparent border-0 ps-2">Order</th>
                      <th className="bg-transparent border-0 ps-2">Invoice</th>
                      <th
                        className="bg-transparent border-0 ps-2"
                        // onClick={() => {
                        //   setSortTypeDate(
                        //     sortTypeDate === "up" ? "down" : "up"
                        //   );
                        //   onFilter({
                        //     sortBy: "CreatedAt",
                        //   });
                        // }}
                      >
                        Amount
                        {/* <i
                          className={`fas fa-sort-amount-${sortTypeDate} px-2`}
                        ></i> */}
                      </th>
                      <th className="bg-transparent border-0 ps-2">Status</th>
                      <th className="bg-transparent border-0 ps-2">Reason</th>
                      <th className="bg-transparent border-0 ps-2">Date</th>
                      {/* <th className="bg-transparent border-0 ps-2">
                        CreatedBy
                      </th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {props.credit_notes.length > 0 ? (
                      props.credit_notes[0].Items.map((item, index) => {
                        return (
                          <tr
                            className="rounded-1 mb-1 align-middle border-bottom"
                            key={index}
                          >
                            <td className="ps-2">
                              {item.Invoice.Customer.Name}
                            </td>
                            <td className="ps-2">
                              <Link
                                to={Endpoints.Management.OrderDetail.url.replace(
                                  ":" +
                                    Endpoints.Management.OrderDetail.url.split(
                                      ":"
                                    )[1],
                                  item.Invoice.OrderId
                                )}
                                className="text-decoration-underline"
                              >
                                {item.Invoice.OrderNumber}
                              </Link>
                            </td>
                            <td className="ps-2">
                              {item.Invoice.Accounting.ExternalInvoiceId}
                            </td>
                            <td className="ps-2">{item.TotalExcVatAmount}</td>
                            <td className="ps-2">{item.Status}</td>
                            <td className="ps-2">{item.Reason}</td>
                            <td className="ps-2">
                              {moment
                                .utc(item.Invoice.CreatedAt)
                                .format("DD/MM/YYYY - H:mm:ss")}{" "}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </tbody>
                </table>
              </div>
              <Pager
                pageIndex={props.credit_notes[0]?.PageIndex}
                totalPages={props.credit_notes[0]?.TotalPages}
                totalCount={props.credit_notes[0]?.TotalCount}
                onChange={(pageIndex) => onChangePageIndex(pageIndex)}
              />
            </div>
          )}
        </div>
      )}
    </Content>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(CreditNotes);
