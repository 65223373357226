/* eslint-disable react/require-default-props */
/* eslint-disable no-prototype-builtins */
import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import {
  PUBLISHER_ON_LOAD,
  FILTER_LOADER,
  PROVIDER_ON_LOAD,
  STORE_ON_LOAD,
  PARTNER_ON_LOAD,
} from "../constants/actionTypes";
import { dateTypes } from "../constants/defaults";
import moment from "moment";
import "moment/locale/tr";
import "react-datepicker/dist/react-datepicker.css";
import PublisherFilter from "./filter/PublisherFilter";
import StatusFilter from "./filter/StatusFilter";
import DateFilter from "./filter/DateFilter";
import OsFilter from "./filter/OsFilter";
import PlatformFilter from "./filter/PlatformFilter";
import PriceFilter from "./filter/PriceFilter";
import PartnerFilter from "./filter/PartnerFilter";
import InvoicedFilter from "./filter/InvoicedFilter";
import OrderItemStatusFilter from "./filter/OrderItemStatusFilter";
import InvoiceStatusFilter from "./filter/InvoiceStatusFilter";
import { toast } from "react-toastify";
import { Icons } from "../constants/icons";
import PromotionStatusFilter from "./filter/PromotionStatusFilter";
import ProviderFilter from "./filter/ProviderFilter";
import StoreFilter from "./filter/StoreFilter";
import { waitTimeOut } from "../functions/FilterFunctions";
import services from "../api/index";
import settings from "../settings.json";
import InventoryFilter from "./filter/InventoryFilter";
import RegionFilter from "./filter/RegionFilter";

const SYSTEM_PARTNER_ID = settings["SYSTEM_PARTNER_ID"];
const mapStateToProps = (state) => {
  return {
    common: state.common,
    filterIsLoading: state.promotions.filterIsLoading,
    userList: state.management.userList,
    user: state.auth.currentUser,
  };
};

const mapDispatchToProps = (dispatch) => ({
  publisherOnLoad: (payload) => dispatch({ type: PUBLISHER_ON_LOAD, payload }),
  providerOnLoad: (payload) => dispatch({ type: PROVIDER_ON_LOAD, payload }),
  partnerOnLoad: (payload) => dispatch({ type: PARTNER_ON_LOAD, payload }),
  storeOnLoad: (payload) => dispatch({ type: STORE_ON_LOAD, payload }),
  filterIsLoading: (payload) => dispatch({ type: FILTER_LOADER, payload }),
});

class Filter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      publisherNames: [],
      providerNames: [],
      customDate: false,
      selectedDateItem: props.selectedDateItem ?? 0,
      selectedPublisherItem: 0,
      minPrice: 0,
      maxPrice: 0,
      filter: props.filter
        ? props.filter
        : {
            Text: "",
            Publishers: [],
            PublishersName: [],
            Providers: [],
            Status: [],
            Store: "",
            Price: {},
            DateRange: {},
            Platforms: [],
            OperatingSystems: [],
            PartnerId: [],
            InvoicedStatus: "",
            OnlyActivePromotion: "",
            InvoiceStatus: [],
            DeliveredStatus: "",
            SelectedInventory: props.SelectedInventory,
            Regions: [],
          },
    };
  }

  componentDidMount() {
    if (this.props.isPublisherAvailable !== undefined) this.onFilterPublisher();
    if (this.props.isProviderAvailable !== undefined) this.onFilterProvider();
    if (this.props.isStoreAvailable !== undefined) this.onFilterStore();
    if (this.props.isPartnerAvailable !== undefined) this.onFilterPartner();
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(this.props.filter) !== JSON.stringify(prevProps.filter) &&
      prevProps.filter !== null
    ) {
      this.setFilterState(this.props.filter);
    }
    if (this.props.clearFilter !== prevProps.clearFilter) {
      this.clearFilter(false);
    }

    return true;
  }

  setFilterState(filter) {
    this.setState({ filter: filter });
  }

  onFilterPublisher = (params) => {
    const { keywords = "", pageindex = 0, pagesize = 6 } = params || {};
    this.props.publisherOnLoad(
      Promise.all([services.publisher.getAll(keywords, pageindex, pagesize)])
        .then((res) => {
          return res;
        })
        .catch((err) => {
          toast.error(<div>{JSON.parse(err.response.text).message}</div>);
          return undefined;
        })
    );
  };

  onFilterProvider = (params) => {
    const { keywords = "", pageindex = 0, pagesize = 6 } = params || {};
    if (this.props.isProviderAvailable) {
      this.props.providerOnLoad(
        Promise.all([
          services.provider.getAllFromManagement(keywords, pageindex, pagesize),
        ])
          .then((res) => {
            return res;
          })
          .catch((err) => {
            if (err.response?.data?.message)
              toast.error(<div>{JSON.parse(err.response.text).message}</div>);
            return undefined;
          })
      );
    }
  };

  onFilterPartner = (params) => {
    const { keywords = "", pageindex = 0, pagesize = 50 } = params || {};
    this.props.partnerOnLoad(
      Promise.all([
        services.company.getCompanies(keywords, pageindex, pagesize),
      ])
        .then((res) => {
          if (this.props.removeSystemUser)
            return res[0].Items.filter((f) => f.Id !== SYSTEM_PARTNER_ID);
          else return res[0].Items;
        })
        .catch((err) => {
          if (err.response?.data?.message)
            toast.error(<div>{JSON.parse(err.response.text).message}</div>);
          return undefined;
        })
    );
  };

  onFilterStore = (params) => {
    this.props.storeOnLoad(
      Promise.all([services.common.getManagementStores()])
        .then((res) => {
          return res;
        })
        .catch((err) => {
          if (err.response?.data?.message)
            toast.error(<div>{JSON.parse(err.response.text).message}</div>);
          return undefined;
        })
    );
  };

  onPartnerChecked = (ev) => {
    const myFilter = this.state.filter;
    if (ev.target.checked) {
      if (this.props.singlePartnerFilter)
        myFilter.PartnerId = [ev.target.value];
      else myFilter.PartnerId.push(ev.target.value);
    } else
      myFilter.PartnerId.splice(myFilter.PartnerId.indexOf(ev.target.value), 1);
    this.setState({
      filter: myFilter,
    });
    this.props.onFilter && this.props.onFilter({ filter: this.state.filter });
  };

  searchText = (ev) => {
    const myFilter = this.state.filter;
    myFilter.Text = ev.target.value;
    this.setState({
      filter: myFilter,
    });
    waitTimeOut(() => {
      this.props.onFilter && this.props.onFilter({ filter: myFilter });
    });
  };

  onPublisherChecked = (ev, Name) => {
    const myFilter = this.state.filter;
    const myPubNames = this.state.publisherNames;
    if (ev.target.checked) {
      if (this.props.isPublisherSelectSingle) {
        myFilter.Publishers[0] = ev.target.value;
        myFilter.PublishersName[0] = Name;
      } else {
        myFilter.Publishers.push(ev.target.value);
        myFilter.PublishersName.push(Name);
        myPubNames.push(Name);
      }
    } else {
      myFilter.Publishers.splice(
        myFilter.Publishers.indexOf(ev.target.value),
        1
      );
      myFilter.PublishersName.splice(myFilter.PublishersName.indexOf(Name), 1);
      myPubNames.splice(myPubNames.indexOf(Name), 1);
    }
    this.setState({
      filter: myFilter,
      publisherNames: myPubNames,
      selectedPublisherItem: ev.target.value,
    });
    if (this.props.isPublisherSelectSingle) {
      this.onPublisherDone();
    }
  };

  onProviderChecked = (ev, Name) => {
    const myFilter = this.state.filter;
    const myProNames = this.state.providerNames;
    if (ev.target.checked) {
      myFilter.Providers.push(ev.target.value);
      myProNames.push(Name);
    } else {
      myFilter.Providers.splice(myFilter.Providers.indexOf(ev.target.value), 1);
      myProNames.splice(myProNames.indexOf(Name), 1);
    }
    this.setState({
      filter: myFilter,
      providerNames: myProNames,
    });
  };

  onPublisherDone = () => {
    const myFilter = this.state.filter;
    this.props.filterIsLoading(true);
    this.setState({
      filter: myFilter,
      publisherNames: this.state.publisherNames,
    });

    this.props.onFilter &&
      this.props.onFilter({
        filter: this.state.filter,
        publisherNames: this.state.publisherNames,
      });
    if (document.getElementById("publisher-filter-search").value !== "")
      document.getElementById("publisher-filter-search").value = "";
    // this.onFilterPublisher();
  };

  onProviderDone = () => {
    const myFilter = this.state.filter;
    this.props.filterIsLoading(true);
    this.setState({
      filter: myFilter,
    });
    this.props.onFilter && this.props.onFilter({ filter: this.state.filter });
    if (document.getElementById("provider-filter-search").value !== "")
      document.getElementById("provider-filter-search").value = "";
    this.onFilterProvider();
  };

  onOsPlatformDone = () => {
    const myFilter = this.state.filter;
    this.props.filterIsLoading(true);
    this.setState({
      filter: myFilter,
    });
    this.props.onFilter && this.props.onFilter({ filter: this.state.filter });
  };

  onStatusChecked = (ev) => {
    const myFilter = this.state.filter;
    if (ev.target.checked) myFilter.Status.push(ev.target.value);
    else myFilter.Status.splice(myFilter.Status.indexOf(ev.target.value), 1);
    this.setState({
      filter: myFilter,
    });
    this.props.onFilter && this.props.onFilter({ filter: this.state.filter });
  };

  onIventoryChecked = (ev) => {
    const myFilter = this.state.filter;
    if (ev.target.checked) myFilter.SelectedInventory = ev.target.value;
    else myFilter.SelectedInventory = "";
    this.setState({
      filter: myFilter,
    });
    this.props.onFilter && this.props.onFilter({ filter: myFilter });
  };

  onStoreChecked = (ev) => {
    const myFilter = this.state.filter;
    if (ev.target.checked) myFilter.Store = ev.target.value;
    this.setState({
      filter: myFilter,
    });
    this.props.onFilter && this.props.onFilter({ filter: this.state.filter });
  };

  onInvoiceStatusChecked = (ev) => {
    const myFilter = this.state.filter;
    if (ev.target.checked) myFilter.InvoiceStatus.push(ev.target.value);
    else
      myFilter.InvoiceStatus.splice(
        myFilter.InvoiceStatus.indexOf(ev.target.value),
        1
      );
    this.setState({
      filter: myFilter,
    });
    this.props.onFilter && this.props.onFilter({ filter: this.state.filter });
  };

  onPriceChange = (item) => {
    const myFilter = this.state.filter;
    myFilter.Price = item;
    this.setState({
      filter: myFilter,
    });
    this.props.onFilter && this.props.onFilter({ filter: myFilter });
  };

  priceFilterClick = () => {
    const myFilter = this.state.filter;
    myFilter.Price.up = this.state.maxPrice;
    myFilter.Price.down = this.state.minPrice;
    this.props.onFilter && this.props.onFilter({ filter: myFilter });
  };

  inputPriceFilter = (e) => {
    const { name, value } = e.target;
    name === "min"
      ? this.setState({ minPrice: value })
      : this.setState({ maxPrice: value });
  };

  onDateChange = (dateType, index) => {
    const myFilter = this.state.filter;
    const dateRange = this.dateCreate(dateType);
    myFilter.DateRange = dateRange;
    this.setState({
      filter: myFilter,
      selectedDateItem: index,
      customDate: false,
    });
    this.props.onFilter && this.props.onFilter({ filter: myFilter });
  };

  clearFilter = (isApiCall = true) => {
    const myFilter = this.state.filter;
    this.onFilterPublisher();
    this.onFilterProvider();
    this.onFilterPartner();
    this.onFilterStore();
    myFilter.Text = "";
    myFilter.PartnerId = [];
    myFilter.Publishers = [];
    myFilter.PublishersName = [];
    myFilter.Providers = [];
    myFilter.Status = [];
    myFilter.Store = "";
    myFilter.Price = {};
    myFilter.DateRange = {};
    myFilter.Platforms = [];
    myFilter.OperatingSystems = [];
    myFilter.InvoicedStatus = "";
    myFilter.OnlyActivePromotion = "";
    myFilter.InvoiceStatus = [];
    myFilter.DeliveredStatus = "";
    myFilter.SelectedInventory = "";
    this.setState({
      filter: myFilter,
      selectedDateItem: this.props.selectedDateItem ?? 0,
      selectedPublisherItem: 0,
      customDate: false,
      maxPrice: 0,
      minPrice: 0,
      publisherNames: [],
      providerNames: [],
    });
    if (isApiCall)
      this.props.onFilter &&
        this.props.onFilter({ filter: myFilter, pageindex: 0 });
  };

  onPublisherClear = () => {
    const myFilter = this.state.filter;
    this.onFilterPublisher();
    myFilter.Publishers = [];
    myFilter.PublishersName = [];
    this.state.publisherNames = [];
    this.setState({
      filter: myFilter,
      publisherNames: [],
      selectedPublisherItem: 0,
    });
    this.props.onFilter &&
      this.props.onFilter({ filter: myFilter, publisherNames: [] });
  };

  onProviderClear = () => {
    const myFilter = this.state.filter;
    this.onFilterProvider();
    myFilter.Providers = [];
    this.state.providerNames = [];
    this.setState({
      filter: myFilter,
      providerNames: [],
    });
    this.props.onFilter && this.props.onFilter({ filter: myFilter });
  };

  onPriceClear = () => {
    const myFilter = this.state.filter;
    myFilter.Price = {};
    this.setState({
      filter: myFilter,
      maxPrice: 0,
      minPrice: 0,
    });
    this.props.onFilter && this.props.onFilter({ filter: myFilter });
  };

  onDateRangeClear = () => {
    const myFilter = this.state.filter;
    myFilter.DateRange = {};
    this.setState({
      filter: myFilter,
      selectedDateItem: this.props.selectedDateItem ?? 0,
      customDate: false,
    });
    this.props.onFilter && this.props.onFilter({ filter: myFilter });
  };

  onPlatformsClear = () => {
    const myFilter = this.state.filter;
    myFilter.Platforms = [];
    this.setState({
      filter: myFilter,
    });
    this.props.onFilter && this.props.onFilter({ filter: myFilter });
  };

  onIventoriesClear = () => {
    const myFilter = this.state.filter;
    myFilter.SelectedInventory = "";
    this.setState({
      filter: myFilter,
    });
    this.props.onFilter && this.props.onFilter({ filter: myFilter });
  };

  onOperatingSystemsClear = () => {
    const myFilter = this.state.filter;
    myFilter.OperatingSystems = [];
    this.setState({
      filter: myFilter,
    });
    this.props.onFilter && this.props.onFilter(myFilter);
  };

  onStatusClear = () => {
    const myFilter = this.state.filter;
    myFilter.Status = [];
    this.setState({
      filter: myFilter,
    });
    this.props.onFilter && this.props.onFilter({ filter: myFilter });
  };

  onStoreClear = () => {
    const myFilter = this.state.filter;
    myFilter.Store = "";
    this.setState({
      filter: myFilter,
    });
    this.props.onFilter && this.props.onFilter({ filter: myFilter });
  };

  onPartnerClear = () => {
    const myFilter = this.state.filter;
    myFilter.PartnerId = [];
    this.setState({
      filter: myFilter,
    });
    this.props.onFilter && this.props.onFilter({ filter: myFilter });
  };

  onInvoiceStatusClear = () => {
    const myFilter = this.state.filter;
    myFilter.InvoiceStatus = [];
    this.setState({
      filter: myFilter,
    });
    this.props.onFilter && this.props.onFilter({ filter: myFilter });
  };

  RemovePublisherName = (Name) => {
    const myFilter = this.state.filter;
    const myPubNames = this.state.publisherNames;
    const NameIndex = myPubNames.indexOf(Name);
    myPubNames.splice(NameIndex, 1);
    myFilter.Publishers.splice(NameIndex, 1);
    myFilter.PublishersName.splice(NameIndex, 1);

    this.setState({
      filter: myFilter,
      publisherNames: myPubNames,
    });
  };

  RemoveProviderName = (Name) => {
    const myFilter = this.state.filter;
    const myProNames = this.state.providerNames;
    const NameIndex = myProNames.indexOf(Name);
    myProNames.splice(NameIndex, 1);
    myFilter.Providers.splice(NameIndex, 1);

    this.setState({
      filter: myFilter,
      providerNames: myProNames,
    });
  };

  onChoseCustomDate = (index) => {
    const myFilter = this.state.filter;
    const dateRange = this.dateCreate(undefined);
    myFilter.DateRange = dateRange;
    this.setState({
      filter: myFilter,
      selectedDateItem: index,
      customDate: true,
    });
  };

  onChangeCustomDate = (date, min = false) => {
    let myFilter = this.state.filter;
    if (min === true)
      myFilter.DateRange.max = moment(date.toISOString())
        .endOf("day")
        .toISOString();
    else
      myFilter.DateRange.min = moment(date.toISOString())
        .startOf("day")
        .toISOString();
    this.setState({
      filter: myFilter,
    });
    this.props.onFilter && this.props.onFilter({ filter: myFilter });
  };

  dateCreate = (dateType) => {
    let min;
    let max;
    let date = new Date();
    const dateFormat = "MM-DD-YYYY";
    switch (dateType) {
      case dateTypes.LAST2MONTH:
        min = moment()
          .subtract(2, "months")
          .startOf("months")
          .startOf("day")
          .toISOString();
        max = moment()
          .subtract(0, "months")
          .endOf("months")
          .endOf("day")
          .toISOString();
        break;

      case dateTypes.THISMONTH:
        min = moment().startOf("months").toISOString();
        max = moment().endOf("months").toISOString();
        break;
      case dateTypes.LASTMONTH:
        min = moment()
          .subtract(1, "months")
          .startOf("months")
          .startOf("day")
          .toISOString();
        max = moment()
          .subtract(0, "months")
          .endOf("months")
          .endOf("day")
          .toISOString();
        break;
      case dateTypes.ALLTIME:
        min = new Date("01.01.1970");
        max = new Date("01.01.2070");
        break;
      default:
        min = moment().startOf("day").toISOString();
        max = moment().add(7, "day").endOf("day").toISOString();
    }

    return { min: min, max: max };
  };

  onOsChange = (ev) => {
    const myFilter = this.state.filter;
    if (ev.target.checked) {
      myFilter.OperatingSystems.push(ev.target.value);
    } else {
      myFilter.OperatingSystems.splice(
        myFilter.OperatingSystems.indexOf(ev.target.value),
        1
      );
    }
    this.setState({
      filter: myFilter,
    });
  };

  onPlatformChange = (ev) => {
    const myFilter = this.state.filter;
    if (ev.target.checked) {
      myFilter.Platforms.push(ev.target.value);
    } else {
      myFilter.Platforms.splice(myFilter.Platforms.indexOf(ev.target.value), 1);
    }
    this.setState({
      filter: myFilter,
    });
  };

  onChangeInventory = (ev) => {
    const myFilter = this.state.filter;
    if (ev.target.checked) {
      myFilter.SelectedInventory = ev.target.value;
    } else {
      myFilter.SelectedInventory = "";
    }
    this.setState({
      filter: myFilter,
    });
  };

  filterClear = (filter) => {
    return (
      (filter.Publishers.length > 0 ||
        filter.Providers.length > 0 ||
        filter.Store !== "" ||
        filter.Price.hasOwnProperty("up") ||
        filter.Price.hasOwnProperty("down") ||
        filter.DateRange.hasOwnProperty("min") ||
        filter.Platforms.length > 0 ||
        filter.OperatingSystems.length > 0 ||
        filter.Status.length > 0 ||
        filter.InvoiceStatus.length > 0 ||
        filter.PartnerId.length > 0 ||
        filter.Text !== "" ||
        filter.InvoicedStatus !== "" ||
        (filter.SelectedInventory !== "" &&
          filter.SelectedInventory !== undefined) ||
        filter.OnlyActivePromotion !== "" ||
        filter.DeliveredStatus !== "") && (
        <div className="d-block d-sm-flex">
          <div className="btn-group pe-2 pt-1 rounded-1 align-items-center">
            <a
              type="button"
              className="button text px-2"
              onClick={() => this.clearFilter()}
            >
              <span className="mx-1">Clear All Filters</span>
            </a>
          </div>
        </div>
      )
    );
  };
  render() {
    const { publisherList, providerList, storeList, partnerList } =
      this.props.common;
    const publishers = publisherList ? publisherList[0] : undefined;
    const providers = providerList ? providerList[0] : undefined;
    const partners = partnerList ? partnerList : undefined;
    const stores = storeList ? storeList[0] : undefined;
    const {
      minPrice,
      maxPrice,
      customDate,
      selectedDateItem,
      publisherNames,
      providerNames,
      filter,
    } = this.state;

    const {
      isPublisherAvailable,
      isRegionAvailable,
      isProviderAvailable,
      isStoreAvailable,
      isPartnerAvailable,
      priceList,
      dateList,
      platformList,
      osList,
      statusList,
      orderItemStatusList,
      invoicesStatusList,
      statusTitle,
      inventories,
    } = this.props;

    return (
      <>
        <div className="filter">
          <div className="d-flex flex-wrap w-100 align-items-center">
            <StoreFilter
              stores={stores}
              filter={filter}
              isStoreAvailable={isStoreAvailable}
              onStoreChecked={this.onStoreChecked}
              onStoreClear={this.onStoreClear}
            />
            <ProviderFilter
              providers={providers}
              isProviderAvailable={isProviderAvailable}
              filter={filter}
              onFilterProvider={this.onFilterProvider}
              onProviderChecked={this.onProviderChecked}
              onProviderDone={this.onProviderDone}
              onProviderClear={this.onProviderClear}
              providerNames={providerNames}
              RemoveProviderName={this.RemoveProviderName}
            />
            <PartnerFilter
              isPartnerAvailable={isPartnerAvailable}
              partners={partners}
              filter={filter}
              onFilterPartner={this.onFilterPartner}
              onPartnerChecked={this.onPartnerChecked}
              onPartnerClear={this.onPartnerClear}
              singlePartnerFilter={this.props.singlePartnerFilter}
            />
            <PublisherFilter
              publishers={publishers}
              isPublisherAvailable={isPublisherAvailable}
              filter={filter}
              onFilterPublisher={this.onFilterPublisher}
              onPublisherChecked={this.onPublisherChecked}
              onPublisherDone={this.onPublisherDone}
              onPublisherClear={this.onPublisherClear}
              publisherNames={publisherNames}
              RemovePublisherName={this.RemovePublisherName}
              isPublisherSelectSingle={this.props.isPublisherSelectSingle}
              selectedPublisherItem={this.state.selectedPublisherItem}
            />
            <RegionFilter
              defaultValues={this.state.Regions}
              hidden={!isRegionAvailable}
              onFilter={(e) => {
                this.state.filter.Regions = e;
                this.props.onFilter?.({ filter: this.state.filter });
              }}
              user={this.props.user}
            />
            <PriceFilter
              priceList={priceList}
              minPrice={minPrice}
              maxPrice={maxPrice}
              inputPriceFilter={this.inputPriceFilter}
              priceFilterClick={this.priceFilterClick}
              filter={filter}
              onPriceChange={this.onPriceChange}
              onPriceClear={this.onPriceClear}
            />

            <DateFilter
              dateList={dateList}
              onDateChange={this.onDateChange}
              selectedDateItem={selectedDateItem}
              customDate={customDate}
              filter={filter}
              onChoseCustomDate={this.onChoseCustomDate}
              onChangeCustomDate={this.onChangeCustomDate}
              onDateRangeClear={this.onDateRangeClear}
            />

            <PlatformFilter
              platformList={platformList}
              filter={filter}
              onPlatformChange={this.onPlatformChange}
              onPlatformsClear={this.onPlatformsClear}
              onPlatformDone={this.onOsPlatformDone}
            />
            <InventoryFilter
              inventories={inventories}
              filter={filter}
              onChangeInventory={this.onChangeInventory}
              onIventoriesClear={this.onIventoriesClear}
              onIventoryChecked={this.onIventoryChecked}
              onFilterInventories={this.props.onFilterInventories}
            />

            <OsFilter
              osList={osList}
              filter={filter}
              onOsChange={this.onOsChange}
              onOperatingSystemsClear={this.onOperatingSystemsClear}
              onOperatingSystemsDone={this.onOsPlatformDone}
            />

            {this.props.statusVisible && (
              <StatusFilter
                statusList={statusList}
                filter={filter}
                onStatusChecked={this.onStatusChecked}
                onStatusClear={this.onStatusClear}
              />
            )}

            <OrderItemStatusFilter
              orderItemStatusList={orderItemStatusList}
              filter={filter}
              onStatusChecked={this.onStatusChecked}
              onStatusClear={this.onStatusClear}
            />

            <InvoicedFilter
              filter={this.state.filter}
              isInvoicedAvailable={this.props.isInvoicedAvailable}
              isDeliveredAvailable={this.props.isDeliveredAvailable}
              onInvoicedChecked={(ev) => {
                const myfilter = this.state.filter;
                myfilter.InvoicedStatus = ev;
                this.setState({ filter: myfilter });
                this.props.onFilter &&
                  this.props.onFilter({ filter: this.state.filter });
              }}
              onDeliveredChecked={(ev) => {
                const myfilter = this.state.filter;
                myfilter.DeliveredStatus = ev;
                this.setState({ filter: myfilter });
                this.props.onFilter &&
                  this.props.onFilter({ filter: this.state.filter });
              }}
            ></InvoicedFilter>

            <PromotionStatusFilter
              filter={this.state.filter}
              isActivePromotion={this.props.isActivePromotion}
              onActivePromotionChecked={(ev) => {
                const myfilter = this.state.filter;
                myfilter.OnlyActivePromotion = ev;
                this.setState({ filter: myfilter });
                this.props.onFilter &&
                  this.props.onFilter({ filter: this.state.filter });
              }}
            ></PromotionStatusFilter>

            {this.props.invoicesStatusVisible && (
              <InvoiceStatusFilter
                statusList={invoicesStatusList}
                filter={filter}
                onStatusChecked={this.onInvoiceStatusChecked}
                onStatusClear={this.onInvoiceStatusClear}
                statusTitle={statusTitle}
              />
            )}
            <div className="filter-clear filter-clear-desktop">
              {this.filterClear(filter)}
            </div>
          </div>
          <div className="filter-search">
            {!this.props.hideSearch && (
              <div className="d-flex flex-wrap justify-content-end">
                <div className="form-search permission-search btn-group pe-2 pt-1 rounded-1 align-items-center">
                  <input
                    className="form-control bg-light border-0 shadow-none"
                    type="search"
                    placeholder="Search"
                    onChange={(ev) => this.searchText(ev)}
                    aria-label="Search"
                    value={this.state.filter.Text}
                  />
                  <i className="fa-icon">
                    <img src={Icons.filterSearchIcon} />
                  </i>
                </div>
              </div>
            )}
            <div className="filter-clear filter-clear-tablet">
              {this.filterClear(filter)}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Filter);

// eslint-disable-next-line no-undef
Filter.propTypes = {
  isPublisherAvailable: PropTypes.bool,
  isRegionAvailable: PropTypes.bool,
  singlePartnerFilter: PropTypes.bool,
  isActivePromotion: PropTypes.bool,
  isDeliveredAvailable: PropTypes.bool,
  isPartnerAvailable: PropTypes.bool,
  isProviderAvailable: PropTypes.bool,
  isInvoicedAvailable: PropTypes.bool,
  invoicesStatusVisible: PropTypes.bool,
  statusVisible: PropTypes.bool,
  isPublisherSelectSingle: PropTypes.bool,
  isStoreAvailable: PropTypes.bool,
  filter: PropTypes.oneOfType(),
  onFilter: PropTypes.func,
  priceList: PropTypes.oneOfType(),
  dateList: PropTypes.oneOfType(),
  osList: PropTypes.oneOfType(),
  statusList: PropTypes.oneOfType(),
  orderItemStatusList: PropTypes.oneOfType(),
  invoicesStatusList: PropTypes.oneOfType(),
  statusTitle: PropTypes.oneOfType(),
  inventories: PropTypes.oneOfType(),
};
