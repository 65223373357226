import React from "react";
import { connect } from "react-redux";
import { UPDATE_FIELD_COMMON } from "./constants/actionTypes";

class WindowsResizing extends React.Component {
  updateWindowDimensions = () => {
    // this.setState({ width: window.innerWidth, height: window.innerHeight });
    const sizes = this.props.sizes;
    let keys = Object.keys(sizes);

    let size_name = "xxl";
    for (let index = 0; index < keys.length; index++) {
      const key = keys[index];
      const value = sizes[key];
      if (window.innerWidth < value) {
        size_name = key;
        break;
      }
    }

    this.props.set("device_info", {
      width: window.innerWidth,
      height: window.innerHeight,
      size_name: size_name,
    });
  };

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  render() {
    return <div></div>;
  }
}
const mapStateToProps = (state) => {
  return {
    sizes: state.common.sizes,
  };
};

const mapDispatchToProp = (dispatch) => {
  return {
    set: (name, device_info) => {
      dispatch({ type: UPDATE_FIELD_COMMON, key: name, value: device_info });
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProp)(WindowsResizing);
