import { MANAGEMENT_VENDORS_PAGE_LOADED } from "../constants/actionTypes";

const defaultState = {
  list: undefined,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case MANAGEMENT_VENDORS_PAGE_LOADED:
      return {
        ...state,
        list: action.payload,
      };
  }

  return state;
};
