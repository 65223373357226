import React from "react";
import Button from "../../../../../components/common/Button";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { defaultDateFormat } from "../../../../../constants/defaults";
import moment from "moment";
import { Icons } from "../../../../../constants/icons";
import services from "../../../../../api/index";

const mapStateToProps = (state) => {
  return { order: state.order };
};

const mapDispatchToProps = (dispatch) => ({});

const SendPackageModal = (props) => {
  const sendPackage = (orderId, packageId) => {
    services.orders
      .sendPackage(orderId, packageId)
      .then(() => {
        toast.success(<div>Packages send successfully</div>);
        setTimeout(() => {
          props.refreshItem(props.orderId);
        }, 500);
      })
      .catch((err) => {
        if (err.response?.data?.message)
          toast.error(<div>{err.response?.data.message}</div>);
      });
  };
  return (
    <div className="send-package-modal">
      <div className="d-flex flex-column justify-content-start">
        <div className="go-back py-4 px-5">
          <img src={Icons.goBackIcon} />
          <Button
            className="go-back-button"
            bindEvent={() => props.handleClose()}
            label="Go back"
          />
        </div>
        <div className="send-package-header px-5">
          <label className="m-0">Send Package</label>
          <p className="send-package-sub-header">
            CD: Created Date | SD: Last Sended Date
          </p>
        </div>
        <div className="send-package-container py-3 px-4">
          {props.packages.map((item, index) => {
            return (
              <div
                key={index}
                className={`send-package-item px-4 d-flex flex-row justify-content-between
                ${item.Status === "Sent" ? "opacity-6" : ""}
              `}
              >
                <div className="d-flex flex-column justify-content-center">
                  <div className="send-package-item-id">
                    <span>
                      Package {index + 1} ({item.History[0]?.ModifiedByName})
                    </span>
                  </div>
                  <div className="send-package-item-quantity">
                    <span>{item.Quantity} licenses</span>
                  </div>
                  <div className="d-flex flex-row">
                    <p className="send-package-item-quantity my-0">
                      CD:{" "}
                      {moment(item.CreatedAt).format(
                        defaultDateFormat + "\tH:mm:ss"
                      )}
                    </p>
                    <p className="send-package-item-quantity mx-4 my-0">
                      SD:{" "}
                      {moment(item.ModifiedAt).format(
                        defaultDateFormat + "\tH:mm:ss"
                      )}
                    </p>
                  </div>
                </div>
                <Button
                  bindEvent={() => sendPackage(props.orderId, item.PackageId)}
                  className="resend-button"
                  label={item.Status === "Sent" ? "Resend" : "Send"}
                  buttonIcon={Icons.circleCarnationIcon}
                  iconWidth={15}
                  iconHeight={15}
                />
              </div>
            );
          })}
        </div>{" "}
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SendPackageModal);
