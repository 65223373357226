import { OrderStatus, salesman } from "../../../../../../../constants/defaults";

import api from "../../../../../../../api";

const refuse = ({ Id, Status }, userScope) => ({
  isEnabled:
    [OrderStatus.SalesmanApproved].includes(Status) &&
    [salesman].includes(userScope),
  modal: {
    title: "Refuse Order",
    accessibility: {
      submit: {
        onClick: () => api.orders.refuse(Id),
        successMessage: "Order is refused, successfully",
        title: "Refuse",
        theme: "danger",
      },
    },
    trigger: {
      title: "Refuse",
      theme: "danger",
    },
    description: "Order will be refused.",
  },
});

export default refuse;
