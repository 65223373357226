import { OrderStatus } from "../../../../../../../constants/defaults";

import api from "../../../../../../../api";

const cancel = ({ Id, Status }) => ({
  isEnabled: [
    OrderStatus.Processing,
    OrderStatus.Failed,
    OrderStatus.Paused,
  ].includes(Status),
  modal: {
    title: "Cancel Order",
    accessibility: {
      submit: {
        onClick: ({ sendBackOrderedKeysToInventory }) =>
          api.orders.close(Id, sendBackOrderedKeysToInventory),
        successMessage: "Order is cancelled, successfully",
        title: "Cancel",
        theme: "danger",
      },
    },
    trigger: {
      title: "Cancel",
      theme: "danger",
    },
    description: "Order will be cancelled.",
    form: [
      {
        defaultChecked: true,
        key: "sendBackOrderedKeysToInventory",
        title: "Send Back Ordered Keys To Inventory",
        type: "checkbox",
        map: (e) => e.checked,
      },
    ],
  },
});

export default cancel;
