import React, { useEffect, useState } from "react";
import Content from "../../../components/containers/Content";
import Loader from "../../../components/Loader";
import base64 from "base-64";
import {
  REPORT_TOP_SELLERS_PAGE_LOADED,
  REDIRECT,
} from "../../../constants/actionTypes";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import Pager from "../../../components/Pager";
import BlankPage from "../../../components/BlankPage";
import Filter from "../../../components/Filter";
import { dateTypes } from "../../../constants/defaults";
import { Endpoints } from "../../../constants/endpoints";
import services from "../../../api/index";

const mapStateToProps = (state) => {
  return {
    reportTopSellers: state.report.reportTopSellers,
  };
};

const mapDispatchToProps = (dispatch) => ({
  PageOnLoad: (payload) => {
    dispatch({ type: REPORT_TOP_SELLERS_PAGE_LOADED, payload });
  },
  onRedirect: (redirectTo) => dispatch({ type: REDIRECT, redirectTo }),
});

function TopSellers(props) {
  const [pageIndex, setPgeIndex] = useState(0);
  const [myfilter, setFilter] = useState(
    props.match.params.filter
      ? JSON.parse(base64.decode(props.match.params.filter))
      : null
  );
  useEffect(() => {
    document.title = "Top Sellers";
  }, []);
  useEffect(() => {
    let ev = myfilter;

    getTopSellers({
      pageindex: ev?.pageindex,
      startDate: ev?.DateRange?.min,
      endDate: ev?.DateRange?.max,
    }); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTopSellers = (params) => {
    const { pageindex, startDate, endDate } = params || {};
    setPgeIndex(pageindex);
    return props.PageOnLoad(
      Promise.all([
        services.report.getTopSellers(startDate, endDate, pageindex, 10),
      ])
        .then((res) => {
          return res;
        })
        .catch((err) => {
          if (err.response?.data?.message)
            toast.error(<div>{err.response?.data.message}</div>);
          return undefined;
        })
    );
  };

  const onChangePageIndex = (pageindex) => {
    onFilter({ pageindex: pageindex });
  };

  const onFilter = (params) => {
    let { filter = myfilter, sortBy = undefined, pageindex = 0 } = params;
    let ev = filter;
    if (ev !== undefined) setFilter(ev);
    else ev = myfilter;

    let url = Endpoints.Management.TopSellers.url
      .replace(":filter?", base64.encode(ev ? JSON.stringify(ev) : "0"))
      .replace(":sort?", sortBy ? sortBy : "0")
      .replace(":page?", pageindex);
    props.onRedirect(url);

    getTopSellers({
      pageindex: pageindex,
      startDate: ev?.DateRange?.min,
      endDate: ev?.DateRange?.max,
    });
  };

  return (
    <Content pageTitle="Top Sellers">
      {props.reportTopSellers === undefined ? (
        <Loader />
      ) : (
        <div>
          <Filter
            filter={myfilter}
            onFilter={(ev) => onFilter(ev)}
            dateList={[dateTypes.LASTMONTH, dateTypes.LAST2MONTH]}
            hideSearch
          ></Filter>
          {props.reportTopSellers[0]?.Items.length === 0 ? (
            <BlankPage description="There is no sellers." />
          ) : (
            <div>
              <div className="table-total-count">
                Total Count: {props.reportTopSellers[0]?.TotalCount}
              </div>
              <div className="table-responsive-xl p-0">
                <table
                  className="table table-centered table-nowrap table-hover mb-0 rounded table-responsive cursor-pointer"
                  id="datatable"
                >
                  <thead className="thead-light d-short border-0">
                    <tr>
                      <th className="bg-transparent border-0 ">Product Sku</th>
                      <th className="bg-transparent border-0 ">Product Name</th>
                      <th className="bg-transparent border-0 ">Sale Count</th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.reportTopSellers.length > 0 ? (
                      props.reportTopSellers[0].Items.map((item, index) => {
                        return (
                          <tr
                            className="rounded-1 mb-1 align-middle border-bottom"
                            key={index}
                          >
                            <td className="ps-0">{item.ProductSku}</td>
                            <td className="ps-0">{item.ProductName}</td>
                            <td className="ps-0">{item.SaleCount}</td>
                          </tr>
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </tbody>
                </table>
              </div>
              <Pager
                pageIndex={props.reportTopSellers[0]?.PageIndex}
                totalPages={props.reportTopSellers[0]?.TotalPages}
                totalCount={props.reportTopSellers[0]?.TotalCount}
                onChange={(pageindex) => onChangePageIndex(pageindex)}
              />
            </div>
          )}
        </div>
      )}
    </Content>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(TopSellers);
