import React from "react";
import { Card } from "react-bootstrap";
import RegionTable from "../RegionTable";

const PromotionCard = (props) => {
  return (
    <Card
      className={"promotion-card " + (props.selected ? " selected" : "")}
      onClick={(e) => {
        if (props.changePrice !== undefined)
          props.changePrice(props.item.Id, !props.selected);
      }}
    >
      {props.region && (
        <Card.Title>
          <input
            className="form-check-input"
            type="checkbox"
            value={props.item.Id}
            checked={props.selected}
            onChange={(e) => {}}
          />
          <label className="mb-4">Region {props.id}</label>
          <div className="d-flex flex-row justify-content-between">
            <div className="d-flex flex-column">
              <div className="currency-header">
                <span className="mb-4">Currency</span>
              </div>
              <div className="currency">
                <span>{props.item.OriginalCurrency}</span>
              </div>
            </div>
            <div className="d-flex flex-column">
              <div className="currency-header">
                <span className="mb-4">Cost(€)</span>
              </div>
              <div className="currency">
                <span>{Math.round(props.item.Cost * 100) / 100}</span>
              </div>
            </div>
            <div className="d-flex flex-column">
              <div className="currency-header">
                <span className="mb-4">Sales Price</span>
              </div>
              <div className="currency">
                <span>{Math.round(props.item.Price * 100) / 100}</span>
              </div>
            </div>
          </div>
          <div className="countries-header">
            <span className="mb-4">Countries</span>
          </div>
          <div className="countries">
            <RegionTable regions={props.region.regions} size={3} />
          </div>
        </Card.Title>
      )}
    </Card>
  );
};

export default PromotionCard;
