import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ToastMessages(props) {
  return (
    <ToastContainer
      position="bottom-right"
      hideProgressBar
      newestOnTop
      pauseOnHover
      autoClose={props.autoClose}
    />
  );
}

export default ToastMessages;
