import React from "react";
import { Icons } from "../../constants/icons";
import MerModal from "./MerModal";

const ConfirmModel = (props) => {
  return (
    <MerModal
      id="order-confirm"
      showModal={props.showModal}
      ariaLabelledby="contained-modal-title-vcenter"
      onHide={() => props.onHide()}
    >
      <div className="order-confirm-modal">
        <div className="order-confirm-icon">
          <img src={Icons.maskIcon}></img>
        </div>
        <div className="order-confirm-title">
          <label>
            {props.confirmFields ? props.confirmFields.Title : "Are you sure?"}
          </label>
        </div>
        <div className="order-confirm-description">
          <span>If you close this popup, there will be no change</span>
        </div>
        <div>
          <button
            className="order-confirm-yes-button "
            onClick={() => props.callback()}
          >
            <img
              className="me-2"
              width={30}
              height={30}
              src={Icons.thickIcon}
            />
            {props.confirmFields
              ? props.confirmFields.Title
              : " Yes, I am sure"}
          </button>

          <button
            className="order-confirm-no-button"
            onClick={() => props.onHide()}
          >
            <img
              className="me-2"
              width={30}
              height={30}
              src={Icons.leftArrowIcon}
            />
            Go Back
          </button>
        </div>
      </div>
    </MerModal>
  );
};

export default ConfirmModel;
