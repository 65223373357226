import React from "react";
import { connect } from "react-redux";
import NumberFormatter from "../../../../../components/common/NumberFormatter";
import { getFontSize, OrderStatus } from "../../../../../constants/defaults";
import { getDemandedOrder } from "../../../../../functions/orderValues";
import moment from "moment";
const mapStateToProps = (state) => {
  return { order: state.order, common: state.common };
};

const ManagementOrderModalRightSide = (props) => {
  const {
    selected_all_offers,
    managementDemandQuantity,
    managementDemandPrice,
  } = props.order;

  const isOfferPrice =
    managementDemandPrice === undefined ||
    managementDemandPrice === null ||
    managementDemandPrice === "" ||
    managementDemandPrice === 0 ||
    Number.isNaN(managementDemandPrice);

  const isOfferQuantity =
    managementDemandQuantity === undefined ||
    managementDemandQuantity === null ||
    managementDemandQuantity === "" ||
    managementDemandQuantity === 0 ||
    Number.isNaN(managementDemandQuantity);

  const demandedOrder = getDemandedOrder(
    props.order.selected_all_offers?.History,
    false
  );

  return (
    <div className="modal-right-side">
      {props.order.selected_all_offers?.Status === OrderStatus.Processing && (
        <p className="completion-time">
          Estimated Completion Time:
          {Math.floor(
            new Date(props.order.selected_all_offers.EstimatedFinishDate) / 100
          ) > 0
            ? moment(
                props.order.selected_all_offers.EstimatedFinishDate
              ).format(" DD-MM-YYYY H:mm:ss")
            : 0}
        </p>
      )}
      <div className="price1">
        <label>List Price</label>
        <div
          className="total"
          style={{
            fontSize:
              props.common?.device_info.width > 768 &&
              getFontSize({
                textLength:
                  (
                    selected_all_offers?.Product?.VoiduPromotion.Price *
                    (isOfferQuantity
                      ? demandedOrder?.OfferQuantity
                      : managementDemandQuantity)
                  )
                    .toFixed(2)
                    .toString().length + 4,
                minSize: props.common?.device_info.width > 1400 ? 1.15 : 1.9,
                maxSize: props.common?.device_info.width > 1400 ? 1.4 : 1.75,
              }),
          }}
        >
          {isOfferQuantity ? (
            <NumberFormatter
              offerPrice={demandedOrder?.OfferQuantity}
              offerQuantity={selected_all_offers.Product?.VoiduPromotion.Price}
              supVisible
            />
          ) : (
            <NumberFormatter
              offerPrice={selected_all_offers?.Product?.VoiduPromotion.Price}
              offerQuantity={managementDemandQuantity}
              supVisible
            />
          )}
        </div>
        <div className="multiply">
          {isOfferQuantity
            ? demandedOrder?.OfferQuantity
            : managementDemandQuantity}
          {" x "}
          {
            <NumberFormatter
              offerPrice={selected_all_offers?.Product?.VoiduPromotion.Price}
              supVisible
            />
          }
        </div>
      </div>
      {managementDemandPrice === undefined ||
      managementDemandQuantity === undefined ? (
        <div className="price2">
          <label>Salesteam offered:</label>
          <div className="no-demand-label">
            <label>There's no demand yet.</label>
          </div>
        </div>
      ) : (
        <div className="price2">
          <label>Salesteam offered:</label>
          <div
            className="total"
            style={{
              fontSize:
                props.common?.device_info.width > 768 &&
                getFontSize({
                  textLength:
                    (
                      (isOfferPrice ? 0 : managementDemandPrice) *
                      (isOfferQuantity ? 0 : managementDemandQuantity)
                    )
                      .toFixed(2)
                      .toString().length + 4,
                  minSize: props.common?.device_info.width > 1400 ? 1.15 : 1.9,
                  maxSize: props.common?.device_info.width > 1400 ? 1.4 : 1.75,
                }),
            }}
          >
            {isOfferPrice || isOfferQuantity ? (
              <NumberFormatter offerPrice={0} offerQuantity={0} supVisible />
            ) : (
              <NumberFormatter
                offerPrice={managementDemandPrice}
                offerQuantity={managementDemandQuantity}
                supVisible
              />
            )}
          </div>
          <div className="multiply">
            {isOfferQuantity ? "0" : managementDemandQuantity}
            {" x "}
            {isOfferPrice ? (
              <NumberFormatter offerPrice={0} supVisible />
            ) : (
              <NumberFormatter offerPrice={managementDemandPrice} supVisible />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default connect(mapStateToProps)(ManagementOrderModalRightSide);
