import { SELECT_PRODUCT } from "../constants/actionTypes";

const defaultState = {
  selectedProduct: undefined,
  product: undefined,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case SELECT_PRODUCT:
      return {
        ...state,
        selectedProduct: action.selectedProduct,
      };
  }
  return state;
};
