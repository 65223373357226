import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

const Button = ({
  disabled = false,
  hidden,
  onClick,
  size,
  title,
  theme,
  type,
  ...props
}) => {
  if (hidden) return null;

  return (
    <button
      {...props}
      disabled={disabled}
      className={classNames(
        styles.container,
        propStyles.type[type]?.container,
        propStyles.theme[theme]?.container,
        propStyles.size[size]?.container,
        disabled && "disabled"
      )}
      style={{
        borderRadius: "0.5rem",
      }}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        onClick?.();
      }}
    >
      <span
        className={classNames(
          styles.title,
          propStyles.type[type]?.title,
          propStyles.theme[theme]?.title,
          propStyles.size[size]?.title
        )}
      >
        {title}
      </span>
    </button>
  );
};

const propStyles = {
  type: {
    button: {
      container: "py-2 border",
    },
    link: {
      container: "p-0 bg-transparent border-0",
    },
  },
  theme: {
    primary: {
      container: "bg-primary text-white",
    },
    secondary: {
      container: "bg-secondary text-white",
    },
    outline: {
      container: "bg-transparent text-black",
    },
    success: {
      container: "bg-success text-white",
    },
    danger: {
      container: "btn-danger text-white",
    },
    warning: {
      container: "bg-warning text-black",
    },
    info: {
      container: "bg-info text-white",
    },
    light: {
      container: "bg-light text-black",
    },
    dark: {
      container: "bg-dark text-white",
    },
    "link-danger": {
      container: "text-danger",
    },
  },
  size: {
    default: {
      container: "px-2 py-2",
    },
    "flex-1": {
      container: "flex-1",
    },
    "w-1/4": {
      container: "w-25",
    },
    "w-1/2": {
      container: "w-50",
    },
    "w-full": {
      container: "w-100",
    },
  },
};

const styles = {
  container:
    "button-v2 d-flex justify-content-center align-items-center cursor-pointer hover-overlay",
  title: "m-0 h6",
};

Button.defaultProps = {
  onClick: () => null,
  size: "flex-1",
  theme: "primary",
  type: "button",
};

Button.propTypes = {
  onClick: PropTypes.func,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  theme: PropTypes.oneOf([
    "primary",
    "secondary",
    "outline",
    "success",
    "danger",
    "warning",
    "info",
    "light",
    "dark",
    "link-danger",
  ]),
  type: PropTypes.oneOf(["button", "link"]),
  size: PropTypes.oneOf(["default", "flex-1", "w-1/4", "w-1/2", "w-full"]),
};

export default Button;
