import React, { useEffect, useState } from "react";
import Button from "../../../../../components/common/Button";
import Loader from "../../../../../components/Loader";
import { Icons } from "../../../../../constants/icons";
import { connect } from "react-redux";
import { REDIRECT } from "../../../../../constants/actionTypes";
import { Endpoints } from "../../../../../constants/endpoints";

const mapStateToProps = (state) => {
  return {
    redirectTo: state.common.redirectTo,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onRedirect: (redirectTo) => dispatch({ type: REDIRECT, redirectTo }),
});

function PublishFinish(props) {
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  const goToPromotion = () => {
    props.onRedirect(
      Endpoints.Management.Promotions.url
        .replace(":" + Endpoints.Management.Promotions.url.split(":"))
        .replace("/:filter?", "")
        .replace("/:sort?", "")
        .replace("/:page?", "")
    );
  };

  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="d-flex justify-content-center mt-5">
            <div className="text-center">
              <img src={Icons.publishFinishedIcon} />
              <p className="finish-Promotion mt-4">
                Your promotion has been published successfully!
              </p>
              <p className="finish-Promotion-note">
                You can check out the special promotions for you of Merkur
                games.
              </p>
              <Button
                className="new-promotion-finish-button"
                bindEvent={() => goToPromotion()}
                label="See Promotions"
                buttonIcon={Icons.rightArrowIcon}
                iconAlign="right"
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(PublishFinish);
