import React, { useState } from "react";
import Button from "../../../../components/common/Button";
import NumberInput from "../../../../components/common/NumberInput";
import { toast } from "react-toastify";
import { Icons } from "../../../../constants/icons";
import { CHANGE_ALL_MY_OFFERS_DETAILS } from "../../../../constants/actionTypes";
import { connect } from "react-redux";
import services from "../../../../api/index";
import Loader from "../../../../components/Loader";
import settings from "../../../../settings.json";

const SYSTEM_PARTNER_ID = settings["SYSTEM_PARTNER_ID"];
const mapStateToProps = (state) => {
  return {
    InventoryCheckResult: state.order.InventoryCheckResult,
    SelectedInventory: state.order.SelectedInventory,
  };
};

const mapDispatchToProps = (dispatch) => ({
  InventoriesLoad: (payload) => {
    dispatch({ type: CHANGE_ALL_MY_OFFERS_DETAILS, payload });
  },
  InventoryCheck: (value) => {
    dispatch({
      type: CHANGE_ALL_MY_OFFERS_DETAILS,
      key: "InventoryCheckResult",
      value,
    });
  },
});

function CreditNoteModal(props) {
  const [reason, setReason] = useState("");
  return (
    <div className="container create-package-modal">
      <div className="d-flex my-4 mx-2 flex-column justify-content-start">
        <div className="go-back">
          <img src={Icons.goBackIcon} />
          <Button
            className="go-back-button"
            bindEvent={() => props.handleClose()}
            label="Go back"
          />
        </div>
        <div className="create-package-header">
          <span>Create Credit Note</span>
        </div>

        <div className="form-group">
          <label for="exampleFormControlTextarea1">Reason:</label>
          <textarea
            className="form-control"
            rows="3"
            onChange={(ev) => setReason(ev.target.value)}
          >
            {reason}
          </textarea>
        </div>

        <Button
          bindEvent={() => {
            services.invoice
              .createCreditMemo(props.selectedInvoice.Id, reason)
              .then((res) => {
                props.handleClose();
                toast.success(<div>Credit Memo was successfully created.</div>);
                return res;
              })
              .catch((err) => {
                if (err.response?.data?.message)
                  toast.error(<div>{err.response?.data.message}</div>);
                return undefined;
              });
          }}
          className="create-button mt-3"
          label="Create Credit Memo"
          buttonIcon={Icons.bigThickIcon}
          iconWidth={20}
          iconHeight={20}
        />
      </div>
    </div>
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(CreditNoteModal);
