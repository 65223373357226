import React from "react";

class RegionContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      region_country: this.groupBy(this.props.region_country, "region"),
    };
  }

  // eslint-disable-next-line class-methods-use-this
  groupBy(array, key) {
    return array.reduce((result, currentValue) => {
      (result[currentValue[key]] = result[currentValue[key]] || []).push(
        currentValue
      );
      return result;
    }, {});
  }

  render() {
    const keys = Object.keys(this.state.region_country);

    return (
      <div>
        {keys.map((key, keyIndex, arr) => {
          return (
            <div className="container" key={keyIndex}>
              <div className="row mb-3">
                <h5 className="m-0">{key}</h5>
                <div className="col order-1">
                  <div className="row">
                    {this.state.region_country[key].map(
                      (item, countryKeyIndex) => {
                        return (
                          <div className="col-6">
                            <div
                              key={countryKeyIndex}
                              className="d-inline-block pe-3 m-1 badge-md rounded-2 bg-gray-200"
                            >
                              <div
                                className={
                                  "flag-icon mr-2 flag-icon-" +
                                  item.alpha2.toLowerCase()
                                }
                              ></div>{" "}
                              {item.name}
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}
export default RegionContent;
