import React, { useEffect, useMemo, useState } from "react";
import api from "../../../../api";
import { toast } from "react-toastify";
import Loader from "../../../../components/Loader";

const PriceModalBody = ({ data, loading }) => {
  const { Prices } = data || {};

  const prices = useMemo(() => {
    return Prices?.sort((a, b) => a.Cost - b.Cost);
  }, [Prices]);

  if (loading) {
    return <span className="order-detail-table-no-item">Loading...</span>;
  }

  if (!data) {
    <span className="order-detail-table-no-item">Product does not exist.</span>;
  }

  if (!prices?.length) {
    <span className="order-detail-table-no-item">
      There is no available price.
    </span>;
  }

  return (
    <>
      {prices.map((e, i) => {
        return (
          <tr key={i}>
            <td>
              <p>{e.OriginalCurrency ?? "-"}</p>
            </td>
            <td>
              <p>{e.OriginalCost + "(" + e.OriginalCurrency + ")" ?? "-"}</p>
            </td>
            <td>
              <p>{e.OriginalPrice + "(" + e.OriginalCurrency + ")" ?? "-"}</p>
            </td>
            <td>
              <p>{e.Cost ?? "-"}</p>
            </td>
            <td>
              <p>{e.OldPrice ?? "-"}</p>
            </td>
            <td>
              <p>{e.Price ?? "-"}</p>
            </td>
            <td>
              <p>{e.DiscountRate ?? "-"}</p>
            </td>
            <td>
              <p>{data.ValidFrom ?? "-"}</p>
            </td>
            <td>
              <p>{data.ValidTo ?? "-"}</p>
            </td>
          </tr>
        );
      })}
    </>
  );
};

const PriceModal = ({ selectedProduct }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const result = await api.product.getById({ Id: selectedProduct.Id });
        setData(result);
      } catch (err) {
        toast.error(
          <div>
            {err.response?.data.message || err.message || "Unknown error"}
          </div>
        );
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [selectedProduct.Id]);

  return (
    <div className="table-responsive-xl px-5 py-4 h-100">
      <table className="table table-centered table-nowrap table-hover mb-0 rounded table-responsive cursor-pointer card-table">
        <thead>
          <tr>
            <th>
              <p>Region</p>
            </th>
            <th>
              <p>Local Cost</p>
            </th>
            <th>
              <p>Local Price</p>
            </th>
            <th>
              <p>Cost (€)</p>
            </th>
            <th>
              <p>List Price (€)</p>
            </th>
            <th>
              <p>Sale Price (€)</p>
            </th>
            <th>
              <p>Discount Rate</p>
            </th>
            <th>
              <p>Start</p>
            </th>
            <th>
              <p>End</p>
            </th>
          </tr>
        </thead>
        <tbody>
          <PriceModalBody data={data} loading={loading} />
        </tbody>
      </table>
    </div>
  );
};

export default PriceModal;
