import React, { useEffect, useState } from "react";
import Content from "../../components/containers/Content";
import services from "../../api/index";
import Loader from "../../components/Loader";
import Filter from "../../components/Filter";
import { dateTypes } from "../../constants/defaults";
import {
  PARTNER_INVIOCE_PAGE_LOADED,
  REDIRECT,
} from "../../constants/actionTypes";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import Pager from "../../components/Pager";
import BlankPage from "../../components/BlankPage";
import { getSorting } from "../../functions/FilterFunctions";
import base64 from "base-64";
import { Endpoints } from "../../constants/endpoints";
import { Icons } from "../../constants/icons";
import moment from "moment";
import JsFileDownloader from "js-file-downloader";
import { getLocalAccessToken } from "../../api/services/token";
import settings from "../../settings.json";
import CheckPermission, { StaticPermission } from "../../constants/permission";

const mapStateToProps = (state) => {
  return {
    partner_invoice: state.invoice.partner_invoice,
    mypermission: state.permission.mypermission,
    pageSize: state.common.pageSize,
  };
};

const mapDispatchToProps = (dispatch) => ({
  PartnerInvoiceOnLoad: (payload) => {
    dispatch({ type: PARTNER_INVIOCE_PAGE_LOADED, payload });
  },
  onRedirect: (redirectTo) => dispatch({ type: REDIRECT, redirectTo }),
});

const MyInvoices = (props) => {
  const [isLoading, setLoading] = useState(false);
  const [pgeIndex, setPgeIndex] = useState(0);
  const [myFilter, setFilter] = useState(
    props.match.params.filter
      ? JSON.parse(base64.decode(props.match.params.filter))
      : null
  );
  const [sortby, setSortBy] = useState(null);
  const [sortTypeDate, setSortTypeDate] = useState("down");

  useEffect(() => {
    let ev = myFilter;

    getAllPartnerInvoice({
      keywords: ev?.Text,
      pageindex: pgeIndex,
      pagesize: props.pageSize,
      StartDate: ev?.DateRange?.min,
      FinishDate: ev?.DateRange?.max,
      Statuses: "",
      MinPrice: ev?.Price?.down,
      MaxPrice: ev?.Price?.up,
      CustomerIds: ev?.PartnerId,
      SortBy: sortby,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    onFilter({ pageindex: 0 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.pageSize]);

  const getAllPartnerInvoice = (params) => {
    setLoading(true);
    const {
      keywords = "",
      pageindex = pgeIndex,
      pagesize = props.pageSize,
      StartDate = "",
      FinishDate = "",
      Statuses = "",
      MinPrice = "",
      MaxPrice = "",
      CustomerIds = "",
      SortBy = "",
    } = params || {};
    setPgeIndex(pageindex);
    props.PartnerInvoiceOnLoad(
      Promise.all([
        services.invoice.getAll(
          keywords,
          pageindex,
          pagesize,
          StartDate,
          FinishDate,
          Statuses,
          MinPrice,
          MaxPrice,
          CustomerIds,
          SortBy
        ),
      ])
        .then((res) => {
          setLoading(false);
          return res;
        })
        .catch((err) => {
          if (err.response?.data?.message)
            toast.error(<div>{err.response?.data.message}</div>);
          return undefined;
        })
    );
  };

  const onChangePageIndex = (pageindex) => {
    onFilter({ pageindex: pageindex });
  };

  const onFilter = (params) => {
    let { filter, sortBy = undefined, pageindex = 0 } = params || {};
    let ev = filter;
    if (ev !== undefined) setFilter(ev);
    else ev = myFilter;
    sortBy = getSorting(sortBy, sortby);
    setSortBy(sortBy);

    let url = Endpoints.Partner.MyInvoices.url
      .replace(":filter?", base64.encode(ev ? JSON.stringify(ev) : "0"))
      .replace(":sort?", sortBy ? sortBy : "0")
      .replace(":page?", pageindex);
    props.onRedirect(url);

    getAllPartnerInvoice({
      keywords: ev?.Text,
      pageindex: pageindex,
      pagesize: props.pageSize,
      StartDate: ev?.DateRange?.min,
      FinishDate: ev?.DateRange?.max,
      Statuses: "",
      MinPrice: ev?.Price?.down,
      MaxPrice: ev?.Price?.up,
      CustomerIds: ev?.PartnerId,
      SortBy: sortBy,
    });
  };

  const downloadFile = (invoiceid, filename) => {
    const token = getLocalAccessToken();
    let download = new JsFileDownloader({
      url: `${settings.API_ROOT}/invoice/me/downloadpdf/${invoiceid}`,
      headers: [{ name: "Authorization", value: "Bearer " + token }],
      autoStart: false,
      nameCallback: () => {
        return filename;
      },
    });

    download
      .start()
      .then(() => {})
      .catch(function (error) {
        // handle errors
      });
  };

  return (
    <Content pageTitle="My Invoices">
      {props.partner_invoice === undefined ? (
        <Loader />
      ) : (
        <div>
          <Filter
            filter={myFilter}
            onFilter={(ev) => onFilter(ev)}
            priceList={[
              { down: undefined, up: 25 },
              { down: 25, up: 50 },
              { down: 50, up: 100 },
              { down: 100, up: 200 },
              { down: 300, up: undefined },
            ]}
            dateList={[
              dateTypes.ALLTIME,
              dateTypes.THISWEEK,
              dateTypes.THISMONTH,
              dateTypes.LAST2MONTH,
            ]}
          ></Filter>
          {isLoading ? (
            <Loader />
          ) : props.partner_invoice[0]?.Items?.length === 0 ? (
            <BlankPage description="There is no invoice." />
          ) : (
            <div>
              <div className="table-responsive-xl p-0">
                <table
                  className="table table-centered table-nowrap table-hover mb-0 rounded table-responsive cursor-pointer"
                  id="datatable"
                >
                  <thead className="thead-light d-short border-0">
                    <tr>
                      <th className="bg-transparent border-0 ">Invoice No</th>
                      <th className="bg-transparent border-0 ">Order No</th>
                      <th className="bg-transparent border-0 ">Invoice Id</th>
                      <th
                        className="bg-transparent border-0 "
                        onClick={() => {
                          setSortTypeDate(
                            sortTypeDate === "up" ? "down" : "up"
                          );
                          onFilter({
                            sortBy: "CreatedAt",
                          });
                        }}
                      >
                        Date
                        <i
                          className={`fas fa-sort-amount-${sortTypeDate} px-2`}
                        ></i>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.partner_invoice.length > 0 ? (
                      props.partner_invoice[0].Items.map((item, index) => {
                        return (
                          <tr
                            className="rounded-1 mb-1 align-middle border-bottom"
                            key={index}
                          >
                            <td className="ps-0">{item.InvoiceNumber}</td>
                            <td className="ps-0">
                              <div className="">{item.OrderNumber}</div>
                            </td>
                            <td className="ps-0">
                              {item.Accounting.ExternalInvoiceId}

                              {CheckPermission(
                                StaticPermission.Invoice.Download
                              ) && (
                                <>
                                  <a
                                    className="px-1"
                                    onClick={() => {
                                      downloadFile(item.Id, item.Filename);
                                    }}
                                    hidden={
                                      item.Filename === null ||
                                      item.ExistPdf === false
                                    }
                                  >
                                    <img
                                      className="mb-1"
                                      src={Icons.pdfIcon}
                                      style={{ height: "20px" }}
                                    />
                                  </a>
                                </>
                              )}
                            </td>
                            <td className="ps-0">
                              {moment
                                .utc(item.CreatedAt)
                                .format("DD/MM/YYYY - HH:mm:ss")}{" "}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </tbody>
                </table>
              </div>

              <Pager
                pageIndex={props.partner_invoice[0]?.PageIndex}
                totalPages={props.partner_invoice[0]?.TotalPages}
                totalCount={props.partner_invoice[0]?.TotalCount}
                onChange={(pageIndex) => onChangePageIndex(pageIndex)}
              />
            </div>
          )}
        </div>
      )}
    </Content>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MyInvoices);
